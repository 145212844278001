import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { AIServicesInstance } from '../AIServices';
import { AIChatDraftReactionParams } from '../types';

export const useAIChatDraftReaction = <RCN = undefined>(
  configs?: MakeRequestHookConfig<AIChatDraftReactionParams, GetOptions, RCN>,
) => {
  const f = AIServicesInstance.AIChatDraftReaction;
  const baseConfigs: UseRequestFactory<
    AIChatDraftReactionParams,
    string,
    typeof f,
    GetOptions,
    string
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof f>) => (
      AIServicesInstance.AIChatDraftReaction(...args)
    ),
    ResponseClassCreator: AIServicesInstance.getResponse,
    typeName: 'AIChatDraftReaction',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

import { Form, Tree, TreeProps } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { Key, useMemo, useState } from 'react';
import { ROLE_ENUM } from '../../../../../types/roles';
import { useCareOperationContext } from '../../../careOperationHierarchy/context/CareOperationContext/CareOperationContext';
import { ExpandAllButton } from '../../../../../uiComponent/ExpandAllButton/ExpandAllButton';
import './UserRoleHierarchyComponent.scss';
import { HierarchyNode, RenderHierarchyNode } from '../../../careOperationHierarchy/service/UcOrganizationService';
import { CareUnitIconComponent } from '../../../careOperationHierarchy/component/CareUnitIconComponent/CareUnitIconComponent';

export interface CareCenterLevelComponentProps extends TreeProps {
  name: ROLE_ENUM;
  roleLabel: string;
  disabledIds?: string[];
}

export const UserRoleHierarchyComponent = ({
  name,
  roleLabel,
  disabledIds,
  ...props
}: CareCenterLevelComponentProps) => {
  const form = Form.useFormInstance();
  const [currentIds, setCurrentIds] = useState<Key[]>(props.checkedKeys as string[] || []);
  const { ucOrgService } = useCareOperationContext();
  const data = ucOrgService.getTreeData(disabledIds);

  const processTreeData = (
    treeData: HierarchyNode[]
  ): RenderHierarchyNode[] => (
    treeData.map((node): RenderHierarchyNode => {
      const title = (<div>{node.title}</div>);
      const icon = <CareUnitIconComponent value={node.type} />;
      const children = node.children ? processTreeData(node.children) : [];

      return {
        ...node,
        key: node.id,
        title,
        icon,
        children,
      };
    })
  );

  const processedTreeData = useMemo(() => (
    processTreeData(data)
  ), [data]);

  const handleChange: TreeProps['onCheck'] = (
    values
  ) => {
    const checkedValues = Array.isArray(values) ? values : values.checked;
    setCurrentIds(checkedValues);
    form.setFieldValue(name, checkedValues);
  };

  return (
    <ExpandAllButton className="user-role-hierarchy-expand" data={data} defaultExpandedKeys={props.checkedKeys as string[] || []}>
      {({ onExpand, keys }) => (
        <div className="mt10 px20 py10 border default-br">
          <div>
            <p className="text-gray-scale-2 fs12">
              {`Select care units for this user to be added as a ${roleLabel}`}
              <span className="warning">*</span>
            </p>
            <div className="user-role-hierarchy-tree w100">
              <Tree
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onExpand={onExpand}
                expandedKeys={keys}
                autoExpandParent
                className="department-tree bg-transparent"
                treeData={processedTreeData as DataNode[]}
                checkable
                showIcon
                defaultExpandedKeys={props.checkedKeys as string[] || []}
                onCheck={handleChange}
                checkedKeys={currentIds}
                checkStrictly
                selectable={false}
              />
            </div>
          </div>
        </div>
      )}
    </ExpandAllButton>
  );
};

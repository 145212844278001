import { ProgramCategoryEnum, useTemplateGroupConsentForms } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { SelectType } from '../../../Input/types';
import { MedicalOrgConsentFormSelectComponent } from '../../component/MedicalOrgConsentFormSelectComponent/MedicalOrgConsentFormSelectComponent';
import { ProgramsCommonWrapperComponent } from '../../component/ProgramsCommonWrapperComponent/ProgramsCommonWrapperComponent';
import './MedicalOrgConsentFormContainer.scss';

export interface MedicalOrgConsentFormContainerProps extends SelectType<string> {
  programs?: ProgramCategoryEnum[];
}

export const MedicalOrgConsentFormContainer = ({
  programs,
  ...props
}: MedicalOrgConsentFormContainerProps) => {
  const forms = useTemplateGroupConsentForms({
    params: {
      searchRequest: {
        filter: {
          programCategoryIn: {
            in: programs
          }
        }
      }
    },
    options: {
      sendOnMount: true,
    }
  });

  return (
    <FetchComponent info={forms} alwaysShowChildren>
      {(value) => (value?.rawValue?.data?.content ? (
        <ProgramsCommonWrapperComponent title="Consent Form">
          <span className="fs12i required">
            Consent Form
            {' '}
          </span>
          <MedicalOrgConsentFormSelectComponent
            consentForms={value?.rawValue?.data?.content}
                // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        </ProgramsCommonWrapperComponent>
      ) : null)}
    </FetchComponent>
  );
};

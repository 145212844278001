import {
  Select, SelectProps, TreeSelect, TreeSelectProps
} from 'antd';
import { find, findIndex, intersection } from 'lodash';
import { RenderDOMFunc } from 'rc-select/lib/interface';
import { ReactNode, useCallback } from 'react';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import { PORTAL_USER_FILTER, useCoworker, UseCoworkerHookOptions } from '../../hooks/useCoworker/useCoworker';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import { Employee } from '../../types/user';
import { RoleTypeEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import './CoWorkerDropdownComponent.scss';

interface OptionLabelRendererProps extends EmployeeInfo { }
interface ChildrenProps {
  isLoading: boolean;
  data?: EmployeeInfo[];
}

export interface CoWorkerDropdownComponentProps extends Omit<TreeSelectProps, 'onChange'> {
  searchable?: boolean;
  sortByName?: boolean;
  filterRoles?: RoleTypeEnum[];
  filterOrganizationIds?: string[];
  clinicIdList?: string[];
  optionLabelRenderer?: (props: OptionLabelRendererProps) => ReactNode;
  onChange?: (value: string, coworkerData?: Employee) => void;
  children?: (props: ChildrenProps) => ReactNode;
  needToFilter?: boolean;
  internalEmployeeForPatientId?: string;
  externalEmployeeForPatientId?: string;
  internalEmployeeForMyMedicalOrg?: boolean;
  addCurrentUserToOption?: boolean;
  reassignAssigneesClinicIdList?: string[];
  allowClear?: boolean;
  unassignedOnly?: boolean;
  mode?: 'multiple' | 'tags',
  isSelectedPatient?: boolean;
  assignedMembers?: string[];
  includeUnassigned?: boolean;
  fullNameRenderer?: (props: EmployeeInfo) => ReactNode;
  containerId?: string;
  statusListFilter?: UseCoworkerHookOptions['statusListFilter'];
  pagination?: boolean;
  pageSize?: number;
}

export const CoWorkerDropdownComponent = ({
  searchable = true,
  sortByName = true,
  filterRoles = PORTAL_USER_FILTER,
  filterOrganizationIds,
  clinicIdList,
  optionLabelRenderer,
  onChange,
  children,
  needToFilter,
  mode,
  internalEmployeeForPatientId,
  externalEmployeeForPatientId,
  internalEmployeeForMyMedicalOrg,
  addCurrentUserToOption,
  reassignAssigneesClinicIdList,
  allowClear,
  unassignedOnly,
  isSelectedPatient,
  assignedMembers,
  loading,
  includeUnassigned,
  tagRender,
  fullNameRenderer,
  statusListFilter,
  pagination,
  pageSize,
  ...treeSelectProps
}: CoWorkerDropdownComponentProps) => {
  const {
    isLoading,
    myCoWorkerList,
  } = useCoworker({
    roleFilter: filterRoles,
    organizationIdFilter: filterOrganizationIds,
    clinicIdList,
    sortByName,
    needToFilter,
    internalEmployeeForPatientId,
    externalEmployeeForPatientId,
    internalEmployeeForMyMedicalOrg,
    reassignAssigneesClinicIdList,
    statusListFilter,
    pagination,
    pageSize,
  });
  const { userInfo } = useLoggedInUserFromContext();

  const renderNodes = useCallback((
    coworkerList: EmployeeInfo[],
  ) => {
    const processedCoworkerList = [...coworkerList];
    if (
      addCurrentUserToOption
      && userInfo
      && intersection(filterRoles, userInfo.allRoleTypes).length
    ) {
      const userIndex = findIndex(processedCoworkerList, { id: userInfo.id });
      if (userIndex === -1) {
        // add user to the top
        processedCoworkerList.unshift(userInfo);
      } else {
        // move user to the top
        const currentUserInfo = processedCoworkerList.splice(userIndex, 1);
        processedCoworkerList.unshift(currentUserInfo[0]);
      }
    }

    // add unassigned option
    if (unassignedOnly && isSelectedPatient) {
      const unassignedEmployeeInfo: Partial<EmployeeInfo> = {
        id: 'unassign',
        fullNameWithAllRoles: 'Unassign',
      };

      processedCoworkerList.unshift(unassignedEmployeeInfo as EmployeeInfo);
    }

    const nodeList = processedCoworkerList.map((cw) => {
      const key = cw.id;
      return key !== 'invalid'
        ? (
          <TreeSelect.TreeNode
            key={key}
            value={key}
            title={(
              optionLabelRenderer?.(cw)
              || (
                <DisplayOrEmptyComponent
                  value={cw.fullNameWithAllRoles}
                />
              )
            )}
            fullName={cw.fullNameWithAllRoles}
            fullNameTag={fullNameRenderer?.(cw)}
          />
        ) : null;
    });
    if (includeUnassigned) {
      return (
        <>
          <TreeSelect.TreeNode value="unassigned" key="unassigned" title="Unassigned" />
          {nodeList}
        </>
      );
    }
    return nodeList;
  }, [optionLabelRenderer, addCurrentUserToOption, filterRoles, includeUnassigned]);

  const onChangeHelper = (v: string) => {
    const cwId = v;
    const coworkerData = find(
      myCoWorkerList,
      (e) => (e as EmployeeInfo).employeeData.id === cwId
    );
    onChange?.(v, coworkerData?.employeeData);
  };

  const handleOnChange: TreeSelectProps['onChange'] = (value) => {
    onChangeHelper(value);
  };

  const handleOnSelectChange: SelectProps['onChange'] = (value) => {
    onChangeHelper(value);
  };

  if (assignedMembers?.length) {
    const assignedMembersData = myCoWorkerList.filter((e) => assignedMembers.includes(e.id));
    const othersData = myCoWorkerList.filter((e) => !assignedMembers.includes(e.id));
    return (
      <Select
        loading={isLoading || loading}
        className="w100"
        data-testid="coworkersAssignedMembersAndOthersSelect"
        allowClear={allowClear}
        filterOption={(input, option) => (
          option?.label as string)?.toLowerCase().includes(input.toLowerCase())}
        showSearch={searchable}
        mode={mode}
        dropdownMatchSelectWidth
        onChange={handleOnSelectChange}
        options={[
          {
            label: 'Assigned members',
            options: assignedMembersData.map((cw) => ({
              label: cw.fullNameWithAllRoles,
              value: cw.id
            }))
          },
          {
            label: 'Others',
            options: othersData.map((
              cw
            ) => ({
              label: cw.fullNameWithAllRoles,
              value: cw.id
            }))
          },
        ]}
      />
    );
  }

  return (
    <div className="w100">
      {
        children?.({
          isLoading,
          data: myCoWorkerList,
        }) || (
          <TreeSelect
            loading={isLoading}
            tagRender={tagRender}
            popupClassName="coworkers-tree-select__popup employeeSearch"
            className="coworkers-tree-select w100"
            data-testid="coworkersTreeSelect"
            treeDefaultExpandAll
            multiple={!!mode}
            allowClear={allowClear}
            showSearch={searchable}
            dropdownMatchSelectWidth={false}
            filterTreeNode={(inputValue, node) => (
              (node?.fullName as string)?.search?.(new RegExp(inputValue, 'ig')) >= 0
            )}
            onChange={handleOnChange}
            getPopupContainer={
              treeSelectProps.containerId
                ? (() => document.getElementById(treeSelectProps.containerId || '')) as RenderDOMFunc : undefined
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...treeSelectProps}
          >
            {renderNodes(myCoWorkerList)}
          </TreeSelect>
        )
      }
    </div>
  );
};

import { SwitchComponent } from '../../../../../uiComponent/SwitchComponent/SwitchComponent';
import TooltipComponent from '../../../../../uiComponent/TooltipComponent/TooltipComponent';
import { ADMIN_PORTAL2_TEXTS } from '../../../constants/texts';
import { AddStaffDrawerContainer } from '../../container/AddStaffDrawerContainer/AddStaffDrawerContainer';
import { StaffTableContainer } from '../../container/StaffTableContainer/StaffTableContainer';

export interface ClinicalStaffManagementComponentProps {
  enableSharePatientAccess: (checked: boolean) => void;
  value: boolean;
  loading?: boolean;
}

export const ClinicalStaffManagementComponent = ({
  enableSharePatientAccess,
  value,
  loading,
}: ClinicalStaffManagementComponentProps) => (
  <div>
    <div className="flex jc-sb m10">
      <SwitchComponent
        value={value}
        loading={loading}
        onChange={(checked) => enableSharePatientAccess(checked)}
      >
        <TooltipComponent
          type="info-icon"
          placement="topRight"
          title={ADMIN_PORTAL2_TEXTS.tooltips.medicalTooltip}
          triggerOnHoverIcon
        >
          Enable physicians to share patient access with one another
        </TooltipComponent>
      </SwitchComponent>
      <AddStaffDrawerContainer />
    </div>
    <StaffTableContainer />
  </div>
);

import { FrequencyDisplayType } from '../../uc-api-sdk';

export interface DosageFrequencyEnumComponentProps {
  value?: FrequencyDisplayType;
  type?: 'long' | 'default';
}

const getFullName = (
  shortName: FrequencyDisplayType,
) => {
  switch (shortName) {
    case FrequencyDisplayType.QD:
      return 'Once/day';
    case FrequencyDisplayType.BID:
      return 'Twice/day';
    case FrequencyDisplayType.TID:
      return '3 times/day';
    case FrequencyDisplayType.QID:
      return '4 times/day';
    case FrequencyDisplayType.OM:
      return 'Every morning';
    case FrequencyDisplayType.ON:
      return 'Every night';
    case FrequencyDisplayType.Q6H:
      return 'Every 6 hours';
    case FrequencyDisplayType.Q8H:
      return 'Every 8 hours';
    case FrequencyDisplayType.QOD:
      return 'Every other day';
    case FrequencyDisplayType.QWK:
      return 'Every week';
    case FrequencyDisplayType.PRN:
      return 'As needed';
    case FrequencyDisplayType.Others:
      return 'Others';
    default:
      return '';
  }
};

export const getDosingFrequencyText = (
  type: DosageFrequencyEnumComponentProps['type'],
  value?: FrequencyDisplayType | null
) => {
  if (!value) {
    return '--';
  }
  switch (type) {
    case 'long': {
      const fullName = getFullName(value as FrequencyDisplayType);
      if (value === FrequencyDisplayType.Others) {
        return fullName;
      }
      return `${fullName} (${value})`;
    }
    case 'default':
    default:
      return value;
  }
};

export const DosageFrequencyEnumComponent = ({
  value,
  type = 'default',
}: DosageFrequencyEnumComponentProps) => (
  <>{getDosingFrequencyText(type, value)}</>
);

import { ColumnType } from 'antd/lib/table';
import { filter, map } from 'lodash';
import { MedicalAlertDashboardDataSource } from './MedicalAlertDashboardTableComponent';
import TextWithSubAndLinkComponent from '../../../../uiComponent/TextWithSubAndLinkComponent/TextWithSubAndLinkComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { createEmployeeSearchFilter } from '../../../table/filter/EmployeeSearchFilter';
import { PriorityLevelEnum, RoleTypeEnum, SmartAlertTaskStatus } from '../../../../uc-api-sdk';
import { PriorityLevelEnumComponent } from '../../../../enumComponent/PriorityLevelEnumComponent/PriorityLevelEnumComponent';
import { createPatientSearchFilter } from '../../../table/filter/PatientSearchFilter';
import { MedicalAlertNameComponent } from '../MedicalAlertNameComponent/MedicalAlertNameComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { SmartAlertTaskStatusEnumComponent } from '../../../../enumComponent/SmartAlertTaskStatusComponent/SmartAlertTaskStatusComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export const createColumns = (clinicId: string) => ({
  memberName: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Patient',
    dataIndex: 'memberName',
    key: 'memberName',
    width: '10%',
    render: (value, d) => (
      <div className="link-color">
        <TextWithSubAndLinkComponent
          text={<DisplayOrEmptyComponent value={d.memberName} />}
          link={`/care-portal/patients/${d.memberId}?outstanding=ALERTS&tab=medicalHistory`}
        />
      </div>
    ),
    ...createPatientSearchFilter({ selectedOrgIds: clinicId ? [clinicId] : undefined }),
  }),
  alerts: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Alerts',
    dataIndex: 'alertTypes',
    key: 'alerts',
    width: 370,
    filters: map(filter(PriorityLevelEnum, it => it !== PriorityLevelEnum.level), (value) => ({
      text: (<PriorityLevelEnumComponent value={value} type="full" />),
      value,
    })),
    onFilter: (value, record) => record.alerts.some((alert) => alert.priority === value),
    render: (value, d) => (
      <div>
        {map(d.alerts, (alert, index) => (
          <div key={`${index}-${alert.alertName}-${alert.priority}-${alert.reason}`} className="alert-tag">
            <MedicalAlertNameComponent
              alertName={alert.alertName}
              priority={alert.priority}
              alertReason={alert.reason}
            />
          </div>
        ))}
      </div>
    ),
  }),
  provider: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    width: '10%',
    ...createEmployeeSearchFilter([RoleTypeEnum.PROVIDER], undefined, true, clinicId, clinicId),
  }),
  assignedRdHC: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Assigned RD/HC',
    dataIndex: 'assignedRdHC',
    width: '10%',
    key: 'assignedRdHC',
    ...createEmployeeSearchFilter([
      RoleTypeEnum.RD, RoleTypeEnum.HC
    ], undefined, false, undefined, clinicId),
  }),
  triggeredOn: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Triggered On',
    dataIndex: 'triggeredOn',
    width: '15%',
    key: 'triggeredOn',
    sorter: true,
    render: (value) => (
      <div>
        {map(value, (time, index) => (
          <div key={`${index}-${time}`} className="pb10 pt10">
            <DisplayDateComponent value={time} format="DATE_COMMA_TIME_AM_PM" />
          </div>
        ))}
      </div>
    ),
  }),
  resolvedOn: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Resolved On',
    dataIndex: 'resolvedOn',
    key: 'resolvedOn',
    width: '15%',
    sorter: true,
    render: (value) => (
      <div>
        {map(value, (time, index) => (
          <div key={`${index}-${time}`} className="pb10 pt10">
            {
              time
                ? (<DisplayDateComponent value={time} format="DATE_COMMA_TIME_AM_PM" />)
                : (<DisplayOrEmptyComponent value={time} />)
            }
          </div>
        ))}
      </div>
    ),
  }),
  resolvedNote: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Resolved Note',
    dataIndex: 'resolvedNote',
    key: 'resolvedNote',
    width: '6%',
    render: (value) => (
      <div className="h100">
        {map(value, (note, index) => (
          <div key={`${index}-${note}`} className="pb10 pt10">
            {note ? (
              <TooltipComponent title={note} type={null} placement="top" trigger={['click']}>
                <div className="underline">Review</div>
              </TooltipComponent>
            ) : <DisplayOrEmptyComponent />}
          </div>
        ))}
      </div>
    ),
  }),
  status: (): ColumnType<MedicalAlertDashboardDataSource> => ({
    title: 'Status',
    dataIndex: 'status',
    filters: [
      { text: 'Open', value: SmartAlertTaskStatus.TODO },
      { text: 'Resolved', value: SmartAlertTaskStatus.DONE },
    ],
    defaultFilteredValue: [SmartAlertTaskStatus.TODO],
    render: (value) => (
      <div>
        {map(value, (v, index) => (
          <div key={`${index}-${v}`} className="pb10 pt10">
            <SmartAlertTaskStatusEnumComponent value={v} />
          </div>
        ))}
      </div>
    ),
  }),
});

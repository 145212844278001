import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import React, { useState, Key } from 'react';
import { HierarchyNode } from '../../features/adminPortal2/careOperationHierarchy/service/UcOrganizationService';
import { useCareOperationContext } from '../../features/adminPortal2/careOperationHierarchy/context/CareOperationContext/CareOperationContext';

interface ExpandAllButtonChildrenProps {
  onExpand: (expandedKeys: Key[]) => void;
  keys: Key[];
}

export interface ExpandAllButtonProps extends Omit<ButtonProps, 'children'> {
  children: React.ReactNode | ((props: ExpandAllButtonChildrenProps) => React.ReactNode);
  data: HierarchyNode[];
  defaultExpandedKeys?: Key[];
  className?: string;
}

export const ExpandAllButton = ({
  children,
  data,
  defaultExpandedKeys,
  className = '',
}: ExpandAllButtonProps) => {
  const { setExpandedNodes } = useCareOperationContext();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(defaultExpandedKeys ?? []);
  const onExpand = (
    expandedKeys: Key[]
  ) => {
    setExpandedKeys(expandedKeys);
  };

  const getContent = () => {
    if (typeof children === 'function') {
      return children({
        onExpand,
        keys: expandedKeys,
      });
    }
    return children;
  };

  const getAllNodeKeys = (data: HierarchyNode[]): string[] => data.reduce((keys, node) => {
    keys.push(node.id);
    if (node.children) {
      keys.push(...getAllNodeKeys(node.children));
    }
    return keys;
  }, [] as string[]);

  const allKeys = getAllNodeKeys(data);

  const expandAll = () => {
    // Get all node keys and set them as expanded
    // Assuming your tree data has a unique identifier for each node
    if (expandedKeys.length === allKeys.length) {
      setExpandedKeys([]);
      setExpandedNodes([]);
    } else {
      setExpandedKeys(allKeys);
      setExpandedNodes(allKeys);
    }
  };
  return (
    <>
      <Button
        className={classNames({
          'float-right': true,
          'text-gray-scale-1': true,
          [className]: !!className,
        })}
        type="text"
        onClick={expandAll}
      >
        {expandedKeys.length !== allKeys.length ? (
          <>
            Expand all
            {' '}
            <RightOutlined />
          </>
        ) : (
          <>
            Collapse all
            {' '}
            <DownOutlined />
          </>
        )}
      </Button>
      {getContent()}
    </>
  );
};

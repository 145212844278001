import { SelectProps } from 'antd';
import { usePhoneReportInfoGetAppVersions } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MobileVersionSelectComponent } from '../../component/MobileVersionSelectComponent/MobileVersionSelectComponent';

export interface MobileAppVersionSelectContainerProps extends Omit<SelectProps<string[]>, 'children'> {
  children?: (props: {
    isLoading: boolean;
    data: string[] | undefined;
  }) => JSX.Element;
}

export const MobileAppVersionSelectContainer = ({
  children,
  value,
  ...props
}: MobileAppVersionSelectContainerProps) => {
  const versionsList = usePhoneReportInfoGetAppVersions({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {},
        pageInfo: {
          page: 1,
          pageSize: 50,
        },
      }
    }
  });

  return (
    <FetchComponent info={versionsList}>
      {(data) => children?.({
        isLoading: versionsList.isLoading,
        data: data.rawValue?.data?.content
      }) || (
        <MobileVersionSelectComponent
          data={data.data}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
    </FetchComponent>
  );
};

import { useVisibility } from '../../../../../contexts/VisibilityContext/VisibilityContext';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import CardEditWithHeader from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import { AlertEngineDrawerComponent } from '../../../../AlertRuleEngine/component/AlertEngineDrawerComponent/AlertEngineDrawerComponent';
import { useAlertApiContext } from '../../../../AlertRuleEngine/context/AlertApiContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { AlertEngineMedicalOrgViewComponent } from '../AlertEngineMedicalOrgViewComponent/AlertEngineMedicalOrgViewComponent';

export interface AlertEngineMedicalOrgCardComponentProps {

}

export const AlertEngineMedicalOrgCardComponent = () => {
  const { isOpen, open, close } = useOpen();
  const { showMedicalAlertEngineEdit } = useVisibility();
  const { medicalOrgService } = useMedicalOrgContext();
  const { refetch } = useAlertApiContext();

  if (!medicalOrgService.id) return null;

  const handleClose = () => {
    close();
    refetch();
  };

  return (
    <CardEditWithHeader
      modal={{
        maskClosable: false,
      }}
      header="Medical Alert Configuration"
      showEditLabel={showMedicalAlertEngineEdit}
      onEdit={open}
    >
      <AlertEngineMedicalOrgViewComponent />
      <AlertEngineDrawerComponent
        isOpen={isOpen}
        onClose={handleClose}
        clinicId={medicalOrgService.id}
      />
    </CardEditWithHeader>
  );
};

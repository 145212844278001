import { isNaN } from 'lodash';
import { Weight } from '../../uc-api-sdk';

export interface HSUnitProps {
  unit: 'lb' | 'kg';
}
export class WeightService {
  public static ratio = 2.20462;

  public static defaultKgUnit = 'kg';

  public static defaultWeightUnit = 'lb';

  public static weightUnitDisplay = 'lbs';

  public static NO_RECORD_VALUE = -1;

  public static hasNoRecord(value?: number | null) {
    const valueNum = Number(value); // don't need to check for decimals
    return isNaN(valueNum) || valueNum === WeightService.NO_RECORD_VALUE;
  }

  public static kgToLb(kg: number) {
    if (!kg) {
      return 0.0;
    }
    return (kg * WeightService.ratio).toFixed(1);
  }

  public static lbToKg(lb: number) {
    if (!lb) {
      return 0;
    }
    return (lb / WeightService.ratio).toFixed(1);
  }

  public static getWeightInKg(w?: Weight) {
    return (w?.unit === WeightService.defaultKgUnit)
      ? Number(w.value || 0).toFixed(1)
      : WeightService.lbToKg(w?.value || 0);
  }

  public static getWeightInLb(w?: Weight) {
    return (w?.unit === WeightService.defaultKgUnit)
      ? WeightService.kgToLb(w.value || 0)
      : Number(w?.value || 0).toFixed(1);
  }

  public static toLbOrKg(unit: HSUnitProps['unit']) {
    return unit === WeightService.defaultWeightUnit
      ? WeightService.kgToLb : (v: number) => v.toFixed(1); // expect v in kg
  }
}

import { FilterDropdownProps } from 'antd/lib/table/interface';
import { PatientSearchSelectContainer } from '../../../patient/container/PatientSearchSelectContainer/PatientSearchSelectContainer';
import { TableSearchComponent } from '../TableSearchComponent/TableSearchComponent';

export interface PatientSearchFilterComponentProps extends FilterDropdownProps {
  selectedOrgIds?: string[];
}

export const PatientSearchFilterComponent = ({
  ...props
}: PatientSearchFilterComponentProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TableSearchComponent {...props}>
    <PatientSearchSelectContainer
      optionLabel={null}
      value={props.selectedKeys?.[0] as string | undefined}
      onChange={(id) => {
        props.setSelectedKeys(id ? [id] : []);
      }}
      selectedOrgIds={props.selectedOrgIds}
    />
  </TableSearchComponent>
);

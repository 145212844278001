import CardEditWithHeader from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import { ADMIN_PORTAL2_TEXTS } from '../../../constants/texts';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';

export const MedicalOrgBillingComponent = () => {
  const { medicalOrgService, refetch } = useMedicalOrgContext();
  const { open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditMedicalOrgBilling);
  const { billingEntity } = medicalOrgService;
  const {
    medicalOrgRecipientsEmails,
    ihealthInternalRecipientsEmails,
    ihealthInternalCCRecipientsEmails,
    businessAddress,
    ...rest
  } = billingEntity || {};

  const onClick = () => {
    open(
      {
        onSuccess: () => {
          close();
          refetch();
        },
        initialValues: {
          id: medicalOrgService.id,
          medicalOrgRecipientsEmails: medicalOrgRecipientsEmails?.join(', '),
          ihealthInternalRecipientsEmails: ihealthInternalRecipientsEmails?.join(', '),
          ihealthInternalCCRecipientsEmails: ihealthInternalCCRecipientsEmails?.join(', '),
          businessAddress: businessAddress?.streetName,
          city: businessAddress?.city,
          state: businessAddress?.state,
          zip: businessAddress?.postCode,
          ...rest
        }
      }
    );
  };

  const renderPlace = (city: string, state: string, zip: string) => (
    `${city}, ${state}, ${zip}`
  );

  const dataList = [
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.billingEntityName,
      value: medicalOrgService.billingEntity?.billingEntityName,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.netSuiteCustomerId,
      value: medicalOrgService.billingEntity?.netSuiteCustomerId,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.officeNpi,
      value: medicalOrgService.billingEntity?.officeNpi,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.taxId,
      value: medicalOrgService.billingEntity?.taxId,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.medicalOrgRecipientsEmails,
      value: medicalOrgService.billingEntity?.medicalOrgRecipientsEmails?.join(', '),
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.ihealthInternalRecipientsEmails,
      // @ts-ignore
      value: medicalOrgService.billingEntity?.ihealthInternalRecipientsEmails?.join(', '),
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.ihealthInternalCCRecipientsEmails,
      value: medicalOrgService.billingEntity?.ihealthInternalCCRecipientsEmails?.join(', '),
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.addressName,
      value: medicalOrgService.billingEntity?.addressName,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.businessAddress,
      value: medicalOrgService.billingEntity?.businessAddress?.streetName,
    },
    {
      key: ADMIN_PORTAL2_TEXTS.fieldNames.cityStateZip,
      value: renderPlace(
        medicalOrgService.billingEntity?.businessAddress?.city || '-- --',
        medicalOrgService.billingEntity?.businessAddress?.state || '-- --',
        medicalOrgService.billingEntity?.businessAddress?.postCode || '-- --',
      ),
    },
  ];

  return (
    <CardEditWithHeader
      modal={{
        maskClosable: false,
      }}
      header="Billing Information"
      onEdit={onClick}
    >
      <MedicalOrgCommonCardContentComponent data={dataList} />
    </CardEditWithHeader>
  );
};

import { Button, Form, FormProps } from 'antd';
import { useState } from 'react';
import { useUpdateListener } from '../../../../../contexts/UpdateContext/UpdateContext';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import { LanguageType } from '../../../../../types/languages';
import { RemoveRoleValidationResponse, RoleTypeEnum } from '../../../../../uc-api-sdk';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import { CreateUserFormFieldsComponent } from '../../../component/CreateUserFormFieldsComponent/CreateUserFormFieldsComponent';
import { useAdminUserForm } from '../../../hook/useCreateUserHook';
import { CreateUserFormValues } from '../../type';

export interface CreateEditEmployeeFormComponentProps {
  initialValues?: CreateUserFormValues;
  mode?: 'create' | 'edit';
  onSubmit: (values: CreateUserFormValues) => void;
  hasPatientsCheck?: RemoveRoleValidationResponse[];
  orgIdsWithPt?: string[];
}

export const CreateEditEmployeeFormComponent = ({
  initialValues = { language: [LanguageType.English] } as CreateUserFormValues,
  mode = 'create',
  onSubmit,
  hasPatientsCheck,
  orgIdsWithPt
}: CreateEditEmployeeFormComponentProps) => {
  const hook = useAdminUserForm({});
  const { form } = hook;
  const [shouldValidate, setShouldUpdate] = useState(0);

  const onValuesChange: FormProps['onValuesChange'] = (changedValues) => {
    if ('firstName' in changedValues || 'lastName' in changedValues) {
      const firstName = form.getFieldValue('firstName');
      const lastName = form.getFieldValue('lastName');
      const newId = `${firstName?.replaceAll(' ', '') || ''}${lastName?.replaceAll(' ', '') || ''}`.toLocaleLowerCase();
      form.setFieldValue('loginId', newId);
      setShouldUpdate(shouldValidate + 1);
    }
    if ('roles' in changedValues) {
      let { roles } = changedValues;

      if (changedValues.roles.includes(RoleTypeEnum.PROVIDER)) {
        roles = changedValues.roles.filter((role: RoleTypeEnum) => role !== RoleTypeEnum.MA);
      } else if (changedValues.roles.includes(RoleTypeEnum.MA)) {
        roles = changedValues.roles.filter((role: RoleTypeEnum) => role !== RoleTypeEnum.PROVIDER);
      }

      if (changedValues.roles.includes(RoleTypeEnum.CLINICAL_GROUP_BILLER)) {
        roles = changedValues.roles.filter(
          (role: RoleTypeEnum) => role !== RoleTypeEnum.CLINICAL_MANAGER
        );
      } else if (changedValues.roles.includes(RoleTypeEnum.CLINICAL_MANAGER)) {
        roles = changedValues.roles.filter(
          (role: RoleTypeEnum) => role !== RoleTypeEnum.CLINICAL_GROUP_BILLER
        );
      }

      form.setFieldsValue({ roles });
    }
  };

  useUpdateListener('RESET_EMPLOYEE_FORM', form.resetFields);

  const {
    value: isValidEmail,
    setTrue: setEmailValid, setFalse: setEmailInvalid
  } = useBoolean(true);

  const {
    value: isValidId, setTrue:
    setIdValid, setFalse: setIdInvalid
  } = useBoolean(true);

  return (
    <Form
      form={form}
      onValuesChange={onValuesChange}
      onFinish={hook.handleSubmit(onSubmit)}
      layout="vertical"
      initialValues={initialValues}
      className="flex h100 w100"
    >
      <FixedComponent className="w100">
        <FixedComponent.Child>
          <CreateUserFormFieldsComponent
            form={form}
            isEditing={mode === 'edit'}
            shouldValidate={shouldValidate}
            initialValues={initialValues}
            setEmailInvalid={setEmailInvalid}
            setEmailValid={setEmailValid}
            setIdInvalid={setIdInvalid}
            setIdValid={setIdValid}
            hasPatientsCheck={hasPatientsCheck}
            restrictedMedOrgIds={
              hasPatientsCheck?.[0]?.limitedClinics?.map((clinic) => clinic.id || '') || []
            }
            orgIdsWithPt={orgIdsWithPt}
          />
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="border-t">
          <Button
            htmlType="submit"
            type="primary"
            className="w100 bold"
            disabled={!isValidId || !isValidEmail}
          >
            {mode === 'create' ? 'Create' : 'Confirm'}
          </Button>
          <p className="mt10 text-gray-scale-2">By creating this user account, a sign in invitation email will be sent to the user's email address.</p>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};

import { map } from 'lodash';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MobileVersionConfigTableComponent } from '../../component/MobileVersionConfigTableComponent/MobileVersionConfigTableComponent';
import {
  AppVersionConfig, ContentDetail, MiniOrganization, VersionDetail, useAppVersionConfigSearch
} from '../../../../uc-api-sdk';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export const MobileVersionConfigTableContainer = () => {
  const tableChange = useTableChange<AppVersionConfig, AppVersionConfig>({
    name: 'MOBILE_VERSION_CONFIG_TABLE',
    sortProcessor: (sorter) => sorterHelper(sorter),
    filterProcessor: (filters) => {
      const f: AppVersionConfig = {};
      if (filters.miniOrganizations) {
        f.medOrgIdIn = { in: filters.miniOrganizations as string[] || [] };
      }
      return f;
    },
  });

  const appVersionConfigHook = useAppVersionConfigSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        ...tableChange.processedFilter,
      },
      ...tableChange.pagination,
    },
  });

  const handleRefetch = () => {
    appVersionConfigHook.refetch();
  };

  useUpdateListener('MOBILE_VERSION_CONFIG_CREATED', handleRefetch);
  useUpdateListener('MOBILE_VERSION_CONFIG_UPDATED', handleRefetch);

  return (
    <FetchComponent info={appVersionConfigHook} showOnRefetch>
      {(data, info) => {
        const result: AppVersionConfig[] = data?.data
          ? map(appVersionConfigHook.data?.data?.content, (v) => ({
            id: v.id as string,
            name: v.name as string,
            versionDetails: v.versionDetails as VersionDetail[],
            medOrgIds: v.medOrgIds as string[],
            miniOrganizations: v.miniOrganizations as MiniOrganization[],
            activateDate: v.activateDate,
            contents: v.contents as ContentDetail[],
            note: v.note as string,
          })) : [];
        return (
          <MobileVersionConfigTableComponent
            isLoading={appVersionConfigHook.isLoading}
            data={result}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
          />
        );
      }}
    </FetchComponent>
  );
};

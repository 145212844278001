import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Col, Row, Space, Tooltip
} from 'antd';
import { ReactNode } from 'react';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { NumberService } from '../../../../helpers/number';
import {
  MeasurementResultsStats,
  MeasurementResultTypeEnum, Number as NumberType, PatientControlLevel, VitalEnumType
} from '../../../../uc-api-sdk';
import { SummaryTwoIndexComponent } from '../SummaryColumnComponents/SummaryTwoIndexComponent';
import './VitalSummaryComponent.scss';

// SummaryItem
interface SummaryItemProps {
  title: ReactNode;
  value: ReactNode;
  hide?: boolean;
  span?: number;
  extra?: ReactNode;
}

export const SummaryItem = ({
  title,
  value,
  hide = false,
  span = 6,
  extra
}: SummaryItemProps) => (
  !hide ? (
    <Col span={span} className="vital-summary-component__item">
      <>
        <div className="item-title">{title}</div>
        <div className="item-value">{value}</div>
        {extra ? <div className="item-extra">{extra}</div> : null}
      </>
    </Col>
  ) : null
);

// BaseVitalSummaryComponent
interface BaseVitalSummaryComponentProps {
  type: VitalEnumType;
  total: ReactNode;
  average: ReactNode;
  inNormal: ReactNode;
  pulse?: ReactNode;
  range?: ReactNode;
  bmi?: ReactNode;
  variability?: ReactNode;
  baseline?: ReactNode;
}

const BaseVitalSummaryComponent = ({
  type,
  total,
  average,
  inNormal,
  pulse,
  range,
  bmi,
  variability,
  baseline,
}: BaseVitalSummaryComponentProps) => {
  const renderAverageTitle = () => (
    <div>
      Avg
      {' '}
      <VitalsEnumTypeComponent
        type="short"
        value={type}
      />
    </div>
  );

  const renderAverageBPTitle = () => (
    <div>
      Avg
      {' '}
      <VitalsEnumTypeComponent
        type="short"
        value={type}
      />
      , P.
    </div>
  );

  const renderAverageBP = () => (
    <div className="flex">
      {average}
      {pulse}
    </div>
  );

  const renderVariabilityTitle = () => (
    <div>
      Variability
      {' '}
      <Tooltip placement="topLeft" title="(Highest weight - Lowest weight) /2" overlayClassName="hs-variability-tooltip">
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  const getBaselineType = (type: VitalEnumType) => {
    switch (type) {
      case VitalEnumType.BP:
        return (
          <Space>
            <Tooltip title="BP baseline should be transcribed from the latest
            valid EHR data within 30 days of enrollment or measured on site during technical onboarding."
            >
              BP Baseline
              {' '}
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      case VitalEnumType.BG:
        return (
          <Space>
            <Tooltip title="A1C baseline should be transcribed from the EHR using valid data
            from within 183 days before or 30 days after the enrollment date."
            >
              A1c Baseline
              {' '}
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      default:
        return undefined;
    }
  };

  const getSpan = (type: VitalEnumType) => {
    switch (type) {
      case VitalEnumType.BP:
        return 5;
      case VitalEnumType.BG:
        return 5;
      case VitalEnumType.HS:
        return 5;
      default:
        return undefined;
    }
  };

  const span = getSpan(type);

  return (
    <Row className="vital-summary-component" justify="start">
      <SummaryItem title="Total Measure" value={total} span={4} />
      <SummaryItem
        span={span}
        title={type === VitalEnumType.BP ? renderAverageBPTitle() : renderAverageTitle()}
        value={type === VitalEnumType.BP ? renderAverageBP() : average}
      />
      <SummaryItem title="BMI" value={bmi} hide={!bmi} span={span} />
      <SummaryItem
        title={renderVariabilityTitle()}
        value={variability}
        hide={!variability && variability !== 0}
        span={span}
      />
      <SummaryItem
        title="Vitals in normal"
        value={`${inNormal}%`}
        hide={!inNormal}
        span={span}
      />
      <SummaryItem
        title={type === VitalEnumType.BP ? 'Range (SYS/DIA)' : 'Range'}
        value={`${range}`}
        hide={!range}
        span={span}
      />
      <SummaryItem
        title={getBaselineType(type)}
        value={`${baseline}`}
        hide={!baseline}
        span={span}
      />
    </Row>
  );
};

// VitalSummaryComponent
export interface VitalSummaryComponentProps {
  type: MeasurementResultTypeEnum;
  value?: MeasurementResultsStats;
  controlLevel?: PatientControlLevel;
}

export const VitalSummaryComponent = ({
  type,
  value,
  controlLevel
}: VitalSummaryComponentProps) => {
  const getFixed = (v: NumberType | number | null | undefined) => (
    (+(v || 0).toString()).toFixed(0)
  );

  const getBGValues = () => {
    const {
      count, average, inRangePercentage, range
    } = value?.bg?.all || {};
    const { bgBaseline } = controlLevel || {};

    return {
      type: VitalEnumType.BG,
      total: count || 0,
      average: (average?.[0] || 0).toFixed(0),
      inNormal: ((inRangePercentage || 0) * 100).toFixed(0),
      range: `${getFixed(range?.[0])}-${getFixed(range?.[1])}`,
      baseline: bgBaseline?.a1c ? `${bgBaseline.a1c}%` : 'N/A',
    };
  };

  const getBPValues = () => {
    const {
      count, average, inRangePercentage, range
    } = value?.bp?.all || {};
    const { bpBaseline } = controlLevel || {};

    return {
      type: VitalEnumType.BP,
      total: count || 0,
      average: (
        <SummaryTwoIndexComponent
          value={NumberService.numberArrayToFixed(average || [], 0)}
        />
      ),
      inNormal: ((inRangePercentage || 0) * 100).toFixed(0),
      pulse: ((average?.length || 0) >= 3) ? average?.[2]?.toFixed(0) : 'N/A',
      range: `${getFixed(range?.[0])}-${getFixed(range?.[1])}/${getFixed(range?.[2])}-${getFixed(range?.[3])}`,
      baseline: bpBaseline?.sbp && bpBaseline?.dbp ? `${Math.floor(bpBaseline.sbp)}/${Math.floor(bpBaseline.dbp)}` : 'N/A',
    };
  };

  const getPOValues = () => {
    const { count, average } = value?.po?.all || {};
    return {
      type: VitalEnumType.PO,
      total: count || 0,
      average: (average || 0).toFixed(0),
      inNormal: undefined,
    };
  };

  const getValues = (): BaseVitalSummaryComponentProps | null => {
    switch (type) {
      case MeasurementResultTypeEnum.BG: return getBGValues();
      case MeasurementResultTypeEnum.BP: return getBPValues();
      case MeasurementResultTypeEnum.PO: return getPOValues();
      default: return null;
    }
  };

  const v = getValues();
  if (!v) return null;

  return (
    <BaseVitalSummaryComponent
      type={v.type}
      total={v.total}
      average={v.average}
      inNormal={v.inNormal}
      pulse={v.pulse}
      range={v.range}
      bmi={v.bmi}
      variability={v.variability}
      baseline={v.baseline}
    />
  );
};

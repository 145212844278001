import { useState } from 'react';
import classNames from 'classnames';
import { Button, Modal } from 'antd';
import { ContentDetail } from '../../../../uc-api-sdk';

export interface VersionContentsModalComponentProps {
  data: ContentDetail[];
}

export const VersionContentsModalComponent = ({
  data,
}: VersionContentsModalComponentProps) => {
  const [show, setShow] = useState(false);

  const renderContent = (content: ContentDetail, index: number, isModal: boolean) => (
    <div
      className={classNames({
        'border-gray-1 p10': true,
        mb10: isModal,
      })}
      key={`${index}-${content.title}-isModal-${isModal}`}
    >
      <div>
        <strong>Title:</strong>
        {content.title}
      </div>
      <div>
        <strong>Description:</strong>
        {content.description}
      </div>
      <div>
        <strong>Language:</strong>
        {content.language}
      </div>
    </div>
  );

  return (
    <div>
      {renderContent(data[0], 0, false)}
      <Button onClick={() => setShow(true)} type="link" className="p0">...more</Button>
      {show && (
        <Modal onCancel={() => setShow(false)} open={show}>
          <div className="p15">
            {data.map((item, index) => renderContent(item, index, true))}
          </div>
        </Modal>
      )}
    </div>
  );
};

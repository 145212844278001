import {
  Button, Form, FormProps, Input, DatePicker, Divider
} from 'antd';
import { useEffect } from 'react';
import { useCreateMobileVersionConfigForm } from '../../hook/useCreateMobileVersionConfigForm';
import { CreateEditMobileVersionConfigFormValue } from '../CreateEditMobileVersionConfigDrawerComponent/CreateEditMobileVersionConfigDrawerComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MobileAppVersionSelectContainer } from '../../containers/MobileAppVersionSelectContainer/MobileAppVersionSelectContainer';
import { MedicalOrgSelectContainer } from '../../../adminPortal2/container/MedicalOrgSelectContainer/MedicalOrgSelectContainer';
import { Icons } from '../../../../icons/Icons';
import LanguageSelectComponent from '../../../../selectComponents/LanguageSelectComponent/LanguageSelectComponent';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface CreateMobileVersionConfigFormComponentProps extends FormProps {
  isLoading?: boolean;
  onSubmit: (v: CreateEditMobileVersionConfigFormValue) => void;
  initialValues?: CreateEditMobileVersionConfigFormValue;
}

export const CreateMobileVersionConfigFormComponent = ({
  onSubmit,
  initialValues,
  ...props
}: CreateMobileVersionConfigFormComponentProps) => {
  const hook = useCreateMobileVersionConfigForm();

  useEffect(() => {
    if (!initialValues?.contents?.length && !initialValues?.id) {
      hook.form.setFieldsValue({
        contents: [{ title: '', description: '', language: 'EL' }]
      });
    }
  }, [initialValues]);

  return (
    <div className="flex gap2 fd-c h100">
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        layout="vertical"
        className="h100 w100"
        initialValues={initialValues}
        form={hook.form}
        disabled={props.isLoading}
        onFinish={hook.handleSubmit(onSubmit)}
      >
        <FixedComponent className="w100">
          <FixedComponent.Child>
            <FormItem
              info={hook.getInfo('name')}
              required
            >
              <Input />
            </FormItem>
            <FormItem
              info={hook.getInfo('activateDate')}
              required
            >
              <DatePicker format={USA_DATE} />
            </FormItem>
            <FormItem
              info={hook.getInfo('versionDetails')}
              required
            >
              <MobileAppVersionSelectContainer />
            </FormItem>
            <FormItem
              required
              info={hook.getInfo('medOrgIds')}
            >
              <MedicalOrgSelectContainer showAssociated={false} />
            </FormItem>
            <FormItem
              info={hook.getInfo('note')}
              required
            >
              <Input />
            </FormItem>
            <Divider />
            <div>
              <span className="text-secondary-gray">Contents</span>
              <span className="text-red">*</span>
            </div>
            <Form.List name="contents">
              {(fields, { add, remove }) => (
                <div className="flex gap4 fd-c mb30 mt10">
                  {fields.map(({ name, ...restField }) => (
                    <div className="flex ai-c jc-sb">
                      <div className="border-gray-1 p10 w100">
                        <FormItem
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...restField}
                          name={[name, 'title']}
                          label="Title"
                          rules={[{ required: true, message: 'Title is required' }]}
                        >
                          <Input />
                        </FormItem>
                        <FormItem
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...restField}
                          name={[name, 'description']}
                          label="Description"
                          rules={[{ required: true, message: 'Description is required' }]}
                        >
                          <Input.TextArea />
                        </FormItem>
                        <FormItem
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...restField}
                          name={[name, 'language']}
                          label="Language"
                          rules={[{ required: true, message: 'Language is required' }]}
                        >
                          <LanguageSelectComponent />
                        </FormItem>
                      </div>
                      <Icons.TrashCanIcon onClick={() => remove(name)} />
                    </div>
                  ))}
                  <div className="flex ai-c jc-c">
                    <Button
                      type="primary"
                      onClick={() => add()}
                      className="b7"
                    >
                      + Add content
                    </Button>
                  </div>
                </div>
              )}
            </Form.List>
          </FixedComponent.Child>
          <FixedComponent.Child isFixed className="border-t mt20">
            <Button type="primary" className="w100 b5 fs16" htmlType="submit">{initialValues ? 'Save' : 'Create'}</Button>
          </FixedComponent.Child>
        </FixedComponent>
      </Form>
    </div>
  );
};

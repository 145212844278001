import { useMemo } from 'react';
import { map } from 'lodash';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { DefaultDrawerProps } from '../../../../../types/drawer';
import { useMedicalOrganizationMultiUpsertAssignments, useMedicalOrganizationRemoveAssociationCheck } from '../../../../../uc-api-sdk';
import { useCareOperationContext } from '../../../careOperationHierarchy/context/CareOperationContext/CareOperationContext';
import { AssociateMedicalOrgDrawerComponent } from '../../component/AssociateMedicalOrgDrawerComponent/AssociateMedicalOrgDrawerComponent';
import { AssociatedFormValues } from '../../component/AssociateMedicalOrgFormComponent/AssociateMedicalOrgFormComponent';

export interface AssociatedMedicalOrgDrawerContainerProps extends DefaultDrawerProps {
}

export const AssociatedMedicalOrgDrawerContainer = ({
  ...props
}: AssociatedMedicalOrgDrawerContainerProps) => {
  const { selectedEntityId = '', accessibleOrgsListInfo } = useCareOperationContext();

  const updateHook = useMedicalOrganizationMultiUpsertAssignments({});
  const associatedOrgIds = accessibleOrgsListInfo.data?.data?.map(
    (org) => org.medicalOrgId as string
  ) || [];

  const updateAssignment = async (values: AssociatedFormValues) => {
    if (!selectedEntityId) return;

    const result = updateHook.send({
      params: {
        request: {
          careGroupId: selectedEntityId,
          medicalOrgIds: values.organizationIds
        }
      }
    });

    await ApiRequestHelper.tryCatch(result, {
      success: 'Successfully updated',
      error: 'Failed to update',
      onSuccess: props.onSuccess,
      onError: props.onSuccess
    });
  };

  const removeMedicalOrgCheckHook = useMedicalOrganizationRemoveAssociationCheck({
    options: { sendOnMount: true },
    params: {
      ids: associatedOrgIds,
    }
  });

  const restrictedMedOrgIds = useMemo(() => {
    const res: string[] = [];
    if (removeMedicalOrgCheckHook.data?.data) {
      map(removeMedicalOrgCheckHook.data.data, (org) => {
        if (!org.removable) {
          res.push(org.id as string);
        }
      });
    }
    return res;
  }, [removeMedicalOrgCheckHook.data]);

  return (
    <AssociateMedicalOrgDrawerComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onSubmit={updateAssignment}
      restrictedMedOrgIds={restrictedMedOrgIds}
    />
  );
};

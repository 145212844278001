import classNames from 'classnames';
import { LimitedUser, ReporterEnum } from '../../../../uc-api-sdk';
import { AccountHelper } from '../../../../helpers/account';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface SymptomTableReporterComponentProps {
  reporterType?: ReporterEnum;
  reportedByUser?: LimitedUser | undefined;
  isNested: boolean;
}

export const SymptomTableReporterComponent = ({
  reporterType,
  reportedByUser,
  isNested,
}: SymptomTableReporterComponentProps) => (
  <div className={classNames({
    SymptomTableReporterComponent: true,
    nested: isNested,
  })}
  >
    <div>
      {reporterType === ReporterEnum.PATIENT_REPORTED
        ? 'Patient self-reported' : <DisplayOrEmptyComponent value={AccountHelper.getFullNameWithTitle(reportedByUser)} />}
    </div>
  </div>
);

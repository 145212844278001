import { Alert, AlertProps } from 'antd';
import { ReactNode } from 'react';
import './ConfigurationAlertComponent.scss';

export interface ConfigurationAlertComponentProps extends AlertProps {
  title?: ReactNode;
}

export const ConfigurationAlertComponent = ({
  title,
  type = 'warning',
  ...props
}: ConfigurationAlertComponentProps) => (
  <Alert
    className="configuration-alert-component ai-bl no-border text-gray-scale-2"
    type={type}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    message={(
      <div>
        <div className="bold text-gray-scale-2">{title}</div>
        <div className="text-gray-scale-2">{props.message}</div>
      </div>
    )}
  />
);

import {
  Button, Col, Input, Row, Space
} from 'antd';
import { InputType } from '../../../features/Input/types';
import { Icons } from '../../../icons/Icons';
import USStateSelectComponent from '../../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';
import FormItem from '../../FormItem/FormItem';
import './AddressComponent.scss';

export interface AddressComponentProps extends InputType<string> {
  name: string | number;
  label: string;
  key: number;
  onRemove: () => void;
}

export const AddressComponent = ({ ...props }: AddressComponentProps) => (
  <div className="address-component">
    <Row align="bottom">
      <Col span={22}>
        <FormItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          name={[props.name, 'streetName']}
          label={props.label}
        >
          <Input />
        </FormItem>
      </Col>
      <Col span={2}>
        <Button type="text" className="remove-address">
          <Icons.TrashCanIcon onClick={props.onRemove} />
        </Button>
      </Col>
    </Row>
    <Row>
      <Col span={22}>
        <Space size="large" className="w100">
          <FormItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            name={[props.name, 'state']}
            label="State"
          >
            <USStateSelectComponent />
          </FormItem>

          <FormItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            label="City"
            name={[props.name, 'city']}
          >
            <Input />
          </FormItem>

          <FormItem
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            label="Zip"
            name={[props.name, 'postCode']}
          >
            <Input />
          </FormItem>
        </Space>
      </Col>
    </Row>
  </div>
);

import {
  map,
} from 'lodash';
import { ConditionEnum } from '../../../uc-api-sdk';

export const clinicGoalConditionOrder = [
  ConditionEnum.GLUCOSE_MANAGEMENT,
  ConditionEnum.BLOOD_PRESSURE_MANAGEMENT,
  ConditionEnum.LIPID_MANAGEMENT,
  ConditionEnum.CANCER,
  ConditionEnum.WEIGHT_REDUCTION,
  ConditionEnum.WEIGHT_REDUCTION_GLP,
  ConditionEnum.UNDERWEIGHT,
  ConditionEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE,
  ConditionEnum.CONGESTIVE_HEART_FAILURE,
  ConditionEnum.CHRONIC_KIDNEY_DISEASE,
  ConditionEnum.COVID,
];

/*
@output
[Object] {
  GLUCOSE_MANAGEMENT: 1,
  BLOOD_PRESSURE_MANAGEMENT: 2,
 ...
}
*/
export const clinicGoalConditionOrderMap = Object.assign(
  {},
  ...map(clinicGoalConditionOrder, (condition, idx) => ({ [condition]: idx + 1 })),
) as Record<Partial<ConditionEnum>, number>;

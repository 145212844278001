import { Button, Form, FormProps } from 'antd';
import { FixedChildComponent } from '../../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { MedicalOrgSelectContainer } from '../../../container/MedicalOrgSelectContainer/MedicalOrgSelectContainer';
import { useAssociateMedicalOrgForm } from '../../hook/useAssociateMedicalOrgForm';
import { associatedMedicalOrgManageRestrictionModal } from '../../../component/AssociatedMedicalOrgManageRestrictionModal/AssociatedMedicalOrgManageRestrictionModal';

export interface AssociatedFormValues {
  organizationIds: string[];
  careGroupId: string;
}

export interface AssociateMedicalOrgFormComponentProps extends FormProps {
  onSubmit: (values: AssociatedFormValues) => void;
  restrictedMedOrgIds?: string[];
}

export const AssociateMedicalOrgFormComponent = ({
  restrictedMedOrgIds,
  ...props
}: AssociateMedicalOrgFormComponentProps) => {
  const hook = useAssociateMedicalOrgForm();
  const { form } = hook;

  return (
    <Form
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      form={form}
      onFinish={hook.handleSubmit(props.onSubmit)}
      layout="vertical"
      className="associate-medical-org-form-component h100 w100"
    >
      <FixedComponent>
        <FixedChildComponent>
          <div className="flex jc-e">
            {
              restrictedMedOrgIds?.length
                ? (
                  <div className="mr10">
                    <Button
                      className="primary-link p0 mb5"
                      type="link"
                      onClick={() => associatedMedicalOrgManageRestrictionModal()}
                    >
                      Can't remove certain medical orgs?
                    </Button>
                  </div>
                )
                : null
            }
          </div>
          <FormItem
            name={hook.getName('organizationIds')}
          >
            <MedicalOrgSelectContainer
              mode="multiple"
              optionFilterProp="label"
              placeholder="Search Medical Organization by alias"
              restrictedMedOrgIds={restrictedMedOrgIds}
            />
          </FormItem>
        </FixedChildComponent>
        <FixedChildComponent isFixed>
          <Button className="w100" type="primary" htmlType="submit">Save</Button>
        </FixedChildComponent>
      </FixedComponent>
    </Form>
  );
};

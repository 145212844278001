import {
  Button, Col, Divider, Form, Input, Row, Tooltip
} from 'antd';
import { Rule } from 'antd/lib/form';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormType } from '../../../../Input/types';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { useMedicalOrgBillingEntityForm } from '../../hook/useMedicalOrgBillingEntityForm';
import { ADMIN_PORTAL2_TEXTS } from '../../../constants/texts';
import USStateSelectComponent from '../../../../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';

export interface BillingEntityFormValues {
  id: string;
  billingEntityName: string;
  netSuiteCustomerId: string;
  officeNpi: string;
  taxId: string;
  medicalOrgRecipientsEmails?: string;
  ihealthInternalRecipientsEmails: string;
  ihealthInternalCCRecipientsEmails: string;
  addressName: string;
  businessAddress: string;
  state: string;
  city: string;
  zip: string;
}

export enum MedicalOrgBillingEntityFormFields {
  billingEntityName = 'billingEntityName',
  netSuiteCustomerId = 'netSuiteCustomerId',
  officeNpi = 'officeNpi',
  taxId = 'taxId',
  medicalOrgRecipientsEmails = 'medicalOrgRecipientsEmails',
  ihealthInternalRecipientsEmails = 'ihealthInternalRecipientsEmails',
  ihealthInternalCCRecipientsEmails = 'ihealthInternalCCRecipientsEmails',
  addressName = 'addressName',
  businessAddress = 'businessAddress',
}

export interface MedicalOrgBillingEntityFormComponentProps
extends FormType<BillingEntityFormValues> { }

export const MedicalOrgBillingEntityFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
}: MedicalOrgBillingEntityFormComponentProps) => {
  const form = useMedicalOrgBillingEntityForm({});
  const emailsRules = form.getEmailRules({ required: false, isIhealthEmail: false });

  const handleFormSubmit = (values: BillingEntityFormValues) => {
    onSubmit?.(values);
  };

  const renderInputField = (name: MedicalOrgBillingEntityFormFields, tooltip?: string) => (
    <Row className="flex gap1 mt10">
      <Col span={22}>
        <FormItem
          info={form.getInfo(name)}
        >
          <Input />
        </FormItem>
      </Col>
      <Col span={1} className="flex ai-c ml15">
        {
          tooltip && (
            <Tooltip
              className="mt20"
              placement="top"
              title={tooltip}
            >
              <InfoCircleOutlined />
            </Tooltip>
          )
        }
      </Col>
    </Row>
  );

  const renderEmailsInputField = (
    name: MedicalOrgBillingEntityFormFields,
    rules: Rule[],
    tooltip: string
  ) => (
    <Row className="flex gap1 mt10">
      <Col span={22}>
        <FormItem
          info={form.getInfo(name)}
          rules={rules}
        >
          <Input placeholder="Enter multiple emails and separate by “,”" />
        </FormItem>
      </Col>
      <Col span={1} className="flex ai-c ml15">
        <Tooltip
          className="mt20"
          placement="top"
          title={tooltip}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Row>
  );

  return (
    <Form
      form={form.form}
      layout="vertical"
      initialValues={initialValues}
      className="h100 w100"
      onFinish={handleFormSubmit}
      disabled={isLoading}
    >
      <FixedComponent>
        <FixedComponent.Child>
          {renderInputField(
            MedicalOrgBillingEntityFormFields.billingEntityName,
            ADMIN_PORTAL2_TEXTS.tooltips.billingEntityName
          )}
          {renderInputField(
            MedicalOrgBillingEntityFormFields.netSuiteCustomerId,
            ADMIN_PORTAL2_TEXTS.tooltips.netSuiteCustomerId
          )}
          {renderInputField(
            MedicalOrgBillingEntityFormFields.officeNpi,
            ADMIN_PORTAL2_TEXTS.tooltips.officeNpi
          )}
          {renderInputField(
            MedicalOrgBillingEntityFormFields.taxId,
            ADMIN_PORTAL2_TEXTS.tooltips.taxId
          )}
          <Divider />
          {renderEmailsInputField(
            MedicalOrgBillingEntityFormFields.medicalOrgRecipientsEmails,
            emailsRules || [],
            ADMIN_PORTAL2_TEXTS.tooltips.medicalOrgRecipientsEmails
          )}
          {renderEmailsInputField(
            MedicalOrgBillingEntityFormFields.ihealthInternalRecipientsEmails,
            emailsRules || [],
            ADMIN_PORTAL2_TEXTS.tooltips.ihealthInternalRecipientsEmails
          )}
          {renderEmailsInputField(
            MedicalOrgBillingEntityFormFields.ihealthInternalCCRecipientsEmails,
            emailsRules || [],
            ADMIN_PORTAL2_TEXTS.tooltips.ihealthInternalCCRecipientsEmails
          )}
          <Divider />
          {renderInputField(
            MedicalOrgBillingEntityFormFields.addressName,
            ADMIN_PORTAL2_TEXTS.tooltips.addressName
          )}
          {renderInputField(MedicalOrgBillingEntityFormFields.businessAddress)}

          <Row className="mt10">
            <Col span={22}>
              <Row gutter={30}>
                <Col span={8}>
                  <FormItem
                    info={form.getInfo('state')}
                  >
                    <USStateSelectComponent />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    info={form.getInfo('city')}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    info={form.getInfo('zip')}
                  >
                    <Input />
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <Divider />
          <Button
            type="primary"
            className="w100"
            htmlType="submit"
          >
            Save
          </Button>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};

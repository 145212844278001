import { Radio, RadioGroupProps } from 'antd';
import { UserTypeEnum } from '../../../../uc-api-sdk';

export interface UserTypeInputComponentProps extends RadioGroupProps {
}

export const UserTypeInputComponent = ({
  onChange,
  value,
  disabled
}: UserTypeInputComponentProps) => (
  <Radio.Group onChange={onChange} value={value} disabled={disabled}>
    <Radio value={UserTypeEnum.IHEALTH_EMPLOYEE}>iHealth employee</Radio>
    <Radio value={UserTypeEnum.CLINICAL_STAFF}>Clinical staffs</Radio>
  </Radio.Group>
);

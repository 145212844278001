import { Radio, RadioGroupProps, Space } from 'antd';
import { PriorityLevelEnum } from '../../../../uc-api-sdk';
import { PriorityLevelEnumComponent } from '../../../../enumComponent/PriorityLevelEnumComponent/PriorityLevelEnumComponent';

export interface PriorityRadioComponentProps extends RadioGroupProps {
}

export const PriorityRadioComponent = ({
  value,
  onChange,
  ...props
}: PriorityRadioComponentProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Radio.Group onChange={onChange} value={value} {...props}>
    <Space>
      <Radio
        value={PriorityLevelEnum.HIGH}
      >
        <PriorityLevelEnumComponent value={PriorityLevelEnum.HIGH} type="long" />
      </Radio>
      <Radio
        value={PriorityLevelEnum.MEDIUM}
      >
        <PriorityLevelEnumComponent value={PriorityLevelEnum.MEDIUM} type="long" />
      </Radio>
      <Radio
        value={PriorityLevelEnum.LOW}
      >
        <PriorityLevelEnumComponent value={PriorityLevelEnum.LOW} type="long" />
      </Radio>
    </Space>
  </Radio.Group>
);

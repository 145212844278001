import { Button, Card, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import { OperatorEnum } from '../../type';
import { InputType } from '../../../Input/types';
import { OperatorComponent, OperatorComponentProps } from '../../inputComponent/OperatorComponent/OperatorComponent';
import { useConditionsContext } from '../../context/ConditionContext';
import { MetricValueTypeEnum } from '../../../../uc-api-sdk';
import './ConditionComponent.scss';

// TODO: move this to types later
export enum Field {
  Operator = 'operator',
  Filters = 'filters',
}

export type FieldValue = boolean | string | string[] | undefined | OperatorComponentProps['value'];

export interface ConditionComponentProps extends InputType<Record<Field, FieldValue>> {
  onRemove?: () => void;
}

export const ConditionComponent = ({
  value,
  onChange,
  onRemove,
}: ConditionComponentProps) => {
  const { conditionsService } = useConditionsContext();

  // @ts-ignore
  const conditionConfigObj = conditionsService?.getCondition(value?.metric);

  const renderOperators = () => {
    const parseValue: OperatorComponentProps['onChange'] = (v) => {
      const val = v?.value;
      if (val === null || val === undefined) {
        return val;
      }
      switch (conditionConfigObj?.metricValueType) {
        case MetricValueTypeEnum.DOUBLE:
          return parseFloat(val as string);
        case MetricValueTypeEnum.INT:
          return parseInt(val as string, 10);
        case MetricValueTypeEnum.DATE:
        case MetricValueTypeEnum.LIST:
        case MetricValueTypeEnum.BOOLEAN:
        case MetricValueTypeEnum.STRING:
        default:
          return val;
      }
    };

    const handleOperatorChange: OperatorComponentProps['onChange'] = (v) => {
      const val = parseValue(v);
      onChange?.({
        ...(value || {}),
        // @ts-ignore
        operator: v?.operator || value?.operator,
        // @ts-ignore
        value: isNil(val) ? value?.value : val,
      });
    };

    return !!conditionConfigObj?.supportedOperators?.length && (
      <div className="w100">
        <p>{conditionConfigObj?.description}</p>
        <h5>Operator</h5>
        {conditionConfigObj.metricValueType ? (
          <OperatorComponent
            operators={(conditionConfigObj.supportedOperators || []) as OperatorEnum[]}
            metricType={conditionConfigObj.metricValueType}
            valueOptions={conditionConfigObj.supportedValues ?? undefined}
            onChange={handleOperatorChange}
            value={value as OperatorComponentProps['value']}
          />
        ) : <div>Missing metricValueType</div>}
      </div>
    );
  };

  const renderTitle = () => (
    <div className="flex jc-sb ai-c">
      <h4 className="m0">
        {conditionConfigObj?.name}
      </h4>
      <div>
        <Button
          onClick={onRemove}
          icon={<MinusCircleOutlined />}
          type="text"
        />
      </div>
    </div>
  );

  // const renderFilters = () => {
  //   const handleInputChange = (name: ConditionFilterEnum):
  // ConditionFilterComponentProps['onChange'] => (v) => {
  //     handleChange(Field.Filters)({
  //       // @ts-ignore
  //       ...(value?.filters || {}),
  //       [name]: v,
  //     });
  //   };

  //   return !!conditionConfigObj?.supportedFilters?.length && (
  //     <div>
  //       <div className="fs12">Filters</div>
  //       <Space direction="vertical" className="w100">
  //         {map(conditionConfigObj?.supportedFilters, (v, i) => (
  //           <div key={i}>
  //             <h5>
  //               <ConditionFilterEnumComponent value={v.name} />
  //             </h5>
  //             <ConditionFilterComponent
  //               filter={v}
  //               onChange={handleInputChange(v.name)}
  //               // @ts-ignore
  //               value={value?.[Field.Filters]?.[v.name]}
  //             />
  //           </div>
  //         ))}
  //       </Space>
  //     </div>
  //   );
  // };

  return (
    <Card className="condition-component" title={renderTitle()}>
      <Space direction="vertical" size="small" className="w100">
        {renderOperators()}
        {/* {renderFilters()} */}
      </Space>
    </Card>
  );
};

import { map } from 'lodash';
import moment from 'moment';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { DefaultDrawerProps } from '../../../../types/drawer';
import { VersionDetail, useAppVersionConfigInsert } from '../../../../uc-api-sdk';
import { CreateEditMobileVersionConfigDrawerComponent, CreateEditMobileVersionConfigFormValue } from '../../component/CreateEditMobileVersionConfigDrawerComponent/CreateEditMobileVersionConfigDrawerComponent';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface CreateEditMobileVersionConfigDrawerContainerProps extends DefaultDrawerProps {}

export const CreateEditMobileVersionConfigDrawerContainer = ({
  onSuccess,
}: CreateEditMobileVersionConfigDrawerContainerProps) => {
  const createConfig = useAppVersionConfigInsert({});
  const createHook = useUpdate('MOBILE_VERSION_CONFIG_CREATED');

  const handleInsert = (v: CreateEditMobileVersionConfigFormValue) => {
    const versionDetails = map(v.versionDetails, (versionDetail) => ({
      version: versionDetail,
      updateTime: undefined,
    })) as VersionDetail[];
    const result = createConfig.send({
      params: {
        document: {
          activateDate: moment(v.activateDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
          versionDetails: versionDetails as VersionDetail[],
          note: v.note || '',
          name: v.name || '',
          contents: v.contents || [],
          medOrgIds: v.medOrgIds || [],
        }
      }
    });

    ApiRequestHelper.tryCatch(result, {
      error: 'An error occurred. Please try again later.',
      success: 'Mobile Versions Configuration created.',
      onSuccess: () => {
        onSuccess?.();
        createHook.updateValue();
      }
    });
  };

  return (
    <CreateEditMobileVersionConfigDrawerComponent
      onSubmit={handleInsert}
    />
  );
};

import { map } from 'lodash';
import { DEFAULT_ICD_SEARCH_SIZE } from '../constant';
import {
  HealthConditionItem,
  Icd10Billable,
  PatientDiagnosesEnum,
  PatientProtocolEnum,
} from '../../../uc-api-sdk';

export const getProtocolFromDiagnoses = (diagnoses?: PatientDiagnosesEnum[] | null) => {
  const defaultProtocol = PatientProtocolEnum.OTHER;
  if (!diagnoses?.length) return defaultProtocol;
  if (diagnoses?.includes(PatientDiagnosesEnum.DM2)) {
    return PatientProtocolEnum.DM2;
  }
  if (diagnoses?.includes(PatientDiagnosesEnum.HTN)) {
    return PatientProtocolEnum.HTN;
  }
  return PatientProtocolEnum.OTHER;
};

export class ICDCodeHelpers {
  static icdSelectFieldName = 'icdSelect';

  static icdTableFieldName = 'icdTable';

  static makeSearchParams(
    searchValue: string,
    page: number,
  ) {
    const params = {
      searchRequest: {
        filter: {
          nameNear: searchValue,
        },
        pageInfo: {
          page,
          size: DEFAULT_ICD_SEARCH_SIZE,
        },
      },
    };
    return params;
  }

  static getHealthConditionFromIcd10(icdCode?: Icd10Billable) {
    if (!icdCode) return icdCode;
    return {
      ...icdCode,
      code: icdCode.code,
      condition: icdCode.title,
      isBillable: false,
    } as HealthConditionItem;
  }

  static getFormValues<T extends Record<string, unknown>>(
    formValues: T,
    icdTableFieldName = this.icdTableFieldName,
  ) {
    const tableValues = formValues[icdTableFieldName] as HealthConditionItem[];
    if (Array.isArray(tableValues)) {
      return map(tableValues, (v) => ({
        code: v.code,
        condition: v.condition,
        isBillable: v.isBillable,
      }));
    }
    return undefined;
  }
}

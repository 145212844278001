import { TabsProps } from 'antd';
import { MedicalOrgConfigurationComponent } from '../MedicalOrgConfigurationComponent/MedicalOrgConfigurationComponent';
import { AdminPortalTabsComponent } from '../../../layout/AdminPortalTabsComponent/AdminPortalTabsComponent';
import { ClinicalStaffManagementContainer } from '../../container/ClinicalStaffManagementContainer/ClinicalStaffManagementContainer';
import { MedicalOrgServiceConfigurationComponent } from '../MedicalOrgServiceConfigurationComponent/MedicalOrgServiceConfigurationComponent';
import { MedicalOrgAlertRelatedComponent } from '../MedicalOrgAlertRelatedComponent/MedicalOrgAlertRelatedComponent';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';

export interface MedicalOrgInfoComponentProps {
}

export const TABS_KEYS = {
  orgAndBillingSetting: 'orgAndBillingSetting',
  serviceConfiguration: 'configuration',
  clinicalStaffManagement: 'clinicalStaffManagement',
  alertRelated: 'alertRelated',
};

export const MedicalOrgInfoComponent = () => {
  const { currTab, setCurrTab } = useMedicalOrgContext();

  const items: TabsProps['items'] = [
    {
      label: 'Org and Billing Setting',
      key: TABS_KEYS.orgAndBillingSetting,
      children: <MedicalOrgConfigurationComponent />,
    },
    {
      label: 'Service Configuration',
      key: TABS_KEYS.serviceConfiguration,
      children: <MedicalOrgServiceConfigurationComponent />,
    },
    {
      label: 'Clinical Staff Management',
      key: TABS_KEYS.clinicalStaffManagement,
      children: <ClinicalStaffManagementContainer />,
    },
    {
      label: 'Alert Related',
      key: TABS_KEYS.alertRelated,
      children: <MedicalOrgAlertRelatedComponent />,
    },
  ];

  const onTabChange: TabsProps['onChange'] = (key) => {
    setCurrTab(key);
  };

  return (
    <AdminPortalTabsComponent
      className="medical-org-info-component"
      onChange={onTabChange}
      activeKey={currTab}
      items={items}
    />
  );
};

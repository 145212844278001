import { filter, map } from 'lodash';
import { MedicationRouteEnumComponent } from '../../../../enumComponent/MedicationRouteEnumComponent/MedicationRouteEnumComponent';
import { TooltipComponent } from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { MEDICATION_ROUTE_ORDER } from '../../constant/constant';
import { MedicationRouteEnum } from '../../../../uc-api-sdk';

export interface MedicationRouteTooltipComponentProps {
}
export const MedicationRouteTooltipComponent = () => {
  const routes = filter(MEDICATION_ROUTE_ORDER, (v) => v !== MedicationRouteEnum.OTHER);
  const title = (
    <ul>
      {map(routes, (v) => (
        <li key={v}>
          <b>
            <MedicationRouteEnumComponent value={v} />
          </b>
          {' - '}
          <MedicationRouteEnumComponent value={v} isDescription />
        </li>
      ))}
    </ul>
  );

  return (
    <TooltipComponent
      overlayInnerStyle={{ width: 500 }}
      title={title}
      type="info-icon"
    />
  );
};

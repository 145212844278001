import { map } from 'lodash';
import { SymptomSeverityEnum } from '../../../uc-api-sdk';
import { SymptomSeverityEnumComponent } from '../../symptom/component/SymptomSeverityEnumComponent/SymptomSeverityEnumComponent';
import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createSymptomSeverityFilter = (): TableFilterType => {
  const options = map([
    SymptomSeverityEnum.MILD,
    SymptomSeverityEnum.MODERATE,
    SymptomSeverityEnum.SEVERE,
    SymptomSeverityEnum.NOT_SURE,
    SymptomSeverityEnum.NA,
  ], (p) => ({
    label: (
      <SymptomSeverityEnumComponent
        value={p as SymptomSeverityEnum}
        type="tag"
      />
    ),
    value: p,
  }));

  return ({
    filterDropdown: ((props) => (
      <CheckboxFilterComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        filterOptions={options}
      />
    )),
    filterIcon: FilterIcon,
  });
};

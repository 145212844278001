import { BpTriageLevelEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../Input/types';
import { BPTriageLevelEnumComponent } from '../../enumComponent/BPTriageLevelEnumComponent/BPTriageLevelEnumComponent';

export interface BPTriageLevelSelectComponentProps extends InputType<BpTriageLevelEnum> {
  options: BpTriageLevelEnum[];
}

export const BPTriageLevelSelectComponent = ({
  options,
  value,
  onChange,
}: BPTriageLevelSelectComponentProps) => (
  <EnumSelectComponent
    className="w100"
    options={options}
    // @ts-ignore
    optionRenderer={BPTriageLevelEnumComponent}
    // @ts-ignore
    onChange={onChange}
    // @ts-ignore
    value={value}
  />
);

import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { FC } from 'react';
import { SymptomLog } from '../../../../uc-api-sdk';
import { SymptomDeleteComponent } from './SymptomDeleteComponent';
import { SymptomEditComponent } from '../SymptomEditComponent/SymptomEditComponent';
import { SymptomTableRowValue } from './columns';

export interface SymptomSettingComponentProps {
  symptomRowValue?: SymptomTableRowValue;
}
export const SymptomSettingComponent: FC<SymptomSettingComponentProps> = ({
  symptomRowValue,
}) => {
  const editSymptomLog = {
    id: symptomRowValue?.id,
    patientId: symptomRowValue?.patientId || '',
    symptomList: symptomRowValue?.originalValue?.symptomList,
  };

  const items = [
    {
      key: 1,
      label: (
        <SymptomEditComponent
          initialValue={editSymptomLog as SymptomLog}
        />
      ),
    },
    {
      key: 2,
      label: (
        <SymptomDeleteComponent
          id={symptomRowValue?.id || undefined}
        />
      ),
    },
  ];
  return (
    <Dropdown
      overlayClassName="button-in-dropdown"
      className="medicationSettings jc-e no-border"
      trigger={['click']}
      menu={{ items }}
    >
      <MoreOutlined />
    </Dropdown>
  );
};

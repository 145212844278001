import { Image, Typography } from 'antd';
import tooltipRight from '../../../../assets/icons/new-bp-tooltip-right.svg';
import tooltipLeft from '../../../../assets/icons/new-bp-tooltip-left.svg';

const { Text } = Typography;

export const BPTooltipComponent = () => (
  <div>
    <Text className="ml12 mt12 b7 fs14">
      How to Read BP Log Book
    </Text>
    <div className="flex">
      <Image className="ml12 mt20" src={tooltipLeft} preview={false} width={377} />
      <Image className="mt20 mb10 mr10 ml20" src={tooltipRight} preview={false} width={334} />
    </div>
  </div>
);

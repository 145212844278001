import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { DefaultDrawerProps } from '../../../../../types/drawer';
import { useMedicalOrganizationUpdateOutstandingConfig } from '../../../../../uc-api-sdk';
import { EditOutstandingItemConfigDrawerComponent } from '../../component/EditOutstandingItemConfigDrawerComponent/EditOutstandingItemConfigDrawerComponent';
import { OutstandingItemConfigFormValues } from '../../component/MedicalOrgOutstandingItemConfigFormComponent/MedicalOrgOutstandingItemConfigFormComponent';

export interface EditOutstandingItemConfigDrawerContainerProps extends DefaultDrawerProps { }

export const EditOutstandingItemConfigDrawerContainer = ({
  ...props
}: EditOutstandingItemConfigDrawerContainerProps) => {
  const hook = useMedicalOrganizationUpdateOutstandingConfig({ options: { sendOnMount: false } });

  const handleSubmit = (values: OutstandingItemConfigFormValues) => {
    // eslint-disable-next-line no-console
    if (!props.initialValues?.id) {
      return;
    }
    const request = hook.send({
      params: {
        id: props.initialValues?.id,
        configUpdate: {
          medicalAlertTrigger: values.medicalAlertTrigger || false,
          complianceAlertTrigger: values.complianceAlertTrigger || false,
          onboardingTodoTrigger: values.onboardingTodoTrigger || false,
          mtprTrigger: values.mtprTrigger || false,
          sendAutoMessageTrigger: values.sendAutoMessageTrigger || false,
          messageTrigger: values.messageTrigger || false,
          foodLogTrigger: values.foodLogTrigger || false,
        }
      },
    });

    ApiRequestHelper.tryCatch(request, {
      success: 'Service updated!',
      error: 'An error occurred. Please try again later.',
      onSuccess() {
        props.onSuccess?.();
      },
    });
  };
  return (
    <div>
      <EditOutstandingItemConfigDrawerComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../types/form';
import {
  CareQualityStatus, EhrClinic, EhrServiceName, FileInfo
} from '../../../uc-api-sdk';

export interface MedicalOrgEHRFormSubmitValue {
  ehrData: EhrServiceName;
  careQuality: boolean;
  fileInfo?: FileInfo;
  history?: CareQualityStatus[];
  ehrIntegrationAvailable: boolean;
  enableAdvancedConfiguration?: boolean;
  athenaPracticeId?: string;
  departments?: EhrClinic[];
  autoClaimSubmit?: boolean;
}

export const useMedicalOrgEHRForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    ehrData: {
      name: 'ehrData',
      label: 'Service provider',
    },
    ehrIntegrationAvailable: {
      name: 'ehrIntegrationAvailable',
      label: 'Is EHR integration available at this medical organization?',
    },
    enableAdvancedConfiguration: {
      name: 'enableAdvancedConfiguration',
      label: 'Enable advanced configuration',
    },
    athenaPracticeId: {
      name: 'athenaPracticeId',
      label: 'Athena Practice ID',
    },
    departments: {
      name: 'departments',
      label: 'Select Departments',
    },
    autoClaimSubmit: {
      name: 'autoClaimSubmit',
      label: 'Enable Auto Claim Submit',
    },
    careQuality: {
      name: 'careQuality',
      label: 'Enable Care Quality for this medical organization?',
    },
    defaultMedicalOrg: {
      name: 'defaultMedicalOrg',
      label: 'Default Medical Organization',
    },
  }, options);

  const shouldShowAdvancedConfiguration = (getFieldValue: GetFieldValue) => getFieldValue('enableAdvancedConfiguration') && getFieldValue('ehrIntegrationAvailable')
    && getFieldValue('ehrData') === EhrServiceName.ATHENA;

  const showEnableAdvancedConfigurationRadio = (getFieldValue: GetFieldValue) => getFieldValue('ehrIntegrationAvailable') && getFieldValue('ehrData') === EhrServiceName.ATHENA;

  const response = useMemo(() => ({
    ...factory,
    shouldShowAdvancedConfiguration,
    showEnableAdvancedConfigurationRadio
  }), [factory]);

  return response;
};

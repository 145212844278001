import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BlueBackgroundComponent } from '../BlueBackgroundComponent/BlueBackgroundComponent';
import { PerCodeFeeModelButtonComponent } from '../PerCodeFeeModelButtonComponent/PerCodeFeeModelButtonComponent';

export const PerCodeFeeModelFormItemComponent = () => (
  <BlueBackgroundComponent>
    <FormItem
      name={['perCode', 'dataServiceFee']}
      required
    >
      <PerCodeFeeModelButtonComponent
        options={[20, 25, 30]}
        title="Data Service Fee"
      />
    </FormItem>
    <FormItem
      name={['perCode', 'first20MinutesFee']}
      required
    >
      <PerCodeFeeModelButtonComponent
        options={[20, 25, 30]}
        title="Fee for first 20 minutes billable time"
      />
    </FormItem>
    <FormItem
      name={['perCode', 'moreThan40MinutesFee']}
      required
    >
      <PerCodeFeeModelButtonComponent
        options={[40, 50, 60]}
        title="Fee for 40+ minutes billable time"
      />
    </FormItem>
  </BlueBackgroundComponent>
);

import { Checkbox, Space } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState } from 'react';
import { RoleTypeEnumComponent } from '../../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { MedicalOrganizationHelper } from '../../../../../helpers/medicalOrganization';
import { Employee, RoleTypeEnum } from '../../../../../uc-api-sdk';
import { EmployeeSelectComponentInfo } from '../../../../Employee/component/EmployeeSelectComponent/EmployeeSelectComponent';
import { ClinicalStaffRolesInCareUnit } from '../../../CareOpHierarchy/constants/constant';
import { useRoleLimit } from '../../../hook/useRoleLimit';

export interface StaffRolesCheckboxGroupComponentProps extends CheckboxGroupProps {
  hasPatientsCheck?: RoleTypeEnum[];
  useNamedValues?: boolean;
  data?: EmployeeSelectComponentInfo<Employee>
}

export const StaffRolesCheckboxGroupComponent = ({
  onChange,
  hasPatientsCheck,
  name,
  useNamedValues,
  data,
  ...props
}: StaffRolesCheckboxGroupComponentProps) => {
  const { shouldDisableRole } = useRoleLimit();
  const rolesInOtherClinics = data?.info?.assignedRoles?.map((role) => role.roleType) || [];
  const [selectedValues, setSelectedValues] = useState<CheckboxValueType[]>([]);
  const conflictingRoles = MedicalOrganizationHelper.getClinicalStaffConflictingRoles(
    rolesInOtherClinics
  );

  const handleChange: CheckboxGroupProps['onChange'] = (values) => {
    setSelectedValues(values);
    if (useNamedValues) {
      const formattedValues = values.map((v) => `${name}-${v.toString()}`);
      onChange?.(formattedValues);
    } else {
      onChange?.(values);
    }
  };

  return (
    <Checkbox.Group
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      name={name}
      value={selectedValues}
      className="flex jc-sb"
      onChange={handleChange}
    >
      <Space direction="vertical">
        {
          ClinicalStaffRolesInCareUnit.map((role) => (
            <Checkbox
              value={role}
              key={role}
              disabled={(
                shouldDisableRole(role as RoleTypeEnum, selectedValues as RoleTypeEnum[])
                || hasPatientsCheck?.includes(role as RoleTypeEnum)
                || conflictingRoles.includes(role as RoleTypeEnum)
              )}
            >
              <RoleTypeEnumComponent value={role as RoleTypeEnum} />
            </Checkbox>
          ))
        }
      </Space>
    </Checkbox.Group>
  );
};

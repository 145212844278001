import {
  Button, Form, Input, InputNumber, Select, Space, Spin
} from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import { useBoolean } from 'usehooks-ts';
import { useUpdateListener } from '../../../../../contexts/UpdateContext/UpdateContext';
import { OverlayComponent } from '../../../../../uiComponent/overlayComponent/OverlayComponent';
import { FormType } from '../../../../Input/types';
import { useCustomizedAlertsForm } from '../../hook/useCustomizedAlertsForm';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import FormSubmitButton, { FormSubmitButtonProps } from '../../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import { PriorityRadioComponent } from '../../../component/PriorityRadioComponent/PriorityRadioComponent';
import { CustomizedAlertTypeRadioComponent } from '../../../component/CustomizedAlertTypeRadioComponent/CustomizedAlertTypeRadioComponent';
import { CustomizedAlertCategoryRadioComponent } from '../../../component/CustomizedAlertCategoryRadioComponent/CustomizedAlertCategoryRadioComponent';
import { Title } from '../../../../../uiComponent/Title/Title';
import { AlertCategoryEnum, EventTypeEnum, PriorityLevelEnum } from '../../../../../uc-api-sdk';
import './CustomizedAlertsFormComponent.scss';

export interface CustomizedAlertsFormSubmitValue {
  name?: string;
  description?: string;
  priority?: PriorityLevelEnum;
  type?: EventTypeEnum;
  category?: AlertCategoryEnum;
  muteLogic?: number;
  muteLogicScale?: string;
}

export interface CustomizedAlertsFormComponentProps
extends FormType<CustomizedAlertsFormSubmitValue>{
  initialLoading?: boolean,
  mode: 'Create' | 'Edit',
}

export const CustomizedAlertsFormComponent = ({
  onSubmit,
  onCancel,
  initialLoading,
  initialValues,
  mode = 'Create',
}: CustomizedAlertsFormComponentProps) => {
  const hook = useCustomizedAlertsForm();
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const handleSubmit = async (v: CustomizedAlertsFormSubmitValue) => {
    try {
      const submit = hook.handleSubmitAndReset(onSubmit);
      setTrue();
      await submit?.(v);
      setFalse();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  useUpdateListener('CUSTOMIZED_ALERT_UPDATED', hook.handleReset);

  const shouldBeDisabled: FormSubmitButtonProps['shouldBeDisabled'] = (values: CustomizedAlertsFormSubmitValue) => {
    // disable submit button if required is undefined
    // or if it is loading
    const required = values.name === undefined
    || values.priority === undefined
    || values.type === undefined
    || values.category === undefined;
    const disable = required || isLoading;
    return disable;
  };
  const { Option } = Select;

  const selectAfter = (
    <Select>
      <Option value="Day">Day</Option>
      <Option value="Hours">Hours</Option>
    </Select>
  );

  return (
    <OverlayComponent
      overlay={<Spin size="large" />}
      showOverlay={initialLoading}
    >
      <Form
        form={hook.form}
        onFinish={handleSubmit}
        className="customized-alert-component"
        initialValues={initialValues}
        layout="vertical"
      >
        <Title className="mb30">
          {mode === 'Create'
            ? 'Create Customized Alert'
            : 'Alert Basic Information'}
        </Title>
        <FormItem
          info={hook.getInfo('name')}
          label={hook.getLabel('name')}
          required
        >
          <Input type="text" className="customized-alerts-name" />
        </FormItem>
        <FormItem
          info={hook.getInfo('description')}
          label={hook.getLabel('description')}
        >
          <Input type="text" />
        </FormItem>
        <FormItem info={hook.getInfo('priority')} label={hook.getLabel('priority')} required>
          <PriorityRadioComponent />
        </FormItem>
        <FormItem info={hook.getInfo('type')} label={hook.getLabel('type')} required>
          <CustomizedAlertTypeRadioComponent disabled={mode === 'Edit'} />
        </FormItem>
        <div className="mb10 text-gray-scale-2">
          <ExclamationCircleFilled className="customized-alerts-warning" />
          {' '}
          Type can't be changed after creation.
        </div>
        <FormItem info={hook.getInfo('category')} label={hook.getLabel('category')} required>
          <CustomizedAlertCategoryRadioComponent />
        </FormItem>
        <div className="mb10 text-gray-scale-2">
          <ExclamationCircleFilled className="customized-alerts-warning" />
          {' '}
          Type can't be changed after creation.
        </div>
        <div>
          <div className="text-gray-scale-2 customized-alerts-mute-logic-title">
            Mute Logic
          </div>
          <div className="flex ai-c">
            If the alert has been generated in the past
            {' '}
            <FormItem info={hook.getInfo('muteLogic')} className="customized-alerts-mute-logic-form-item">
              <InputNumber
                className="customized-alerts-mute-logic"
                min={0}
                precision={0}
                addonAfter={(
                  <FormItem
                    info={hook.getInfo('muteLogicScale')}
                    className="customized-alerts-scale"
                  >
                    {selectAfter}
                  </FormItem>
                )}
              />
            </FormItem>
            , do not generate the alert.
          </div>
        </div>
        <div className="mt30">
          <Space className="flex jc-e" size={20}>
            <Button onClick={onCancel}>Cancel</Button>
            <FormSubmitButton
              text={mode === 'Create' ? 'Create' : 'Save'}
              shouldBeDisabled={shouldBeDisabled}
              isLoading={isLoading}
            />
          </Space>
        </div>
      </Form>
    </OverlayComponent>
  );
};

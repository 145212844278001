import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  BillingGenerationEnum, InvoiceCycleEnum, ProgramCategoryEnum, useMedicalOrganizationUpdate
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MedicalOrgProgramParticipationDrawerComponent } from '../../component/MedicalOrgProgramParticipationDrawerComponent/MedicalOrgProgramParticipationDrawerComponent';
import { ProgramFormValues } from '../../component/MedicalOrgProgramParticipationFormComponent/MedicalOrgProgramParticipationFormComponent';

export interface MedicalOrgProgramParticipationContainerProps {
  initialValues?: ProgramFormValues;
  onSuccess?: () => void;
}

export const MedicalOrgProgramParticipationContainer = ({
  initialValues,
  onSuccess,
}: MedicalOrgProgramParticipationContainerProps) => {
  const hook = useMedicalOrganizationUpdate({});

  const onUpdate = async (values: ProgramFormValues) => {
    if (!initialValues?.clinicId || !(values.programType)) {
      return;
    }
    const programParticipation = values.programs?.map((program) => ({
      name: program,
      consentFormId: values.consentFormId1,
    })) || [];

    const feeModel = {
      type: values.feeModel,
      perCode: values.perCode,
      pmapm: values.pmapm,
      pepm: values.pepm,
      pbpm: values.pbpm,
      invoiceCycle: values.invoiceCycle || InvoiceCycleEnum.MONTHLY,
      isReportRequiredForInsurance: values.isReportRequiredForInsurance || false,
      billingGeneration: values.billingGeneration || BillingGenerationEnum.SINGLE,
      codes: Object.entries(values.codeSettings || {})
        .filter(r => r[1].checked).map(r => ({ ...r[1], code: r[0] }))
    };

    const result = hook.send({
      params: {
        id: initialValues?.clinicId || '',
        $set: {
          programParticipation: values.programType === 'ccmRpm'
            ? programParticipation : [{
              name: ProgramCategoryEnum.VALUE_BASED,
              consentFormId: values.consentFormId2,
            }],
          feeModel,
          isSaasModel: values.isSaasModel as unknown as object,
        }
      }
    });

    await ApiRequestHelper.tryCatch(result, {
      success: 'Program Participation updated!',
      error: 'An error occurred. Please try again later.',
      onSuccess
    });
  };

  return (
    <FetchComponent
      info={hook}
      alwaysShowChildren
    >
      {() => (
        <MedicalOrgProgramParticipationDrawerComponent
          onSubmit={onUpdate}
          initialValues={initialValues}
        />
      )}
    </FetchComponent>
  );
};

import {
  Button,
  Checkbox,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { useState } from 'react';
import { AlertRule } from '../../type';
import { ConditionConfigComponent } from '../ConditionConfigComponent/ConditionConfigComponent';
import { ConditionsContextProvider } from '../../context/ConditionContext';
import { RuleDryRunContainer } from '../../container/RuleDryRunContainer/RuleDryRunContainer';
import { ActionTypeEnum, Condition, EventTypeEnum } from '../../../../uc-api-sdk';
import './RuleOptionsComponent.scss';

export interface OnSubmitOptions {
  name: string;
  conditions: Condition[];
  timeWindow?: string | null;
  ruleType?: ActionTypeEnum;
}

export interface RuleOptionsComponentProps {
  alertType: EventTypeEnum;
  rule?: AlertRule;
  onSubmit?: (v: OnSubmitOptions) => void;
  isSubmitting?: boolean;
}

export const RuleOptionsComponent = ({
  alertType,
  rule,
  onSubmit,
  isSubmitting,
}: RuleOptionsComponentProps) => {
  const [conditions, setConditions] = useState<Condition[]>(rule?.conditions || []);
  const [ruleName, setRuleName] = useState<string>(rule?.name || '');
  const [isRecheck, setIsRecheck] = useState<boolean>(
    rule?.ruleType === ActionTypeEnum.RECHECK
  );
  const [timeWindow, setTimeWindow] = useState<string >(rule?.timeWindow?.match(/(\d+)/)?.[0] || '');
  const [timeWindowUnit, setTimeWindowUnit] = useState<string>(rule?.timeWindow?.replace(/(\d+)/, '') || 'd');

  const handleSubmit = () => {
    onSubmit?.({
      name: ruleName,
      conditions,
      ruleType: isRecheck ? ActionTypeEnum.RECHECK : ActionTypeEnum.GENERATE,
      timeWindow: timeWindow && timeWindowUnit ? timeWindow + timeWindowUnit : null,
    });
  };

  const renderHeader = () => (
    <div className="mb20">
      <h4>Rule name</h4>
      <Input
        placeholder="Rule Name"
        value={ruleName}
        onChange={(e) => setRuleName(e.target.value)}
      />
      <Checkbox
        className="my10"
        checked={isRecheck}
        onChange={(e) => setIsRecheck(e.target.checked)}
      >
        Recheck
      </Checkbox>
      {isRecheck && (
        <>
          <h4>Window time (in min)</h4>
          <InputNumber
            value={parseInt(timeWindow, 10)}
            onChange={(v) => setTimeWindow(v?.toString() || '')}
          />
          <Select
            onChange={setTimeWindowUnit}
            value={timeWindowUnit}
          >
            <Select.Option value="m">Mins</Select.Option>
            <Select.Option value="d">Days</Select.Option>
            <Select.Option value="h">Hours</Select.Option>
          </Select>
        </>
      )}
    </div>
  );

  return (
    <div className="rule-options-component">
      <div className="rule-options-component__content">
        <div className="rule-options-component__rule">
          {renderHeader()}
          <div className="conditions-wrapper">
            <ConditionsContextProvider eventType={alertType}>
              <ConditionConfigComponent
                value={conditions || []}
                onChange={(v = []) => setConditions(v)}
              />
            </ConditionsContextProvider>
          </div>
        </div>
        <RuleDryRunContainer
          type={alertType}
          rule={{
            name: ruleName,
            ruleType: isRecheck ? ActionTypeEnum.RECHECK : ActionTypeEnum.GENERATE,
            timeWindow,
            conditions,
          }}
        />
      </div>
      <div className="flex jc-e pt20">
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

import { Modal, ModalProps } from 'antd';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import { renderNodeOrString } from '../../helpers/render/render';
import CardHoverEditComponent from '../CardHoverEditComponent/CardHoverEditComponent';
import CardWHorizontalSectionItemComponent from '../CardWHorizontalSectionComponent/CardWHorizontalSectionItemComponent';
import { Title } from '../Title/Title';

export interface FormComponentProps {
  onCancel?: () => void;
}

export interface CardEditWithHeaderProps<
  T = unknown
> {
  isSubmitted?: Date;
  onEdit?: () => void;
  editModalContent?: ReactElement<T>;
  header?: ReactNode | string;
  subHeader?: ReactNode | string;
  children: ReactNode | ReactNode[];
  modal?: ModalProps;
  showEditLabel?: boolean;
}

export const CardEditWithHeader = <T extends FormComponentProps>({
  isSubmitted,
  onEdit,
  editModalContent,
  header,
  subHeader,
  children,
  modal,
  showEditLabel,
}: CardEditWithHeaderProps<T>) => {
  const { value: isOpen, setTrue, setFalse } = useBoolean(false);
  const renderHeader = () => (
    <div>
      {header && renderNodeOrString(header, <Title noMargin>{header}</Title>)}
      {subHeader && renderNodeOrString(subHeader, <Title.SubTitle>{subHeader}</Title.SubTitle>)}
    </div>
  );

  const handleEdit = () => {
    onEdit?.();
    if (editModalContent) {
      setTrue();
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setFalse();
    }
  }, [isSubmitted]);

  const editModalContentWithProps = React.Children.map(editModalContent, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement, { onCancel: setFalse });
    }
    return child;
  });

  return (
    <>
      {editModalContent && (
        <Modal
          open={isOpen}
          onCancel={setFalse}
          destroyOnClose
          footer={null}
          width={700}
          maskClosable={!!modal?.maskClosable}
        >
          {editModalContentWithProps}
        </Modal>
      )}
      <CardHoverEditComponent
        onEdit={handleEdit}
        showEditLabel={showEditLabel}
      >
        {header && (
          <CardHoverEditComponent.Item isHeader>
            {renderHeader()}
          </CardHoverEditComponent.Item>
        )}
        {children}
      </CardHoverEditComponent>
    </>
  );
};

CardEditWithHeader.Item = CardWHorizontalSectionItemComponent;

export default CardEditWithHeader;

import { message } from 'antd';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import {
  MedicalOrganizationStatus,
  useMedicalOrganizationInsert,
  useMedicalOrganizationUpdate
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { CreateMedicalOrganizationFormValue } from '../../component/CreateMedicalOrganizationFormComponent/CreateMedicalOrganizationFormComponent';
import { CreateMedicalOrganizationFormDrawerComponent } from '../../component/CreateMedicalOrganizationFormDrawerComponent/CreateMedicalOrganizationFormDrawerComponent';

/**
 * Container component for creating a medical organization form drawer.
 * @returns JSX.Element
 */
export interface CreateMedicalOrganizationFormDrawerContainerProps {
  mode?: 'edit' | 'create';
  initialValues?: CreateMedicalOrganizationFormValue;
  onSuccess?: () => void;
  showButton?: boolean;
}

export const CreateMedicalOrganizationFormDrawerContainer = ({
  mode = 'create',
  initialValues,
  onSuccess,
  showButton
}: CreateMedicalOrganizationFormDrawerContainerProps) => {
  const createOrg = useMedicalOrganizationInsert({});
  const updateOrg = useMedicalOrganizationUpdate({});
  const { value: submitted, setTrue, setFalse } = useBoolean(false);

  const handleInsert = (v: CreateMedicalOrganizationFormValue) => {
    const result = createOrg.send({
      params: {
        document: {
          status: MedicalOrganizationStatus.ACTIVE,
          type: v.type,
          profile: {
            alias: v.alias || '',
            businessName: v.businessName || '',
            description: v.description || '',
            phone: v.phone || '',
            addresses: v.addresses || [],
            timezone: v.timezone || '',
            orgNumber: v.orgNumber || '',
          }
        }
      }
    });

    ApiRequestHelper.tryCatch(result, {
      error: 'An error occurred. Please try again later.',
      success: undefined,
      onSuccess: () => {
        message.success('Medical organization created.');
        setTrue();
        onSuccess?.();
      }
    });
  };

  const handleUpdate = (v: CreateMedicalOrganizationFormValue) => {
    const result = updateOrg.send({
      params: {
        id: initialValues?.id || '',
        document: {
          profile: {
            alias: v.alias || '',
            businessName: v.businessName || '',
            description: v.description || '',
            phone: v.phone || '',
            addresses: v.addresses || [],
            timezone: v.timezone || '',
            orgNumber: v.orgNumber || '',
          }
        }
      }
    });

    ApiRequestHelper.tryCatch(result, {
      error: 'An error occurred. Please try again later.',
      success: 'Profile updated',
      onSuccess: () => {
        onSuccess?.();
      }
    });
  };

  return (
    <FetchComponent
      info={createOrg}
      alwaysShowChildren
    >
      {
        (value, info) => (
          <CreateMedicalOrganizationFormDrawerComponent
            submitted={submitted}
            initialValues={initialValues}
            onSubmit={mode === 'edit' ? handleUpdate : handleInsert}
            isLoading={info.isLoading}
            mode={mode}
            onClose={setFalse}
            showButton={showButton}
            values={value?.rawValue?.data || undefined}
          />
        )
      }
    </FetchComponent>
  );
};

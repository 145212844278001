import { Form } from 'antd';
import { useCallback, useMemo } from 'react';
import { get, intersection } from 'lodash';
import { ShouldShow, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import {
  PatientDiagnosesEnum, ProgramCategoryEnum, usePatientGetRecommendVitalAndProgram, VitalEnumType
} from '../../../../uc-api-sdk';
import { useBasicInfoForm } from '../useBasicInfoForm/useBasicInfoForm';

export const useProgramEnrollmentForm = () => {
  const form = Form.useFormInstance();
  const basicInfoForm = useBasicInfoForm({ form });
  const getRecommendedVitals = usePatientGetRecommendVitalAndProgram({});
  const factory = useFormHookFactory({
    diagnoses: {
      name: 'diagnoses',
      label: '',
    },
    vitals: {
      name: 'vitals',
      label: '',
    },
    programs: {
      name: 'programs',
      label: '',
    },
    wouldJoin: {
      name: 'wouldJoin',
      label: '',
    },
    notJoiningReason: {
      name: 'notJoiningReason',
      label: 'Reason',
    },
    hasSmartPhone: {
      name: 'hasSmartPhone',
      label: '',
    },
    ...basicInfoForm.formInput,
  }, { form });

  const shouldVitalsAndPrograms: ShouldShow = useCallback((getFieldValue) => (
    !!getFieldValue(factory.getName('diagnoses'))?.length
  ), []);

  const shouldShowNotJoinReason: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('wouldJoin')) === false
  ), []);

  const shouldShowBasicInformation: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('wouldJoin')) === true
  ), []);

  const shouldShowNoSmartPhoneWarning: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('hasSmartPhone')) === false
  ), []);

  const mapVitals = (v: string) => {
    switch (v) {
      case 'isCCM':
        return ProgramCategoryEnum.CCM;
      case 'isRPM':
        return ProgramCategoryEnum.RPM;
      case 'isVBC':
        return ProgramCategoryEnum.VALUE_BASED;
      case 'bloodGlucose': return VitalEnumType.BG;
      case 'bloodPressure': return VitalEnumType.BP;
      case 'weight': return VitalEnumType.HS;
      case 'bloodOxygen': return VitalEnumType.PO;
      default:
        return null;
    }
  };

  const getPrefilledValues = async (
    diagnoses: PatientDiagnosesEnum[],
    availablePrograms: ProgramCategoryEnum[],
  ) => {
    const req = await getRecommendedVitals.send({
      params: {
        diagnoses
      }
    });

    if (req?.code === 200) {
      const data = Object.keys(req?.data || {})
        .filter(r => get(req.data, r)).map(v => mapVitals(v));
      const vitals = data.filter((x) => (
        Object.keys(VitalEnumType).includes(x as VitalEnumType)
      )) as VitalEnumType[];
      const programs = data.filter((y) => (
        Object.keys(ProgramCategoryEnum).includes(y as ProgramCategoryEnum)
      )) as ProgramCategoryEnum[];
      return {
        vitals,
        programs: intersection(availablePrograms, programs),
      };
    }
    return {};
  };

  const response = useMemo(() => ({
    ...basicInfoForm,
    ...factory,
    shouldVitalsAndPrograms,
    shouldShowNotJoinReason,
    shouldShowBasicInformation,
    shouldShowNoSmartPhoneWarning,
    getPrefilledValues,
  }), [
    basicInfoForm,
    factory,
    shouldVitalsAndPrograms,
    shouldShowNotJoinReason,
    shouldShowBasicInformation,
    shouldShowNoSmartPhoneWarning,
    getPrefilledValues,
  ]);

  return response;
};

import { useMemo } from 'react';
import { DisableContextProvider, DisableContextValue } from '../../../../contexts/DisableContext/DisableContext';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { EnrollmentDrawerComponent } from '../../../enrollment/component/EnrollmentDrawerComponent/EnrollmentDrawerComponent';
import { PrintCarePlanModalComponent } from '../../../print/PrintCarePlan/component/PrintCarePlanModalComponent/PrintCarePlanModalComponent';
import { CreateTicketDrawerComponent } from '../../../ticket/component/CreateTicketDrawerComponent/CreateTicketDrawerComponent';
import { PrintVitalModalComponent } from '../../../vitals/component/PrintVitalComponent/PrintVitalModalComponent';
import { usePatientPageControllerContext } from '../../context/PatientPageControllerContext/PatientPageControllerContext';
import { PatientInfoCardComponent as PatientInfoCardComponentBase, PatientInfoCardComponentProps } from './PatientInfoCardComponent';
import { PhysicianReportDrawerComponent } from '../../../print/PhysicianReport/component/PhysicianReportDrawerComponent';

export interface PatientInfoDisableValue extends DisableContextValue {
  disableCreateMTPR: boolean;
}

export interface PatientInfoCardComponentEnhancedProps {
  isLoading?: boolean;
  patientInfo: PatientInfo | undefined;
  hasNewMessage?: boolean;
  onClickChat?: PatientInfoCardComponentProps['onClickChat'];
  showEnrollmentDrawer?: string;
  hyperlinkToPatientProfile?: boolean;
}

export const PatientInfoCardComponent = ({
  isLoading = false,
  patientInfo,
  hasNewMessage,
  onClickChat,
  showEnrollmentDrawer = 'false',
  hyperlinkToPatientProfile = false,
}: PatientInfoCardComponentEnhancedProps) => {
  const {
    isOpen: isEnrollmentDrawerOpen,
    open: openEnrollmentDrawer,
    close: closeEnrollmentDrawer,
  } = useOpen(showEnrollmentDrawer === 'true');
  const {
    isOpen: isPrintVitalModalOpen,
    open: openPrintVitalModal,
    close: closePrintVitalModal,
  } = useOpen();
  const {
    isOpen: isCreateTicketDrawerOpen,
    open: openCreateTicketDrawer,
    close: closeCreateTicketDrawer,
  } = useOpen();
  const {
    isOpen: isPrintCarePlanOpen,
    open: openPrintCarePlanModal,
    close: closePrintCarePlanModal,
  } = useOpen();
  const {
    isOpen: isPrintPhysicianReportOpen,
    open: openPrintPhysicianReport,
    close: closePrintPhysicianReport,
  } = useOpen();
  const {
    isOpen: isPrintPreFollowUpSummaryOpen,
    open: openPreFollowUpSummary,
    close: closePreFollowUpSummary,
  } = useOpen();

  const {
    openManualMTPR
  } = usePatientPageControllerContext();

  const disableValue = useMemo<PatientInfoDisableValue>(() => ({
    disableCreateMTPR: (
      Number(patientInfo?.outstandingService?.outstandingItemCount?.monthlyReviewCount) > 0
    ),
  }), [
    patientInfo?.outstandingService?.outstandingItemCount?.monthlyReviewCount
  ]);

  const handleEnrollmentDrawerClose = () => {
    closeEnrollmentDrawer();
  };

  const refetchControlLevel = () => {
    patientInfo?.refetch(['controlLevelRefetch']);
  };

  useUpdateListener('PATIENT_INFO_UPDATED', () => patientInfo?.refetch());
  useUpdateListener('DEVICE_MONITOR_UPDATED', () => patientInfo?.refetch(['devicesRefetch']));
  useUpdateListener('PROGRAM_UPDATED', refetchControlLevel);
  useUpdateListener('CUSTOMIZED_A1C_GOAL_UPDATED', refetchControlLevel);
  useUpdateListener('A1C_UPDATED', refetchControlLevel);
  useUpdateListener('LAB_EDITED', refetchControlLevel);
  useUpdateListener('LAB_DELETE', refetchControlLevel);
  useUpdateListener('LAB_CREATED', refetchControlLevel);
  useUpdateListener('ICD_UPDATED', refetchControlLevel);

  if (!patientInfo) {
    return <LoadingOverlayComponent isLoading />;
  }

  const { id: patientId = '' } = patientInfo || {};
  const clinicId = patientInfo?.patientInfo?.clinicId;

  return (
    <>
      <DisableContextProvider value={disableValue}>
        <OverlayLoading loading={isLoading} />
        <PatientInfoCardComponentBase
          patientInfo={patientInfo}
          onClickStartEnrollment={openEnrollmentDrawer}
          onClickPrintVitalsData={openPrintVitalModal}
          onClickCreateTask={openCreateTicketDrawer}
          onClickCreateMTPR={openManualMTPR}
          onClickPrintCarePlan={openPrintCarePlanModal}
          onClickPrintPhysicianReport={openPrintPhysicianReport}
          onClickPreFollowUpSummary={openPreFollowUpSummary}
          hasNewMessage={hasNewMessage}
          onClickChat={onClickChat}
          hyperlinkToPatientProfile={hyperlinkToPatientProfile}
        />
      </DisableContextProvider>
      <EnrollmentDrawerComponent
        patientId={patientId}
        onClose={handleEnrollmentDrawerClose}
        isOpen={isEnrollmentDrawerOpen}
      />
      <PrintVitalModalComponent
        open={isPrintVitalModalOpen}
        onClose={closePrintVitalModal}
        memberId={patientId}
      />
      <PrintCarePlanModalComponent
        open={isPrintCarePlanOpen}
        onCancel={closePrintCarePlanModal}
        patientId={patientId}
      />
      <PhysicianReportDrawerComponent
        patientId={patientId}
        isOpen={isPrintPhysicianReportOpen}
        onClose={closePrintPhysicianReport}
      />
      <PhysicianReportDrawerComponent
        patientId={patientId}
        isPreFollowUpSummary
        isOpen={isPrintPreFollowUpSummaryOpen}
        onClose={closePreFollowUpSummary}
      />
      {clinicId ? (
        <CreateTicketDrawerComponent
          patientId={patientId}
          clinicId={clinicId}
          isOpen={isCreateTicketDrawerOpen}
          onClose={closeCreateTicketDrawer}
        />
      ) : null}
    </>
  );
};

import { FC, useMemo } from 'react';
import { SupportDeviceEnum } from '../../features/device/type';
import { NonSmartphoneAlt } from '../../types/devices/smartphoneAlt';
import { AlternativeDeviveType, DeviceModelEnum } from '../../uc-api-sdk';

export interface DeviceNameComponentProps {
  device: NonSmartphoneAlt | DeviceModelEnum | SupportDeviceEnum | AlternativeDeviveType;
}

// FOR ADMIN PORTAL
export const DeviceNameComponent: FC<DeviceNameComponentProps> = ({
  device,
}) => {
  const name = useMemo(() => {
    switch (device) {
      case NonSmartphoneAlt.BpMonitorClear: return 'BP monitor - Clear';
      case NonSmartphoneAlt.LoanerPhone: return 'Loaner phone';
      case DeviceModelEnum.BG1: return 'BG1';
      case DeviceModelEnum.BG5: return 'BG5';
      case DeviceModelEnum.BG5S: return 'BG5S';
      case DeviceModelEnum.BG1S: return 'BG1S';
      case DeviceModelEnum.IPAD_MINI: return 'IPAD_MINI';
      case DeviceModelEnum.BP3L: return 'BP3L';
      case DeviceModelEnum.BP5: return 'BP5';
      case DeviceModelEnum.BP7250: return 'BP7250';
      case DeviceModelEnum.BPM1: return 'BP Clear';
      case DeviceModelEnum.BPM1_HOTSPOT: return 'BPM1_HOTSPOT';
      case DeviceModelEnum.LOAN_DEVICE: return 'LOAN_DEVICE';
      case DeviceModelEnum.PO3: return 'PO3';
      case DeviceModelEnum.HS2: return 'HS2';
      case DeviceModelEnum.HS4S: return 'HS4S';
      case DeviceModelEnum.HS6: return 'HS6';
      case DeviceModelEnum.HS2S: return 'HS2S';
      case DeviceModelEnum.TM3: return 'TM3';
      case DeviceModelEnum.TS28B: return 'TS28B';
      case DeviceModelEnum.PY_802_LTE: return 'BP Cellular Pylo';
      case DeviceModelEnum.BIOLAND: return 'BIOLAND';
      case DeviceModelEnum.EXERCISE: return 'EXERCISE';
      case DeviceModelEnum.KN_550BT: return 'KN_550BT';
      case DeviceModelEnum.BPM5: return 'BPM5';
      case DeviceModelEnum.BP3: return 'BP3';
      case SupportDeviceEnum.HOTSPOT: return 'Hotspot';
      case SupportDeviceEnum.WIFI_ROUTER: return 'Wi-fi Router';
      case AlternativeDeviveType.HOTSPOT: return 'Hotspot';
      case AlternativeDeviveType.WIFI_ROUTER: return 'Wifi Router';
      case AlternativeDeviveType.LOANER_PHONE: return 'Loaner phone';
      case AlternativeDeviveType.BP_CLEAR: return 'BP Clear';
      case AlternativeDeviveType.BP_CELLULAR_PYLO: return 'BP Pylo';

      default: return null;
    }
  }, [device]);

  if (!name) return null;

  return (
    <span data-testid="device-name-component" className="b6">{name}</span>
  );
};

export default DeviceNameComponent;

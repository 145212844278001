import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicalOrgConsentFormContainer } from '../../container/MedicalOrgConsentFormContainer/MedicalOrgConsentFormContainer';
import { useMedicalOrgProgramParticipationForm } from '../../hook/useMedicalOrgProgramParticipationForm';
import { BillingFeeModelFormItemComponent } from '../../medicalOrg/component/BillingFeeModelFormItemComponent/BillingFeeModelFormItemComponent';
import { MedicalOrgParticipatingProgramsComponent } from '../MedicalOrgParticipatingProgramsComponent/MedicalOrgParticipatingProgramsComponent';

export const CcmRpmConfigurationComponent = () => {
  const hook = useMedicalOrgProgramParticipationForm({});
  return (
    <div className="flex fd-c gap2">
      <FormItem dependencies={['programType']} noStyle>
        {({ getFieldValue }) => {
          const show = hook.isCcmRpm(getFieldValue);
          return show && (
            <FormItem
              name={hook.getName('programs')}
              label={null}
              required
            >
              <MedicalOrgParticipatingProgramsComponent />
            </FormItem>

          );
        }}
      </FormItem>
      <FormItem dependencies={['programType', 'programs']}>
        {({ getFieldValue }) => {
          const show = hook.isCcmRpm(getFieldValue);
          return show && (

            <BillingFeeModelFormItemComponent name="feeModel" />
          );
        }}

      </FormItem>
      <FormItem dependencies={['programType']} className="mb20">
        {({ getFieldValue }) => {
          const show = hook.isCcmRpm(getFieldValue);
          return show && (
            <FormItem
              name={hook.getName('consentFormId1')}
              required
            >
              <MedicalOrgConsentFormContainer
                className="mb20"
                programs={
                  [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM]
                }
                defaultActiveFirstOption
              />
            </FormItem>
          );
        }}
      </FormItem>
    </div>
  );
};

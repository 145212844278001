import {
  Button, Form, FormProps, Input
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { AlternativeDeviveType } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { useMedicalOrgServiceForm } from '../../hook/useMedicalOrgServiceForm';
import { CallCenterLocalNumberSelectContainer } from '../../medicalOrg/container/CallCenterLocalNumberSelectContainer/CallCenterLocalNumberSelectContainer';
import { MedicalOrgCobrandingUploadComponent } from '../MedicalOrgCobrandingUploadComponent/MedicalOrgCobrandingUploadComponent';
import { MedicalOrgDeviceSelectComponent } from '../MedicalOrgDeviceSelectComponent/MedicalOrgDeviceSelectComponent';

export interface ServiceFormValues {
  inClinicInventory: boolean;
  callCenterLocalNumber: string;
  nonSmartPhoneAlternatives: AlternativeDeviveType[];
  coBrandName: string;
  logo: string;
  orgId: string;
}

export interface MedicalOrgServiceFormComponentProps extends FormProps {
  orgId: string;
  onSubmit?: (values: ServiceFormValues) => void;
}

export const MedicalOrgServiceFormComponent = ({
  orgId,
  onSubmit,
  ...props
}: MedicalOrgServiceFormComponentProps) => {
  const hook = useMedicalOrgServiceForm({
  });
  const { form } = hook;

  return (
    <Form
      layout="vertical"
      form={form}
      className={classNames('h100 w100', props.className)}
      size="middle"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onFinish={hook.handleSubmit(onSubmit)}
    >
      <FixedComponent className="w100">
        <FixedComponent.Child>
          <div className="flex fd-c gap4">
            <div>
              <Title level={5}>In-clinic inventory</Title>
              <p>
                Will the measurement devices be inventoried in this organization?
                If so, patients have the option to get the device at the clinic during enrollment.
              </p>
              <FormItem
                info={hook.getInfo('inClinicInventory')}
                noStyle
              >
                <RadioGroupComponent
                  direction="vertical"
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </FormItem>
            </div>
            <div>
              <Title level={5}>Call center local number</Title>
              <p>
                If a local number is selected, the caller ID shown on the patient's phone
                when receiving calls will be "iHealth Unified Care + this number"
                instead of our number starting with 866.
              </p>
              <FormItem
                info={hook.getInfo('callCenterLocalNumber')}
                noStyle
              >
                <CallCenterLocalNumberSelectContainer />
              </FormItem>
            </div>
            <div className="mt20">
              <Title level={5}>IoT Devices</Title>
              <p>
                IoT Devices can be offered to patients who don't use smartphones
                for conducting vital measurements.
              </p>
              <FormItem
                info={hook.getInfo('nonSmartPhoneAlternatives')}
                noStyle
              >
                <MedicalOrgDeviceSelectComponent />
              </FormItem>
            </div>
            <div>
              <Title level={5}>Co-branding</Title>
              <p>
                Does this medical organization have co-branding needs? If so,
                the co-branding name and logo will show up in the portal header and
                App for the staffs and patients from this clinical org.
              </p>
              <FormItem
                info={hook.getInfo('coBrandName')}
              >
                <Input />
              </FormItem>
              <FormItem
                info={hook.getInfo('logo')}
              >
                <MedicalOrgCobrandingUploadComponent id={orgId} value={form.getFieldValue('logo')} />
              </FormItem>
            </div>
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="border-t">
          <Button htmlType="submit" className="w100 bold" type="primary">Save</Button>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};

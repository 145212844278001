import { Col, Row } from 'antd';

export interface SelectStaffDropdownRenderComponentProps {
  menu: React.ReactElement;
}

export const SelectStaffDropdownRenderComponent = ({
  menu,
}: SelectStaffDropdownRenderComponentProps) => (
  <>
    <div className="ml20 mr20">
      <div className="fs12 text-secondary-gray mt10 mb10">
        <Row gutter={24}>
          <Col>
            Name
          </Col>
        </Row>
      </div>
    </div>
    <div className="ml10 mr10 mt5 mb5">
      {menu}
    </div>
  </>
);

import { AlternativeDeviveType } from '../../../../../uc-api-sdk';
import { DeviceBundleComponent } from '../../../../device/component/DeviceBundleComponent/DeviceBundleComponent';
import './MedicalOrgDeviceDisplayComponent.scss';

export interface MedicalOrgDeviceDisplayComponentProps {
  devices: AlternativeDeviveType[];
}

export const MedicalOrgDeviceDisplayComponent = ({
  devices,
}: MedicalOrgDeviceDisplayComponentProps) => {
  const renderDevice = (device: AlternativeDeviveType) => {
    switch (device) {
      case AlternativeDeviveType.WIFI_ROUTER:
        return (
          <div className="border p10 secondary-br h100">
            <DeviceBundleComponent devices={[
              AlternativeDeviveType.BP_CLEAR,
              AlternativeDeviveType.WIFI_ROUTER,
            ]}
            />
          </div>
        );
      case AlternativeDeviveType.HOTSPOT:
        return (
          <div className="border p10 secondary-br h100">
            <DeviceBundleComponent devices={[
              AlternativeDeviveType.BP_CLEAR,
              AlternativeDeviveType.HOTSPOT,
            ]}
            />
          </div>
        );
      case AlternativeDeviveType.BP_CELLULAR_PYLO:
        return (
          <div className="border p10 secondary-br h100">
            <DeviceBundleComponent devices={[
              AlternativeDeviveType.BP_CELLULAR_PYLO
            ]}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-wrap">
      {devices.map((device) => (
        <div key={device} className="flex gap2 fd-c ai-c">
          {renderDevice(device)}
        </div>
      ))}
    </div>
  );
};

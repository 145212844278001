import { useMemo } from 'react';
import { map } from 'lodash';
import { useMedicalOrganizationSearch } from '../../../../uc-api-sdk';
import { CareUnitSearchSelectComponent, CareUnitSearchSelectComponentProps } from '../../component/CareUnitSearchSelectComponent/CareUnitSearchSelectComponent';

export interface MedicalOrganizationSearchSelectContainerProps
extends CareUnitSearchSelectComponentProps {
  onChange: (value: string) => void;
  value?: string;
}

export const MedicalOrganizationSearchSelectContainer = ({
  onChange,
  value,
}: MedicalOrganizationSearchSelectContainerProps) => {
  const medicalOrgSearch = useMedicalOrganizationSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      // @ts-ignore
      filter: { },
      // @ts-ignore
      pageInfo: { },
    },
  });

  const medicalOrgOptions = useMemo(() => (
    map(medicalOrgSearch.data?.data?.content, (v) => ({
      value: v.id || '',
      label: v.profile?.alias || '',
    }))
  ), [medicalOrgSearch.data]);

  const handleChange: CareUnitSearchSelectComponentProps['onChange'] = (value) => {
    onChange?.(value);
  };

  return (
    <CareUnitSearchSelectComponent
      value={value}
      options={medicalOrgOptions}
      placeholder="Search a medical organization"
      onChange={handleChange}
      filterOption={
        (input, option) => `${option?.label}`.toLowerCase().includes(`${input}`.toLowerCase())
      }
    />
  );
};

import { message } from 'antd';
import { useUpdate } from '../../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import {
  useMedicalOrganizationUpdate
} from '../../../../../uc-api-sdk';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { ClinicalStaffManagementComponent } from '../../component/ClinicalStaffManagementComponent/ClinicalStaffManagementComponent';

export interface ClinicalStaffManagementContainerProps {
}

export const ClinicalStaffManagementContainer = () => {
  const { medicalOrgService, refetch } = useMedicalOrgContext();
  const updateHook = useUpdate('STAFF_UPDATED');
  const updateRequest = useMedicalOrganizationUpdate({});

  const enableSharePatientAccess = (checked: boolean) => {
    if (!medicalOrgService.id) {
      message.warn('Medical organization id is not found');
      return;
    }
    if (!medicalOrgService.type) {
      message.warn('Medical organization type is not found');
      return;
    }
    if (!medicalOrgService.status) {
      message.warn('Medical organization status is not found');
      return;
    }

    ApiRequestHelper.tryCatch(updateRequest.send({
      params: {
        id: medicalOrgService.id ?? '',
        document: {
          type: medicalOrgService.type,
          status: medicalOrgService.status,
          enableSharePatientAccess: checked,
        }
      }
    }), {
      success: checked ? 'Enabled!' : 'Disabled!',
      error: 'Failed!',
      onSuccess: () => {
        updateHook.updateValue();
        refetch?.();
      }
    });
  };

  return (
    <ClinicalStaffManagementComponent
      enableSharePatientAccess={enableSharePatientAccess}
      value={medicalOrgService.enableSharePatientAccess}
      loading={updateRequest.isLoading}
    />
  );
};

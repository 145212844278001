import { SymptomSeverityEnum } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { SymptomSeverityTagComponent } from '../SymptomSeverityTagComponent/SymptomSeverityTagComponent';

export interface SymptomSeverityEnumComponentProps {
  value: SymptomSeverityEnum | undefined;
  type?: 'tag' | 'filter' | 'name';
}

export const getSymptomSeverityText = (value?: SymptomSeverityEnum): string => {
  switch (value) {
    case SymptomSeverityEnum.MILD:
      return 'Mild';
    case SymptomSeverityEnum.MODERATE:
      return 'Moderate';
    case SymptomSeverityEnum.SEVERE:
      return 'Severe';
    default:
      return 'Not Sure';
  }
};

export const SymptomSeverityEnumComponent = ({
  value,
  type = 'tag',
}: SymptomSeverityEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case SymptomSeverityEnum.MILD:
        return <SymptomSeverityTagComponent.MildTag />;
      case SymptomSeverityEnum.MODERATE:
        return <SymptomSeverityTagComponent.ModerateTag />;
      case SymptomSeverityEnum.SEVERE:
        return <SymptomSeverityTagComponent.SevereTag />;
      case SymptomSeverityEnum.NOT_SURE:
        return <SymptomSeverityTagComponent.NotSureTag />;
      default:
        return <SymptomSeverityTagComponent.NATag />;
    }
  };

  const getSymptomSeverityFilter = (): React.ReactNode => {
    const severityText = getSymptomSeverityText(value);
    switch (value) {
      case SymptomSeverityEnum.MILD:
      case SymptomSeverityEnum.MODERATE:
      case SymptomSeverityEnum.SEVERE:
      case SymptomSeverityEnum.NOT_SURE:
        return severityText;
      default:
        return <EmptyComponent />;
    }
  };

  switch (type) {
    case 'tag':
      return <>{getTag()}</>;
    case 'filter':
      return <>{getSymptomSeverityFilter()}</>;
    default:
      return <>{getSymptomSeverityText()}</>;
  }
};

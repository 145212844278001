import { map } from 'lodash';
import { useMemo } from 'react';
import moment from 'moment';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { AppVersionConfig, VersionDetail, useAppVersionConfigUpdate } from '../../../../uc-api-sdk';
import { CreateEditMobileVersionConfigFormValue } from '../../component/CreateEditMobileVersionConfigDrawerComponent/CreateEditMobileVersionConfigDrawerComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { CreateMobileVersionConfigFormComponent } from '../../component/CreateMobileVersionConfigFormComponent/CreateMobileVersionConfigFormComponent';

export interface EditMobileConfigContainerProps {
  initialValues?: AppVersionConfig;
  onSuccess?: () => void;
}

export const EditMobileConfigContainer = ({
  initialValues,
  onSuccess,
}: EditMobileConfigContainerProps) => {
  const updateConfig = useAppVersionConfigUpdate({});
  const updateHook = useUpdate('MOBILE_VERSION_CONFIG_UPDATED');

  const handleUpdate = (v: CreateEditMobileVersionConfigFormValue) => {
    const versionDetails = map(v.versionDetails, (versionDetail) => ({
      version: versionDetail,
      updateTime: undefined,
    })) as VersionDetail[];
    const result = updateConfig.send({
      params: {
        id: initialValues?.id || '',
        document: {
          id: initialValues?.id || '',
          activateDate: moment(v.activateDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
          versionDetails: versionDetails as VersionDetail[],
          note: v.note || '',
          name: v.name || '',
          contents: v.contents || [],
          medOrgIds: v.medOrgIds || [],
        }
      }
    });

    ApiRequestHelper.tryCatch(result, {
      error: 'An error occurred. Please try again later.',
      success: 'Mobile Versions Configuration updated.',
      onSuccess: () => {
        onSuccess?.();
        updateHook.updateValue();
      }
    });
  };

  const editFormInitialValues = useMemo(() => (
    initialValues ? {
      id: initialValues.id as string,
      activateDate: moment(initialValues.activateDate as string),
      versionDetails: initialValues.versionDetails?.map((v) => v.version || '') || [],
      note: initialValues.note as string,
      name: initialValues.name as string,
      miniOrganizations: initialValues.miniOrganizations,
      medOrgIds: initialValues.medOrgIds || [],
      contents: initialValues.contents || [],
    } : {}
  ), [initialValues]);

  return (
    <CreateMobileVersionConfigFormComponent
      onSubmit={handleUpdate}
      initialValues={editFormInitialValues}
    />
  );
};

import { map } from 'lodash';
import { useMemo } from 'react';
import { AlertDetail } from '../../../../../uc-api-sdk';
import { ConfigurationAlertComponent } from '../../../../../uiComponent/ConfigurationAlertComponent/ConfigurationAlertComponent';
import { useAlertApiContext } from '../../../../AlertRuleEngine/context/AlertApiContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';

export interface AlertEngineMedicalOrgViewComponentProps {

}

export const AlertEngineMedicalOrgViewComponent = () => {
  const { alerts } = useAlertApiContext();
  const { medicalOrgService } = useMedicalOrgContext();
  const { careServiceConfig } = medicalOrgService;
  const { medicalAlertTrigger = false } = careServiceConfig || {};

  if (!medicalAlertTrigger) {
    return (
      <ConfigurationAlertComponent
        title="Configuration of Medical Alerts is currently unavailable."
        message='If you want to activate individual medical alerts triggering, please go to the "Outstanding Items Configuration Settings" and set “Medical Alerts” as ON first. '
        showIcon
      />
    );
  }

  const renderAlerts = (alert: AlertDetail) => ({
    key: (
      <div>
        {alert.displayName}
      </div>
    ),
    value: (
      <div>
        {alert.active ? 'On' : 'Off'}
      </div>
    ),
  });

  const data = useMemo(() => ([
    ...(map(alerts.clinicalAlerts, renderAlerts) || []),
    ...(map(alerts.providerAlerts, renderAlerts) || []),
    ...(map(alerts.customizedProviderAlerts, renderAlerts) || []),
  ]), [alerts]);

  return (
    <MedicalOrgCommonCardContentComponent data={data} />
  );
};

import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BlueBackgroundComponent } from '../BlueBackgroundComponent/BlueBackgroundComponent';
import { PerCodeFeeModelButtonComponent } from '../PerCodeFeeModelButtonComponent/PerCodeFeeModelButtonComponent';

export const PmapmFeeModelFormItemComponent = () => (
  <BlueBackgroundComponent>
    <FormItem name={['pmapm', 'unitFee']} required>
      <PerCodeFeeModelButtonComponent
        options={[38, 40]}
        title="Fee for patients who have at least 1 measurement"
      />

    </FormItem>
  </BlueBackgroundComponent>
);

import {
  AlertDetail, MetricItem,
  MetricValueTypeEnum as MetricValueType,
  RuleItem as AlertRule
} from '../../../uc-api-sdk';

export enum AlertType {
  BP = 'BP',
  BG = 'BG',
}

export enum MealTypeEnum {
  BEDTIME = 'BEDTIME',
  BREAKFAST = 'BREAKFAST',
  LUNCH = 'LUNCH',
  DINNER = 'DINNER',
  OVERNIGHT = 'OVERNIGHT',
  SNACK = 'SNACK',
}

export enum ConditionFilterEnum {
  MealType = 'mealType',
  BeforeMeal = 'beforeMeal',
}

export enum OperatorEnum {
  GreaterThan = '>',
  GreaterAndEqualTo = '>=',
  EqualTo = '==',
  SmallerAndEqualTo = '<=',
  SmallerThan = '<',
  In = 'in',
}

export { MetricValueType };
export { type AlertRule };

export enum TrueFalseEnum {
  True = 'true',
  False = 'false',
}

export type ConditionFilter = {
  name: ConditionFilterEnum.MealType;
  values: MealTypeEnum[];
} | {
  name: ConditionFilterEnum.BeforeMeal;
  values: TrueFalseEnum[];
}

export type AlertCondition = MetricItem;

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }
export type WithoutNull<T, K extends keyof T> = T & { [P in K]: Exclude<T[P], null> }
export type WithoutNullAndRequired<T, K extends keyof T> = WithoutNull<WithRequired<T, K>, K>
export type Alert = AlertDetail;

export enum OperatorValueType {
  INT = 'INT',
  DOUBLE = 'DOUBLE',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
}
export interface AlertsValue {
  clinicalAlerts: Alert[],
  providerAlerts: Alert[],
  customizedProviderAlerts: Alert[],
}

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Icons } from '../../../../icons/Icons';

export interface HSLogBookWeightColComponentProps {
  weight: number;
  tookMedication?: boolean;
  showWarning?: boolean;
}

export const HSLogBookWeightColComponent = ({
  weight,
  tookMedication,
  showWarning,
}: HSLogBookWeightColComponentProps) => (
  <div className="flex g1">
    <div className="b6">{weight}</div>
    {tookMedication && <div><Icons.PillIcon /></div>}
    {showWarning && <div><ExclamationCircleTwoTone twoToneColor="#DA6453" /></div>}
  </div>
);

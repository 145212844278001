import React, { FC } from 'react';
import { Select } from 'antd';
import { map } from 'lodash';
import { SelectType } from '../../features/Input/types';
import { CredentialType } from '../../types/credentials';
import CheckboxSelectComponent from '../../uiComponent/CheckboxSelect/CheckboxSelectComponent';

export type CredentialsSelectComponentProps = SelectType<CredentialType>;

export const CredentialsSelectComponent: FC<CredentialsSelectComponentProps> = ({
  value,
  onChange,
  disabled,
  ...props
}) => {
  const handleChange = (v: string) => {
    onChange?.(v as CredentialType);
  };

  return (
    <div className="credentials-select-component" data-testid="credentials-select-component">
      <CheckboxSelectComponent
        disabled={disabled}
        className="w100"
        mode="multiple"
        value={value}
        onChange={handleChange}
        showSearch
        showArrow
        // for form item if applicable
        id={props.id}
        aria-required={props['aria-required']}
      >
        {map(CredentialType, (v) => (
          <Select.Option key={v} value={v}>{v}</Select.Option>
        ))}
      </CheckboxSelectComponent>
    </div>
  );
};

export default CredentialsSelectComponent;

/* eslint-disable class-methods-use-this */
import axios from 'axios';
import EnvConfig from '../../configs/envConfig/envConfig';
import {
  AIChatDraftInstructionRequestParams,
  AIRequestFunction,
  AIChatDraftRequestParams,
  AIChatDraftReactionParams
} from './types';

const AIDomain = EnvConfig.unicareCopilotUrl;

export class AIServices {
  protected endPoints = {
    AIChatDraft: `${AIDomain}/draft-chat-response`,
    AIChatDraftInstruction: `${AIDomain}/draft-chat-response/instruction`,
    AIChatDraftReaction: `${AIDomain}/draft-chat-response/reaction`,
  };

  getResponse = <T>(value: T) => value;

  getAIChatDraft: AIRequestFunction<AIChatDraftRequestParams, string> = (
    { params, headers },
  ) => axios.post(this.endPoints.AIChatDraft, params, { headers });

  getAIChatDraftInstruction:
  AIRequestFunction<AIChatDraftInstructionRequestParams, string> = (
      { params, headers },
    ) => axios.post(this.endPoints.AIChatDraftInstruction, params, { headers });

  AIChatDraftReaction: AIRequestFunction<AIChatDraftReactionParams, string> = (
    { params, headers },
  ) => axios.post(this.endPoints.AIChatDraftReaction, params, { headers });
}

export const AIServicesInstance = new AIServices();

import { SelectProps } from 'antd';
import { MedicalOrganization, useMedicalOrganizationSearchList } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MedicalOrganizationsSelectComponent } from '../../component/MedicalOrganizationsSelectComponent/MedicalOrganizationsSelectComponent';

interface MedicalOrgSelectContainerChildrenProps {
  isLoading: boolean;
  data?: MedicalOrganization[];
}
export interface MedicalOrgSelectContainerProps extends Omit<SelectProps<string[]>, 'children'> {
  showAssociated?: boolean;
  restrictedMedOrgIds?: string[];
  orgIdsWithPt?: string[];
  children?: (props: MedicalOrgSelectContainerChildrenProps) => React.ReactNode;
}

export const MedicalOrgSelectContainer = ({
  restrictedMedOrgIds,
  orgIdsWithPt,
  children,
  ...props
}: MedicalOrgSelectContainerProps) => {
  const orgs = useMedicalOrganizationSearchList({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {},
        pageInfo: {
          pagination: false,
        },
      }
    }
  });

  return (
    <FetchComponent info={orgs}>
      {(data) => children?.({
        isLoading: orgs.isLoading,
        data: data.rawValue?.data?.content,
      }) || (
        <MedicalOrganizationsSelectComponent
              // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          data={data?.rawValue?.data?.content}
          restrictedMedOrgIds={restrictedMedOrgIds}
          orgIdsWithPt={orgIdsWithPt}
        />
      )}
    </FetchComponent>
  );
};

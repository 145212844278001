import { Button, Drawer } from 'antd';
import Title from 'antd/lib/typography/Title';
import { MedicalOrganization } from '../../../../uc-api-sdk';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../layout/context/AdminPortalContext/AdminPortalContext';
import { CreateMedicalOrganizationFormComponent, CreateMedicalOrganizationFormValue } from '../CreateMedicalOrganizationFormComponent/CreateMedicalOrganizationFormComponent';
import { CreateMedicalOrganizationSuccessComponent } from '../CreateMedicalOrganizationSuccessComponent/CreateMedicalOrganizationSuccessComponent';

export interface CreateMedicalOrganizationFormDrawerComponentProps {
  isLoading: boolean;
  onSubmit: (v: CreateMedicalOrganizationFormValue) => void;
  values?: MedicalOrganization;
  initialValues?: CreateMedicalOrganizationFormValue;
  mode?: 'edit' | 'create';
  submitted?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  showButton?: boolean;
}

export const CreateMedicalOrganizationFormDrawerComponent = ({
  isLoading,
  onSubmit,
  values,
  initialValues,
  mode = 'create',
  submitted,
  onClose,
  onSuccess,
  showButton = true,
}: CreateMedicalOrganizationFormDrawerComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, open, close } = getDrawer(PopupDrawerTypes.CreateMedicalOrg);

  const handleClick = () => {
    open({
      onSuccess,
      initialValues,
      mode: 'edit',
    });
  };

  const handleClose = () => {
    onClose?.();
    close();
  };

  return (
    <>
      {showButton && <Button type="primary" onClick={handleClick}>Create Medical Organization</Button>}
      <Drawer
        destroyOnClose
        width={643}
        open={isOpen}
        onClose={handleClose}
        title={(
          <Title level={5} className="m0i p0">
            {
              mode === 'create' ? 'Create Medical Organization' : 'Edit Profile'
            }
          </Title>
        )}
      >

        {values && submitted ? (
          <CreateMedicalOrganizationSuccessComponent
            organizationType={values?.type ?? undefined}
            alias={values?.profile?.alias}
            id={values?.id || ''}
            onClose={handleClose}
          />
        )
          : (
            <CreateMedicalOrganizationFormComponent
              isLoading={isLoading}
              initialValues={initialValues}
              isEditing={mode === 'edit'}
              onSubmit={onSubmit}
            />
          )}
      </Drawer>
    </>
  );
};

import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import useHover from '../../uiHooks/useHover/useHover';

export interface HoverComponentProps {
  children: ReactNode;
  onHover?: () => void;
  onLeave?: () => void;
  onChange?: (isHover: boolean) => void;
}

export const HoverComponent: FC<HoverComponentProps> = ({
  children,
  onHover,
  onLeave,
  onChange,
}) => {
  const hoverRef = useRef(null);
  const isHoverPrev = useRef(false);
  const isHover = useHover(hoverRef);

  useEffect(() => {
    if (isHoverPrev.current !== isHover) {
      isHoverPrev.current = isHover;
    }
  }, [isHover]);

  useEffect(() => {
    onChange?.(isHover);
    if (isHoverPrev) {
      onHover?.();
    } else {
      onLeave?.();
    }
  }, [isHoverPrev, isHover, onChange, onHover, onLeave]);

  return (
    <div ref={hoverRef}>
      {children}
    </div>
  );
};

export default HoverComponent;

import { ReactNode } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { ExtendableDrawerButtonComponent, ExtendableDrawerButtonComponentProps } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';
import { SymptomLog } from '../../../../uc-api-sdk';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { SymptomFormContainer } from '../../container/SymptomFormContainer/SymptomFormContainer';

export interface SymptomEditComponentProps {
  initialValue: SymptomLog;
  buttonText?: ReactNode;
  buttonProps?: ExtendableDrawerButtonComponentProps['buttonProps'];
}

export const SymptomEditComponent = ({
  initialValue,
  buttonText = 'Edit',
  buttonProps = { type: 'text' },
}: SymptomEditComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText={buttonText}
        buttonProps={buttonProps}
        onClick={setOpen}
      />
      <ExtendableDrawerComponent
        id={DefaultExtendableDrawers.editSymptom}
        open={open}
        onClose={setClose}
        className="drawerComponent"
        title="Record Symptoms"
        footer={null}
        placement="right"
        width={520}
        destroyOnClose
      >
        <SymptomFormContainer
          symptomLog={initialValue}
          onSubmit={setClose}
        />
      </ExtendableDrawerComponent>
    </>
  );
};

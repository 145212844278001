import { Space } from 'antd';
import { FeeModelEnumComponent } from '../../../../enumComponent/FeeModelEnumComponent/FeeModelEnumComponent';
import { BillingFeeModelEnum } from '../../../../uc-api-sdk';
import { ListComponent } from '../../../../uiComponent/ListComponent/ListComponent';

export interface PricingModelSummaryComponentProps {
  type?: BillingFeeModelEnum;
  fees?: number[];
}

export const PricingModelSummaryComponent = ({ type, fees }: PricingModelSummaryComponentProps) => (
  <Space>
    <FeeModelEnumComponent value={type} type="short" />
    <ListComponent
      value={fees || []}
      splitter="/"
      renderItem={(v) => <span>{v}</span>}
    />
  </Space>
);

import { Divider, Form } from 'antd';
import { FormProviderProps } from 'antd/lib/form/context';
import { keys } from 'lodash';
import { ClinicalGoalTemplateFetchContainer } from '../../../intervention/container/ClinicalGoalContainer/ClinicalGoalTemplateFetchContainer';
import { SubmitValue as ClinicalGoalSubmitValue } from '../../../intervention/component/ClinicalGoalComponent/ClinicalGoalFormComponent/ClinicalGoalCardFormComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { MTPRNoteFormItemComponent } from '../MTPRNoteFormItemComponent/MTPRNoteFormItemComponent';
import { FormInstanceType } from '../../../../types/form';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

import './MTPRFormComponent.scss';
import { MTPRAssistButtonsComponent } from '../MTPRAssistButtonsComponent/MTPRAssistButtonsComponent';
import { useAutoSave, UseAutoSaveEnum } from '../../../../hooks/useAutoSave/useAutoSave';

export interface SubmitValue extends ClinicalGoalSubmitValue {
  note?: string;
}

export interface MTPRFormComponentProps
  extends NestedFormComponentChildProps<SubmitValue> {
  patientId: string;
  initialValues?: SubmitValue;
  setIsMTPRDisabled?: (isDisabled: boolean) => void;
}

export const MTPRFormComponent = ({
  patientId,
  isLoading,
  initialValues,
  onSubmit,
  onCancel,
  onValuesChange,
  formButtons,
  disabled,
  setIsMTPRDisabled,
}: MTPRFormComponentProps) => {
  const { autoSavedValue, onAutoSaveChange, removeItem } = useAutoSave<SubmitValue>(
    UseAutoSaveEnum.MTPR,
    patientId,
  );

  const getFormValues = (
    forms: Record<string, FormInstanceType>
  ) => {
    let allValues = {} as SubmitValue;
    keys(forms).forEach((formKey) => {
      const form = forms[formKey];
      const formValues = form.getFieldsValue();
      allValues = {
        ...allValues,
        ...formValues,
      };
    });
    return allValues;
  };

  // mimic onValuesChange of 1 Form
  const handleOnValuesChange: FormProviderProps['onFormChange'] = (
    __,
    info,
  ) => {
    const { changedFields, forms } = info;
    const changedValues = {} as SubmitValue;
    const allValues = getFormValues(forms);
    changedFields.forEach((field) => {
      const { value, name } = field;
      const fieldName = name.toString() as keyof SubmitValue;
      changedValues[fieldName] = value;
    });
    onAutoSaveChange(allValues);
    onValuesChange?.(changedValues, allValues);
  };
  const handleSubmit: FormProviderProps['onFormFinish'] = (
    __,
    info,
  ) => {
    const { forms } = info;
    const allValues = getFormValues(forms);
    onSubmit?.(allValues);
    removeItem();
  };

  const debouncedHandleSubmit = useDebounce(
    handleSubmit,
    500,
    [handleSubmit],
    { leading: true }
  );
  return (
    <div className="mtpr-form">
      <Form.Provider
        onFormChange={handleOnValuesChange}
        onFormFinish={debouncedHandleSubmit}
      >
        <ClinicalGoalTemplateFetchContainer
          onSubmit={() => 0}
          onCancel={onCancel}
          isLoading={!!isLoading}
          initialValues={{
            clinicalGoals: autoSavedValue?.clinicalGoals || initialValues?.clinicalGoals,
          }}
          formButtons={null}
          disabled={disabled}
        />
        <div className="mtpr-form-fixed-bottom">
          <Divider className="mtpr-form-divider" />
          <Form
            name="mtprNote"
            layout="vertical"
            initialValues={{ note: autoSavedValue?.note ?? initialValues?.note }}
            onFinish={() => 0}
            disabled={disabled}
          >
            <MTPRNoteFormItemComponent
              required={!disabled}
            />
            <div className="flex gap3">
              <MTPRAssistButtonsComponent
                patientId={patientId}
                clinicalGoals={initialValues?.clinicalGoals}
                updatedClinicalGoals={autoSavedValue?.clinicalGoals || undefined}
                isMTPRDisabled={disabled}
                setIsMTPRDisabled={setIsMTPRDisabled}
              />
            </div>
            {formButtons}
          </Form>
        </div>
      </Form.Provider>
    </div>
  );
};

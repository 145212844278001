import { Radio, RadioGroupProps, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import { OrganizationTypeEnum } from '../../../../../uc-api-sdk';
import { CareUnitIconComponent } from '../../../careOperationHierarchy/component/CareUnitIconComponent/CareUnitIconComponent';
import { useCareOperationContext } from '../../../careOperationHierarchy/context/CareOperationContext/CareOperationContext';
import { HierarchyNode } from '../../../careOperationHierarchy/service/UcOrganizationService';
import './AssociatedCareUniteRadioComponent.scss';
import { ExpandAllButton } from '../../../../../uiComponent/ExpandAllButton/ExpandAllButton';

export interface AssociatedCareUniteRadioProps extends RadioGroupProps {
  onChangeSelectedGroupName?: (name: string) => void;
}

export const AssociatedCareUniteRadioComponent = ({
  value,
  className = '',
  onChangeSelectedGroupName,
  ...props
}: AssociatedCareUniteRadioProps) => {
  const { ucOrgService } = useCareOperationContext();

  const data = ucOrgService.getTreeData();

  const cleanData = (arr: HierarchyNode[]) => arr.filter(
    (item) => (item.children && item.children.length > 0)
      || item.type === OrganizationTypeEnum.CARE_GROUP
  ) as HierarchyNode[];

  const buildRadioButtons = (arr: HierarchyNode[]): DataNode[] => cleanData(arr).map(
    (item: HierarchyNode) => {
      if (item.id === value) {
        onChangeSelectedGroupName?.(item.title as string);
      }

      return ({
        ...item,
        icon: <CareUnitIconComponent value={item.type} />,
        title: item.type === OrganizationTypeEnum.CARE_GROUP
          ? (
            <Radio value={item.id}>
              {item.title}
            </Radio>
          ) : item.title,
        children: item.children && item.children.length > 0
          ? buildRadioButtons(item.children) : null
      } as DataNode);
    }
  );

  const handleOnChange: RadioGroupProps['onChange'] = (e) => {
    onChangeSelectedGroupName?.(e.target.name as string);
  };

  return (
    <ExpandAllButton data={data} defaultExpandedKeys={[value]}>
      {({ onExpand, keys }) => (
        <div className="mt40 px20 py10 border default-br">
          <Radio.Group
            value={value}
            onChange={handleOnChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={classNames({
              'associated-radio-group w100': true,
              [className]: !!className
            })}
          >
            <Tree
              onExpand={onExpand}
              expandedKeys={keys}
              treeData={buildRadioButtons(data)}
              autoExpandParent
              showIcon
              selectable={false}
              defaultExpandedKeys={[value]}
            />
          </Radio.Group>
        </div>
      )}
    </ExpandAllButton>
  );
};

import { Table, TableProps } from 'antd';
import { AppVersionConfig } from '../../../../uc-api-sdk';
import { createColumns } from './columns';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';

export interface MobileVersionConfigTableComponentProps {
  data?: AppVersionConfig[];
  onChange?: TableProps<AppVersionConfig>['onChange'];
  isLoading?: boolean;
  pagination?: TableProps<AppVersionConfig>['pagination'];
}

export const MobileVersionConfigTableComponent = ({
  data,
  isLoading,
  onChange,
  pagination,
}: MobileVersionConfigTableComponentProps) => {
  const newColumns = useTableColumns(
    createColumns,
    [
      'name',
      'versionDetails',
      'miniOrganizations',
      'activateDate',
      'contents',
      'note',
      'actions'
    ],
  );

  return (
    <Table
      dataSource={data}
      className="mb10"
      loading={isLoading}
      columns={newColumns}
      size="middle"
      onChange={onChange}
      rowKey={(v) => v.id}
      pagination={pagination}
    />
  );
};

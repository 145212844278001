import {
  Button, Divider, Form, FormProps, Space
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { ConfigurationAlertComponent } from '../../../../../uiComponent/ConfigurationAlertComponent/ConfigurationAlertComponent';
import { FixedChildComponent } from '../../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { RadioGroupWithDescriptionComponent } from '../../../../../uiComponent/RadioGroupWithDescriptionComponent/RadioGroupWithDescriptionComponent';
import { useMedicalOrgOutstandingItemForm } from '../../hook/useMedicalOrgOutstandingItemForm';
import './MedicalOrgOutstandingItemConfigFormComponent.scss';

export interface OutstandingItemConfigFormValues {
  medicalAlertTrigger: boolean;
  complianceAlertTrigger: boolean;
  onboardingTodoTrigger: boolean;
  mtprTrigger: boolean;
  transcribingA1cTrigger: boolean;
  sendAutoMessageTrigger: boolean;
  messageTrigger: boolean;
  foodLogTrigger: boolean;
}

export interface MedicalOrgOutstandingItemConfigFormComponentProps extends FormProps {
  onSubmit: (values: OutstandingItemConfigFormValues) => void;
}

export const MedicalOrgOutstandingItemConfigFormComponent = ({
  ...props
}: MedicalOrgOutstandingItemConfigFormComponentProps) => {
  const hook = useMedicalOrgOutstandingItemForm();
  const { form } = hook;

  const options = [{
    label: 'ON',
    value: true,
  },
  {
    label: 'OFF',
    value: false,
  }];

  return (
    <Form
      form={form}
      onFinish={hook.handleSubmit(props.onSubmit)}
      layout="vertical"
      className="medical-org-outstanding-item-config-form-component h100 w100"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FixedComponent>
        <FixedChildComponent>
          <Title level={5}>Care Service:</Title>
          <Space size={30} direction="vertical">
            <FormItem
              name="medicalAlertTrigger"
              label={hook.getLabel('medicalAlertTrigger')}
            >
              <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('medicalAlertTrigger')
                }
              />
            </FormItem>
            <FormItem dependencies={['medicalAlertTrigger']} noStyle>
              {
                ({ getFieldValue }) => {
                  const isOn = getFieldValue('medicalAlertTrigger');
                  return !isOn && (
                    <ConfigurationAlertComponent
                      message={(
                        <span>
                          No Medical Alerts will be triggered and
                          {' '}
                          <b>ALL</b>
                          {' '}
                          Medical Alerts in the Alert Configuration will be toggled off.
                        </span>
                      )}
                      showIcon
                    />
                  );
                }
              }
            </FormItem>
            <FormItem dependencies={['medicalAlertTrigger']} noStyle>
              {
                ({ getFieldValue }) => {
                  const isOn = getFieldValue('medicalAlertTrigger');
                  return isOn && (
                    <ConfigurationAlertComponent
                      className="ai-bl no-border text-gray-scale-2"
                      message={(
                        <span>
                          Please be aware that activating Medical Alerts
                          {' '}
                          <b>DOES NOT</b>
                          {' '}
                          automatically trigger all alerts.
                          To configure the triggering of each alert,
                          please go to the "Medical Alert Configuration" section.
                        </span>
                      )}
                      showIcon
                    />
                  );
                }
              }
            </FormItem>
            <FormItem
              name="complianceAlertTrigger"
              label={hook.getLabel('complianceAlertTrigger')}
            >
              <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('complianceAlertTrigger')
                }
              />
            </FormItem>
            <FormItem
              name="onboardingTodoTrigger"
              label={hook.getLabel('onboardingTodoTrigger')}
            >
              <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('onboardingTodoTrigger')
                }
              />
            </FormItem>
            <FormItem
              name="mtprTrigger"
              label={hook.getLabel('mtprTrigger')}
            >
              <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('mtprTrigger')
                }
              />
            </FormItem>
            <FormItem
              name="sendAutoMessageTrigger"
              label={hook.getLabel('sendAutoMessageTrigger')}
            >
              <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('sendAutoMessageTrigger')
                }
              />
            </FormItem>

          </Space>
          <Divider />
          <Title level={5} className="mt40">Patient Engagement Service:</Title>
          <Space size={30} direction="vertical" className="mb40">
            <FormItem
              info={hook.getInfo('messageTrigger')}
            >
              <div className="description">{hook.getDescription('messageTrigger')}</div>
              <div className="coming-soon">Coming soon</div>
              {/* <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('messageTrigger')
                }
              /> */}
            </FormItem>
            <FormItem
              info={hook.getInfo('foodLogTrigger')}
            >
              <div className="description">{hook.getDescription('foodLogTrigger')}</div>
              <div className="coming-soon">Coming soon</div>
              {/* <RadioGroupWithDescriptionComponent
                options={options}
                direction="vertical"
                description={
                  hook.getDescription('foodLogTrigger')
                }
              /> */}
            </FormItem>

          </Space>
        </FixedChildComponent>
        <FixedChildComponent isFixed>
          <Button className="w100" type="primary" htmlType="submit">Save</Button>
        </FixedChildComponent>
      </FixedComponent>
    </Form>
  );
};

import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { AlertCondition } from '../type';
import { ConditionsService } from '../service/ConditionsService';
import { EventTypeEnum, useRuleEngineGetAllMetrics } from '../../../uc-api-sdk';

interface ConditionsContextValue {
  conditions?: AlertCondition[];
  conditionsService?: ConditionsService;
}

const ConditionsContext = createContext<ConditionsContextValue | undefined>(undefined);

export interface ConditionsContextProviderProps {
  eventType: EventTypeEnum;
  children: ReactNode;
}

export const ConditionsContextProvider = ({
  eventType,
  children,
}: ConditionsContextProviderProps) => {
  const types = useMemo(() => {
    switch (eventType) {
      case EventTypeEnum.BG:
        return [EventTypeEnum.BG, EventTypeEnum.BG_RECHECK];
      case EventTypeEnum.BP:
        return [EventTypeEnum.BP, EventTypeEnum.BP_RECHECK];
      default:
        return [];
    }
  }, [eventType]);
  const getAllMetrics = useRuleEngineGetAllMetrics({
    options: { sendOnMount: true },
    params: {
      eventType: types,
    },
  });
  const conditions = getAllMetrics.data?.data || [];

  const value = useMemo(() => {
    if (!eventType || !conditions) return {};
    const conditionsService = new ConditionsService(eventType, conditions || []);

    return {
      conditions,
      conditionsService,
    };
  }, [eventType, conditions]);

  return (
    <ConditionsContext.Provider
      value={value}
    >
      {children}
    </ConditionsContext.Provider>
  );
};

export const useConditionsContext = () => {
  const context = useContext(ConditionsContext) || {};
  return context;
};

import { RoleTypeEnum } from '../../uc-api-sdk';
import { ListComponent } from '../../uiComponent/ListComponent/ListComponent';
import { RoleTypeEnumComponent } from '../RoleTypeEnumComponent/RoleTypeEnumComponent';

export interface RoleTypeEnumListComponentProps {
  value: RoleTypeEnum[];
  splitter?: string;
}

export const RoleTypeEnumListComponent = ({
  value,
  splitter,
}: RoleTypeEnumListComponentProps) => (
  <ListComponent
    value={value || []}
    splitter={splitter}
    renderItem={(v) => (
      <RoleTypeEnumComponent
        key={v}
        value={v}
      />
    )}
  />
);

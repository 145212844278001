import { Button } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React from 'react';

export interface TableFilterDropdownProps extends FilterDropdownProps {
  filter: React.ReactNode;
}
export const FilterDropdownComponent = ({
  filter,
  confirm,
  clearFilters,
  close,
}: TableFilterDropdownProps) => (
  <div className="custom-filter-dropdown">
    <div className="custom-filter-input">
      {filter}
    </div>
    <div className="flex jc-sb">
      <Button
        type="link"
        onClick={() => {
          if (clearFilters) {
            clearFilters();
            confirm();
            close();
          }
        }}
      >
        Reset
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        onClick={() => confirm()}
      >
        Search
      </Button>
    </div>
  </div>
);

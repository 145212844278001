import { FormInstance, Input, InputProps } from 'antd';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useEmployeeSearch } from '../../../../uc-api-sdk';

export interface EmployeeInputValidationComponentProps {
  value?: string;
  onChange?: InputProps['onChange'];
  form: FormInstance;
  setValid: () => void;
  setInvalid: () => void;
  shouldValidate?: number;
  fieldName: string;
  fieldLabel: string;
  placeHolder?: string;
  currentValue?: string;
}

export const EmployeeInputValidationComponent = ({
  value,
  onChange,
  form,
  setValid,
  setInvalid,
  shouldValidate = 0,
  fieldName = 'loginId',
  fieldLabel = 'Login ID',
  placeHolder,
  currentValue
}: EmployeeInputValidationComponentProps) => {
  const searchEmpHook = useEmployeeSearch({});
  const errString = `${fieldLabel} is taken.`;
  const currentErrors = form.getFieldError(fieldName);
  const useSearch = useDebounce((v: string) => {
    if (!v) {
      return;
    }
    const req = searchEmpHook.send({
      params: {
        filter: {
          [fieldName]: v
        },
      }
    });
    ApiRequestHelper.tryCatch(
      req,
      {
        success: undefined,
        error: 'An error has occurred, please try again later.',
        onSuccess(res) {
          if (!res?.data?.totalSize || currentValue === v) {
            setValid?.();
            form?.setFields([
              {
                name: fieldName,
                errors: currentErrors.filter((v) => v !== errString),
              },
            ]);
          } else {
            const errors = new Set([...currentErrors, errString]);
            form?.setFields([
              {
                name: fieldName,
                errors: Array.from(errors),
              },
            ]);
            setInvalid?.();
          }
        },
        onError: setInvalid
      }
    );
  }, 1000);

  useEffect(() => {
    useSearch(value || '');
  }, [shouldValidate]);

  const handleOnchange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    if (e.target.value) {
      useSearch(e.target.value);
    } else {
      form?.setFields([
        {
          name: fieldName,
          errors: currentErrors.filter((v) => v !== errString),
        }
      ]);
    }
  }, []);

  return (
    <Input
      value={value}
      onChange={handleOnchange}
      placeholder={placeHolder}
    />
  );
};

import { Col, Row } from 'antd';
import { map } from 'lodash';
import { ReactNode } from 'react';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

import './MedicalOrgCommonCardContentComponent.scss';

interface Data {
  key: ReactNode;
  value?: ReactNode;
}

export interface MedicalOrgCommonCardContentComponentProps {
  data: Data[];
  keyColSpan?: number;
  valueColSpan?: number;
}

export const MedicalOrgCommonCardContentComponent = ({
  data,
  keyColSpan = 8,
  valueColSpan = 16,
}: MedicalOrgCommonCardContentComponentProps) => (
  <div className="medical-org-common-card-content">
    {map(data, (d, i) => (
      <Row gutter={16} key={i} className="data-item">
        <Col span={keyColSpan} className="data-key">
          {d.key}
        </Col>
        <Col span={valueColSpan} className="text-gray-scale-1">{d.value || <EmptyComponent />}</Col>
      </Row>
    ))}
  </div>
);

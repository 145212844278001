/* eslint-disable camelcase */
export const DATE = 'DD/MM/YYYY';
export const USA_DATE = 'MM/DD/YYYY';
export const BIRTH_DATE = 'DD/MM/YYYY';
export const DATE_TIME_CALENDAR = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const TERMINATION_DATE = 'MM/DD/YYYY';
export const DATE_RANGE_START = 'YYYY-MM-DDT00:00:00.000';
export const DATE_RANGE_END = 'YYYY-MM-DDT00:00:00.000';
export const WORKING_HOUR = 'h:mm a';
export const WORKING_DATE_WITH_HOUR = 'MM/DD/YYYY hh:mm a';
export const DATE_DAY_TIME_TIMEZONE = 'MM/DD/YYYY ddd HH:mm (z)';
export const TIME_TIMEZONE = 'HH:mm (z)';
export const DATE_TIME = 'MM/DD/YYYY HH:mm';
export const MONTH_OBV_DATE = 'MMM/DD';
export const MEDICATION_DATE_FORMAT = 'MM/DD/YYYY';
export const DATA_ANALYTICS_DATE_FORMAT = 'YYYY-MM-DD';
export const DATA_ANALYTICS_LABEL_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATA_ANALYTICS_SAME_YEAR_MONTHLY_LABEL_DATE_FORMAT = 'MMM';
export const DATA_ANALYTICS_NOT_SAME_YEAR_MONTHLY_LABEL_DATE_FORMAT = 'MMM\nYYYY';
export const DATA_ANALYTICS_DAILY_LABEL_DATE_FORMAT = 'M/D\nddd';
export const MEDICATION_DATE_INSERT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MMM_YYYY = 'MMM, YYYY';
export const MMM_DD = 'MMM DD';
export const TIME = 'HH:mm';
export const TIME_AM_PM = 'h:mm a';
export const OBV_DAY = 'ddd';
export const CONTACT_HISTORY_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const CALL_CENTER_LAST_AGENT_ACK_DATE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const MONTH_YEAR = 'MMMM YYYY';
export const FULL_DAY_OF_MONTH = 'dddd, MMMM DD';
export const OBR_DAY_OF_MONTH = 'ddd, MMM DD';
export const MONTH_DAY_YEAR = 'MMMM DD, YYYY';
export const SMALL_TIME = 'h:mm';
export const SMALL_HOUR = 'h';
export const AM_PM = 'a';
export const MEDICATION_HISTORY_DATE_FORMAT = 'MM/DD/YYYY HH:mm a';
export const DATE_TIME_AM_PM = 'MM/DD/YYYY hh:mm a';
export const DATE_COMMA_TIME_AM_PM = 'MM/DD/YYYY, hh:mm a';
export const DATE_COMMA_TIME_AM_PM_UPPERCASE = 'MM/DD/YYYY, hh:mm A';
export const VISIT_START_TIME_FORMAT = 'h';
export const VISIT_END_TIME_FORMAT = 'h:mm a';
export const VISIT_TIME_FORMAT = 'dddd, MMMM D';
export const MESSAGE_DATE_FORMAT = 'MMM DD, YYYY';
export const URL_DATE = 'YYYY-MM-DD';
export const DATE_STANDARD = 'YYYY-MM-DD';
export const MONTH_DATE_YEAR = 'MM/DD/YY';
export const CLINIC_TIMEZONE_FORMAT = 'UTC Z';
export const MONTH_DAY_DATE = 'MM/DD';
export const YEAR_DATE = 'YYYY';
export const CARE_NOTE_DATE = 'MM/DD/YY, HH:mm';
export const USERID_BIRTHDAY = 'MMDDYYYY';
export const MONTH_DATE_YEAR_TIME_AM_PM = 'MM/DD/YY hh:mm a';
export const CLINIC_EVENT_DATE_FORMAT = 'MMM D, h:mm A';
export const HIATUS_SPAN_DATE_FORMAT = 'MMM DD, YYYY';
export const BILLING_DATE = 'YYYYMM';
export const YYYYMM = 'YYYYMM';
export const HHmmss_A = 'HH:mm:ss a';
export const HHmm_a = 'HH:mm a'; // 24-hour format am/pm
export const hhmm_a = 'hh:mm a'; // 12-hour format am/pm
export const hhmm_a_UPPERCASE = 'hh:mm A'; // 12-hour format AM/PM
export const MMDDYYYY_AT_HHmm_a_z = 'MM/DD/YYYY [at] hh:mm a z';
export const HHmm_A_PATIENT_LOCAL_TIME = 'hh:mm A [Patient local time]';
export const MMM_DD_YYYY = 'MMM DD, YYYY';
export const MEDICAL_ALERT_DATE = 'hh:mm a, MM/DD/YY';
export const MEDICAL_ALERT_PATIENT_DATE = 'hh:mm a, MM/DD/YY (z)';
export const EXPORT_DATE = 'MM_DD_YYYY';
export const MDYYYY = 'M/D/YYYY';
export const MMDDYY = 'MM/DD/YY';
export const MMM_DD_YYYY_HH_MM_A = 'MMM DD, YYYY hh:mm A';

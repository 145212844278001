import {
  RemoveRoleValidationResponse,
  RoleAssignmentOverWriteByOrganizationRequest,
  RoleTypeEnum,
  UserTypeEnum
} from '../../../../../uc-api-sdk';
import { DrawerComponent, DrawerComponentProps } from '../../../../../uiComponent/Drawer/DrawerComponent';
import { ModifyMemberRolesComponent } from '../ModifyMemberRolesComponent/ModifyMemberRolesComponent';

export interface ModifyMemberRolesDrawerComponentProps extends DrawerComponentProps {
  onSave?: (close: () => void, employees: RoleAssignmentOverWriteByOrganizationRequest) => void;
  memberId?: string;
  initialRoles?: RoleTypeEnum[];
  careUnitId: string;
  hasPatientsCheck?: RoleTypeEnum[];
  otherRoles?: RoleTypeEnum[];
  validationModalData?: RemoveRoleValidationResponse[];
  userType?: UserTypeEnum;
  isLoading?: boolean;
}

export const ModifyMemberRolesDrawerComponent = ({
  onSave,
  buttonText = 'Modify Role',
  buttonType = 'text',
  memberId,
  initialRoles,
  careUnitId,
  hasPatientsCheck,
  otherRoles,
  validationModalData,
  isLoading,
  userType = UserTypeEnum.IHEALTH_EMPLOYEE
}: ModifyMemberRolesDrawerComponentProps) => (
  <DrawerComponent
    size="default"
    width="643px"
    title="Modify Role"
    buttonText={buttonText}
    buttonType={buttonType}
    buttonSize="small"
    destroyOnClose
  >
    {({ close }) => (
      <ModifyMemberRolesComponent
        onClickSave={(employees) => {
          onSave?.(close, employees);
        }}
        memberId={memberId}
        initialRoles={initialRoles}
        careUnitId={careUnitId}
        hasPatientsCheck={hasPatientsCheck}
        validationModalData={validationModalData}
        userType={userType}
        otherRoles={otherRoles}
        isLoading={isLoading}
      />
    )}
  </DrawerComponent>
);

import { ColumnType } from 'antd/lib/table';
import { DailyWeight, ResultSourceEnum } from '../../../../uc-api-sdk';
import { HSLogBookDateComponent } from '../HSLogBookDateComponent/HSLogBookDateComponent';
import { HSLogBookWeightColComponent } from '../HSLogBookWeightColComponent/HSLogBookWeightColComponent';

export interface HSTableRowValue extends DailyWeight {
  isNested: boolean;
  isAverage?: boolean;
  weight?: number;
  children?: HSTableRowValue[];
  key?: React.ReactNode;
}

export const createHSTableColumns = () => ({
  date: (): ColumnType<HSTableRowValue> => ({
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (v, record) => (
      <HSLogBookDateComponent
        source={record.source as ResultSourceEnum}
        date={record.date || ''}
        timezone={record.timezone || undefined}
        count={record.dailyCount || 1}
        isNested={record.isNested}
      />
    ),
  }),
  weight: (): ColumnType<HSTableRowValue> => ({
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
    width: 90,
    render: (v, record) => (
      <HSLogBookWeightColComponent
        weight={v}
        tookMedication={record.withMedication || false}
        showWarning={record.isOutlier || false}
      />
    ),
  }),
  bmi: (): ColumnType<HSTableRowValue> => ({
    title: 'BMI',
    dataIndex: 'bmi',
    key: 'bmi',
    width: 60,
    render: (bmi: number) => bmi?.toFixed(1),
  }),
  weightChange: (): ColumnType<HSTableRowValue> => ({
    title: 'Change',
    dataIndex: 'weightChange',
    key: 'weightChange',
    width: 70,
    render: (weightChange: number) => `${weightChange > 0 ? '+' : ''}${Number(weightChange).toFixed(1)}`,
  }),
  action: (): ColumnType<HSTableRowValue> => ({
    title: '',
    dataIndex: 'isAverage',
    key: 'isAverage',
    width: 45,
  }),
});

import { Button } from 'antd';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { PageComponent } from '../../../../uiComponent/PageComponent/PageComponent';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../adminPortal2/layout/context/AdminPortalContext/AdminPortalContext';
import { MobileVersionConfigTableContainer } from '../../containers/MobileVersionConfigTableContainer/MobileVersionConfigTableContainer';

export const MobileVersionConfigDashboardComponent = () => {
  const refetchConfigs = useUpdate('MOBILE_VERSION_CONFIG_CREATED');
  const { getDrawer } = useAdminPortalPopupContext();
  const { open } = getDrawer(PopupDrawerTypes.CreateMobileConfig);

  const handleClick = () => {
    open({
      onSuccess: refetchConfigs.updateValue,
    });
  };

  return (
    <PageComponent
      title="Mobile Version Configuration"
      extra={(
        <Button
          type="primary"
          onClick={handleClick}
        >
          Create Mobile Version Configuration
        </Button>
      )}
    >
      <MobileVersionConfigTableContainer />
    </PageComponent>
  );
};

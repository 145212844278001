import { Button, Space } from 'antd';

export interface CompleteChartingButtonComponentProps {
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

export const CompleteChartingButtonComponent = ({
  onOk,
  onCancel,
  okText = "Yes, It's Complete",
  cancelText = "No, I'll Complete Later",
  isLoading,
  disabled,
}: CompleteChartingButtonComponentProps) => (
  <Space className="w100" direction="vertical">
    <span>Is charting complete?</span>
    <div className="flex f-w jc-sb">
      <Button
        loading={isLoading}
        disabled={disabled}
        type="primary"
        className="f1"
        onClick={onOk}
        size="large"
      >
        {okText}
      </Button>
      <Button
        type="primary"
        className="f1"
        onClick={onCancel}
        size="large"
        ghost
      >
        {cancelText}
      </Button>
    </div>
  </Space>
);

import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useCreateMedicalOrganizationForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    type: {
      name: 'type',
      label: 'Organization Type',
    },
    businessName: {
      name: 'businessName',
      label: 'Business Name'
    },
    orgNumber: {
      name: 'orgNumber',
      label: 'Alias'
    },
    alias: {
      name: 'alias',
      label: ' '
    },
    description: {
      name: 'description',
      label: 'Description'
    },
    phone: {
      name: 'phone',
      label: 'Phone number'
    },
    address: {
      name: 'address',
      label: 'Address 1'
    },
    address2: {
      name: 'address2',
      label: 'Address 2'
    },
    state: {
      name: 'state',
      label: 'State'
    },
    city: {
      name: 'city',
      label: 'City'
    },
    zip: {
      name: 'zip',
      label: 'Zip'
    },
    timezone: {
      name: 'timezone',
      label: 'Main timezone'
    },
  }, options);

  return factory;
};

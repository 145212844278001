import { BillingFeeModelEnum } from '../../uc-api-sdk';

export interface FeeModelEnumComponentProps {
  value?: BillingFeeModelEnum;
  type?: 'short' | 'long';
}

export const FeeModelEnumComponent = ({
  value,
  type = 'long'
}: FeeModelEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BillingFeeModelEnum.PER_CODE:
        return 'Per Code';
      case BillingFeeModelEnum.PMAPM:
        return 'PMAPM (Per Minimum Active Per Month)';
      case BillingFeeModelEnum.PBPM:
        return 'PBPM (Per Billable Patient Per Month)';
      case BillingFeeModelEnum.PEPM:
        return 'PEPM (Per Enrolled Patient Per Month)';
      case BillingFeeModelEnum.NA:
        return 'N/A';
      default:
        return '';
    }
  };

  const getShortText = () => {
    switch (value) {
      case BillingFeeModelEnum.PER_CODE:
        return 'Per Code';
      case BillingFeeModelEnum.PMAPM:
        return 'PMAPM';
      case BillingFeeModelEnum.PBPM:
        return 'PBPM';
      case BillingFeeModelEnum.PEPM:
        return 'PEPM';
      case BillingFeeModelEnum.NA:
        return 'N/A';
      default:
        return '';
    }
  };

  if (type === 'short') {
    return (
      <span>{getShortText()}</span>
    );
  }

  return (
    <span>{getText()}</span>
  );
};

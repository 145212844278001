import { map } from 'lodash';
import { Select } from 'antd';
import { SelectType } from '../../../../Input/types';
import { ServiceFormValues } from '../../../component/MedicalOrgServiceFormComponent/MedicalOrgServiceFormComponent';
import { formatPhoneNumber } from '../../../../../helpers/phone';

export interface CallCenterLocalNumberSelectComponentProps extends SelectType<ServiceFormValues> {
  data?: string[];
}

export const CallCenterLocalNumberSelectComponent = ({
  value,
  onChange,
  data,
}: CallCenterLocalNumberSelectComponentProps) => (
  <div>
    <Select
      className="w100"
      value={value}
      onChange={onChange}
    >
      {map(data, (data, i) => (
        <Select.Option
          key={i}
          value={data}
        >
          {formatPhoneNumber(data)}
        </Select.Option>
      ))}
    </Select>
  </div>
);

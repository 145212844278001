import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { EhrAddressParams, makeEhrOrgAddressString } from '../../../../../helpers/address';
import { EhrClinic } from '../../../../../uc-api-sdk';

export interface CareQualityDefaultMedicalOrgComponentProps {
  defaultMedicalOrg?: EhrClinic
  onClick?: () => void;
}

export const CareQualityDefaultMedicalOrgComponent = ({
  defaultMedicalOrg,
  onClick
}: CareQualityDefaultMedicalOrgComponentProps) => {
  const renderOrg = () => (
    <div className="breakword px20 text-gray-scale-1">
      {makeEhrOrgAddressString(
        {
          ...defaultMedicalOrg
        } as EhrAddressParams
      )}
    </div>
  );
  return (
    <div className="flex jc-sb ai-c">
      <div className="flex ai-c">
        <CheckCircleFilled className="primary-link fs20" />
        {renderOrg()}
      </div>
      {onClick && <Button type="link" className="primary-link p0 no-border" onClick={onClick}>Update</Button>}
    </div>
  );
};

import { DisplayOrEmptyComponent } from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { OperatorEnum } from '../../type';

export interface OperatorEnumComponentProps {
  value?: OperatorEnum;
}

export const OperatorEnumComponent = ({
  value,
}: OperatorEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case OperatorEnum.EqualTo: return 'Equal to';
      case OperatorEnum.GreaterAndEqualTo: return 'Greater than and Equal to';
      case OperatorEnum.GreaterThan: return 'Greater than';
      case OperatorEnum.SmallerAndEqualTo: return 'Smaller than and Equal to';
      case OperatorEnum.SmallerThan: return 'Smaller than';
      case OperatorEnum.In: return 'In';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };

  return (<>{getText()}</>);
};

import { RcFile, UploadFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { useUpdate } from '../../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { getBase64, pdfStringHeaders } from '../../../../../helpers/file';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import { useBoolean } from '../../../../../hooks/useBoolean/useBoolean';
import {
  EhrClinic,
  EhrOrgMappingRequest,
  EhrServiceName,
  FileInfo,
  MedicalOrganizationStatus,
  MedicalOrganizationType,
  useEhrOrganizationMappingInsertDetail,
  useEhrOrganizationMappingUpdate,
  useMedicalOrganizationUpdate,
  useMedicalOrganizationUploadConsent
} from '../../../../../uc-api-sdk';
import { MedicalOrgEHRFormSubmitValue } from '../../../hook/useMedicalOrgEHRForm';
import { OpenPopupArg } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { MedicalOrgEHRFormComponent } from '../../component/MedicalOrgEHRFormComponent/MedicalOrgEHRFormComponent';

export interface MedicalOrgEHRFormContainerProps extends OpenPopupArg {
}

export const MedicalOrgEHRFormContainer = ({
  ...props
}: MedicalOrgEHRFormContainerProps) => {
  const { initialValues } = props;
  const [file, setFile] = useState<UploadFile | undefined>();
  const [fileString, setFileString] = useState<string | undefined>();
  const [
    defaultMedicalOrg,
    setDefaultMedicalOrg
  ] = useState<EhrClinic | undefined>(initialValues?.defaultMedicalOrg);
  const { value: submitting, setTrue, setFalse } = useBoolean(false);

  const updateOrg = useMedicalOrganizationUpdate({});
  const uploadConsent = useMedicalOrganizationUploadConsent({});
  const ehrOrgMapping = useEhrOrganizationMappingUpdate({});
  const addEhrOrgMappingDetail = useEhrOrganizationMappingInsertDetail({});

  const updateOrgMappingDetail = async (clinic: EhrClinic, practiceId?: string) => {
    const res = addEhrOrgMappingDetail.send({
      params: {
        detail: {
          ehr: clinic.ehr || EhrServiceName.CAREQUALITY,
          ehrOrgId: clinic.id || '',
          name: clinic.businessName || '',
          city: clinic.city || '',
          state: clinic.state || '',
          zipcode: clinic.zip || '',
          ehrParentOrgId: practiceId || '',
        }
      }
    });

    await ApiRequestHelper.tryCatch(res, {
      error: 'An error occurred. Please try again later.',
      success: 'Medical organization detail updated.',
    });
  };

  const updateOrgMappings = async (ehrOrgId: string, departments: EhrClinic[]) => {
    const data: EhrOrgMappingRequest[] = [];
    if (defaultMedicalOrg) {
      data.push({
        ehr: EhrServiceName.CAREQUALITY,
        ehrOrgId: defaultMedicalOrg.id as string,
        ehrDepartmentIds: [],
      });
    }

    if (ehrOrgId && departments.length > 0) {
      data.push({
        ehr: EhrServiceName.ATHENA,
        ehrOrgId,
        ehrDepartmentIds: departments.map((d) => d.id) as string[],
      });
    }

    const res = ehrOrgMapping.send({
      params: {
        ucClinicId: initialValues?.id || '',
        request: data
      }
    });

    ApiRequestHelper.tryCatch(res, {
      error: 'An error occurred. Please try again later.',
      success: 'EHR and Carequality settings updated',
    });
  };

  const refetchMedicalOrgService = useUpdate('MEDICAL_ORG_SERVICE_UPDATED');
  const handleOnConsentChange = (f: RcFile) => {
    setFile(f);
    getBase64(f, (base64String) => {
      setFileString(base64String?.replace(pdfStringHeaders, ''));
    });
    return f.name;
  };

  const updateOrgStatus = async (v: MedicalOrgEHRFormSubmitValue, fileInfo?: FileInfo) => {
    // update athena departments
    updateOrgMappings(v.athenaPracticeId as string, v.departments as EhrClinic[]);

    // update medical org ehr config
    const result = updateOrg.send({
      params: {
        id: initialValues?.id as string,
        document: {
          status: MedicalOrganizationStatus.ACTIVE,
          type: initialValues?.type as MedicalOrganizationType,
          careQualityStatus: {
            agreedToJoin: v.careQuality,
            fileInfo: v.careQuality ? fileInfo : null,
            signedDate: fileInfo || (v.careQuality !== initialValues?.careQuality)
              ? TimezoneService.getUTC(dayjs()) : null
          },
          integrationFeature: {
            ehrServiceProvider: v.ehrData,
            isReferralEnabled: v.ehrIntegrationAvailable || false,
            isAutoBillingEnabled: v.autoClaimSubmit || false,
          }
        }
      }
    });

    await ApiRequestHelper.tryCatch(result, {
      error: 'An error occurred. Please try again later.',
      success: 'EHR settings updated',
      onSuccess: () => {
        setFile(undefined);
        setFileString(undefined);
      }
    });
  };

  const handleUpdate = (v: MedicalOrgEHRFormSubmitValue) => {
    if (!initialValues?.id) {
      return;
    }
    setTrue();

    if (v.careQuality && defaultMedicalOrg) {
      updateOrgMappingDetail(defaultMedicalOrg);
    }

    if (v.athenaPracticeId && (v.departments && v.departments.length > 0)) {
      v.departments.forEach(async d => {
        await updateOrgMappingDetail(d, v.athenaPracticeId);
      });
    }

    if (v.careQuality && file) {
      const res = uploadConsent.send({
        params: {
          medicalOrgId: initialValues?.id as string,
          request: {
            content: fileString as string,
            fileName: file.name as string,
          }
        }
      });

      ApiRequestHelper.tryCatch(res, {
        error: 'An error occurred. Please try again later.',
        success: 'Consent form uploaded',
        onSuccess: (data) => {
          const fileInfo = data?.data;
          updateOrgStatus(v, fileInfo as FileInfo);
        }
      });
    } else {
      updateOrgStatus(v);
    }
  };

  useUpdateEffect(() => {
    if (
      !updateOrg.isLoading
      && !uploadConsent.isLoading
      && !ehrOrgMapping.isLoading
      && !addEhrOrgMappingDetail.isLoading
    ) {
      setFalse();
      props.onSuccess?.();
      refetchMedicalOrgService.updateValue();
    }
  }, [
    updateOrg.isLoading,
    uploadConsent.isLoading,
    ehrOrgMapping.isLoading,
    addEhrOrgMappingDetail.isLoading,
    submitting
  ]);

  return (
    <MedicalOrgEHRFormComponent
      action={handleOnConsentChange}
      onSubmit={handleUpdate}
      initialValues={initialValues}
      isLoading={
        updateOrg.isLoading
        || uploadConsent.isLoading
        || ehrOrgMapping.isLoading
        || addEhrOrgMappingDetail.isLoading
      }
      file={fileString}
      fileName={file?.name}
      defaultMedicalOrg={defaultMedicalOrg}
      setDefaultMedicalOrg={setDefaultMedicalOrg}
    />
  );
};

import { CheckCircleFilled } from '@ant-design/icons';
import {
  Button, Col, Divider, Result, Row
} from 'antd';
import { BasicEnumComponent } from '../../../../enumComponent/BasicEnumComponent/BasicEnumComponent';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { MedicalOrganizationType } from '../../../../uc-api-sdk';
import './CreateMedicalOrganizationSuccessComponent.scss';

export interface CreateMedicalOrganizationSuccessComponentProps {
  organizationType?: MedicalOrganizationType;
  alias?: string;
  id?: string;
  onClose?: () => void;
}

export const CreateMedicalOrganizationSuccessComponent = ({
  organizationType,
  alias,
  id,
  onClose
}: CreateMedicalOrganizationSuccessComponentProps) => {
  const { goToAdminPortalMedicalOrg } = useChangePage();
  const handleClick = () => {
    onClose?.();
    goToAdminPortalMedicalOrg(id || '');
  };

  return (
    <Result
      status="success"
      title={<div className="bold">Medical Organization Created</div>}
      icon={<CheckCircleFilled className="createSuccess" />}
      extra={(
        <div>
          <div className="organizationInfo secondary-br fs14 b5 ta-l gap2 flex fd-c">
            <Row>
              <Col span={12} className="text-gray-scale-2">Organization type</Col>
              <Col span={12}>
                <BasicEnumComponent value={organizationType} />
              </Col>
            </Row>
            <Row>
              <Col span={12} className="text-gray-scale-2">Alias</Col>
              <Col span={12}>
                {alias}
              </Col>
            </Row>
          </div>
          <Divider className="my60" />
          <div className="nextSteps ta-l mb40 flex fd-c gap1">
            <div>Next, you can go to the Medical Organization page to:</div>
            <div>1). Setup configuration</div>
            <div>2). Add clinical staffs</div>
            <div>3). Associate to a care unit</div>
          </div>
          <Button type="primary" className="w100 p10 autoH b5" onClick={handleClick}>
            Go to Medical Organization Page
          </Button>
        </div>
      )}
    />
  );
};

import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../types/form';
import { BILLING_CODE_DEPENDENCIES } from '../constants/billingCodes';

export const useMedicalOrgProgramParticipationForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    programType: {
      name: 'programType',
      label: 'Program Type',
    },
    programs: {
      name: 'programs',
      label: 'Programs',
    },
    consentFormId1: {
      name: 'consentFormId1',
      label: 'Consent Form',
    },
    consentFormId2: {
      name: 'consentFormId2',
      label: 'Consent Form',
    },
    feeModel: {
      name: 'feeModel',
      label: 'Fee Model',
    },
    isSaasModel: {
      name: 'isSaasModel',
      label: 'SaaS Model',
    },
    invoiceCycle: {
      name: 'invoiceCycle',
      label: 'iHealth invoice cycle',
    },
    isReportRequiredForInsurance: {
      name: 'isReportRequiredForInsurance',
      label: 'Does this organization require billing report to bill claim to insurance?',
    },
    billingGeneration: {
      name: 'billingGeneration',
      label: 'Billing documents generation',
    },
  }, options);

  const shouldDisable = (getFieldValue: GetFieldValue, name: string) => name in BILLING_CODE_DEPENDENCIES && !getFieldValue('billingCodes')?.includes(BILLING_CODE_DEPENDENCIES[name]);
  const isCcmRpm = (getFieldValue: GetFieldValue) => getFieldValue('programType') === 'ccmRpm';

  return {
    ...factory,
    shouldDisable,
    isCcmRpm
  };
};

import {
  Card,
  Col,
  Row,
  Space,
  Switch,
} from 'antd';
import { useBoolean } from 'usehooks-ts';
import { Alert } from '../../type';
import { PriorityEnumComponent } from '../../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { Icons } from '../../../../icons/Icons';
import { handleDuplicateAlertModal } from '../../modal/confirmModals';
import './AlertDefaultCardComponent.scss';
import { useAlertApiContext } from '../../context/AlertApiContext';

export interface AlertsCheckboxGroupComponentProps {

}

export interface AlertDefaultCardComponentProps {
  alert: Alert;
}

export const AlertDefaultCardComponent = ({
  alert,
}: AlertDefaultCardComponentProps) => {
  const {
    onDuplicateAlert,
    onActiveChange,
    isActiveChangeLoading,
  } = useAlertApiContext();
  const { value: isHovering, setTrue, setFalse } = useBoolean();

  const handleActiveChange = (value: boolean) => {
    onActiveChange(alert.alertType as string, value);
  };

  const handleDuplicate = () => {
    handleDuplicateAlertModal({
      onDuplicate: () => onDuplicateAlert?.(alert.alertType as string),
    });
  };

  return (
    <Card className="alert-default-card-comp" onMouseEnter={setTrue} onMouseLeave={setFalse}>
      <Row align="middle">
        <Col span={9}>
          <Space>
            <Switch
              disabled={isActiveChangeLoading}
              checked={!!alert.active}
              onChange={handleActiveChange}
            />
            <div className="alert-name">{alert.displayName}</div>
          </Space>
        </Col>
        <Col span={15} className="flex jc-sb ai-c">
          <div className="alert-default-card-comp__info">
            <PriorityEnumComponent value={alert.priority} />
            <div>{alert.eventType}</div>
            <Space
              direction="vertical"
              align="center"
              className="alert-default-card-comp__info__rules"
            >
              {alert.rules?.map((rule, i) => <div key={i?.toString()}>{rule.name}</div>)}
            </Space>
          </div>
          {isHovering ? (
            <Icons.CopyIcon onClick={handleDuplicate} />
          ) : <div className="empty-div" />}
        </Col>
      </Row>
    </Card>
  );
};

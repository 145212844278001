import { FC, ReactNode, useState } from 'react';
import CardWHorizontalSectionComponent from '../CardWHorizontalSectionComponent/CardWHorizontalSectionComponent';
import CardWHorizontalSectionItemComponent from '../CardWHorizontalSectionComponent/CardWHorizontalSectionItemComponent';
import HoverComponent from '../HoverComponent/HoverComponent';
import './CardHoverEditComponent.scss';
import { Icons } from '../../icons/Icons';
import { ClickableDiv } from '../ClickableDiv/ClickableDiv';

export interface CardHoverEditComponentProps {
  children: ReactNode,
  onEdit?: () => void;
  editLabel?: ReactNode;
  showEditLabel?: boolean;
}

interface SubComponents {
  Item: typeof CardWHorizontalSectionItemComponent;
}

export const CardHoverEditComponent: FC<CardHoverEditComponentProps> & SubComponents = ({
  children,
  onEdit,
  editLabel,
  showEditLabel = true,
}: CardHoverEditComponentProps) => {
  const [isShowing, setIsShowing] = useState(true);
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <div className="card-hover-edit-component">
      <HoverComponent onChange={setIsShowing}>
        <CardWHorizontalSectionComponent>
          <CardWHorizontalSectionComponent.Item noDivider>
            <div
              className="flex jc-e card-edit-icon"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {showEditLabel && isShowing && (
                <ClickableDiv onClick={onEdit}>
                  <Icons.EditPenIcon hexColor={hover ? '1890ff' : ''} />
                  {editLabel !== undefined && <span className="ml5">{editLabel}</span>}
                </ClickableDiv>
              )}
            </div>
          </CardWHorizontalSectionComponent.Item>
          <div className="card-hover-content">
            {children}
          </div>
        </CardWHorizontalSectionComponent>
      </HoverComponent>
    </div>
  );
};

CardHoverEditComponent.Item = CardWHorizontalSectionItemComponent;

export default CardHoverEditComponent;

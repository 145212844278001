import { ConditionSelectComponent, ConditionSelectComponentProps } from '../../inputComponent/ConditionSelectComponent/ConditionSelectComponent';
import { ConditionComponent, ConditionComponentProps } from '../ConditionComponent/ConditionComponent';
import { InputType } from '../../../Input/types';
import { Condition } from '../../../../uc-api-sdk';

export interface ConditionConfigComponentProps extends InputType<Condition[]> {
}

export const ConditionConfigComponent = ({
  value = [],
  onChange,
}: ConditionConfigComponentProps) => {
  const handleConditionChange = (conditionIndex: number): ConditionComponentProps['onChange'] => (v) => {
    const newConditions = [...value];
    // @ts-ignore
    newConditions[conditionIndex] = v;
    onChange?.(newConditions);
  };

  const handleConditionSelect: ConditionSelectComponentProps['onChange'] = (v) => {
    onChange?.(v?.condition ? [...value, { metric: v.condition.name }] : value);
  };

  const handleRemoveCondition = (conditionIndex: number): ConditionComponentProps['onRemove'] => () => {
    const newConditions = [...value];
    newConditions.splice(conditionIndex, 1);
    onChange?.(newConditions);
  };

  return (
    <div>
      <h4>
        Conditions
      </h4>
      <div>
        {value.map((v, i) => (
          <div key={i?.toString()} className="mb10">
            <ConditionComponent
              // @ts-ignore
              value={v}
              onChange={handleConditionChange(i)}
              onRemove={handleRemoveCondition(i)}
            />
          </div>
        ))}
      </div>
      <div>
        <ConditionSelectComponent
          // adding key so the value is undefined on change
          // meaning the dropdown will not be selected
          key={value.length}
          onChange={handleConditionSelect}
          value={undefined}
        />
      </div>
    </div>
  );
};

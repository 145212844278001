import { Nullable } from '../../../../../uc-api-sdk';
import CardEditWithHeader from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import TooltipComponent from '../../../../../uiComponent/TooltipComponent/TooltipComponent';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';
import '../MedicalOrgConfigurationComponent/MedicalOrgConfigurationComponent.scss';

export const MedicalOrgOutstandingItemsConfigComponent = () => {
  const { medicalOrgService: service, refetch } = useMedicalOrgContext();
  const { careServiceConfig } = service;
  const { getDrawer } = useAdminPortalPopupContext();
  const { open, close } = getDrawer(PopupDrawerTypes.EditOutstandingItemsConfig);

  const handleClick = () => {
    open({
      mode: 'edit',
      initialValues: {
        medicalAlertTrigger: careServiceConfig?.medicalAlertTrigger || false,
        complianceAlertTrigger: careServiceConfig?.complianceAlertTrigger || false,
        onboardingTodoTrigger: careServiceConfig?.onboardingTodoTrigger || false,
        mtprTrigger: careServiceConfig?.mtprTrigger || false,
        sendAutoMessageTrigger: careServiceConfig?.sendAutoMessageTrigger || false,
        messageTrigger: careServiceConfig?.messageTrigger || false,
        foodLogTrigger: careServiceConfig?.foodLogTrigger || false,
        id: service.id
      },
      onSuccess: () => {
        close();
        refetch();
      }
    });
  };

  const renderOnOff = (value?: Nullable<boolean>) => (value ? 'On' : 'Off');

  const data = [
    {
      key: <div className="bold text-gray-scale-1 fs14">Care Service:</div>,
      value: ' '
    },
    {
      key: <div>Medical Alerts</div>,
      value: <div>{renderOnOff(careServiceConfig?.medicalAlertTrigger)}</div>
    },
    {
      key: <div>Compliance Alerts</div>,
      value: <div>{renderOnOff(careServiceConfig?.complianceAlertTrigger)}</div>
    },
    {
      key: <div>Onboarding to-do</div>,
      value: <div>{renderOnOff(careServiceConfig?.onboardingTodoTrigger)}</div>
    },
    {
      key: <div>MTPR</div>,
      value: <div>{renderOnOff(careServiceConfig?.mtprTrigger)}</div>,
    },
    {
      key: (
        <TooltipComponent
          triggerOnHoverIcon
          type="info-icon"
          title="Automated messages will be sent to
patients via the in-app chat channel,
with the goal of enhancing patient
engagement."
        >
          Auto messages
        </TooltipComponent>
      ),
      value: <div>{renderOnOff(careServiceConfig?.sendAutoMessageTrigger)}</div>
    },
    {
      key: <div className="bold text-gray-scale-1 fs14">Patient Engagement Service:</div>,
      value: ' '
    },
    {
      key: <div>Message</div>,
      value: <div>Coming soon</div>
      // value: <div>{renderOnOff(careServiceConfig?.messageTrigger)}</div>
    },
    {
      key: <div>Foodlogs</div>,
      value: <div>Coming soon</div>
      // value: <div>{renderOnOff(careServiceConfig?.foodLogTrigger)}</div>
    },
  ];

  return (
    <div className="medical-org-profile">
      <CardEditWithHeader
        modal={{
          maskClosable: false,
        }}
        onEdit={handleClick}
        header="Outstanding Items Configuration Settings"
      >
        <MedicalOrgCommonCardContentComponent data={data} />
      </CardEditWithHeader>
    </div>
  );
};

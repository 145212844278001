import { Select, SelectProps } from 'antd';

export interface HSTableUnitDropdownComponentProps extends SelectProps {
}

export const HSTableUnitDropdownComponent = ({
  ...props
}: HSTableUnitDropdownComponentProps) => {
  const options = ['lb', 'kg'].map((unit) => ({ label: unit, value: unit }));
  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      options={options}
      dropdownMatchSelectWidth
    />
  );
};

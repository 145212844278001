import { Col, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Checkbox, { CheckboxGroupProps } from 'antd/lib/checkbox';
import { BillingCodeInputComponent } from '../BillingCodeInputComponent/BillingCodeInputComponent';

export interface BillingCodesFormItemComponentProps extends CheckboxGroupProps {
  ccmCodes: string[];
  rpmCodes: string[];
}

export const BillingCodesFormItemComponent = ({
  ccmCodes,
  rpmCodes,
  ...props
}: BillingCodesFormItemComponentProps) => (
  <div className="w100 bg-gray5 default-br p20">
    <div className="text-gray-scale-2 mb5">Applied billing code and cap</div>
    <FormItem dependencies={['programs']}>
      {({ getFieldValue }) => {
        const programs = getFieldValue('programs');
        return (
          <FormItem name="billingCodes">
            <Checkbox.Group
              className="w100"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              <Row gutter={30}>
                {
                  programs?.includes('CCM') && (
                    <Col span={12}>
                      <div className="mb5">CCM code:</div>
                      {ccmCodes.map((code) => (
                        <BillingCodeInputComponent
                          key={code}
                          name={String(code)}
                        />
                      ))}
                    </Col>
                  )
                }
                {
                  programs?.includes('RPM') && (
                    <Col span={12}>
                      <div className="mb5">RPM code:</div>
                      {rpmCodes.map((code) => (
                        <BillingCodeInputComponent
                          key={code}
                          name={String(code)}
                        />
                      ))}
                    </Col>
                  )
                }
              </Row>
            </Checkbox.Group>
          </FormItem>
        );
      }}
    </FormItem>
  </div>
);

/* eslint-disable camelcase */
import classNames from 'classnames';
import { DATE_COMMA_TIME_AM_PM_UPPERCASE, hhmm_a_UPPERCASE, USA_DATE } from '../../../../constants/timeFormat';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { Icons } from '../../../../icons/Icons';
import { ResultSourceEnum } from '../../../../uc-api-sdk';
import './HSLogBookDateComponent.scss';

export interface HSLogBookDateComponentProps {
  source: ResultSourceEnum;
  date: string;
  count: number;
  isNested?: boolean;
  timezone?: string;
}

export const HSLogBookDateComponent = ({
  source,
  date = '',
  count = 1,
  isNested,
  timezone
}: HSLogBookDateComponentProps) => {
  const getIcon = (source?: ResultSourceEnum) => {
    if (
      [
        ResultSourceEnum.MEASUREMENT,
        ResultSourceEnum.MANUALLY_INPUT
      ].includes(source as ResultSourceEnum)
    ) {
      return <Icons.WeightScaleIcon />;
    } if (source === ResultSourceEnum.MIXED) {
      return <Icons.MixedSourceIcon />;
    }
    return <Icons.InClinicVisitIcon hexColor="#00C8BC" />;
  };

  return (
    <div className={classNames({
      HSLogBookDateComponent: true,
      nested: isNested,
    })}
    >
      <div className="flex jc-c ai-c">
        {
          getIcon(source)
        }
      </div>
      <div>
        {(!count || count === 1)
          ? TimezoneService.calcDateTime(date, timezone
            || undefined).format(isNested ? hhmm_a_UPPERCASE : DATE_COMMA_TIME_AM_PM_UPPERCASE)
          : TimezoneService.calcDateTime(date, timezone || undefined).format(USA_DATE)}
      </div>
      {count > 1 ? <div className="hs-count">{count}</div> : null}
    </div>
  );
};

import { RoleTypeEnum } from '../../../../uc-api-sdk';

export const EmployeeRolesInCareUnit = [
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
  RoleTypeEnum.MANAGER,
  RoleTypeEnum.CALL_CENTER_REPRESENTATIVE,
];

export const ClinicalStaffRolesInCareUnit = [
  RoleTypeEnum.PROVIDER,
  RoleTypeEnum.MA,
  RoleTypeEnum.CLINICAL_MANAGER,
  RoleTypeEnum.CLINICAL_GROUP_BILLER,
];

export interface ClinicalStaffRolesValue {
  id: string,
  orgIds: string[]
}

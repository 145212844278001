import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import {
  forEach,
  intersection,
  map,
  uniq
} from 'lodash';
import { useMemo, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../../../contexts/loggedInUserContext';
import { ROLE_ENUM } from '../../../../../types/roles';
import { RemoveRoleValidationResponse, RoleTypeEnum } from '../../../../../uc-api-sdk';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { CreateUserFormValues, LimitedRoleTypes } from '../../type';
import { UserRoleHierarchyComponent } from '../UserRoleHierarchyComponent/UserRoleHierarchyComponent';
import { iHealthEmployeeRoleTypes, roleCombinationLimit, roleWithDefaultCareUnit } from './constant';

export interface InternalEmployeeRoleTreeComponentProps extends CheckboxGroupProps {
  initialValues?: CreateUserFormValues;
  hasPatientsCheck: RemoveRoleValidationResponse[];
}

export const InternalEmployeeRoleTreeComponent = ({
  initialValues,
  hasPatientsCheck,
  ...props
}: InternalEmployeeRoleTreeComponentProps) => {
  const [currentValues, setValues] = useState<CheckboxValueType[]>(props.value || []);
  const { isAdmin } = useLoggedInUserFromContext();
  const handleChange = (
    value: CheckboxValueType[],
  ) => {
    setValues(value);
    props.onChange?.(value);
  };

  const getDisabledIds = (role: ROLE_ENUM) => {
    const ids: string[] = [];
    forEach(hasPatientsCheck, (item) => {
      if (item.roles?.includes(role)) {
        forEach(item.careGroupAssociatedMaps, (mapItem) => {
          if (mapItem.careGroup?.id) {
            const { id } = mapItem.careGroup;
            ids.push(id);
          }
        });
      }
    });
    return ids;
  };

  const roleList = isAdmin
    ? iHealthEmployeeRoleTypes.filter((role) => role.value !== ROLE_ENUM.ADMIN)
    : iHealthEmployeeRoleTypes;

  const disabledRoles = useMemo(() => {
    const disabledRoles: RoleTypeEnum[] = [];
    // order to make sure role check based on order priority to migrate locally
    const orderedCurrentValues = intersection(map(roleList, 'value'), currentValues);
    forEach(orderedCurrentValues, (role) => {
      if (disabledRoles.includes(role as RoleTypeEnum)) {
        // already limited by previous role selection
        return;
      }
      const limit = roleCombinationLimit[role as RoleTypeEnum];
      if (limit && limit.length) {
        disabledRoles.push(...limit);
      }
    });
    return uniq(disabledRoles);
  }, [
    currentValues,
    map(roleList, 'value')
  ]);

  const options = roleList.map((role) => (
    <div key={role.value}>
      <Checkbox
        value={role.value}
        className="m0i"
        disabled={(
          disabledRoles.includes(role.value as RoleTypeEnum)
          || !!hasPatientsCheck.find((item) => item.roles?.includes(role.value))
        )}
      >
        {role.label}
      </Checkbox>
      {
        !roleWithDefaultCareUnit.includes(role.value as ROLE_ENUM)
        && currentValues.includes(role.value)
        && (
          <FormItem
            name={role.value}
            required
          >
            <UserRoleHierarchyComponent
              roleLabel={role.label}
              name={role.value}
              checkedKeys={initialValues?.[role.value as LimitedRoleTypes] as string[]}
              disabledIds={getDisabledIds(role.value)}
            />
          </FormItem>
        )
      }
    </div>
  ));

  return (
    <Checkbox.Group
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={handleChange}
      className={classNames({
        'internal-employee-role-tree-component flex fd-c': true,
        [props.className as string]: !!props.className
      })}
    >
      {options}
    </Checkbox.Group>
  );
};

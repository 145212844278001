import { FormItemProps } from 'antd';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import EmailService from '../../../services/EmailService';
import { GetFieldValue } from '../../../types/form';
import { UserTypeEnum } from '../../../uc-api-sdk';

export interface EmailRules {
  required: boolean;
  isIhealthEmail?: boolean;
}

export const useAdminUserForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    userType: {
      name: 'userType',
      label: 'User type',
    },
    firstName: {
      name: 'firstName',
      label: 'First name',
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
    },
    middleName: {
      name: 'middleName',
      label: 'Middle name',
    },
    email: {
      name: 'email',
      label: 'Account email',
    },
    loginId: {
      name: 'loginId',
      label: 'Login ID',
    },
    phone: {
      name: 'phone',
      label: 'Phone number',
    },
    language: {
      name: 'language',
      label: 'Language',
    },
    credentials: {
      name: 'credentials',
      label: 'Credentials',
    },
    description: {
      name: 'description',
      label: 'Description',
    },
    roles: {
      name: 'roles',
      label: 'Please select roles for this user',
    },
    ihealthRoles: {
      name: 'ihealthRoles',
      label: 'Please select roles for this user',
    },
    isObserverUser: {
      name: 'isObserverUser',
      label: 'Observer user',
    },
    organizations: {
      name: 'organizations',
      label: 'Please select medical organization',
    },
    npi: {
      name: 'npi',
      label: 'NPI',
    },
    supervisingProviderId: {
      name: 'supervisingProviderId',
      label: 'Supervising provider',
    },

  }, options);

  const isClinicalStaff = (getFieldValue: GetFieldValue) => getFieldValue('userType') === UserTypeEnum.CLINICAL_STAFF;
  const shouldShowOrgDropdown = (getFieldValue: GetFieldValue) => isClinicalStaff(getFieldValue) && !!getFieldValue('roles');
  const getEmailRules = ({ required, isIhealthEmail }: EmailRules) => {
    const requiredRules = [
      { validator: EmailService.getEmailValidator(true, isIhealthEmail), required },
    ];
    return requiredRules as FormItemProps['rules'];
  };

  return {
    ...factory,
    isClinicalStaff,
    shouldShowOrgDropdown,
    getEmailRules,
  };
};

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { useState } from 'react';
import { RoleTypeEnumComponent } from '../../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { MedicalOrganizationHelper } from '../../../../../helpers/medicalOrganization';
import {
  RemoveRoleValidationResponse,
  RoleAssignmentOverWriteByOrganizationRequest, RoleTypeEnum, UserTypeEnum
} from '../../../../../uc-api-sdk';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import { userRolesRestrictionModal } from '../../../component/UserRolesRestrictionModal/UserRolesRestrictionModal';
import { useRoleLimit } from '../../../hook/useRoleLimit';
import { ClinicalStaffRolesInCareUnit, EmployeeRolesInCareUnit } from '../../constants/constant';

export interface ModifyMemberRolesComponentProps extends CheckboxGroupProps {
  onClickSave?: (employees: RoleAssignmentOverWriteByOrganizationRequest) => void;
  memberId?: string;
  careUnitId: string;
  initialRoles?: RoleTypeEnum[];
  hasPatientsCheck?: RoleTypeEnum[];
  otherRoles?: RoleTypeEnum[];
  validationModalData?: RemoveRoleValidationResponse[];
  userType?: UserTypeEnum;
  isLoading?: boolean;
}

export const ModifyMemberRolesComponent = ({
  onClickSave,
  onChange,
  memberId = '',
  careUnitId,
  initialRoles,
  hasPatientsCheck = [],
  otherRoles = [],
  validationModalData,
  isLoading,
  userType = UserTypeEnum.IHEALTH_EMPLOYEE
}: ModifyMemberRolesComponentProps) => {
  const [checkedValues, setCheckedValues] = useState<RoleTypeEnum[]>(initialRoles || []);
  const [data, setData] = useState<RoleAssignmentOverWriteByOrganizationRequest>();
  const { shouldDisableRole } = useRoleLimit();

  const conflictingRoles = userType === UserTypeEnum.CLINICAL_STAFF
    ? MedicalOrganizationHelper.getClinicalStaffConflictingRoles(
      initialRoles || []
    ) : [];

  const handleChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    setCheckedValues(checkedValue as RoleTypeEnum[]);
    const result = {
      memberId,
      orgId: careUnitId,
      roles: checkedValue.map((role) => role as RoleTypeEnum),
    };
    onChange?.(checkedValue);
    setData(result);
  };

  const shouldBeDisabled = (role: RoleTypeEnum, otherRoles: RoleTypeEnum[]) => {
    const c1 = shouldDisableRole(role as RoleTypeEnum, otherRoles);
    const c2 = hasPatientsCheck?.includes(role);
    const c3 = conflictingRoles.includes(role);
    const c4 = shouldDisableRole(role, checkedValues);
    return c1 || c2 || c3 || c4;
  };

  return (
    <FixedComponent>
      <FixedComponent.Child>
        <div className="secondary-gray mb10">Member's role in this Care Unit</div>
        <Checkbox.Group
          value={checkedValues}
          onChange={handleChange}
          className="flex jc-sb"
        >
          {
            (userType === UserTypeEnum.IHEALTH_EMPLOYEE
              ? EmployeeRolesInCareUnit : ClinicalStaffRolesInCareUnit).map((role) => (
                <Checkbox
                  value={role}
                  key={role}
                  disabled={
                    shouldBeDisabled(role, otherRoles)
                  }
                >
                  <RoleTypeEnumComponent value={role as RoleTypeEnum} />
                </Checkbox>
            ))
          }
        </Checkbox.Group>
        {
          checkedValues.length === 0 && (
            <div className="flex gap1 mt20 danger ai-s">
              <ExclamationCircleOutlined className="danger mt5" />
              {userType === UserTypeEnum.IHEALTH_EMPLOYEE && (
                <div>
                  A member must be assigned to at least one role in a Care Unit.
                  If you want to remove all roles,
                  please consider removing this user from the Care Unit instead.
                </div>
              )}
              {userType === UserTypeEnum.CLINICAL_STAFF && (
                <div>
                  A member must be assigned to at least one role.
                  If you want to remove all roles,
                  please consider removing this user from the organization instead.
                </div>
              )}
            </div>
          )
        }
        {
          !!validationModalData?.length && (
            <div className="flex jc-e mt10">
              <Button
                type="link"
                onClick={() => userRolesRestrictionModal('tip', validationModalData)}
                className="p0"
              >
                Can't modify certain roles?
              </Button>
            </div>
          )
        }
      </FixedComponent.Child>
      <FixedComponent.Child isFixed>
        <Button
          type="primary"
          className="w100"
          disabled={isLoading || checkedValues.length === 0}
          loading={isLoading}
          onClick={() => {
            if (checkedValues.length !== 0) {
              onClickSave?.(
                data || { memberId, orgId: careUnitId, roles: checkedValues }
              );
            }
          }}
        >
          Save
        </Button>
      </FixedComponent.Child>
    </FixedComponent>
  );
};

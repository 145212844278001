import classNames from 'classnames';
import { DATE_TIME_AM_PM } from '../../../../constants/timeFormat';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface SymptomTableDateComponentProps {
  date: string;
  total: number;
  isNested?: boolean;
}

export const SymptomTableDateComponent = ({
  date,
  total,
  isNested,
}: SymptomTableDateComponentProps) => (
  <div className={classNames({
    SymptomTableComponent: true,
    nested: isNested,
  })}
  >
    <div className="flex">
      {TimezoneService.calcDateTimeDayjs(date).format(DATE_TIME_AM_PM)}
      {total > 1 ? (
        <div className="symptom-count">
          {total}
        </div>
      ) : null}
    </div>
  </div>
);

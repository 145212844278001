import { Icons } from '../../../../../icons/Icons';
import { OrganizationTypeEnum } from '../../../../../uc-api-sdk';

export interface CareUnitIconComponentProps {
  value: OrganizationTypeEnum | undefined;
}

export const CareUnitIconComponent = ({
  value,
}: CareUnitIconComponentProps) => {
  const getIcon = () => {
    switch (value) {
      case OrganizationTypeEnum.CARE_CENTER: return <Icons.CareCenterIcon />;
      case OrganizationTypeEnum.CARE_DIVISION: return <Icons.CareDivisionIcon />;
      case OrganizationTypeEnum.CARE_GROUP: return <Icons.CareGroupIcon />;
      case OrganizationTypeEnum.ROOT: return <Icons.CareUnitRootIcon />;
      default: return null;
    }
  };

  return (<span>{getIcon()}</span>);
};

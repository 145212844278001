import React from 'react';
import { HealthConditionItem, PatientComplexity, PatientDiagnosesEnum } from '../../../../uc-api-sdk';
import { PatientDiagnosesEnumComponent } from '../../../../enumComponent/PatientDiagnosesEnumComponent/PatientDiagnosesEnumComponent';
import { ICDTableComponent } from '../../../ICDCode/component/ICDTableComponent/ICDTableComponent';
import { DiagnosisTitleComponent } from '../../../patient/container/PatientHealthConditionComplexityFormContainer/DiagnosisTitleComponent';
import { ComplexityComponent } from '../../../complexity/component/ComplexityComponent/ComplexityComponent';
import { IcdCodeTitleComponent } from '../../../patient/container/PatientHealthConditionComplexityFormContainer/IcdCodeTitleComponent';
import { IcdCodeWarningComponent } from '../../../ICDCode/component/IcdCodeWarningComponent/IcdCodeWarningComponent';

export interface DiagnosisIcdCodeViewComponentProps {
  initDx: PatientDiagnosesEnum[];
  initHealthCondition?: HealthConditionItem[];
  complexity?: PatientComplexity;
}

export const DiagnosisIcdCodeViewComponent = ({
  initDx,
  initHealthCondition,
  complexity,
}: DiagnosisIcdCodeViewComponentProps) => {
  const targetDx = initHealthCondition?.map(i => i?.diagnosis)
    .filter((d): d is PatientDiagnosesEnum => d !== PatientDiagnosesEnum.OTHERS);

  return (
    <div className="ml12 mt10">
      <DiagnosisTitleComponent />
      <div className="mb20 gap3">
        {initDx.map((diagnose, index) => (
          <React.Fragment key={diagnose}>
            <PatientDiagnosesEnumComponent value={diagnose} />
            {index < initDx.length - 1 && '\u00A0\u00A0\u00A0\u00A0\u00A0'}
          </React.Fragment>
        ))}
      </div>
      <IcdCodeTitleComponent />
      <ICDTableComponent
        dataSource={initHealthCondition || []}
        disabled
        isEdit={false}
      />
      <IcdCodeWarningComponent
        checkedDx={initDx.filter(i => i !== PatientDiagnosesEnum.OTHERS)}
        targetDx={targetDx}
      />
      <ComplexityComponent
        complexityLevel={complexity?.level || undefined}
        updatedByUser={complexity?.updatedByUser || {}}
      />
    </div>
  );
};

import { Modal } from 'antd';
import { AlertRule } from '../../type';
import { RuleOptionContainer } from '../../container/RuleOptionContainer/RuleOptionContainer';
import { EventTypeEnum } from '../../../../uc-api-sdk';

export interface AlertConfigModalComponentProps {
  alertId: string;
  rule?: AlertRule;
  type?: EventTypeEnum;
  isOpen?: boolean;
  onCancel?: () => void;
}

export const AlertConfigModalComponent = ({
  alertId,
  rule,
  type,
  isOpen,
  onCancel,
}: AlertConfigModalComponentProps) => (
  <Modal
    open={isOpen}
    width={1300}
    onCancel={onCancel}
    footer={null}
    maskClosable={false}
    title="Rule Configuration"
    destroyOnClose
  >
    {type && rule
      ? (
        <RuleOptionContainer
          alertId={alertId}
          rule={rule}
          alertType={type}
          onSubmit={onCancel}
        />
      )
      : `Missing Alert Type or Rule: ${type}`}
  </Modal>
);

import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { MedicalOrganizationStatus, useMedicalOrganizationUpdate } from '../../../../../uc-api-sdk';
import { MedicalOrgBillingEntityDrawerComponent } from '../../component/MedicalOrgBillingEntityDrawerComponent/MedicalOrgBillingEntityDrawerComponent';
import { BillingEntityFormValues } from '../../component/MedicalOrgBillingEntityFormComponent/MedicalOrgBillingEntityFormComponent';

export interface MedicalOrgBillingFormContainerProps {
  initialValues?: BillingEntityFormValues;
  onSuccess?: () => void;
}

export const MedicalOrgBillingFormContainer = ({
  initialValues,
  onSuccess,
}: MedicalOrgBillingFormContainerProps) => {
  const hook = useMedicalOrganizationUpdate({});

  const handleUpdate = async (values: BillingEntityFormValues) => {
    if (!initialValues?.id) {
      return;
    }

    const {
      businessAddress,
      state,
      city,
      zip,
      medicalOrgRecipientsEmails,
      ihealthInternalRecipientsEmails,
      ihealthInternalCCRecipientsEmails,
      ...rest
    } = values;

    const submitValues = {
      medicalOrgRecipientsEmails: medicalOrgRecipientsEmails?.split(',').map((
        email
      ) => email.trim()),
      ihealthInternalRecipientsEmails: ihealthInternalRecipientsEmails?.split(',').map((
        email
      ) => email.trim()),
      ihealthInternalCCRecipientsEmails: ihealthInternalCCRecipientsEmails?.split(',').map((
        email
      ) => email.trim()),
      businessAddress: {
        streetName: businessAddress,
        state,
        city,
        postCode: zip,
      },
      ...rest,
    };

    const result = hook.send({
      params: {
        id: initialValues?.id,
        // @ts-ignore
        document: {
          status: MedicalOrganizationStatus.ACTIVE,
          billingEntity: submitValues,
        }
      }
    });
    await ApiRequestHelper.tryCatch(result, {
      success: 'Billing Entity updated!',
      error: 'Failed to update billing entity. Please try again.',
      onSuccess() {
        onSuccess?.();
      },
    });
  };

  return (
    <MedicalOrgBillingEntityDrawerComponent
      onSubmit={handleUpdate}
      initialValues={initialValues}
    />
  );
};

export default MedicalOrgBillingFormContainer;

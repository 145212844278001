import { ReactNode } from 'react';
import { EHRProviderEnumComponent } from '../../../../../enumComponent/EHRProviderEnumComponent/EHRProviderEnumComponent';
import { EhrAddressParams, makeEhrOrgAddressString } from '../../../../../helpers/address';
import { CareQualityStatus, FileInfo } from '../../../../../uc-api-sdk';
import { CardEditWithHeader } from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import YesNoComponent from '../../../../../uiComponent/YesNoComponent/YesNoComponent';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { CareQualityConsentDocumentContainer } from '../../container/CareQualityConsentDocumentContainer/CareQualityConsentDocumentContainer';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';

interface Data {
  key: ReactNode;
  value?: ReactNode;
}

export const MedicalOrgEHRComponent = () => {
  const { medicalOrgService, refetch } = useMedicalOrgContext();
  const { open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditMedicalOrgEHR);

  const { fileInfo } = medicalOrgService.careQualityStatus || {};
  const initialValues = {
    ehrData: medicalOrgService.ehr,
    id: medicalOrgService.id,
    type: medicalOrgService.type,
    careQuality: medicalOrgService.careQualityStatus?.agreedToJoin || false,
    fileInfo: medicalOrgService.careQualityStatus?.agreedToJoin ? fileInfo : undefined,
    history: medicalOrgService.careQualityHistory || [] as CareQualityStatus[],
    ehrIntegrationAvailable: medicalOrgService.integrationFeature?.isReferralEnabled || false,
    enableAdvancedConfiguration: !!medicalOrgService.enableAdvancedConfiguration || false,
    athenaPracticeId: medicalOrgService.athenaPracticeId || '',
    autoClaimSubmit: medicalOrgService.integrationFeature?.isAutoBillingEnabled || false,
    departments: medicalOrgService.athenaDepartmentIds,
    defaultMedicalOrg: medicalOrgService.careQualityMedicalOrg?.ehrOrgInformation,
  };

  const onClick = () => {
    open(
      {
        onSuccess: () => {
          close();
          refetch();
        },
        initialValues
      }
    );
  };

  const ehrData: Data[] = [
    {
      key: 'EHR Service Provider',
      value: <EHRProviderEnumComponent value={medicalOrgService.ehr} />
    },
    {
      key: 'EHR Integration Available',
      value: <YesNoComponent
        yes={medicalOrgService.integrationFeature?.isReferralEnabled}
      />
    },
    {
      key: 'EHR Athena Departments',
      value: <DisplayOrEmptyComponent
        value={medicalOrgService.athenaDepartments?.map(dep => dep.ehrDepartmentInformation?.name).join(', ')}
      />
    },
    {
      key: 'EHR Auto Claim Submit',
      value: <YesNoComponent yes={medicalOrgService.integrationFeature?.isAutoBillingEnabled} />
    },
    {
      key: 'Enable UC Data Sharing with Carequality',
      value: medicalOrgService.careQualityStatus?.agreedToJoin ? (
        <>
          Yes
          <CareQualityConsentDocumentContainer fileInfo={fileInfo as FileInfo} />
        </>
      ) : 'No'
    },
    {
      key: 'Carequality Default Medical Organization',
      value: <DisplayOrEmptyComponent
        value={
          makeEhrOrgAddressString(
            {
              ...medicalOrgService.careQualityMedicalOrg?.ehrOrgInformation,
            } as EhrAddressParams
          )
        }
      />
    },
  ];

  return (
    <CardEditWithHeader
      header="EHR"
      onEdit={onClick}
    >
      <MedicalOrgCommonCardContentComponent data={ehrData} />
    </CardEditWithHeader>
  );
};

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, SwitchProps } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';

import './SwitchComponent.scss';

export interface SwitchComponentProps extends SwitchProps {
  value?: boolean;
  loading ?: boolean;
  disabled ?: boolean;
  className ?: string;
  testId ?: string;
  children ?: ReactNode;
  wrapperClassName ?: string;
  isColumn ?: boolean;
}

export const SwitchComponent = ({
  value,
  onChange,
  children,
  className = '',
  testId = 'switchComponent',
  wrapperClassName = '',
  isColumn,
  ...props
}: SwitchComponentProps) => (
  <div
    className={classNames({
      'inline-flex ai-bl': !isColumn,
      [wrapperClassName]: !!wrapperClassName,
    })}
  >
    <Switch
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      checked={value}
      className={classNames({
        'switch-component': true,
        [className]: !!className,
      })}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      onChange={onChange}
      data-testid={testId}
      loading={props.loading}
    />
    {children}
  </div>
);

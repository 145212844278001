import { EventTypeEnum, RuleItem, useRuleEngineDryRunRuleOnTheFly } from '../../../../uc-api-sdk';
import { AlertSimulatorComponent, AlertSimulatorComponentProps } from '../../component/AlertSimulatorComponent/AlertSimulatorComponent';

export interface RuleDryRunContainerProps {
  type: EventTypeEnum;
  rule: RuleItem;
}

export const RuleDryRunContainer = ({
  type,
  rule,
}: RuleDryRunContainerProps) => {
  const dryRun = useRuleEngineDryRunRuleOnTheFly({});

  const handleDryRun: AlertSimulatorComponentProps['onDryRun'] = async (events) => {
    dryRun.send({
      params: {
        request: {
          eventType: type,
          rule,
          events,
        },
      },
    });
  };

  return (
    <AlertSimulatorComponent
      type={type}
      onDryRun={handleDryRun}
      isLoading={dryRun.isLoading}
      result={dryRun.data?.data ?? undefined}
    />
  );
};

import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const OUTSTANDING_ITEM_CONFIG_FIELDS = {
  medicalAlertTrigger: {
    name: 'medicalAlerts',
    label: 'Medical alerts',
    description: 'Medical alerts are generated when patients have critical readings.',
  },
  complianceAlertTrigger: {
    name: 'complianceAlerts',
    label: 'Compliance alerts',
    description: "Compliance alerts aid care members in supporting patients' adherence to the program protocol.",
  },
  onboardingTodoTrigger: {
    name: 'onboardingToDo',
    label: 'Onboarding to-do',
    description: 'The onboarding to-do includes assigning devices and scheduling initial visits/tech-onboarding visits after enrolling a patient.',
  },
  mtprTrigger: {
    name: 'mtpr',
    label: 'MTPR',
    description: "Monthly Treatment Plan Review (MTPR) is mandated for billing CCM & RPM, and also serves as a communication channel regarding patients' clinical outcome."
  },
  sendAutoMessageTrigger: {
    name: 'autoMessages',
    label: 'Auto messages',
    description: 'Auto messages are sent to patients in order to improve patients engagements.'
  },
  messageTrigger: {
    name: 'messageTrigger',
    label: 'Message',
    description: 'Are care members required to respond to messages sent by patients? Select as “On" if they need to do so.'
  },
  foodLogTrigger: {
    name: 'foodLogTrigger',
    label: 'Foodlogs',
    description: 'Are care members required to review food logs for patients? Select as “On” if they need to do so.'
  },
};

export const useMedicalOrgOutstandingItemForm = (options?: FormOptions) => {
  const factory = useFormHookFactory(OUTSTANDING_ITEM_CONFIG_FIELDS, options);
  return factory;
};

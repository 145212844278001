import Checkbox, { CheckboxGroupProps } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { RoleTypeEnumComponent } from '../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { RoleTypeEnum } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicalOrgSelectContainer } from '../../container/MedicalOrgSelectContainer/MedicalOrgSelectContainer';
import { MedicalOrganizationsSelectComponent } from '../MedicalOrganizationsSelectComponent/MedicalOrganizationsSelectComponent';
import './EmployeeRolesSelectComponent.scss';

export interface EmployeeRolesSelectComponentProps extends CheckboxGroupProps {
  removeRoleValidationList: RoleTypeEnum[];
  restrictedMedOrgIds?: string[];
  orgIdsWithPt?: string[];
  vertical?: boolean;
  // form: FormInstance;
  // currentEmployeeId?: string;
  // supervisingProviderId?: string;
}

export const EmployeeRolesSelectComponent = ({
  removeRoleValidationList,
  value,
  onChange,
  restrictedMedOrgIds,
  orgIdsWithPt,
  vertical = true,
  // form,
  // currentEmployeeId,
  // supervisingProviderId
}: EmployeeRolesSelectComponentProps) => (
  <div className="employee-roles-select-component">
    <MedicalOrgSelectContainer>
      {({ data, isLoading }) => (
        <Checkbox.Group
          name="role"
          onChange={onChange}
          value={value}
          className={classNames({ 'flex fd-c gap2': vertical })}
        >
          <Checkbox
            value={RoleTypeEnum.PROVIDER}
            name={RoleTypeEnum.PROVIDER}
            disabled={
              removeRoleValidationList.includes(RoleTypeEnum.PROVIDER)
              || value?.includes(RoleTypeEnum.MA)
            }
          >
            MD
          </Checkbox>
          {value?.includes(RoleTypeEnum.PROVIDER) && (
            <FormItem
              required
              label="Please Select Medical Organization(s)"
              name={RoleTypeEnum.PROVIDER}
              className="p20 bg-gray5"
            >
              <MedicalOrganizationsSelectComponent
                showAssociated={false}
                data={data || []}
                restrictedMedOrgIds={restrictedMedOrgIds}
                orgIdsWithPt={orgIdsWithPt}
                loading={isLoading}
              />
            </FormItem>
          )}
          {/* {(value?.includes(RoleTypeEnum.PROVIDER) || supervisingProviderId) && (
            <FormItem dependencies={[RoleTypeEnum.PROVIDER]}>
              {({ getFieldValue }) => {
                const clinicIds = getFieldValue(RoleTypeEnum.PROVIDER);
                return (
                  <FormItem
                    name="supervisingProviderId"
                    label={null}
                  >
                    <ClosableCoworkerDropdownButtonComponent
                      clinicIds={clinicIds}
                      form={form}
                      currentEmployeeId={currentEmployeeId}
                    />
                  </FormItem>
                );
              }}
            </FormItem>
          )} */}
          <Checkbox
            value={RoleTypeEnum.MA}
            name={RoleTypeEnum.MA}
            disabled={
              removeRoleValidationList.includes(RoleTypeEnum.MA)
              || value?.includes(RoleTypeEnum.PROVIDER)
            }
          >
            MA
          </Checkbox>
          {value?.includes(RoleTypeEnum.MA) && (
            <FormItem
              required
              label="Please Select Medical Organization(s)"
              name={RoleTypeEnum.MA}
              className="p20 bg-gray5"
            >
              <MedicalOrganizationsSelectComponent
                showAssociated={false}
                data={data || []}
                loading={isLoading}
              />
            </FormItem>
          )}
          <Checkbox
            value={RoleTypeEnum.CLINICAL_MANAGER}
            name={RoleTypeEnum.CLINICAL_MANAGER}
            disabled={removeRoleValidationList.includes(RoleTypeEnum.CLINICAL_MANAGER)
              || value?.includes(RoleTypeEnum.CLINICAL_GROUP_BILLER)}
          >
            <RoleTypeEnumComponent value={RoleTypeEnum.CLINICAL_MANAGER} />
          </Checkbox>
          {value?.includes(RoleTypeEnum.CLINICAL_MANAGER) && (
            <FormItem
              required
              label="Please Select Medical Organization(s)"
              name={RoleTypeEnum.CLINICAL_MANAGER}
              className="p20 bg-gray5"
            >
              <MedicalOrganizationsSelectComponent
                showAssociated={false}
                data={data || []}
                loading={isLoading}
              />
            </FormItem>
          )}
          <Checkbox
            name={RoleTypeEnum.CLINICAL_GROUP_BILLER}
            value={RoleTypeEnum.CLINICAL_GROUP_BILLER}
            disabled={
              removeRoleValidationList.includes(RoleTypeEnum.CLINICAL_GROUP_BILLER)
              || value?.includes(RoleTypeEnum.CLINICAL_MANAGER)
            }
          >
            <RoleTypeEnumComponent value={RoleTypeEnum.CLINICAL_GROUP_BILLER} />
          </Checkbox>
          {value?.includes(RoleTypeEnum.CLINICAL_GROUP_BILLER) && (
            <FormItem
              required
              label="Please Select Medical Organization(s)"
              name={RoleTypeEnum.CLINICAL_GROUP_BILLER}
              className="p20 bg-gray5"
            >
              <MedicalOrganizationsSelectComponent
                showAssociated={false}
                data={data || []}
                loading={isLoading}
              />
            </FormItem>
          )}
        </Checkbox.Group>
      )}
    </MedicalOrgSelectContainer>
  </div>
);

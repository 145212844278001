import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useUpdate } from '../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import {
  useEmployeeCreateEmployeeWithRoles,
  useEmployeeUpdateWithRoleAndReturn,
  useMedicalOrganizationRemoveMedicalOrgFromUserCheck,
  useMedicalOrganizationRemoveStaffCheck,
  UserTypeEnum
} from '../../../uc-api-sdk';
import { FetchComponent } from '../../../uiComponent/FetchComponent/FetchComponent';
import { CreateClinicUserDrawerComponent } from '../component/CreateUserDrawerComponent/CreateUserDrawerComponent';
import { UserService as EmployeeService } from '../users/service/UserService';
import { CreateUserFormValues } from '../users/type';

export interface CreateUserDrawerContainerProps {
  initialValues?: CreateUserFormValues;
  mode?: 'create' | 'edit';
  onSuccess?: () => void;
  showButton?: boolean;
}

export const CreateUserDrawerContainer = ({
  initialValues,
  mode,
  showButton,
  onSuccess,
}: CreateUserDrawerContainerProps) => {
  const createUser = useEmployeeCreateEmployeeWithRoles({});
  const updateUser = useEmployeeUpdateWithRoleAndReturn({});
  const { value: submitted, setTrue, setFalse } = useBoolean(false);
  const updateInternalUserTable = useUpdate('GO_TO_INTERNAL_EMPLOYEE_TABLE');
  const updateExternalUserTable = useUpdate('GO_TO_EXTERNAL_EMPLOYEE_TABLE');

  const onCreate = async (values: CreateUserFormValues) => {
    const roles = EmployeeService.buildRoles(values);
    const result = createUser.send({
      params: {
        employeeCreateRequest: {
          employee: {
            ...values,
            employeeProfile: {
              ...values,
              phone: {
                number: values.phone
              },
              // sdk has npi in caps, api has npi in lowercase
              // @ts-ignore
              npi: values.npi,
            },
          },
          roles
        }
      },
    });

    await ApiRequestHelper.tryCatch(result, {
      success: undefined,
      error: undefined,
      onSuccess: (v) => {
        if (v?.data?.employee?.id) {
          setTrue();
          if (values.userType === UserTypeEnum.IHEALTH_EMPLOYEE) {
            updateInternalUserTable.updateValue();
          } else {
            updateExternalUserTable.updateValue();
          }
          onSuccess?.();
        }
      },
      onError: () => {
        setFalse();
      },
    });
  };

  const onUpdate = async (values: CreateUserFormValues) => {
    if (!initialValues?.memberId) {
      return;
    }
    const roles = EmployeeService.buildRoles(values);
    const unsetFields: string[] = [];
    if (!values.supervisingProviderId) {
      unsetFields.push('supervisingProviderId');
    }
    const result = updateUser.send({
      params: {
        id: initialValues?.memberId,
        employeeCreateRequest: {
          employee: {
            email: values.email,
            loginId: values.loginId,
            userType: values.userType,
            isObserverUser: values.isObserverUser || false,
            supervisingProviderId: values.supervisingProviderId ?? null,
            employeeProfile: {
              firstName: values.firstName || '',
              lastName: values.lastName || '',
              credentials: values.credentials || [],
              language: values.language || [],
              phone: {
                number: values.phone || '',
                countryCode: ''
              },
              // sdk has npi in caps, api has npi in lowercase
              // @ts-ignore
              npi: values.npi || '',
            },
            $unset: {
              fields: unsetFields,
            },
            description: values.description,
          },
          roles
        },
      }
    });

    await ApiRequestHelper.tryCatch(result, {
      success: 'User updated.',
      error: 'An error occurred. Please try again later.',
      onSuccess: () => {
        onSuccess?.();
      },
    });
  };

  const hasPatientsCheckHook = useMedicalOrganizationRemoveStaffCheck({
    options: { sendOnMount: !!initialValues?.memberId },
    params: {
      request: {
        memberId: initialValues?.memberId || '',
      }
    }
  });

  const allOrgIds: string[] = flatten(initialValues?.roles?.reduce((acc, curr) => {
    if (curr && initialValues[curr]) {
      acc.push(initialValues[curr] || []);
    }
    return acc;
  }, [] as string[][]));

  const orgIdsWithPatients = useMedicalOrganizationRemoveMedicalOrgFromUserCheck({
    options: {
      sendOnMount: !!initialValues?.memberId && initialValues?.roles?.length > 0,
    },
    params: {
      userId: initialValues?.memberId || '',
      ids: allOrgIds,
    }
  });

  const nonRemovableOrgIds = useMemo(
    () => orgIdsWithPatients.data?.data?.filter(a => !a.removable).map(o => o.id || '') || [],
    [orgIdsWithPatients.data?.data]
  );

  return (
    <FetchComponent
      info={createUser}
      alwaysShowChildren
    >
      {(data) => (
        <CreateClinicUserDrawerComponent
          initialValues={initialValues}
          mode={mode}
          value={data?.rawValue.data}
          onSubmit={mode === 'edit' ? onUpdate : onCreate}
          submitted={submitted}
          showButton={showButton}
          onReset={setFalse}
          hasPatientsCheck={hasPatientsCheckHook.data?.data || []}
          orgIdsWithPt={
            nonRemovableOrgIds
          }
        />
      )}
    </FetchComponent>
  );
};

export default CreateUserDrawerContainer;

import { Card } from 'antd';
import { HeightService } from '../../../../helpers/height';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { HSUnitProps } from '../../../../helpers/weight';
import { useSessionStorage } from '../../../../hooks';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { _StorageKeyEnum } from '../../../../hooks/useSessionStorage/storageKey';
import { Height, MeasurementResultTypeEnum, WeightSummary } from '../../../../uc-api-sdk';
import { HSLogBookTableDataContainer } from '../../container/HSLogBookTableDataContainer/HSLogBookTableDataContainer';
import { VitalFetchContainer, VitalFetchContainerProps } from '../../container/VitalFetchContainer/VitalFetchContainer';
import { VitalFetchProps } from '../../type';
import { HSChartDataProcessorComponent } from '../HSChartComponent/HSChartDataProcessorComponent';
import { HSSummaryComponent } from '../HSSummaryComponent/HSSummaryComponent';
import { HSSummaryTableComponent, HSSummaryTableData } from '../HSSummaryTableComponent/HSSummaryTableComponent';
import { HSTableComponent } from '../HSTableComponent/HSTableComponent';
import { VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import VitalHeaderComponent from '../VitalHeaderComponent/VitalHeaderComponent';
import { VitalTableAndSummaryViewComponent } from '../VitalTableAndSummaryViewComponent/VitalTableAndSummaryViewComponent';

export interface HSChartTableSummaryViewComponentProps extends
  VitalFetchProps {
  height?: Height;
}

export const HSChartTableSummaryViewComponent = ({
  memberId,
  fromDate,
  toDate,
  height
}: HSChartTableSummaryViewComponentProps) => {
  const [unit = 'lb', setUnit] = useSessionStorage<HSUnitProps['unit']>(_StorageKeyEnum.WEIGHT_UNIT, 'lb');
  const { value: isChartView, toggle } = useBoolean();

  const getLastMeasurementAt = (d?: WeightSummary) => (
    d?.lastHsDate
      ? TimezoneService.calcDateTimeDayjs(d?.lastHsDate) : undefined
  );

  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <HSChartDataProcessorComponent
      data={data?.results?.content || []}
      fromDate={fromDate}
      toDate={toDate}
    />
  );

  const summaryTableView = (data: WeightSummary) => {
    const rows: HSSummaryTableData[] = [];
    const h = HeightService.cmToFeet(height?.value || 0);
    rows.push({ ...data.currentWeightStat, period: 'Current' } as HSSummaryTableData);
    rows.push({ ...data.previousWeightStat, period: 'Previous' } as HSSummaryTableData);
    rows.push({ ...data.overallWeightStat, period: 'Overall' } as HSSummaryTableData);
    return (
      <div>
        <HSSummaryTableComponent data={rows} unit={unit} />
        <div>
          <span>Patient Height: </span>
          {
            !height?.value
              ? 'N/A'
              : (
                <>
                  <span className="bold">{`${h[0]} ft ${h[1]} in `}</span>
                  <span>{`(${Number(height?.value).toFixed(1)} cm)`}</span>
                </>
              )

          }
        </div>
      </div>
    );
  };

  const renderChartView: VitalFetchContainerProps['children'] = (data, info) => chartView({
    // for the chart view we need to set the
    // empty data to {} to avoid the chart error
    data: info.isLoading ? {} : data,
    fromDate,
    toDate,
  });

  const renderTableView = (data: WeightSummary, unit: HSUnitProps['unit'], refetch?: () => void) => (
    <VitalTableAndSummaryViewComponent
      table={(
        <HSTableComponent
          data={data.dailyWeightSummaries || []}
          onUnitChange={setUnit}
          unit={unit}
          refetch={refetch}
        />
      )}
      summary={
        (
          <HSSummaryComponent
            data={data}
            unit={unit}
          />
        )
      }
      summaryTable={summaryTableView(data)}
    />
  );

  return (
    <HSLogBookTableDataContainer
      memberId={memberId}
      fromDate={fromDate}
      toDate={toDate}
    >
      {
        (data, refetch) => (
          <Card>
            <div>
              <div className="mb20">
                <VitalHeaderComponent
                  title="Weight"
                  unit={unit}
                  switchText="Chart view"
                  lastMeasurementAt={getLastMeasurementAt(data)}
                  onSwitchChange={toggle}
                />
              </div>
              <div>
                {isChartView ? (
                  <VitalFetchContainer
                    fromDate={fromDate}
                    toDate={toDate}
                    memberId={memberId}
                    type={MeasurementResultTypeEnum.HS}
                    needStats
                  >
                    {renderChartView}
                  </VitalFetchContainer>
                ) : renderTableView(data, unit, refetch)}
              </div>
            </div>
          </Card>
        )
      }
    </HSLogBookTableDataContainer>
  );
};

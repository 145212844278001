import { Drawer } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { ButtonProps } from 'antd/es/button';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { SymptomFormContainer } from '../../container/SymptomFormContainer/SymptomFormContainer';
import { SymptomLog } from '../../../../uc-api-sdk';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

export interface SymptomDrawerComponentProps extends ButtonProps {
  symptomLog?: SymptomLog;
  patientId?: string | undefined;
  buttonType?: ButtonProps['type'];
  onOpenDrawer?: () => void;
}

export const SymptomDrawerComponent = ({
  symptomLog,
  patientId,
  buttonType = 'primary',
  onOpenDrawer
}: SymptomDrawerComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const onClick = () => {
    onOpenDrawer?.();
    setOpen();
  };

  return (
    <div>
      <ExtendableDrawerButtonComponent
        buttonText="Record Symptoms"
        buttonProps={{
          type: buttonType,
          className: 'mb20',
          ghost: true,
        }}
        onClick={onClick}
      />
      <Drawer
        open={open}
        onClose={setClose}
        footer={null}
        placement="right"
        width={MediumDrawerWidth}
        title={<h3 className="bold mt12">Record Symptoms</h3>}
        destroyOnClose
      >
        <div className="h100 flex">
          <div className="f1 flex fd-c ml12">
            <SymptomFormContainer
              patientId={patientId}
              symptomLog={symptomLog || undefined}
              onSubmit={setClose}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

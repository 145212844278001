import { Space } from 'antd';
import { MetricValueType, OperatorEnum, OperatorValueType } from '../../type';
import { OperatorSelectComponent, OperatorSelectComponentProps } from '../OperatorSelectComponent/OperatorSelectComponent';
import { InputType } from '../../../Input/types';
import { OperatorValueInputComponent, OperatorValueInputComponentProps } from '../OperatorValueInputComponent/OperatorValueInputComponent';
import { OperatorEnumComponent } from '../../component/OperatorEnumComponent/OperatorEnumComponent';

export interface OperatorValue<T> {
  operator?: OperatorEnum;
  value?: T;
}

export interface OperatorComponentProps extends
  InputType<OperatorValue<boolean | string | string[] | undefined>> {
  operators: OperatorEnum[];
  metricType: MetricValueType;
  valueOptions?: string[];
}

export const OperatorComponent = ({
  operators,
  metricType,
  valueOptions,
  value,
  onChange,
}: OperatorComponentProps) => {
  const handleOperatorChange: OperatorSelectComponentProps['onChange'] = (v) => {
    onChange?.({
      ...value,
      operator: v,
    });
  };

  const handleValueChange: OperatorValueInputComponentProps['onChange'] = (v) => {
    onChange?.({
      operator: operators?.length === 1 ? operators[0] : value?.operator,
      value: v,
    });
  };

  const getType = () => {
    switch (metricType) {
      case MetricValueType.BOOLEAN: return OperatorValueType.BOOLEAN;
      case MetricValueType.INT: return OperatorValueType.INT;
      case MetricValueType.DOUBLE: return OperatorValueType.DOUBLE;
      case MetricValueType.STRING:
        if (operators.includes(OperatorEnum.In)) {
          return OperatorValueType.SELECT_MULTIPLE;
        }
        return OperatorValueType.STRING;
      case MetricValueType.LIST: return OperatorValueType.SELECT_MULTIPLE;
      default: return undefined;
    }
  };

  const renderOperatorType = () => {
    if (metricType === MetricValueType.BOOLEAN) {
      return null;
    }
    if (operators.length === 1) {
      return (
        <div className="min-w100px">
          <OperatorEnumComponent value={operators[0] as OperatorEnum} />
        </div>
      );
    }
    return (
      <OperatorSelectComponent
        options={operators}
        value={value?.operator}
        onChange={handleOperatorChange}
      />
    );
  };

  return (
    <Space align="center">
      {renderOperatorType()}
      <OperatorValueInputComponent
        options={valueOptions || []}
        type={getType()}
        value={value?.value}
        onChange={handleValueChange}
      />
    </Space>
  );
};

import { RoleTypeEnumListComponent } from '../../../../../enumComponent/RoleTypeEnumListComponent/RoleTypeEnumListComponent';
import { RoleAssignment } from '../../../../../uc-api-sdk';

export interface StaffRoleListComponentProps {
  roles: RoleAssignment[];
  orgId?: string;
}

export const StaffRoleListComponent = ({
  roles,
  orgId,
}: StaffRoleListComponentProps) => {
  const rolesInOrg = roles.filter(r => r.organizationId === orgId).map(r => r.roleType);
  return (
    <RoleTypeEnumListComponent value={rolesInOrg} splitter=", " />
  );
};

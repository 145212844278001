import { message } from 'antd';
import moment from 'moment';
import { FC, useCallback } from 'react';
import { MEDICATION_DATE_INSERT_FORMAT } from '../../../constants/timeFormat';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { MedicationManagementService } from '../../../services/MedicationManagementService';
import { DiscontinuedMedicationsTableComponent } from '../../../tables/MedicationsTables/DiscontinuedMedicationsTableComponent/DiscontinuedMedicationsTableComponent';
import { PrescribedMedicationsTableComponent } from '../../../tables/MedicationsTables/PrescribedMedicationsTableComponent/PrescribedMedicationsTableComponent';
import {
  MedicationInstructionEnum,
  MedicationReminderEnum,
  MedicationRouteEnum,
  MedicationStatusEnum as MedicationStatus,
  Nullable,
  useMedicationDeleteMedication,
  useMedicationInsert,
  useMedicationUpdate,
  usePatientUpdateUcMedicationManagement
} from '../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { FormType } from '../../Input/types';
import { MedicalHistoryMedicationSectionComponent, MedicalHistoryMedicationSectionComponentProps, MedicationManagementType } from '../component/MedicalHistoryMedicationSectionComponent/MedicalHistoryMedicationSectionComponent';
import { MedicationComplianceFormItemOptions } from '../component/MedicationTabFormComponent/MedicationComplianceFormItemsComponent';
import { MEDICATION_STATUS } from '../constant/constant';
import { MedicationType } from '../type/type';

export interface MedicalHistoryMedicationContainerProps
  extends FormType<MedicationManagementType>,
  MedicationComplianceFormItemOptions {
  form?: MedicalHistoryMedicationSectionComponentProps['form'];
  memberId: string;
  visitId?: string;
  mode?: 'Edit' | 'View';
  onCancel?: () => void;
  onSubmit?: () => void;
  medicationManagement?: MedicationManagementType;
  prescribedMedications: MedicationType[];
  discontinuedMedications: MedicationType[];
  onMedicationSubmit?: () => void;
  shouldResetCompliance?: boolean;
}

export const MedicalHistoryMedicationContainer:
  FC<MedicalHistoryMedicationContainerProps> = ({
    form,
    memberId,
    visitId,
    mode = 'View',
    onCancel,
    onSubmit,
    medicationManagement,
    prescribedMedications,
    discontinuedMedications,
    onError,
    onValuesChange,
    formButtons,
    onMedicationSubmit,
    medicationReviewRequired,
    medicationReminderRequired,
    medicationReviewHidden,
    shouldResetCompliance,
  }) => {
    const deleteMedication = useMedicationDeleteMedication({});
    const insertMedication = useMedicationInsert({});
    const updateMedication = useMedicationUpdate({});
    const medManagementUpdate = usePatientUpdateUcMedicationManagement({});
    const { send } = useBillableTimeInterventionComp();

    const handleClickAddMedication = () => {
      send({
        patientId: memberId,
        component: BillableTimeInterventionComponentEnum.UpdateMedication,
        detail: BillableTimeInterventionDetailEnum.MedicalHistoryMedicationListAddMedication,
      });
    };

    const onDelete = useCallback(async (medId: string) => {
      const req = deleteMedication.send({
        params: {
          request: {
            id: medId,
            visitId,
          }
        },
      });
      await ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.deleteSuccess,
          error: MEDICATION_STATUS.error,
        },
      );
      onMedicationSubmit?.();
    }, [deleteMedication.send]);

    const onInsert = useCallback(async (value: MedicationType) => {
      const req = insertMedication.send({
        params: {
          document: {
            ...value,
            type: value.type as MedicationRouteEnum,
            status: value.status as MedicationStatus,
            instruction: value.instruction as MedicationInstructionEnum,
            startDate: value.startDate
              ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            endDate: value.endDate
              ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            memberId,
            active: true,
            visitId,
          },
        },
      });
      await ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.addSuccess,
          error: MEDICATION_STATUS.error,
          onSuccess: () => {
            if (value.reminders?.length) {
              message.success('Medication reminder added');
            }
          }
        },
      );
      onMedicationSubmit?.();
    }, [memberId, insertMedication.send]);

    const onUpdate = useCallback(
      async (value: MedicationType, id: Nullable<string> | undefined) => {
        const unsets = [];
        if (!value.endDate) {
          unsets.push('endDate');
        }
        if (!value.startDate) {
          unsets.push('startDate');
        }
        if (!value.reminders) {
          unsets.push('reminders');
        }

        const req = updateMedication.send({
          params: {
            id: id || '',
            document: {
              ...value,
              visitId,
              type: value.type as MedicationRouteEnum,
              status: value.status as MedicationStatus,
              instruction: value.instruction as MedicationInstructionEnum,
              startDate: value.startDate
                ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              endDate: value.endDate
                ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              memberId,
              id,
              active: true,
              $unset: {
                fields: unsets,
              },
            },
          },
        });
        await ApiRequestHelper.tryCatch(
          req,
          {
            success: MEDICATION_STATUS.updateSuccess,
            error: MEDICATION_STATUS.error,
          },
        );
        onMedicationSubmit?.();
      },
      [updateMedication.send, memberId],
    );

    const onSave = useCallback(async (v: MedicationManagementType) => {
      const {
        isEditMedicationCompliance,
        isEditMedicationReminder,
      } = MedicationManagementService.checkManagementEdit(medicationManagement, v);
      const unsetFields = [];
      if (
        v.medicationReminder === MedicationReminderEnum.PATIENT_ACCEPTED_MEDICATION_REMINDER
      ) {
        unsetFields.push('medicationReminderDeclineReason');
      }
      const req = medManagementUpdate.send({
        params: {
          memberId,
          request: {
            ...v,
            visitId,
            isEditMedicationCompliance,
            isEditMedicationReminder,
            $unset: { fields: unsetFields },
          },
        },
      });
      ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.medicationSaveSuccess,
          error: MEDICATION_STATUS.error,
          onSuccess: onSubmit,
          onError,
        },
      );
    }, [memberId, medManagementUpdate.send, medicationManagement]);

    return (
      <MedicalHistoryMedicationSectionComponent
        form={form}
        onDelete={onDelete}
        onInsert={onInsert}
        onUpdate={onUpdate}
        onFinish={onSave}
        onCancel={onCancel}
        initialValues={{
          ...medicationManagement,
          medicationCompliance: (
            shouldResetCompliance
              ? undefined
              : medicationManagement?.medicationCompliance
          ),
        }}
        mode={mode}
        onValuesChange={onValuesChange}
        formButtons={formButtons}
        patientId={memberId}
        onClickAddMedication={handleClickAddMedication}
        discontinuedMedications={discontinuedMedications}
        prescribedMedications={prescribedMedications}
        medicationReviewRequired={medicationReviewRequired}
        medicationReminderRequired={medicationReminderRequired}
        medicationReviewHidden={medicationReviewHidden}
      >
        {({ addMedication }) => (
          <div>
            {
              prescribedMedications.length > 0 ? (
                <PrescribedMedicationsTableComponent
                  medications={prescribedMedications}
                  addMedication={addMedication}
                />
              ) : <div className="flex jc-e">{addMedication}</div>
            }
            <DiscontinuedMedicationsTableComponent medications={discontinuedMedications} />
          </div>
        )}
      </MedicalHistoryMedicationSectionComponent>
    );
  };

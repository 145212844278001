/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-invalid-html-attribute */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DatePicker,
  Dropdown,
  InputNumber,
  Table,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import { map, uniqueId } from 'lodash';
import { InputTypeArray } from '../../../Input/types';
import { BgMealTypeEnum } from '../../../../uc-api-sdk';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { BGMealTypeSelectComponent } from '../../inputComponent/BGMealTypeSelectComponent/BGMealTypeSelectComponent';
import { DATE_TIME } from '../../../../constants/timeFormat';
import { AlertGeneratedComponent } from '../AlertGeneratedComponent/AlertGeneratedComponent';

export interface BGAlertTableData {
  id?: string;
  success?: boolean;
  dateTime?: Moment;
  bloodGlucose?: number;
  mealType?: BgMealTypeEnum;
  beforeMeal?: boolean;
}

type BooleanFields = 'beforeMeal';
type DateFields = 'dateTime';
type NumberInputFields = 'bloodGlucose';
type DropdownFields = 'mealType';

export interface AlertBGTableComponentProps
  extends InputTypeArray<BGAlertTableData> {
}

export const AlertBGTableComponent = ({
  value,
  onChange,
}: AlertBGTableComponentProps) => {
  const handleChange = (index: number, key: keyof BGAlertTableData, v: unknown) => {
    onChange?.(map(value, ((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: v,
        };
      }
      return item;
    })));
  };

  const handleDateChange = (index: number, key: DateFields) => (
    (v: Moment | null | undefined = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleDropdownChange = (index: number, key: DropdownFields) => (
    <T, >(v: T | null | undefined = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleInputChange = (index: number, key: NumberInputFields) => (
    (v: number | undefined | null = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleRadioChange = (index: number, key: BooleanFields) => (
    (v = false) => {
      handleChange(index, key, v);
    }
  );

  const renderRowOptions = (index: number) => {
    const handleAddRowAbove = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index, 0, {});
      onChange?.(newVal);
    };

    const handleAddRowBelow = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index + 1, 0, {});
      onChange?.(newVal);
    };

    const handleRemoveRow = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index, 1);
      onChange?.(newVal);
    };

    return [
      {
        key: 'addRowAbove',
        label: (
          <a onClick={handleAddRowAbove}>
            Add row above
          </a>
        ),
      },
      {
        key: 'addRowBelow',
        label: (
          <a onClick={handleAddRowBelow}>
            Add row below
          </a>
        ),
      },
      {
        key: 'deleteRow',
        label: (
          <a onClick={handleRemoveRow}>
            Remove row
          </a>
        ),
      },
    ];
  };

  const columns = [
    {
      title: '',
      dataIndex: 'success',
      key: 'alertGenerated',
      render: (v: boolean) => (
        <AlertGeneratedComponent success={v} />
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'dateTime',
      key: 'uploadedAt',
      width: 200,
      render: (v: Moment | null | undefined, record: BGAlertTableData, index: number) => (
        <DatePicker
          format={DATE_TIME}
          showTime
          value={v}
          onChange={handleDateChange(index, 'dateTime')}
        />
      ),
    },
    {
      title: 'Blood Glucose (mmol/L)',
      dataIndex: 'bloodGlucose',
      key: 'bloodGlucose',
      render: (v: string | null | undefined, record: BGAlertTableData, index: number) => (
        <InputNumber
          value={v ? parseInt(v, 10) : undefined}
          onChange={handleInputChange(index, 'bloodGlucose')}
        />
      ),
    },
    {
      title: 'Meal Type',
      dataIndex: 'mealType',
      key: 'mealType',
      width: 120,
      render: (v: BgMealTypeEnum, record: BGAlertTableData, index: number) => (
        <BGMealTypeSelectComponent
          options={map(BgMealTypeEnum)}
          value={v}
          onChange={handleDropdownChange(index, 'mealType')}
        />
      ),
    },
    {
      title: 'Before Meal',
      dataIndex: 'beforeMeal',
      key: 'beforeMeal',
      render: (v: boolean, record: BGAlertTableData, index: number) => (
        <YesNoRadioComponent
          value={v}
          onChange={handleRadioChange(index, 'beforeMeal')}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (v: unknown, record: BGAlertTableData, index: number) => (
        <Dropdown menu={{ items: renderRowOptions(index) }}>
          <MoreOutlined />
        </Dropdown>
      ),
    }
  ];

  return (
    <Table
      bordered
      dataSource={value}
      columns={columns}
      pagination={false}
      rowKey={() => uniqueId()}
      scroll={{ y: 400, x: true }}
    />
  );
};

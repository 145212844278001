import { toUpper } from 'lodash';
import {
  missingDomain,
  missingGoogleClientId,
  missingGqlServerApiKey,
  missingGqlServerUrl,
  missingMixPanelToken,
  missingRestfulServerApiKey,
  missingRestfulServerUrl,
  missingUnicareCopilotUrl,
  missingUrl
} from '../../errors/errors';

export default class EnvConfig {
  static get isStrictModeOff() {
    return process.env.REACT_APP_STRICT_MODE_OFF === 'true';
  }

  static get gqlServerUrl() {
    const url = process.env.REACT_APP_GQL_SERVER_URL;
    if (url === undefined) missingGqlServerUrl();
    return url as string;
  }

  static get gqlServerApiKey() {
    const apiKey = process.env.REACT_APP_GQL_API_KEY;
    if (apiKey === undefined) missingGqlServerApiKey();
    return apiKey as string;
  }

  static get restfulServerUrl() {
    const url = process.env.REACT_APP_RESTFUL_SERVER_URL;
    if (url === undefined) missingRestfulServerUrl();
    return url as string;
  }

  static get restfulBaseServerUrl() {
    const url = this.restfulServerUrl.replace('/v1/uc', '');
    if (url === undefined) missingRestfulServerUrl();
    return url as string;
  }

  static get restfulServerApiKey() {
    const apiKey = process.env.REACT_APP_RESTFUL_API_KEY;
    if (apiKey === undefined) missingRestfulServerApiKey();
    return apiKey as string;
  }

  static get googleClientId() {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    if (clientId === undefined) missingGoogleClientId();
    return clientId as string;
  }

  static get ccpDomain() {
    const domain = process.env.REACT_APP_CCP_DOMAIN;
    if (domain === undefined) missingDomain('CCP');
    return domain as string;
  }

  static get chsDomain() {
    const domain = process.env.REACT_APP_CHS_DOMAIN;
    if (domain === undefined) missingDomain('CHS');
    return domain as string;
  }

  static get notificationClientUrl() {
    const domain = process.env.REACT_APP_NOTIFICATION_SERVER_URL;
    if (domain === undefined) missingUrl('Notification Client');
    return domain as string;
  }

  static get idleTime() {
    // amount of time in seconds before the user is considered idle
    const idleTime = process.env.REACT_APP_IDLE_TIME;
    if (idleTime === undefined) return 2 * 60;
    return +idleTime;
  }

  static get refreshTokenTime() {
    // amount of time in ms to trigger a refresh token
    const refreshTokenTime = process.env.REACT_APP_REFRESH_TOKEN_TIME;
    if (refreshTokenTime === undefined) return 6 * 60 * 60 * 1000; // 6 hours
    return +refreshTokenTime;
  }

  static get afkTimerThreshold() {
    // amount of time in seconds before the the afk timer popup should be opened
    const afkTimerThreshold = process.env.REACT_APP_AFK_TIMER_THRESHOLD;
    if (afkTimerThreshold === undefined) return 2 * 60;
    return +afkTimerThreshold;
  }

  // Max time in minutes
  static get afkMaxTime() {
    const afkMaxTime = process.env.REACT_APP_AFK_MAX_TIME;
    if (afkMaxTime === undefined) return 30;
    return +afkMaxTime;
  }

  static get unicareCopilotUrl() {
    const unicareCopilotUrl = process.env.REACT_APP_UNICARE_COPILOT_URL;
    if (unicareCopilotUrl === undefined) missingUnicareCopilotUrl();
    return unicareCopilotUrl as string;
  }

  static get autoAfkTimerThreshold() {
    // amount of time in seconds before the the afk timer should be opened automatically
    const autoAfkTimerThreshold = process.env.REACT_APP_AUTO_AFK_TIMER_THRESHOLD;
    if (autoAfkTimerThreshold === undefined) return 30 * 60;
    return +autoAfkTimerThreshold;
  }

  // Patient profile session timer in seconds
  static get patientProfileSessionTimer() {
    const t = process.env.REACT_APP_PATIENT_PROFILE_SESSION_TIMER;
    if (t === undefined) return 48 * 60 * 60;
    return +t;
  }

  static get heartbeatIntervalTime() {
    const heartbeatIntervalTime = process.env.REACT_APP_HEARTBEAT_INTERVAL;
    if (heartbeatIntervalTime === undefined) return 30;
    return +heartbeatIntervalTime;
  }

  static get mixPanelToken() {
    const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (mixPanelToken === undefined) missingMixPanelToken();
    return mixPanelToken as string;
  }

  static get envName() {
    return process.env.REACT_APP_ENV_NAME;
  }

  static get isProd() {
    return process.env.REACT_APP_ENV_NAME === 'PROD' || process.env.REACT_APP_ENV_NAME === 'production';
  }

  static get isPreview() {
    return toUpper(process.env.REACT_APP_ENV_NAME) === 'PREVIEW';
  }

  static get showPreviewWarning() {
    return EnvConfig.isPreview;
  }

  static get samlUrl() {
    return process.env.REACT_APP_SAML_URL;
  }

  static get soterUrl() {
    return process.env.REACT_APP_SOTER_URL;
  }

  static get soterApiKey() {
    return process.env.REACT_APP_SOTER_API_KEY;
  }

  static get isInsightEnabled() {
    return process.env.REACT_APP_INSIGHT_ENABLED === 'true';
  }

  static get isUnicareCopilotEnabled() {
    return process.env.REACT_APP_UNICARE_COPILOT_ENABLED === 'true';
  }

  static get sharedArrayBufferTrial() {
    return process.env.REACT_APP_SHARED_ARRAY_BUFFER_TRIAL;
  }

  static get logCategories() {
    return process.env.REACT_APP_LOG_CATEGORIES?.split(',') || [];
  }

  static get logTrace() {
    return process.env.REACT_APP_LOG_TRACE === 'true';
  }
}

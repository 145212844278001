import { Select, SelectProps } from 'antd';

export interface CareUnitSearchSelectComponentProps {
  onChange: SelectProps['onChange'];
  options?: SelectProps['options'];
  filterOption?: SelectProps['filterOption']
  placeholder?: string;
  value?: string;
}

export const CareUnitSearchSelectComponent = ({
  onChange,
  filterOption,
  placeholder,
  options,
  value,
}: CareUnitSearchSelectComponentProps) => (
  <Select
    value={value}
    showSearch
    options={options}
    placeholder={placeholder || ''}
    className="w100"
    onChange={onChange}
    filterOption={filterOption}
  />
);

import { Space } from 'antd';
import React, { FC } from 'react';
import { PHONE_ICON } from '../../assets/commonIconInfo';
import { CountryCodeType } from '../../types/countryCode';
import Icon from '../Icon/Icon';
import PhoneComponent from './PhoneComponent';

export interface PhoneWithCountryCodeComponentProps {
  value?: number | string,
  showIcon?: boolean;
  divider?: string[],
  countryCode?: string | CountryCodeType,
}

export const PhoneWithCountryCodeComponent: FC<PhoneWithCountryCodeComponentProps> = ({
  value,
  showIcon,
  divider = ['', '-', '-'],
  countryCode,
}) => (
  <span data-testid="phone-with-country-code-component">
    <Space align="center">
      {showIcon && <Icon img={PHONE_ICON} />}
      {countryCode && <span>{`+${countryCode}`}</span>}
      <PhoneComponent
        showIcon={false}
        divider={divider}
        value={value}
        countryCode={countryCode}
      />
    </Space>
  </span>
);

export default PhoneWithCountryCodeComponent;

import { RadioGroupComponent, RadioGroupComponentProps } from '../RadioGroupComponent/RadioGroupComponent';

export interface RadioGroupWithDescriptionComponentProps<T extends string> extends
  RadioGroupComponentProps<T> {
  description: string;
}

export const RadioGroupWithDescriptionComponent = <T extends string>({
  description,
  ...props
}: RadioGroupWithDescriptionComponentProps<T>) => (
  <div className="flex fd-c gap1">
    <div>{description}</div>
    <RadioGroupComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </div>
  );

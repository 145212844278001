import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { MealTypeEnum } from '../../type';

export interface MealTypeEnumComponentProps {
  value?: MealTypeEnum;
}

export const MealTypeEnumComponent = ({
  value,
}: MealTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case MealTypeEnum.BEDTIME: return 'Bedtime';
      case MealTypeEnum.BREAKFAST: return 'Breakfast';
      case MealTypeEnum.DINNER: return 'Dinner';
      case MealTypeEnum.LUNCH: return 'Lunch';
      case MealTypeEnum.OVERNIGHT: return 'Overnight';
      case MealTypeEnum.SNACK: return 'Snack';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };

  return (<>{getText()}</>);
};

import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';

export const useCreateMobileVersionConfigForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    name: {
      name: 'name',
      label: 'Name',
    },
    medOrgIds: {
      name: 'medOrgIds',
      label: 'Medical Organizations'
    },
    activateDate: {
      name: 'activateDate',
      label: 'Activation Date'
    },
    versionDetails: {
      name: 'versionDetails',
      label: 'Versions',
    },
    contents: {
      name: 'contents',
      label: 'Contents'
    },
    note: {
      name: 'note',
      label: 'Note'
    },
  }, options);

  const contentDetailHook = useIterableFormHook('contents', {
    title: {
      name: 'title',
      label: 'Title',
      required: true,
    },
    description: {
      name: 'description',
      label: 'Description',
      required: true,
    },
    language: {
      name: 'language',
      label: 'Language',
      required: true,
    },
  });

  return useGetContextValue({
    ...contentDetailHook,
    ...factory,
  });
};

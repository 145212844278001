import { makeAddressString } from '../../../../../helpers/address';
import { MedicalOrganizationType } from '../../../../../uc-api-sdk';
import { CardEditWithHeader } from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import { MedicalOrgTypeEnumComponent } from '../../../component/uiComponent/MedicalOrgTypeEnumComponent/MedicalOrgTypeEnumComponent';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';
import '../MedicalOrgConfigurationComponent/MedicalOrgConfigurationComponent.scss';

export interface MedicalOrgProfileComponentProps {
}

export const MedicalOrgProfileComponent = () => {
  const { medicalOrgService: service, refetch } = useMedicalOrgContext();
  const { profile } = service;
  const { getDrawer } = useAdminPortalPopupContext();
  const { open, close } = getDrawer(PopupDrawerTypes.CreateMedicalOrg);

  const handleClick = () => {
    open({
      mode: 'edit',
      initialValues: {
        type: service.type as MedicalOrganizationType,
        id: service.id || '',
        businessName: profile?.businessName || '',
        alias: service.name || '',
        timezone: profile?.timezone || '',
        description: profile?.description || '',
        phone: profile?.phone || '',
        address: profile?.address || '',
        address2: profile?.address2 || '',
        city: profile?.city || '',
        state: profile?.state || '',
        zip: profile?.zip || '',
        orgNumber: profile?.orgNumber || '',
        addresses: [
          ...(profile?.addresses || [{
            streeName: (profile?.address || '').concat(profile?.address2 || ''),
            city: profile?.city || '',
            state: profile?.state || '',
            zip: profile?.zip || '',
          }, {}]),
        ]
      },
      onSuccess: () => {
        close();
        refetch();
      }
    });
  };

  const renderAlias = () => (
    <div className="breakword">
      {service.orgNumber}
      {' '}
      {service.name}
    </div>
  );

  const data = [
    {
      key: <div>Type</div>,
      value: <div><MedicalOrgTypeEnumComponent type={service.type} /></div>
    },
    {
      key: <div>Business name</div>,
      value: <div className="breakword">{profile?.businessName}</div>
    },
    {
      key: <div>Alias</div>,
      value: renderAlias(),
    },
    {
      key: <div>Description</div>,
      value: <div className="breakword">{profile?.description}</div>,
    },
    {
      key: <div>Phone number</div>,
      value: <div className="breakword">{profile?.phone}</div>,
    },
    ...(
      profile?.addresses?.map((address, index) => ({
        key: <div>{`Address ${index + 1}`}</div>,
        value: (
          <div className="breakword">
            {makeAddressString(address)}
          </div>
        )
      }))
      || []),
    {
      key: <div>Timezone</div>,
      value: <div className="breakword">{profile?.timezone}</div>,
    },
  ];

  return (
    <div className="medical-org-profile">
      <CardEditWithHeader
        modal={{
          maskClosable: false,
        }}
        onEdit={handleClick}
        header="Basic Information"
      >
        <MedicalOrgCommonCardContentComponent data={data} />
      </CardEditWithHeader>
    </div>
  );
};

import { MedicationRouteEnum } from '../../../uc-api-sdk';

export const MEDICATION_STATUS = {
  addSuccess: 'Medication added.',
  deleteSuccess: 'Medication deleted.',
  updateSuccess: 'Medication updated.',
  medicationSaveSuccess: 'Medication updated.',
  error: 'An error occurred, please try again later.',
};

export const MEDICATION_ROUTE_ORDER = [
  MedicationRouteEnum.ORAL,
  MedicationRouteEnum.INJECTION,
  MedicationRouteEnum.INHALATION,
  MedicationRouteEnum.TOPICAL,
  MedicationRouteEnum.SUBLINGUAL,
  MedicationRouteEnum.OCULAR,
  MedicationRouteEnum.AURICULAR,
  MedicationRouteEnum.UROGENITAL,
  MedicationRouteEnum.RECTAL,
  MedicationRouteEnum.INTRANASAL,
  MedicationRouteEnum.OTHER,
];

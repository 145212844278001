import { useMemo } from 'react';
import { useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useCustomizedAlertsForm = () => {
  const factory = useFormHookFactory({
    name: {
      name: 'name',
      label: 'Alert Name',
      required: true,
    },
    description: {
      name: 'description',
      label: 'Description',
    },
    priority: {
      name: 'priority',
      label: 'Priority',
    },
    type: {
      name: 'type',
      label: 'Type',
      required: true,
    },
    category: {
      name: 'category',
      label: 'Category',
      required: true,
    },
    muteLogic: {
      name: 'muteLogic',
      label: '',
    },
    muteLogicScale: {
      name: 'muteLogicScale',
      label: '',
    },
  });

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};

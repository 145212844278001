import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState
} from 'react';
import { functionNotImplYet } from '../../../../../contexts/helper';
import {
  useEhrOrganizationMappingSearch,
  useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId,
  useMedicalOrganizationGet,
  useMedicalOrganizationGetMedicalOrgAssignments
} from '../../../../../uc-api-sdk';
import { TABS_KEYS } from '../../component/MedicalOrgInfoComponent/MedicalOrgInfoComponent';
import { MedicalOrgService } from '../../service/MedicalOrgService';

const notImplemented = functionNotImplYet('MedicalOrgContext');

interface MedicalOrgContextValue {
  baseInfo: ReturnType<typeof useMedicalOrganizationGet>;
  medicalOrgService: MedicalOrgService;
  currTab: string;
  setCurrTab: (tabKey: string) => void;
  refetch: () => void;
}

const MedicalOrgContext = createContext<MedicalOrgContextValue>({
  baseInfo: {} as ReturnType<typeof useMedicalOrganizationGet>,
  medicalOrgService: {} as MedicalOrgService,
  currTab: TABS_KEYS.orgAndBillingSetting,
  setCurrTab: notImplemented('setCurrTab'),
  refetch: () => Promise.reject(new Error('Not implemented!')),
});

export interface MedicalOrgContextProviderProps {
  children: ReactNode;
  id: string;
}

export const MedicalOrgContextProvider = ({
  children,
  id,
}: MedicalOrgContextProviderProps) => {
  const [currTab, setCurrTab] = useState(TABS_KEYS.orgAndBillingSetting);

  const getMedicalOrg = useMedicalOrganizationGet({
    options: {
      sendOnMount: true,
    },
    params: {
      id,
    }
  });

  const associatedCareUnits = useMedicalOrganizationGetMedicalOrgAssignments({
    params: {
      id
    },
    options: {
      sendOnMount: true,
    }
  });

  const iHealthMembers = useEmployeeSearchAssociatedIhealthEmployeeByMedOrgId({
    params: {
      medOrgId: id
    },
    options: {
      sendOnMount: true,
    }
  });

  const ehrSettings = useEhrOrganizationMappingSearch({
    params: {
      filter: {
        ucOrgId: id,
      }
    },
    options: {
      sendOnMount: true,
    }
  });

  const refetch = () => {
    getMedicalOrg.refetch();
    associatedCareUnits.refetch();
    iHealthMembers.refetch();
    ehrSettings.refetch();
  };

  const medicalOrgService = useMemo(() => (
    new MedicalOrgService(
      getMedicalOrg.data?.data ?? undefined,
      associatedCareUnits.data?.data ?? undefined,
      iHealthMembers.data?.data ?? undefined,
      ehrSettings.data?.data ?? undefined
    )
  ), [getMedicalOrg.data, associatedCareUnits.data, iHealthMembers.data, ehrSettings.data]);

  const value = useMemo(() => ({
    baseInfo: { ...getMedicalOrg },
    medicalOrgService,
    currTab,
    setCurrTab,
    refetch
  }), [
    getMedicalOrg.isLoading,
    getMedicalOrg.data,
    getMedicalOrg.error,
    associatedCareUnits.data,
    associatedCareUnits.isLoading,
    associatedCareUnits.error,
    iHealthMembers.data,
    iHealthMembers.isLoading,
    iHealthMembers.error,
    medicalOrgService,
    ehrSettings.data,
    ehrSettings.isLoading,
    ehrSettings.error,
    currTab,
    setCurrTab,
  ]);

  return (
    <MedicalOrgContext.Provider value={value}>
      {children}
    </MedicalOrgContext.Provider>
  );
};

export const useMedicalOrgContext = () => {
  const contextValue = useContext(MedicalOrgContext);
  return contextValue;
};

import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useMedicalOrgAssociateForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    careGroupId: {
      name: 'careGroupId',
      label: 'Care group',
    },
  }, options);
  return factory;
};

import { AlternativeDeviveType } from '../../../../uc-api-sdk';
import DeviceNameComponent from '../../../../uiComponent/DeviceNameComponent/DeviceNameComponent';
import { DeviceImageComponent } from '../../DeviceImageComponent';

export interface DeviceBundleComponentProps {
  devices: AlternativeDeviveType[];
}

export const DeviceBundleComponent = ({
  devices
}: DeviceBundleComponentProps) => (
  <div className="device-select-item-info px10">
    <div className="flex gap2 ai-c">
      {
        devices.map<React.ReactNode>((device) => (
          <div key={device} className="flex gap2 fd-c ai-c">
            <DeviceImageComponent deviceModel={device} height="auto" width={40} />
            <DeviceNameComponent device={device} />
          </div>
        )).reduce((prev, curr) => [prev, '+', curr])
      }
    </div>
  </div>
);

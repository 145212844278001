import React, { FC } from 'react';
import { Button, Drawer, DrawerProps } from 'antd';
import './style.scss';
import { useBoolean } from 'usehooks-ts';
import { ButtonProps } from 'antd/es/button';

export interface DrawerComponentProps extends Omit<Omit<DrawerProps, 'children'>, 'open'> {
  children?: React.ReactNode | ((props: { close: () => void }) => React.ReactNode);
  buttonText?: string;
  ghost?: boolean,
  width?: string;
  buttonType?: ButtonProps['type'];
  buttonSize?: ButtonProps['size'];
  onOpen?: () => void;
}

export const DrawerComponent: FC<DrawerComponentProps> = ({
  children,
  buttonText = 'Create a Task',
  ghost,
  width,
  buttonSize = 'middle',
  buttonType = 'primary',
  onOpen,
  ...props
}) => {
  const { value: open, setFalse, setTrue } = useBoolean(false);

  const handleOpen = () => {
    onOpen?.();
    setTrue();
  };

  const getContent = () => {
    if (typeof children === 'function') {
      return children({
        close: setFalse,
      });
    }
    return children;
  };

  return (
    <>
      <Button
        type={buttonType}
        size={buttonSize}
        onClick={handleOpen}
        ghost={ghost && buttonType !== 'text'}
      >
        {buttonText}
      </Button>
      <Drawer
        className="uc-drawer fs2"
        open={open}
        contentWrapperStyle={{ width: width || '520px' }}
        onClose={setFalse}
        {...props}// eslint-disable-line
      >
        {getContent()}
      </Drawer>
    </>
  );
};

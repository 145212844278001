import { AlertEngineComponent } from '../../component/AlertEngineComponent/AlertEngineComponent';
import { AlertApiContextProvider } from '../../context/AlertApiContext';

export interface AlertEngineContainerProps {
  clinicId: string;
}

export const AlertEngineContainer = ({
  clinicId,
}: AlertEngineContainerProps) => (
  <AlertApiContextProvider clinicId={clinicId}>
    <AlertEngineComponent />
  </AlertApiContextProvider>
);

import { EnumSelectComponent } from '../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { MedicationRouteEnumComponent } from '../../enumComponent/MedicationRouteEnumComponent/MedicationRouteEnumComponent';
import { MedicationRouteEnum } from '../../uc-api-sdk';
import { InputType } from '../../features/Input/types';
import { MEDICATION_ROUTE_ORDER } from '../../features/medication/constant/constant';

export interface MedicationTypeSelectComponentProps extends
  InputType<MedicationRouteEnum>{
}

export const MedicationTypeSelectComponent = ({
  onChange,
  value,
  disabled,
}: MedicationTypeSelectComponentProps) => (
  <EnumSelectComponent
    data-testid="medTypeInput"
    placeholder="Select"
    options={MEDICATION_ROUTE_ORDER}
    optionRenderer={MedicationRouteEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);

import {
  MessageHistory,
  MessageHistoryMessage,
  MessageType,
} from '../../services/CHSServices/types/data';
import { Patient, PatientComplexityEnum } from '../../uc-api-sdk';

export enum MessageACKPublisher {
  READ = 'team',
  UNREAD = 'unread',
}

export type MessageNotification = 'Notification';

export interface MessageContent {
  text: string;
  dateTime?: MessageHistoryMessage['dateTime'];
  publisherName?: string;
  isPatientMessage?: boolean;
  type?: MessageType;
}

export interface MessageNewNotification {
  receivedAt: number;
  isACK: boolean;
}

export interface MessageChannel {
  isUnread?: boolean;
  unreadMessages?: number;
  fromTimestamp?: string;
  isOngoing?: boolean;
  lastMsg?: MessageContent;
  lastClientACK?: number;
  newNotification?: MessageNewNotification;
  updatedAt?: number;
  // to represent the channel has new messages and messages not fetched
  isPlaceholder?: boolean;
}

export interface PatientMessageChannel extends MessageChannel {
  patientId: string;
  messages?: MessageHistoryMessage[];
  hasMoreMessages?: boolean;
  messageHistory: MessageHistory; // to store the message history temporarily
}

export interface MessageChannelMap {
  [patientId: string]: MessageChannel;
}

export interface MessageUserInfo {
  firstName: string;
  lastName: string;
  avatar?: string | null;
  patientComplexityLevel?: PatientComplexityEnum;
  rawValues?: Patient;
}

export interface MessageUserMap {
  [userId: string]: MessageUserInfo;
}

export interface MessageLastPaginatedChannelsTimestamp {
  NextFromTimestamp?: string;
  NextPageNumber?: string;
}

import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useEffect, useMemo, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { useMessageStorageContext } from '../../../../contexts/MessageContext/MessageStorageContext';
import StrictFormData from '../../../../hooks/ajaxRequest/formData/strictFormData';
import useUploadFile, { UploadFileResponse } from '../../../../hooks/ajaxRequest/useUploadFile/useUploadFile';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { FileUploadMessagePayload, MessagePayload, MessageType } from '../../../../services/CHSServices/types/data';
import {
  BucketsTypeEnum,
  FileCategoryEnum,
  PatientTypeEnum,
  usePatientDeviceGetUserType
} from '../../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { MessageInputComponent } from '../../component/MessageInputComponent/MessageInputComponent';
import { TranslateInputValue } from '../../../foodLog/component/TranslationInputCommonComponent/TranslationInputCommonComponent';
import { useDefaultLanguageHelper } from '../../../foodLog/hook/useDefaultLanguageHelper';

const nonAppUserMessage = "This patient might not be able to receive messages since their device doesn't require app.";

export interface MessageInputContainerProps { }

export const MessageInputContainer = () => {
  const {
    userId,
  } = useLoggedInUserFromContext();
  const {
    handleSendMessage,
    patientChannel,
    patientInfo,
    textToBeInsertFromAI,
  } = useMessagePatientContext();

  // get default language for translation
  const spokenLanguage = patientInfo?.profile?.languages?.[0] || '';
  const appLanguage = patientInfo?.profile?.appLanguage || '';
  const initialLanguage = useDefaultLanguageHelper(spokenLanguage, appLanguage);

  const { getDraftById, setDraftMessage, setDraftFile } = useMessageStorageContext();
  const { message: draftMessage, file: draftFile } = getDraftById(patientChannel?.patientId || '');
  const [uploadPreviewFile, setUploadPreviewFile] = useState<RcFile | undefined>(draftFile);
  const [isSending, setIsSending] = useState(false);

  const [textInput, setTextInput] = useState<TranslateInputValue>({
    originalText: draftMessage || '',
    translatedText: '',
    language: initialLanguage,
  });

  useEffect(() => {
    setTextInput((prev) => ({
      ...prev,
      language: initialLanguage,
    }));
  }, [initialLanguage]);

  const translatedText = textInput.translatedText || '';
  const originalText = textInput.originalText || '';

  const {
    mutateAsync: uploadFile,
  } = useUploadFile();
  const patientTypeInfo = usePatientDeviceGetUserType({
    params: { memberId: patientChannel?.patientId || '' },
  });
  const { data } = patientTypeInfo || {};
  const {
    placeholder,
  } = useMemo(() => {
    if (!data?.data) {
      return {};
    }
    const isAppUser = data.data === PatientTypeEnum.APP_USER;
    return {
      placeholder: isAppUser ? '' : nonAppUserMessage,
    };
  }, [data]);

  const isTextInputEmpty = textInput.originalText?.trim() === '';
  const isSendDisabled = isSending || (!uploadPreviewFile && isTextInputEmpty);

  const processUpload = async (
    file: RcFile,
  ): Promise<UploadFileResponse | undefined | null> => {
    try {
      const fileFormData = new StrictFormData({
        file,
        bucket: BucketsTypeEnum.FILESUPLOAD,
        category: FileCategoryEnum.FILE_UPLOAD,
        id: userId as string,
      });
      const res = await uploadFile(fileFormData, {});
      if (res?.code && ![200, 204].includes(res.code)) {
        throw new Error(res.msg as string);
      }
      return res?.data;
    } catch (error) {
      message.error('Failed to upload file. Please try again.');
    }
    return null;
  };

  const handleRemovePreview = () => {
    setUploadPreviewFile(undefined);
    setDraftFile(patientChannel?.patientId || '', undefined);
  };

  const debounceMessageUpdate = useDebounce((message: string) => {
    setDraftMessage(patientChannel?.patientId || '', message);
  }, 300, [patientChannel?.patientId]);

  useEffect(() => {
    if (textToBeInsertFromAI) {
      setTextInput((prev) => ({
        ...prev,
        originalText: textToBeInsertFromAI,
      }));
      debounceMessageUpdate(textToBeInsertFromAI || '');
    }
  }, [textToBeInsertFromAI]);

  const handleTextInputChange = (value: TranslateInputValue) => {
    setTextInput(value);
    debounceMessageUpdate(value.originalText || '');
  };

  const { send } = useBillableTimeInterventionComp();

  const handleOnSend = useDebounce(async () => {
    if (isSendDisabled) return;

    send({
      patientId: patientChannel?.patientId,
      component: BillableTimeInterventionComponentEnum.Messaging,
      detail: BillableTimeInterventionDetailEnum.OutstandingMessengerSend,
    });

    setIsSending(true);
    let fileKeyRes;
    // eslint-disable-next-line no-cond-assign
    if (uploadPreviewFile && !(fileKeyRes = await processUpload(uploadPreviewFile))) return;

    const messagePayload: MessagePayload = {
      type: MessageType.TEXT,
      text: translatedText || originalText || '',
      originalText: originalText || '',
      translation: translatedText || '',
    };

    if (fileKeyRes?.fileKey) {
      messagePayload.type = MessageType.FILE_UPLOAD;
      (messagePayload as FileUploadMessagePayload).fileKey = fileKeyRes.fileKey;
    }

    const res = await handleSendMessage(messagePayload);
    if (res && res.success) {
      handleTextInputChange({ originalText: '', language: textInput.language });
      handleRemovePreview();
    } else {
      message.error(res?.error);
    }
    setIsSending(false);
  }, 100, [
    textInput.originalText,
    isSendDisabled,
    uploadPreviewFile,
    processUpload,
  ]);

  const handleUpload = (file: RcFile) => {
    setUploadPreviewFile(file);
    setDraftFile(patientChannel?.patientId || '', file);
    return '';
  };

  return (
    <MessageInputComponent
      value={textInput}
      onChange={handleTextInputChange}
      onSend={handleOnSend}
      isSending={isSending}
      sendDisabled={isSendDisabled}
      action={handleUpload}
      uploadPreviewFile={uploadPreviewFile}
      removeUploadPreviewFile={handleRemovePreview}
      placeholder={placeholder}
    />
  );
};

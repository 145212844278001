import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { RoleTypeEnum, UserTypeEnum } from '../../../../../uc-api-sdk';
import { ModifyMemberDrawerContainer } from '../../../CareOpHierarchy/container/ModifyMemberDrawerContainer/ModifyMemberDrawerContainer';

export interface StaffTableActionsComponentProps {
  onRemoveMember: (id: string) => void;
  memberId: string;
  medOrgId: string;
  currentRoles?: RoleTypeEnum[];
  otherRoles?: RoleTypeEnum[];
}

export const StaffTableActionsComponent = ({
  onRemoveMember,
  memberId,
  medOrgId,
  currentRoles,
  otherRoles
}: StaffTableActionsComponentProps) => {
  const items: MenuItemType[] = [
    {
      key: 'Remove',
      label: <Button type="text" size="small">Remove in Medical Organization</Button>,
      onClick: () => onRemoveMember?.(memberId || ''),
    },
    {
      key: 'editRole',
      label: (
        <ModifyMemberDrawerContainer
          memberId={memberId}
          validationModalData={[]}
          careUnitId={medOrgId}
          userType={UserTypeEnum.CLINICAL_STAFF}
          initialRoles={currentRoles}
          otherRoles={otherRoles}
        />
      ),
    }
  ];

  return (
    <Dropdown
      className="no-border"
      trigger={['click']}
      menu={{ items }}
    >
      <MoreOutlined />
    </Dropdown>
  );
};

import { ColumnFilterItem } from 'antd/lib/table/interface';
import { ReporterEnum } from '../../../../uc-api-sdk';

export const SYMPTOM_REPORTER_TYPES: ColumnFilterItem[] = [
  {
    value: ReporterEnum.PATIENT_REPORTED,
    text: 'Patient self-reported',
  },
  {
    value: ReporterEnum.CARE_TEAM_REPORTED,
    text: 'Care team reported',
  }
];

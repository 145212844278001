import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { ComplexityEnumComponent } from '../../../../enumComponent/ComplexityEnumComponent/ComplexityEnumComponent';
import { EnrolledProgramStatusEnumComponent } from '../../../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import { GenderEnumComponent } from '../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { PatientDiagnosesEnumComponent } from '../../../../enumComponent/PatientDiagnosesEnumComponent/PatientDiagnosesEnumComponent';
import { PatientSourceEnumComponent } from '../../../../enumComponent/PatientSourceEnumComponent/PatientSourceEnumComponent';
import { PriorityEnumComponent } from '../../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { ScreenStatusEnumComponent } from '../../../../enumComponent/ScreenStatusEnumComponent/ScreenStatusEnumComponent';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { AccountHelper } from '../../../../helpers/account';
import { SelectedColumnWithArgs } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { PhoneService } from '../../../../services/PhoneService';
import { ColumnType, TableColumnCreator } from '../../../../types/table';
import {
  EmployeeStatus,
  ControlLevelEnum,
  PatientComplexityEnum,
  PatientDiagnosesEnum,
  PatientResult,
  PatientSourceEnum,
  RiskLevelEnum,
  RoleTypeEnum,
  ScreenStatusEnum,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { BirthdayComponent } from '../../../../uiComponent/BirthdayComponent/BirthdayComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import LanguagesComponent from '../../../../uiComponent/LanguagesComponent/LanguagesComponent';
import { PatientDiagnosesComponent } from '../../../../uiComponent/PatientDiagnosesComponent/PatientDiagnosesComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { MEMBERS_FILTERS } from '../../../adminPortal2/users/component/UserDirectoryTableComponent/filter';
import { BillableStatisticComponent } from '../../../billing/component/BillableStatisticComponent/BillableStatisticComponent';
import { createBillableCheckboxFilter } from '../../../table/filter/BillableFilter';
import { createBillableTimeRangeRadioFilter } from '../../../table/filter/BillableTimeRangeFilter';
import { createClinicFilter } from '../../../table/filter/ClinicFilter';
import { createEmployeeSearchFilter } from '../../../table/filter/EmployeeSearchFilter';
import { createEnrollProgramsCheckboxFilter } from '../../../table/filter/EnrolledProgramsFilter';
import { createEnrolledStatusCheckboxFilter } from '../../../table/filter/EnrolledStatusCheckboxFilter';
import { createGendersCheckboxFilter } from '../../../table/filter/GenderFilter';
import { createMultiProviderSearchFilter } from '../../../table/filter/MultiProviderSearchFilter';
import { createPatientEnrollmentDateFilter } from '../../../table/filter/PatientEnrollmentDateFilter';
import { createPatientSearchFilter } from '../../../table/filter/PatientSearchFilter';
import { createPatientTagSearchFilter } from '../../../table/filter/PatientsTagFilter';
import { createVitalsCheckboxFilter } from '../../../table/filter/VitalsFilter';
import { UnwatchPatientContainer } from '../../container/UnwatchPatientContainer/UnwatchPatientContainer';
import { PatientAgeComponent } from './PatientAgeComponent';
import { PatientNameComponent } from './PatientNameComponent';
import { RiskPriorityTooltipTitleComponent } from './RiskPriorityTooltipTitleComponent';
import { ControlLevelEnumComponent } from '../../../../enumComponent/ControlLevelEnumComponent/ControlLevelEnumComponent';

export enum PatientColumnKey {
  id = 'id',
  age = 'age',
  gender = 'gender',
  vitals = 'vitals',
  clinic = 'clinic',
  program = 'program',
  riskLevel = 'riskLevel',
  controlLevel = 'controlLevel',
  rpmDays = 'rpmDays',
  billableTime = 'billableTime',
  billable = 'billable',
  provider = 'provider',
  assignedRdHC = 'assignedRdHC',
  assignedCA = 'assignedCA',
  enrollmentDate = 'enrollmentDate',
  registrationDate = 'registrationDate',
  dischargedDate = 'dischargedDate',
  mrn = 'mrn',
  action = 'action',
  watchInfoNote = 'watchInfoNote',
  addedTime = 'addedTime',
  diagnoses = 'diagnoses',
  primaryInsurance = 'primaryInsurance',
  secondaryInsurance = 'secondaryInsurance',
  eligibility = 'eligibility',
  status = 'status',
  language = 'language',
  appLanguage = 'appLanguage',
  complexity = 'complexity',
  tag = 'tag',
  updateAt = 'updateAt',
  source = 'source',
  patient = 'patient',
  enrollStatus = 'enrollStatus',
  phone = 'phone'
}

// dataIndex used in sorter(field)
// API require sort: {property: field, ...}
export const DataIndexRPMDays = 'monthlyBillableStatistic.measurementDays';
export const DataIndexBillableTime = 'monthlyBillableStatistic.totalTime';

export const createPatientColumns = {
  name: (defaultSortOrder: SortOrder): ColumnType<PatientResult> => ({
    title: 'Patient',
    dataIndex: 'profile.firstName',
    key: PatientColumnKey.id,
    ...createPatientSearchFilter({}),
    render: (value, record) => (
      <PatientNameComponent
        firstName={record.profile?.firstName}
        lastName={record.profile?.lastName}
        id={record.id || ''}
      />
    ),
    width: 180,
    sorter: true,
    defaultSortOrder,
  }),
  age: (): ColumnType<PatientResult> => ({
    title: 'Age',
    dataIndex: 'profile.birthday',
    key: PatientColumnKey.age,
    sorter: true,
    reverseDirection: true,
    render: (value, record) => <PatientAgeComponent dob={record.profile?.birthday} />,
    width: 150,
  }),
  gender: (): ColumnType<PatientResult> => ({
    title: 'Sex at birth',
    dataIndex: 'profile.gender',
    key: PatientColumnKey.gender,
    ...createGendersCheckboxFilter(),
    width: 110,
    render: (value, record) => (
      <GenderEnumComponent value={record.profile?.gender ?? undefined} />
    ),
  }),
  clinic: (): ColumnType<PatientResult> => ({
    title: 'Clinic',
    dataIndex: 'clinicId',
    key: PatientColumnKey.clinic,
    width: 150,
    ...createClinicFilter('multiple', 'patientTableClinicSearchContainer'),
    render: (v, rec) => (
      <ClinicNameDisplayComponent
        clinic={rec.clinic || undefined}
      />
    ),
  }),
  program: (): ColumnType<PatientResult> => ({
    title: 'Program',
    dataIndex: 'profile',
    key: PatientColumnKey.program,
    width: 120,
    ...createEnrollProgramsCheckboxFilter(),
    render: (value, rec) => {
      if (rec.patientListInfo?.programs?.length) {
        const ep = new EnrolledProgramService(rec.patientListInfo);
        return (
          <ProgramCategoryEnumListComponent
            value={ep.getPrograms()}
            type="short"
            splitter=", "
          />
        );
      }
      return <DisplayOrEmptyComponent />;
    },
  }),
  vitals: (): ColumnType<PatientResult> => ({
    title: 'Vitals',
    dataIndex: 'vitals',
    key: PatientColumnKey.vitals,
    width: 100,
    ...createVitalsCheckboxFilter(
      VitalEnumType,
    ),
    render: (v, rec) => {
      if (rec.patientListInfo?.vitals?.length) {
        const ep = new EnrolledProgramService(rec.patientListInfo ?? undefined);
        return (
          <VitalEnumTypeListComponent
            value={ep.getVitals()}
            type="short"
            splitter=", "
          />
        );
      }
      return <DisplayOrEmptyComponent />;
    },
  }),
  riskLevel: (): ColumnType<PatientResult> => ({
    title: (
      <TooltipComponent
        title={<RiskPriorityTooltipTitleComponent />}
        overlayClassName="page-title-tooltip-overlay"
        type={null}
        placement="rightTop"
      >
        Risk Priority
      </TooltipComponent>
    ),
    dataIndex: 'riskLevel',
    key: PatientColumnKey.riskLevel,
    width: 130,
    render: (level: RiskLevelEnum) => (
      <PriorityEnumComponent value={level} />
    ),
    filters: map(RiskLevelEnum, (value) => ({
      text: (<PriorityEnumComponent value={value} type="filter" />),
      value,
    })),
  }),
  controlLevel: (): ColumnType<ControlLevelEnum> => ({
    title: 'Control Level',
    dataIndex: 'controlLevel',
    key: PatientColumnKey.controlLevel,
    width: 130,
    render: (level: ControlLevelEnum) => (
      <ControlLevelEnumComponent value={level} />
    ),
    filters: map(ControlLevelEnum, (value) => ({
      text: (<ControlLevelEnumComponent value={value} type="filter" />),
      value,
    })),
  }),
  RPMDays: (): ColumnType<PatientResult> => ({
    title: 'RPM Days',
    key: PatientColumnKey.rpmDays,
    dataIndex: DataIndexRPMDays,
    width: 100,
    // ...createRpmDaysCheckboxFilter(),
    sorter: true,
    render: (v, rec) => (
      <BillableStatisticComponent
        statistic={rec.monthlyBillableStatistic ?? undefined}
        isDays
      />
    )
  }),
  billableTime: (): ColumnType<PatientResult> => ({
    title: 'Billable time',
    key: PatientColumnKey.billableTime,
    dataIndex: DataIndexBillableTime,
    width: 100,
    ...createBillableTimeRangeRadioFilter(),
    sorter: true,
    render: (v, rec) => (
      <BillableStatisticComponent
        statistic={rec.monthlyBillableStatistic ?? undefined}
        isMins
      />
    )
  }),
  billable: (): ColumnType<PatientResult> => ({
    title: 'Billing Eligibility',
    key: PatientColumnKey.billable,
    width: 100,
    ...createBillableCheckboxFilter(),
    render: (v, rec) => (
      <BillableStatisticComponent
        statistic={rec.monthlyBillableStatistic ?? undefined}
        billable
      />
    )
  }),
  provider: (): ColumnType<PatientResult> => ({
    title: 'Provider',
    dataIndex: 'provider',
    key: PatientColumnKey.provider,
    width: 180,
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(rec.profile?.doctorUser)}
      />
    ),
    ...createMultiProviderSearchFilter({
      containerId: 'patientTableProviderSearchContainer',
      needToFilter: true,
      statusListFilter: [
        EmployeeStatus.ACTIVATED,
        EmployeeStatus.PENDING,
      ]
    }),
  }),
  assignedRdHC: (): ColumnType<PatientResult> => ({
    title: 'Assigned RD/HC',
    dataIndex: 'assignedRdHC',
    key: PatientColumnKey.assignedRdHC,
    width: 180,
    ...createEmployeeSearchFilter(
      [RoleTypeEnum.RD, RoleTypeEnum.HC],
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(rec.assignedToRDUser)}
      />
    ),
  }),
  assignedCA: (): ColumnType<PatientResult> => ({
    title: 'Assigned CM/CA',
    dataIndex: 'assignedCA',
    key: PatientColumnKey.assignedCA,
    width: 180,
    ...createEmployeeSearchFilter(
      [RoleTypeEnum.CA],
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
    render: (v, rec) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(rec.assignedToCAUser)}
      />
    ),
  }),
  enrollmentDate: (): ColumnType<PatientResult> => ({
    title: 'Enrollment Date',
    dataIndex: 'patientListInfo.enrollmentDate',
    key: PatientColumnKey.enrollmentDate,
    width: 150,
    sorter: true,
    defaultSortOrder: 'descend',
    ...createPatientEnrollmentDateFilter(),
    render: (v, rec) => (
      <DisplayDateComponent value={rec.patientListInfo?.enrollmentDate} format="USA_DATE" fallbackText={<DisplayOrEmptyComponent />} />
    ),
  }),
  registrationDate: (): ColumnType<PatientResult> => ({
    title: 'Registration Date',
    dataIndex: 'createdAt',
    key: PatientColumnKey.registrationDate,
    width: 150,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (v, rec) => <DisplayDateComponent value={rec.createdAt} format="USA_DATE" fallbackText={<DisplayOrEmptyComponent />} />
  }),
  dischargedDate: (): ColumnType<PatientResult> => ({
    title: 'Discharged Date',
    dataIndex: 'dischargedAt',
    key: PatientColumnKey.dischargedDate,
    width: 150,
    sorter: true,
    render: (v, rec) => (
      <DisplayDateComponent
        value={rec.patientListInfo?.dischargedAt}
        format="USA_DATE"
        fallbackText={<DisplayOrEmptyComponent />}
      />
    )
  }),
  mrn: (): ColumnType<PatientResult> => ({
    title: 'MRN',
    dataIndex: 'medicalRecordId',
    key: PatientColumnKey.mrn,
    width: 150,
    render: (v, rec) => (
      <div className="breakword">
        <DisplayOrEmptyComponent value={rec.medicalRecordId} />
      </div>
    )
  }),
  action: () => ({
    title: 'Action',
    dataIndex: 'id',
    key: PatientColumnKey.action,
    width: 150,
    render: (v: string) => <UnwatchPatientContainer memberId={v} />
  }),
  note: (): ColumnType<PatientResult> => ({
    title: 'Note',
    key: PatientColumnKey.watchInfoNote,
    dataIndex: 'watchInfo',
    width: 150,
    render: (v) => <DisplayOrEmptyComponent value={v?.watchNote} />
  }),
  addedTime: (): ColumnType<PatientResult> => ({
    title: 'Added Time',
    dataIndex: 'watchInfo.addedTime',
    key: PatientColumnKey.addedTime,
    width: 150,
    sorter: true,
    defaultSortOrder: 'descend',
    render: (v, rec) => (
      <DisplayDateComponent value={rec.watchInfo?.addedTime} format="USA_DATE" />
    ),
  }),
  dx: (): ColumnType<PatientResult> => ({
    title: 'Dx.',
    key: PatientColumnKey.diagnoses,
    width: 150,
    render: (_, rec) => (
      <PatientDiagnosesComponent
        values={rec.enrollmentRequest?.programEnrollment?.patientDiagnoses || []}
      />
    ),
    filters: map(PatientDiagnosesEnum, (value) => ({
      text: (<PatientDiagnosesEnumComponent value={value} />),
      value,
    })),
  }),
  primaryIns: (): ColumnType<PatientResult> => ({
    title: 'Primary Ins.',
    key: PatientColumnKey.primaryInsurance,
    width: 150,
  }),
  secondaryIns: (): ColumnType<PatientResult> => ({
    title: 'Secondary Ins.',
    key: PatientColumnKey.secondaryInsurance,
    width: 150,
  }),
  source: (): ColumnType<PatientResult> => ({
    title: 'Source',
    key: PatientColumnKey.source,
    width: 150,
    render: (_, rec) => <PatientSourceEnumComponent value={rec.patientSource ?? undefined} />,
    filters: map(PatientSourceEnum, (value) => ({
      text: (<PatientSourceEnumComponent value={value} />),
      value,
    })),
  }),
  eligibility: (): ColumnType<PatientResult> => ({
    title: 'Eligibility',
    key: PatientColumnKey.eligibility,
    width: 100,
    render: (_, rec) => (
      <ScreenStatusEnumComponent value={rec.screenStatus ?? undefined} />
    ),
    filters: map(ScreenStatusEnum, (value) => ({
      text: (<ScreenStatusEnumComponent value={value} />),
      value,
    })),
  }),
  status: (): ColumnType<PatientResult> => ({
    title: 'Status',
    width: 100,
    key: PatientColumnKey.status,
    render: (_, rec) => (
      <EnrolledProgramStatusEnumComponent
        value={rec.patientListInfo?.enrolledStatus ?? undefined}
      />
    ),
    ...createEnrolledStatusCheckboxFilter(),
  }),
  language: (): ColumnType<PatientResult> => ({
    title: 'Language',
    dataIndex: 'language',
    key: PatientColumnKey.language,
    width: 150,
    render: (_, rec) => (
      (rec.profile?.languages || []).length > 0
        ? (
          <div>
            {map(rec.profile?.languages, (lang) => (
              <LanguagesComponent
                key={lang}
                value={lang}
              />
            ))}
          </div>
        )
        : <div className="text-gray-scale-3">N/A</div>
    ),
    filters: MEMBERS_FILTERS.languages,
  }),
  appLanguage: (): ColumnType<PatientResult> => ({
    title: 'APP Language',
    dataIndex: 'appLanguage',
    key: PatientColumnKey.appLanguage,
    width: 150,
    render: (_, rec) => (
      (rec.profile?.appLanguage || []).length > 0
        ? (
          <div>
            {map(rec.profile?.appLanguage, (lang) => (
              <LanguagesComponent
                key={lang}
                value={lang}
              />
            ))}
          </div>
        )
        : <div className="text-gray-scale-3">N/A</div>
    ),
    filters: MEMBERS_FILTERS.languages,
  }),
  complexity: (): ColumnType<PatientResult> => ({
    title: 'Complexity',
    dataIndex: 'complexity',
    key: PatientColumnKey.complexity,
    width: 150,
    render: (_, rec) => (
      <ComplexityEnumComponent value={rec.complexity} />
    ),
    filters: map(PatientComplexityEnum, (value) => ({
      text: (<ComplexityEnumComponent value={value} />),
      value,
    })),
  }),
  tag: (): ColumnType<PatientResult> => ({
    title: 'Tag',
    dataIndex: 'tag',
    key: PatientColumnKey.tag,
    width: 150,
    ...createPatientTagSearchFilter(),
    render: (tags: string[]) => (
      <div>
        <Tag>{tags}</Tag>
      </div>
    ),
  }),
  updateAt: (): ColumnType<PatientResult> => ({
    title: 'Last Updated',
    dataIndex: 'updatedAt',
    key: PatientColumnKey.updateAt,
    width: 150,
    render: (v) => (
      <div>
        {v}
      </div>
    ),
  }),
};

export const createPatientSearchColumns = {
  name: (): ColumnType<PatientResult> => ({
    title: 'Patient',
    key: PatientColumnKey.patient,
    render: (_, rec) => (
      <>
        <PatientNameComponent
          firstName={rec.profile?.firstName}
          lastName={rec.profile?.lastName}
          id={rec.id || ''}
          className="pl0"
          showEnrollmentDrawer={
            new EnrolledProgramService(rec.patientListInfo ?? undefined).isEnrolled()
          }
        />
        <div className="breakword of-hidden fs-small text-secondary-gray">
          <GenderEnumComponent value={rec.profile?.gender ?? undefined} />
          ,
          <BirthdayComponent value={dayjs(rec.profile?.birthday)} showHowOld={false} />
        </div>
      </>
    ),
  }),
  clinic: (): ColumnType<PatientResult> => ({
    title: 'Clinic',
    key: PatientColumnKey.clinic,
    render: (_, rec) => (
      <ClinicNameDisplayComponent clinic={rec.clinic || undefined} />
    ),
  }),
  provider: (): ColumnType<PatientResult> => ({
    title: 'Provider',
    key: PatientColumnKey.provider,
    render: (_, rec) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(rec.profile?.doctorUser)}
      />
    ),
  }),
  medId: (): ColumnType<PatientResult> => ({
    title: 'MRN',
    key: PatientColumnKey.mrn,
    render: (_, rec) => <DisplayOrEmptyComponent value={rec.medicalRecordId} />
  }),
  phone: (): ColumnType<PatientResult> => ({
    title: 'Phone',
    key: PatientColumnKey.phone,
    render: (_, rec) => {
      const pService = new PhoneService(rec.phone);
      return <DisplayOrEmptyComponent value={pService.getMobilePhoneNumber()} />;
    }
  }),
  enrollStatus: (): ColumnType<PatientResult> => ({
    title: 'Enroll Status',
    key: PatientColumnKey.enrollStatus,
    render: (_, rec) => {
      const pro = new EnrolledProgramService(rec.patientListInfo ?? undefined);
      return (
        <ProgramCategoryEnumListComponent
          value={pro.getPrograms()}
          type="long"
          splitter=", "
        />
      );
    }
  }),
};

export type PatientTableKeys = keyof typeof createPatientColumns
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | SelectedColumnWithArgs<TableColumnCreator<any>, any>;

import { SymptomTableComponent } from '../../component/SymptomTableComponent/SymptomTableComponent';
import {
  ReporterEnum,
  SearchRequest,
  SymptomLog,
  SymptomLogRequest,
  SymptomSeverityEnum,
  useSymptomLogGetPatientSymptomLog,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import { SymptomTableRowValue } from '../../component/SymptomTableComponent/columns';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { SymptomDrawerComponent } from '../../component/SymptomDrawerComponent/SymptomDrawerComponent';

export interface SymptomCardContainerProps {
  patientId?: string;
}

export const SymptomCardContainer = ({
  patientId,
}: SymptomCardContainerProps) => {
  const tableChange = useTableChange<SymptomTableRowValue, SearchRequest<SymptomLogRequest>['filter']>({
    sortProcessor: sorterHelper,
    filterProcessor: (filters) => {
      const f = {} as NonNullable<SearchRequest<SymptomLogRequest>['filter']>;
      if (filters?.reporter) {
        f.reporterList = filters.reporter as ReporterEnum[];
      }
      if (filters?.severity) {
        f.severityList = filters.severity as SymptomSeverityEnum[];
      }
      return f;
    }
  });

  const fetchSymptomLog = useSymptomLogGetPatientSymptomLog({
    options: {
      sendOnMount: !!patientId,
    },
    params: {
      searchRequest: {
        filter: {
          patientId,
          ...tableChange.processedFilter,
        },
        pageInfo: tableChange.pageInfo,
      },
    },
  });

  useUpdateListener('SYMPTOM_UPDATED', fetchSymptomLog.refetch);

  const renderContent = (symptomLogs: SymptomLog[]) => (
    symptomLogs.length || tableChange.hasFiltersAndSorters ? (
      <SymptomTableComponent
        patientId={patientId}
        dataSource={symptomLogs}
        isLoading={fetchSymptomLog.isLoading}
        // @ts-ignore
        onChange={tableChange.handleTableChange}
        pagination={tableChange.pagination}
        filters={tableChange.filter}
        sorters={tableChange.sort}
      />
    ) : (
      <div>
        <div className="float-right">
          <SymptomDrawerComponent
            patientId={patientId}
          />
        </div>
        <NoDataWithActionComponent
          description="No symptoms have been recorded yet."
        >
          <SymptomDrawerComponent
            patientId={patientId}
            buttonType="link"
          />
        </NoDataWithActionComponent>
      </div>
    )
  );

  return (
    <FetchComponent info={fetchSymptomLog} showOnRefetch>
      {(data, info) => {
        const symptomLogs = info.data?.data?.content || [];
        return (
          <PatientCommonCardComponent
            title="Symptom Logs"
            showEdit={false}
            content={(renderContent(symptomLogs))}
          />
        );
      }}
    </FetchComponent>
  );
};

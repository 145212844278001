import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { Employee } from '../../../../../uc-api-sdk';
import { AddStaffDrawerContainer } from '../../container/AddStaffDrawerContainer/AddStaffDrawerContainer';
import { createColumns } from './StaffColumn';

export interface StaffTableComponentProps extends Omit<TableProps<Employee>, 'column'> {
  removeStaff: (id: string) => void;
  medOrgId?: string;
}

export const StaffTableComponent = ({
  removeStaff,
  dataSource,
  medOrgId,
  pagination,
  ...props
}: StaffTableComponentProps) => {
  const columns = useTableColumns(
    createColumns,
    ['name', { name: 'role', args: [medOrgId] }, 'lastLoginAt', 'description', { name: 'action', args: [removeStaff, medOrgId] }],
    [medOrgId]
  );

  const emptyNode = (
    <div className="mt100 mb100">
      <p className="mb20">There is no clinical staff in this medical organization</p>
      <AddStaffDrawerContainer buttonType="primary" />
    </div>
  );

  return (
    <Table
      className="StaffTableComponent"
      rowKey={(v) => v.id}
      pagination={pagination}
      columns={columns}
      dataSource={dataSource}
      locale={{ emptyText: emptyNode }}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

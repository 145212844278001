import { Radio, RadioGroupProps, Space } from 'antd';
import { MedicalAlertCategoryEnum } from '../../../../uc-api-sdk';
import { MedicalAlertCategoryEnumComponent } from '../../../../enumComponent/MedicalAlertCategoryEnumComponent/MedicalAlertCategoryEnumComponent';

export interface CustomizedAlertCategoryRadioComponentProps extends RadioGroupProps {
}

export const CustomizedAlertCategoryRadioComponent = ({
  value,
  onChange,
  ...props
}: CustomizedAlertCategoryRadioComponentProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Radio.Group onChange={onChange} value={value} {...props}>
    <Space>
      <Radio
        value={MedicalAlertCategoryEnum.CLINICAL}
      >
        <MedicalAlertCategoryEnumComponent value={MedicalAlertCategoryEnum.CLINICAL} />
      </Radio>
      <Radio
        value={MedicalAlertCategoryEnum.PROVIDER}
      >
        <MedicalAlertCategoryEnumComponent value={MedicalAlertCategoryEnum.PROVIDER} />
      </Radio>
    </Space>
  </Radio.Group>
);

import { useTemplateGroupGet } from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';

export interface ConsentFormNameContainerProps {
  id: string;
}

export const ConsentFormNameContainer = ({
  id
}: ConsentFormNameContainerProps) => {
  const info = useTemplateGroupGet({
    params: {
      id
    },
    options: {
      sendOnMount: !!id
    }
  });
  return (
    <FetchComponent
      info={info}
    >
      {(data) => (
        <div>
          {data?.data?.name}
        </div>
      )}

    </FetchComponent>
  );
};

import { StorageKeyEnum, useSessionStorage } from '../useSessionStorage';
import useDebounce from '../useDebounce/useDebounce';

export enum UseAutoSaveEnum {
  MTPR = 'MTPR',
}

export interface UseAutoSaveParams {
  id: string
  key: UseAutoSaveEnum;
  delay?: number;
}

export const useAutoSave = <T>(
  key: UseAutoSaveEnum,
  id: string,
  delay = 500
) => {
  const [autoSaveValues, setAutoSaveValues] = useSessionStorage<Record<string, unknown>>(
    StorageKeyEnum.AUTO_SAVE,
    {},
  );
  const getKey = () => `${key}_${id}`;

  const onChange = useDebounce((value?: T) => {
    setAutoSaveValues((x) => ({
      ...x,
      [getKey()]: value,
    }));
  }, delay, [setAutoSaveValues]);

  const removeItem = useDebounce(() => {
    setAutoSaveValues((x) => {
      const newItems = { ...x };
      delete newItems[getKey()];
      return newItems;
    });
  }, delay, [setAutoSaveValues]);

  return {
    autoSavedValue: autoSaveValues?.[getKey()] as T | undefined,
    onAutoSaveChange: onChange,
    removeItem,
  };
};

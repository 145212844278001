import { Modal } from 'antd';

export interface HandleDuplicateAlertOptions {
  onDuplicate?: () => void;
}

export const handleDuplicateAlertModal = ({
  onDuplicate,
}: HandleDuplicateAlertOptions) => {
  Modal.confirm({
    className: 'duplicate-alert-confirm-modal',
    title: 'Are you sure to create a customized alert based on this alert?',
    content: 'A duplicated alert will be created once confirm.'
      + 'You can then proceed to adjust the alert\'s basic information and rules from there.',
    onOk: onDuplicate,
    okText: 'Create',
    icon: null,
  });
};

export interface HandleDeleteAlertOptions {
  onDelete?: () => void;
}

export const handleDeleteAlertModal = ({
  onDelete,
}: HandleDeleteAlertOptions) => {
  Modal.confirm({
    title: 'Are you sure to delete this alert?',
    onOk: onDelete,
    okText: 'Yes, delete',
    okType: 'danger',
    icon: null,
  });
};

export interface HandleDeleteRuleOptions {
  onDelete?: () => void;
}

export const handleDeleteRuleModal = ({
  onDelete,
}: HandleDeleteRuleOptions) => {
  Modal.confirm({
    title: 'Are you sure to delete this rule?',
    onOk: onDelete,
    okText: 'Yes, delete',
    okType: 'danger',
    icon: null,
  });
};

import {
  filter,
  map,
  reduce,
  uniqueId,
} from 'lodash';
import { AlertCondition } from '../type';
import { EventTypeEnum } from '../../../uc-api-sdk';

export class ConditionsService {
  protected eventType: EventTypeEnum;

  protected conditions: AlertCondition[];

  protected conditionMap: Record<string, AlertCondition>;

  constructor(eventType: EventTypeEnum, conditions: AlertCondition[]) {
    this.eventType = eventType;
    this.conditions = conditions;
    this.conditionMap = reduce(conditions, (res, curr) => {
      if (!curr) return res;
      res[curr.name || uniqueId('UNKNOWN_METRIC_NAME')] = curr;
      return res;
    }, {} as Record<string, AlertCondition>);
  }

  public get options() {
    return filter(map(this.conditions, (v) => v.name), (v) => !!v) as string[];
  }

  public getCondition(name: string) {
    return this.conditionMap[name] as AlertCondition;
  }
}

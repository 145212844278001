import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React from 'react';

interface ExpandIconProps<T> {
  expanded: boolean;
  expandable: boolean;
  onExpand: (record: T, event: React.MouseEvent<HTMLElement>) => void;
  record: T;
}

const ExpandIcon = <T, >({
  expanded, expandable, onExpand, record
}: ExpandIconProps<T>) => {
  if (!expandable) {
    return null;
  }

  return expanded ? (
    <UpOutlined onClick={(e) => onExpand(record, e)} />
  ) : (
    <DownOutlined onClick={(e) => onExpand(record, e)} />
  );
};

export default ExpandIcon;

import { MedicationRouteEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface MedicationRouteEnumComponentProps {
  value?: MedicationRouteEnum;
  isDescription?: boolean;
}

export const MedicationRouteEnumComponent = ({
  value,
  isDescription = false,
}: MedicationRouteEnumComponentProps) => {
  const renderText = () => {
    switch (value) {
      case MedicationRouteEnum.ORAL:
        return 'Oral (PO)';
      case MedicationRouteEnum.INJECTION:
        return 'Injection';
      case MedicationRouteEnum.INHALATION:
        return 'Inhalation (INH)';
      case MedicationRouteEnum.TOPICAL:
        return 'Topical';
      case MedicationRouteEnum.SUBLINGUAL:
        return 'Sublingual (SL)';
      case MedicationRouteEnum.OCULAR:
        return 'Ocular';
      case MedicationRouteEnum.AURICULAR:
        return 'Auricular (AU)';
      case MedicationRouteEnum.UROGENITAL:
        return 'Urogenital';
      case MedicationRouteEnum.RECTAL:
        return 'Rectal (PR)';
      case MedicationRouteEnum.INTRANASAL:
        return 'Intranasal (ISIN)';
      case MedicationRouteEnum.OTHER:
        return 'Other';
      case MedicationRouteEnum.description:
      case MedicationRouteEnum.displayName:
        return null;
      default:
        return <EmptyComponent />;
    }
  };

  const renderDescription = () => {
    switch (value) {
      case MedicationRouteEnum.ORAL:
        return 'the patient swallows a tablet or capsule';
      case MedicationRouteEnum.INJECTION:
        return 'administered via syringe into the body';
      case MedicationRouteEnum.INHALATION:
        return 'the patient breathes in medication from an inhaler';
      case MedicationRouteEnum.TOPICAL:
        return 'administered by directly applying to the skin';
      case MedicationRouteEnum.SUBLINGUAL:
        return 'applied under the tongue';
      case MedicationRouteEnum.OCULAR:
        return 'administered to or within the eye';
      case MedicationRouteEnum.AURICULAR:
        return 'administered to or by way of the ear';
      case MedicationRouteEnum.UROGENITAL:
        return 'administered via reproductive or urinary pathways';
      case MedicationRouteEnum.RECTAL:
        return 'administered via rectal suppository';
      case MedicationRouteEnum.INTRANASAL:
        return 'administration within the nasal or periorbital sinuses';
      case MedicationRouteEnum.OTHER:
        return 'Other';
      case MedicationRouteEnum.description:
      case MedicationRouteEnum.displayName:
        return null;
      default:
        return <EmptyComponent />;
    }
  };

  return (
    <span>
      {!isDescription ? renderText() : renderDescription()}
    </span>
  );
};

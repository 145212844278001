import dayjs from 'dayjs';
import { difference, map, first } from 'lodash';
import {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Patient } from '../../../../uc-api-sdk';
import { ALL_CLINIC_SELECT_OPTION } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import {
  PatientSearchSelectComponent,
  PatientSearchSelectComponentInfo,
  PatientSearchSelectComponentProps
} from '../../component/PatientSearchSelectComponent/PatientSearchSelectComponent';
import { usePatientSearchPage } from '../../hook/usePatientSearchPage';

export interface PatientSearchSelectContainerProps extends
  Omit<PatientSearchSelectComponentProps<Patient>, 'data' | 'onSearch' | 'onChange'> {
  value?: string | null;
  onChange?: (value: string) => void;
  getByPatientIdOnSelect?: (value?: Patient) => void;
  showClinicInFilter?: boolean;
  getAllClinics?: boolean;
  isCreateOtherEventsInCalender?: boolean;
  selectedOrgIds?: string[];
}

export const PatientSearchSelectContainer = ({
  value,
  onChange,
  getByPatientIdOnSelect,
  optionRenderer,
  dropdownRender,
  optionLabel,
  placeholder,
  showClinicInFilter,
  isCreateOtherEventsInCalender,
  selectedOrgIds,
  ...props
}: PatientSearchSelectContainerProps) => {
  const [selectedClinicIds, setSelectedClinicIds] = useState<string[] | undefined>(selectedOrgIds);
  const { handleSearch, patients } = usePatientSearchPage(value);

  const handleChange: PatientSearchSelectComponentProps<Patient>['onChange'] = (value) => {
    onChange?.(value);
    getByPatientIdOnSelect?.(first(patients.data?.data?.content?.filter(v => v.id === value)));
  };

  const debounceHandleSearch = useDebounce(
    handleSearch,
    500,
    [selectedClinicIds, isCreateOtherEventsInCalender]
  );

  useEffect(() => {
    if (difference(selectedOrgIds, selectedClinicIds || [])) {
      setSelectedClinicIds(selectedOrgIds);
    }
  }, [selectedOrgIds]);

  useUpdateEffect(() => {
    debounceHandleSearch(
      value ?? undefined,
      selectedClinicIds,
      isCreateOtherEventsInCalender
    );
  }, [selectedClinicIds, isCreateOtherEventsInCalender]);

  const patientList = useMemo<PatientSearchSelectComponentInfo<Patient>[]>(() => (
    map(patients.data?.data?.content, (v) => ({
      id: v.id || '',
      fullName: `${v.profile?.firstName} ${v.profile?.lastName}`,
      dob: v.profile?.birthday ? dayjs(v.profile.birthday) : undefined,
      info: v,
    }))
  ), [patients.data?.data?.content]);

  const handleChangeClinicInFilter = (clinicId?: string) => {
    setSelectedClinicIds(clinicId ? [clinicId] : undefined);
  };

  return (
    <PatientSearchSelectComponent
      isLoading={patients.isLoading}
      data={patientList}
      onSearch={(v) => {
        debounceHandleSearch(
          v ?? undefined,
          selectedClinicIds,
          isCreateOtherEventsInCalender
        );
      }}
      value={value}
      optionRenderer={optionRenderer}
      dropdownRender={dropdownRender}
      onChange={handleChange}
      optionLabel={optionLabel}
      placeholder={placeholder}
      showClinicInFilter={showClinicInFilter}
      clinicInFilterValue={selectedClinicIds?.[0] || ALL_CLINIC_SELECT_OPTION.value}
      onChangeClinicInFilter={handleChangeClinicInFilter}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

import {
  Checkbox, CheckboxProps, Col, Form, Row
} from 'antd';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { useEffect } from 'react';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BILLING_CODE_DEPENDENCIES } from '../../../constants/billingCodes';
import './BillingCodeInputComponent.scss';

export interface BillingCodeCheckboxValue {
  checked?: boolean;
  capUnits?: number;
  code?: string;
}

export interface BillingCodeInputComponentProps extends CheckboxProps {
  name: string;
}

export const BillingCodeInputComponent = ({
  name,
  ...props
}: BillingCodeInputComponentProps) => {
  const form = useFormInstance();
  const depCode = BILLING_CODE_DEPENDENCIES[name];
  const depCodeState = Form.useWatch(['codeSettings', depCode], form);
  const { value: disabled, setTrue, setFalse } = useBoolean(false);

  useEffect(() => {
    if (depCodeState && !depCodeState?.checked) {
      setTrue();
    } else {
      setFalse();
    }
  }, [depCodeState]);

  return (
    <Row align="middle" className="billingCodeInput">
      <Col span={8}>
        <FormItem
          name={['codeSettings', name, 'checked']}
          valuePropName="checked"
        >
          <Checkbox
            value={name}
            name={name}
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            {name}
          </Checkbox>
        </FormItem>
      </Col>
    </Row>
  );
};

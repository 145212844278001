import {
  Button, Form, FormProps, Modal
} from 'antd';
import { useEffect, useState } from 'react';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { useMedicalOrgAssociateForm } from '../../../hook/useMedicalOrgAssociateForm';
import { AssociatedCareUniteRadioComponent } from '../AssociatedCareUniteRadioComponent/AssociatedCareUniteRadioComponent';
import { EmployeeResponse, RoleTypeEnum } from '../../../../../uc-api-sdk';
import { MiniUserComponent } from '../../../../user/component/MiniUserComponent/MiniUserComponent';
import { RoleTypeEnumListComponent } from '../../../../../enumComponent/RoleTypeEnumListComponent/RoleTypeEnumListComponent';
import { AccountHelper } from '../../../../../helpers/account';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import './AssociatedCareUnitFormComponent.scss';

export interface AssociatedCareUnitFormValue {
  careGroupId: string;
  id: string;
}
export interface AssociatedCareUnitFormComponentProps extends FormProps {
  onSubmit: (v: AssociatedCareUnitFormValue) => void;
  moveCareGroupWarningPatientList?: EmployeeResponse[];
}

export const AssociatedCareUnitFormComponent = ({
  onSubmit,
  moveCareGroupWarningPatientList,
  ...props
}: AssociatedCareUnitFormComponentProps) => {
  const hook = useMedicalOrgAssociateForm();
  const { isOpen: showModal, close, open } = useOpen();
  const [selectedCareGroupName, setSelectedCareGroupName] = useState<string | undefined>();
  const [selectedCareGroupId, setSelectedCareGroupId] = useState<string | undefined>();
  const { form } = hook;

  useEffect(() => {
    form.resetFields();
  }, [props.initialValues]);

  useEffect(() => {
    setSelectedCareGroupId(form.getFieldValue('careGroupId'));
  }, [form.getFieldValue('careGroupId')]);

  const handleFormSubmit = () => {
    onSubmit({
      careGroupId: selectedCareGroupId as string,
      id: props.initialValues?.id as string
    });
    close();
  };

  return (
    <Form
      className="h100"
      layout="vertical"
      onFinish={hook.handleSubmit(onSubmit)}
      form={form}
      initialValues={props.initialValues}
      scrollToFirstError
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FixedComponent className="w100">
        <FixedComponent.Child>
          <div>
            Please associate the Medical Organization to a Care Unit.
            Selected Care Unit will be responsible for it's patient care delivery.
          </div>
          <FormItem
            required
            name={hook.getName('careGroupId')}
          >
            <AssociatedCareUniteRadioComponent
              value={selectedCareGroupId}
              onChangeSelectedGroupName={(rec) => setSelectedCareGroupName(rec)}
            />
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="border-t">
          {
            moveCareGroupWarningPatientList?.length ? (
              <Button
                className="w100"
                type="primary"
                onClick={open}
              >
                Save
              </Button>
            ) : (
              <Button
                className="w100"
                type="primary"
                htmlType="submit"
              >
                Save
              </Button>
            )
          }
        </FixedComponent.Child>
        <Modal
          title="Are You Sure to Associate this Medical Org to another Care Unit?"
          open={showModal}
          className="associated-care-unit-form-modal"
          onCancel={close}
          footer={[
            <Button key="cancel" onClick={close}>
              Cancel
            </Button>,
            <Button type="primary" onClick={handleFormSubmit} htmlType="submit">
              Yes, I confirm
            </Button>
          ]}
        >
          <FixedComponent>
            <FixedComponent.Child isFixed>
              <div className="mb30">
                Patients of this medical org have already
                {' '}
                been assigned to members in current care group
                By changing the association,
                these members from list below will be added to
                {' '}
                {selectedCareGroupName}
                {' '}
                as well:
              </div>
            </FixedComponent.Child>
            <FixedComponent.Child>
              {
                moveCareGroupWarningPatientList?.map((item) => (
                  <div key={item.employee?.id} className="flex jc-sb ai-c w90 mb10">
                    <MiniUserComponent
                      avatar={item.employee?.employeeProfile?.avatar?.url || undefined}
                      name={AccountHelper.getFullName(item.employee?.employeeProfile)}
                      email={item.employee?.email || undefined}
                    />
                    <RoleTypeEnumListComponent
                      splitter=", "
                      value={item.roles?.map((role) => role.roleType as RoleTypeEnum) || []}
                    />
                  </div>
                ))
              }
            </FixedComponent.Child>
          </FixedComponent>
        </Modal>
      </FixedComponent>
    </Form>
  );
};

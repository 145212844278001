import {
  LimitedClinic,
  MedicalOrganization,
  MedicalOrganizationAssignment,
  MiniOrganization,
  RoleTypeEnum
} from '../../uc-api-sdk';
import { ClinicSelectContainerHelper } from '../../uiContainer/ClinicSelectContainer/helper';

export interface SearchableEntity extends LimitedClinic {
  searchable: string;
  clinicData?: LimitedClinic;
}

export interface SearchableOrg extends MedicalOrganization {
  searchable: string;
}

export class MedicalOrganizationHelper {
  static makeSearchable(org: MedicalOrganization, isProvider?: boolean) {
    const { alias, orgNumber, businessName } = org.profile || {};
    let searchable = `${isProvider ? businessName : (alias?.trim() || businessName)}`;
    if (!isProvider && orgNumber) {
      searchable = `${orgNumber || ''} ${searchable}`;
    }
    return searchable.trim();
  }

  static sortBySearchable(orgs: SearchableEntity[]) {
    return orgs.sort((a, b) => {
      if (a.searchable.toLowerCase() > b.searchable.toLowerCase()) {
        return 1;
      } if (a.searchable.toLowerCase() < b.searchable.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  static sortByOrgNumber(orgs: SearchableEntity[]) {
    return orgs.sort((a, b) => {
      if (Number(a.orgNumber) > Number(b.orgNumber)) {
        return 1;
      } if (Number(a.orgNumber) < Number(b.orgNumber)) {
        return -1;
      }
      return 0;
    });
  }

  static makeAndSort(
    orgs: MedicalOrganization[],
    isProvider: boolean,
  ) {
    const hasNums = orgs.filter((org) => org.profile?.orgNumber);
    const noNums = orgs.filter((org) => !org.profile?.orgNumber);
    const sorted: SearchableOrg[] = MedicalOrganizationHelper.sortByOrgNumber(
      hasNums.map((org) => ({
        ...org,
        searchable: MedicalOrganizationHelper.makeSearchable(org, isProvider)
      }))
    );
    const noNumsSorted: SearchableOrg[] = MedicalOrganizationHelper.sortBySearchable(
      noNums.map((org) => ({
        ...org,
        searchable: MedicalOrganizationHelper.makeSearchable(org, isProvider)
      }))
    );
    return sorted.concat(noNumsSorted);
  }

  static makeAndSortMedicalOrgAssignments(
    orgs: MedicalOrganizationAssignment[],
    isProvider: boolean,
  ) {
    const hasNums = orgs.filter((org) => org.medicalOrganization?.orgNumber);
    const noNums = orgs.filter((org) => !org.medicalOrganization?.orgNumber);

    const sortedHasNums = ClinicSelectContainerHelper.makeAndSortClinics(
      hasNums.map(r => r.medicalOrganization || {}),
      { isProvider }
    );
    const sortedNoNums = ClinicSelectContainerHelper.makeAndSortClinics(
      noNums.map(r => r.medicalOrganization || {}),
      { isProvider }
    );
    const sortedOrgs = sortedHasNums.concat(sortedNoNums);
    const ids = sortedOrgs.map((o) => o.value) as string[];
    const idMap: Record<string, MedicalOrganizationAssignment> = {};
    orgs.forEach((org) => {
      idMap[org.medicalOrgId || ''] = org;
    });
    return ids.map((id) => idMap[id]);
  }

  static makeSearchableMiniOrg(org: MiniOrganization, isProvider?: boolean) {
    const { name, orgNumber, businessName } = org;
    let searchable = `${isProvider ? businessName : name}`;
    if (!isProvider && orgNumber) {
      searchable = `${orgNumber} ${name}`;
    }
    return searchable.trim();
  }

  public static makeAndSortMiniOrganizations(
    orgs: MiniOrganization[],
    isProvider: boolean,
  ) {
    const hasNums = MedicalOrganizationHelper.sortByOrgNumber(
      orgs.filter((org) => org.orgNumber).map(org => ({
        ...org,
        searchable: MedicalOrganizationHelper.makeSearchableMiniOrg(org, isProvider),
      }))
    );
    const noNums = MedicalOrganizationHelper.sortBySearchable(
      orgs.filter((org) => !org.orgNumber).map(org => ({
        ...org,
        searchable: MedicalOrganizationHelper.makeSearchableMiniOrg(org, isProvider),
      }))
    );
    return hasNums.concat(noNums);
  }

  public static getClinicalStaffConflictingRoles(rolesInOtherClinics: RoleTypeEnum[]) {
    const conflictingRoles: RoleTypeEnum[] = [];
    if (rolesInOtherClinics.includes(RoleTypeEnum.PROVIDER)) {
      conflictingRoles.push(RoleTypeEnum.MA);
    }
    if (rolesInOtherClinics.includes(RoleTypeEnum.MA)) {
      conflictingRoles.push(RoleTypeEnum.PROVIDER);
    }
    if (rolesInOtherClinics.includes(RoleTypeEnum.CLINICAL_MANAGER)) {
      conflictingRoles.push(RoleTypeEnum.CLINICAL_GROUP_BILLER);
    }
    if (rolesInOtherClinics.includes(RoleTypeEnum.CLINICAL_GROUP_BILLER)) {
      conflictingRoles.push(RoleTypeEnum.CLINICAL_MANAGER);
    }

    return conflictingRoles;
  }
}

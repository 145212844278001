import { EventTypeEnum } from '../../../../uc-api-sdk';
import { RuleOptionsComponent, RuleOptionsComponentProps } from '../../component/RuleOptionsComponent/RuleOptionsComponent';
import { useAlertApiContext } from '../../context/AlertApiContext';
import { AlertRule } from '../../type';

export interface RuleOptionContainerProps {
  rule: AlertRule;
  alertId: string;
  alertType: EventTypeEnum;
  onSubmit?: () => void;
}

export const RuleOptionContainer = ({
  rule,
  alertId,
  alertType,
  onSubmit,
}: RuleOptionContainerProps) => {
  const {
    onAddRule,
    isAddRuleLoading,
    onEditRule,
    isEditRuleLoading,
  } = useAlertApiContext();

  const handleSubmit: RuleOptionsComponentProps['onSubmit'] = async (v) => {
    if (rule.id) {
      await onEditRule(alertId, { ...rule, ...v });
    } else {
      await onAddRule(alertId, v);
    }
    onSubmit?.();
  };

  return (
    <RuleOptionsComponent
      rule={rule}
      alertType={alertType}
      onSubmit={handleSubmit}
      isSubmitting={isAddRuleLoading || isEditRuleLoading}
    />
  );
};

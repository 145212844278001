import dayjs from 'dayjs';
import { REQUEST_DATE_FORMAT } from '../../../../constants/timeFormat';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { WorklistContextProvider } from '../../../../contexts/WorklistContext/WorklistContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUnsavedNotes } from '../../../../hooks/useUnsavedNotes/useUnsavedNotes';
import {
  CareNoteTagEnum,
  ClinicEvent, useCareNoteInsert,
  useClinicEventCheckOutVisit,
  useClinicEventGet, useClinicEventUpdate, VisitChartingStatusEnum, VisitMethodEnum, VisitTypeEnum
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { TechWorklistComponent } from '../../component/TechWorklistComponent/TechWorklistComponent';
import { getWorklistUnsavedNoteKey } from '../../hook/useWorklistActions';
import { WorklistComponentCommonProps } from '../../types';
import { NonTechWorklistContainer } from '../NonTechWorklistContainer/NonTechWorklistContainer';
import { SimplifiedWorklistComponent } from '../../component/SimplifiedWorklistComponent/SimplifiedWorklistComponent';
import { FutureClinicEventContextProvider } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import { TechOnboardingWorklistComponent } from '../../component/TechOnboardingWorklistComponent/TechOnboardingWorklistComponent';

export interface WorklistContainerProps {
  patientId: string;
  clinicEventId: string;
  onClose?: () => void;
}

export const WorklistContainer = ({
  patientId,
  clinicEventId,
  onClose,
}: WorklistContainerProps) => {
  const updateHook = useUpdate('WORKLIST_CLOSED');
  const updateCareNoteHook = useUpdate('CARE_NOTE_CREATED');
  const clinicEventInfo = useClinicEventGet({
    params: { id: clinicEventId },
  });
  useUpdateListener('VIDEO_VISIT_STATUS_UPDATED', clinicEventInfo.refetch);
  useUpdateListener('VIDEO_VISIT_ENDED', clinicEventInfo.refetch);
  const {
    finishUnsavedNotes,
  } = useUnsavedNotes(getWorklistUnsavedNoteKey(clinicEventId));

  const clinicEventUpdateInfo = useClinicEventUpdate({});
  const checkOutHook = useClinicEventCheckOutVisit({});
  const careNoteInsertInfo = useCareNoteInsert({});
  const { send } = useBillableTimeInterventionComp();

  const clinicEvent = clinicEventInfo.data?.data
    && new ClinicEventsService(clinicEventInfo.data?.data);
  const isLoading = clinicEventUpdateInfo.isLoading || checkOutHook.isLoading;
  const isDisabled = clinicEvent?.isCompleted;
  const isTechVisit = [
    VisitTypeEnum.TECH,
    VisitTypeEnum.TECH_ONBOARDING
  ].includes(clinicEvent?.visitType as VisitTypeEnum);

  const handleOnClose = () => {
    updateHook.updateValue();
    onClose?.();
  };

  const handleUpdateClinicEvent = async (
    values: ClinicEvent,
    shouldRefetchClinicEvent?: boolean,
    successMessage = 'Updated visit successfully.',
    errorMessage = 'Failed to update visit.',
  ) => {
    const res = await ApiRequestHelper.tryCatch(
      clinicEventUpdateInfo.send({
        params: {
          id: clinicEventId,
          document: values,
        }
      }),
      {
        success: successMessage,
        error: errorMessage,
        onSuccess: () => {
          if (shouldRefetchClinicEvent) clinicEventInfo.refetch();
          finishUnsavedNotes();
        }
      },
    );
    return res?.data ?? undefined;
  };

  const handleCheckOut = async (clinicEvent: ClinicEvent = {}) => {
    if (clinicEvent.visitMethod === VisitMethodEnum.VIRTUAL) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitVideoCallCheckOut,
      });
    } else {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitsCheckOut,
      });
    }
    const res = await ApiRequestHelper.tryCatch(
      checkOutHook.send({
        params: { id: clinicEventId, request: clinicEvent }
      }),
      {
        success: 'Check out success!',
        error: 'Failed to check out!',
        onSuccess: () => {
          clinicEventInfo.refetch();
          finishUnsavedNotes();
        },
      },
    );
    return res?.data ?? undefined;
  };

  const handleCompleteCharting: WorklistComponentCommonProps['onCompleteCharting'] = async (
    values?: ClinicEvent,
    closeOnDone = true,
  ) => {
    handleUpdateClinicEvent({
      ...values,
      visitChartingStatus: VisitChartingStatusEnum.DONE,
    });
    const endTime = clinicEvent?.checkOutTime || dayjs();
    ApiRequestHelper.tryCatch(
      careNoteInsertInfo.send({
        params: {
          document: {
            memberId: patientId,
            tags: [CareNoteTagEnum.VISIT],
            content: values?.visitSummary,
            startTime: clinicEvent?.checkInTime?.utc().format(REQUEST_DATE_FORMAT),
            endTime: endTime.utc().format(REQUEST_DATE_FORMAT),
          }
        }
      }),
      {
        success: undefined,
        error: 'Failed to add care note.',
        onSuccess: () => {
          updateCareNoteHook.updateValue();
          if (closeOnDone) handleOnClose();
        }
      }
    );
  };

  if (clinicEvent === undefined) {
    return <LoadingOverlayComponent isLoading><span /></LoadingOverlayComponent>;
  }

  if (clinicEvent === null) {
    return <span>Can't find the given clinic event</span>;
  }

  const renderWorklistBody = () => {
    switch (clinicEvent.visitType) {
      case VisitTypeEnum.INITIAL:
      case VisitTypeEnum.POST_MD_FOLLOW_UP:
        return (
          <FutureClinicEventContextProvider
            patientId={patientId}
            startTime={clinicEvent.startTimeUtc}
          >
            <SimplifiedWorklistComponent
              patientId={patientId}
              clinicEvent={clinicEvent}
              onCheckOut={handleCheckOut}
              onClinicEventUpdate={handleUpdateClinicEvent}
              onClose={handleOnClose}
              onCompleteCharting={handleCompleteCharting}
              disabled={isDisabled}
            />
          </FutureClinicEventContextProvider>
        );
      case VisitTypeEnum.TECH:
        return (
          <TechWorklistComponent
            isLoading={isLoading}
            patientId={patientId}
            clinicEvent={clinicEvent}
            onCheckOut={handleCheckOut}
            onClinicEventUpdate={handleUpdateClinicEvent}
            onClose={handleOnClose}
            onCompleteCharting={handleCompleteCharting}
            disabled={isDisabled}
          />
        );
      case VisitTypeEnum.TECH_ONBOARDING:
        return (
          <FutureClinicEventContextProvider
            patientId={patientId}
            startTime={clinicEvent.startTimeUtc}
          >
            <TechOnboardingWorklistComponent
              patientId={patientId}
              clinicEvent={clinicEvent}
              onCheckOut={handleCheckOut}
              onClinicEventUpdate={handleUpdateClinicEvent}
              onClose={handleOnClose}
              onCompleteCharting={handleCompleteCharting}
              disabled={isDisabled}
            />
          </FutureClinicEventContextProvider>
        );
      default:
        return (
          <NonTechWorklistContainer
            patientId={patientId}
            clinicEvent={clinicEvent}
            isLoading={isLoading}
            onClose={handleOnClose}
            onClinicEventUpdate={handleUpdateClinicEvent}
            onCheckOut={handleCheckOut}
            onCompleteCharting={handleCompleteCharting}
            disabled={isDisabled}
          />
        );
    }
  };

  return (
    <PatientProfileContainer
      patientId={patientId}
      getEnrolledProgram
      getFollowUpVisitRange
      getOutstandingItemCount
      getAssignees
      getInsurance={!isTechVisit}
      getMedicationManagement={!isTechVisit}
      getControlLevel={!isTechVisit}
      getBpBaseline={!isTechVisit}
      getHealthCondition={!isTechVisit}
      getComplexity={!isTechVisit}
      showOnRefetch
    >
      {
        (patientInfo) => (
          <LoadingOverlayComponent
            isLoading={patientInfo === undefined}
          >
            <WorklistContextProvider
              patientInfo={patientInfo}
              clinicEvent={clinicEvent}
            >
              {renderWorklistBody()}
            </WorklistContextProvider>
          </LoadingOverlayComponent>
        )
      }
    </PatientProfileContainer>
  );
};

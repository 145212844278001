import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../Input/types';
import { OperatorEnumComponent } from '../../component/OperatorEnumComponent/OperatorEnumComponent';
import { OperatorEnum } from '../../type';
import './OperatorSelectComponent.scss';

export interface OperatorSelectComponentProps extends InputType<OperatorEnum> {
  options: OperatorEnum[];
}

export const OperatorSelectComponent = ({
  options,
  onChange,
  value,
}: OperatorSelectComponentProps) => (
  <EnumSelectComponent
    options={options}
    optionRenderer={OperatorEnumComponent}
    value={value}
    onChange={onChange}
    className="operator-select-component"
  />
);

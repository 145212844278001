import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useMedicalOrgServiceForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    inClinicInventory: {
      name: 'inClinicInventory',
      label: 'In-clinic inventory',
    },
    callCenterLocalNumber: {
      name: 'callCenterLocalNumber',
      label: 'Call center local number',
    },
    nonSmartPhoneAlternatives: {
      name: 'nonSmartPhoneAlternatives',
      label: 'Non-smartphone alternatives',
    },
    hardwares: {
      name: 'hardwares',
      label: 'Hardware devices',
    },
    bpDevices: {
      name: 'bpDevices',
      label: 'BP devices',
    },
    coBrandName: {
      name: 'coBrandName',
      label: 'Co-branding name',
    },
    logo: {
      name: 'logo',
      label: 'Logo',
    },

  }, options);
  return factory;
};

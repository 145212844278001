import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { SymptomEnum } from '../../../uc-api-sdk';

export const symptomsList = [
  { symptomEnum: SymptomEnum.ABDOMINAL_PAIN, label: 'Abdominal Pain' },
  { symptomEnum: SymptomEnum.CONSTIPATION, label: 'Constipation' },
  { symptomEnum: SymptomEnum.DIARRHEA, label: 'Diarrhea' },
  { symptomEnum: SymptomEnum.DIZZINESS, label: 'Dizziness' },
  { symptomEnum: SymptomEnum.HAIR_LOSS, label: 'Hair Loss' },
  { symptomEnum: SymptomEnum.HEADACHE, label: 'Headache' },
  { symptomEnum: SymptomEnum.LACK_OF_APPETITE, label: 'Lack of Appetite' },
  { symptomEnum: SymptomEnum.NAUSEA, label: 'Nausea' },
  { symptomEnum: SymptomEnum.REACTION_AT_INJECTION_SITE, label: 'Reaction at Injection Site' },
  { symptomEnum: SymptomEnum.RASH, label: 'Rash' },
  { symptomEnum: SymptomEnum.VOMITING, label: 'Vomiting' },
];

const formName = 'symptomList';

export interface SymptomSubmitValue {
  [formName]: {
    [x in SymptomEnum]: {
      flag?: boolean;
      severity?: string;
      note?: string;
      manualSymptom?: string;
    }
  }
}

export const useCreateSymptomForm = () => {
  const factory = useIterableFormHook(
    'symptomList',
    {
      flag: {
        name: 'flag',
        label: '',
      },
      manualSymptom: {
        name: 'manualSymptom',
        label: '',
      },
      severity: {
        name: 'severity',
        label: 'Severity',
        required: true,
      },
      note: {
        name: 'note',
        label: '',
        required: false,
        placeholder: 'Note (Optional)',
      },
    }
  );

  return factory;
};

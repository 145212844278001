import {
  Button, Checkbox, Divider, FormInstance, Input
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { map } from 'lodash';
import { useMemo } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import CredentialsSelectComponent from '../../../../selectComponents/CredentialsSelectComponent/CredentialsSelectComponent';
import LanguageSelectComponent from '../../../../selectComponents/LanguageSelectComponent/LanguageSelectComponent';
import { PhoneService } from '../../../../services/PhoneService';
import { RemoveRoleValidationResponse, RoleTypeEnum } from '../../../../uc-api-sdk';
import { CredentialsTooltipComponent } from '../../../../uiComponent/CredentialsTooltipComponent/CredentialsTooltipComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import PhoneInput from '../../../../uiComponent/PhoneInput/PhoneInput';
import { useAdminUserForm } from '../../hook/useCreateUserHook';
import { InternalEmployeeRoleTreeComponent } from '../../users/component/InternalEmployeeRoleTreeComponent/InternalEmployeeRoleTreeComponent';
import { CreateUserFormValues } from '../../users/type';
import { EmployeeInputValidationComponent } from '../EmployeeInputValidationComponent/EmployeeInputValidationComponent';
import { EmployeeRolesSelectComponent } from '../EmployeeRolesSelectComponent/EmployeeRolesSelectComponent';
import { UserIdInputFormItemComponent } from '../UserIdInputComponent/UserIdInputComponent';
import { userRolesRestrictionModal } from '../UserRolesRestrictionModal/UserRolesRestrictionModal';
import { UserTypeInputComponent } from '../UserTypeInputComponent/UserTypeInputComponent';
import './CreateUserFormFieldsComponent.scss';

export interface CreateUserFormFieldsComponentProps {
  form: FormInstance;
  isEditing?: boolean;
  shouldValidate?: number;
  initialValues?: CreateUserFormValues;
  setIdValid: () => void;
  setIdInvalid: () => void;
  setEmailValid: () => void;
  setEmailInvalid: () => void;
  hasPatientsCheck?: RemoveRoleValidationResponse[]; // 65e0bb7e846e7e31f15b8479
  restrictedMedOrgIds?: string[];
  orgIdsWithPt?: string[];
}

export const CreateUserFormFieldsComponent = ({
  form,
  isEditing = false,
  shouldValidate,
  initialValues,
  setIdValid,
  setIdInvalid,
  setEmailValid,
  setEmailInvalid,
  hasPatientsCheck,
  restrictedMedOrgIds,
  orgIdsWithPt
}: CreateUserFormFieldsComponentProps) => {
  const hook = useAdminUserForm({ form });
  const {
    value: showUserId,
    setTrue,
    setFalse
  } = useBoolean(false);

  const rolesWithPatientsAssigned: RoleTypeEnum[] = useMemo(() => {
    if (hasPatientsCheck) {
      const res = map(hasPatientsCheck, (item) => item.roles?.at(0) as RoleTypeEnum);
      return res;
    }
    return [];
  }, [hasPatientsCheck]);

  return (
    <div className="flex gap2 fd-c pr50">
      <FormItem
        info={hook.getInfo('userType')}
        required
      >
        <UserTypeInputComponent disabled={isEditing} />
      </FormItem>
      <Divider />
      <Title level={5}>Basic Information</Title>
      <FormItem
        info={hook.getInfo('firstName')}
        required
      >
        <Input />
      </FormItem>
      <FormItem
        info={hook.getInfo('middleName')}
      >
        <Input />
      </FormItem>
      <FormItem
        info={hook.getInfo('lastName')}
        required
      >
        <Input />
      </FormItem>
      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const placeholder = !hook.isClinicalStaff(getFieldValue) ? "Enter user's @ihealthlabs.com email" : '';
          const rules = hook.getEmailRules({
            required: true,
            isIhealthEmail: !hook.isClinicalStaff(getFieldValue)
          });
          return (
            <FormItem
              info={hook.getInfo('email')}
              required
              rules={rules}
            >
              <EmployeeInputValidationComponent
                form={form}
                setValid={setEmailValid}
                setInvalid={setEmailInvalid}
                fieldName="email"
                fieldLabel="Email"
                placeHolder={placeholder}
                currentValue={initialValues?.email}
              />

            </FormItem>
          );
        }}
      </FormItem>
      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const show = hook.isClinicalStaff(getFieldValue);
          return show && (
            <FormItem
              info={hook.getInfo('loginId')}
              required
            >
              <EmployeeInputValidationComponent
                form={form}
                setValid={setIdValid}
                setInvalid={setIdInvalid}
                fieldName="loginId"
                fieldLabel="Login ID"
                shouldValidate={shouldValidate}
                currentValue={initialValues?.loginId}
              />
            </FormItem>
          );
        }}
      </FormItem>
      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const show = !hook.isClinicalStaff(getFieldValue);
          return show && (
            <FormItem>
              <span>
                iHeath employees are suggested to only use
                google account to log in. Still want to
                {' '}
                <Button
                  className="p0 m0"
                  type="link"
                  onClick={setTrue}
                >
                  Enable User ID and Password Login
                </Button>
                ?
              </span>
              {showUserId && (
                <UserIdInputFormItemComponent
                  setIdInvalid={setIdInvalid}
                  setIdValid={setIdValid}
                  onClose={() => {
                    setFalse();
                    setIdValid();
                  }}
                  name={hook.getName('loginId')}
                  label={hook.getLabel('loginId')}
                  form={form}
                  shouldValidate={shouldValidate}
                  currentValue={initialValues?.loginId}
                />
              )}
            </FormItem>
          );
        }}
      </FormItem>
      <FormItem
        name="phone"
        label="phone"
        rules={[
          { validator: PhoneService.getPhoneValidator() },
        ]}
      >
        <PhoneInput />
      </FormItem>
      <FormItem
        info={hook.getInfo('language')}
      >
        <LanguageSelectComponent mode="multiple" />
      </FormItem>
      <div className="relative">
        <FormItem
          info={hook.getInfo('credentials')}
        >
          <CredentialsSelectComponent />
        </FormItem>
        <CredentialsTooltipComponent />
      </div>
      <FormItem
        info={hook.getInfo('npi')}
      >
        <Input />
      </FormItem>
      <FormItem
        info={hook.getInfo('description')}
      >
        <Input placeholder="Add any additional notes of this user here" />
      </FormItem>
      <Divider />
      <div className="flex jc-sb">
        <Title level={5}>User role</Title>
        {
          isEditing && !!hasPatientsCheck?.length && (
            <Button
              type="link"
              onClick={() => userRolesRestrictionModal('tip', hasPatientsCheck)}
            >
              Can't Modify Certain Roles or Care Units?
            </Button>
          )
        }
      </div>
      <FormItem shouldUpdate>
        {({ getFieldValue }) => {
          const show = hook.isClinicalStaff(getFieldValue);
          return show ? (
            <FormItem
              info={hook.getInfo('roles')}
              required
            >
              <EmployeeRolesSelectComponent
                removeRoleValidationList={rolesWithPatientsAssigned || []}
                restrictedMedOrgIds={restrictedMedOrgIds}
                orgIdsWithPt={orgIdsWithPt}
                // form={form}
                // currentEmployeeId={initialValues?.currentEmployeeId}
              />
            </FormItem>
          ) : (
            <FormItem
              info={hook.getInfo('ihealthRoles')}
            >
              <InternalEmployeeRoleTreeComponent
                initialValues={initialValues}
                hasPatientsCheck={hasPatientsCheck || []}
              />
            </FormItem>
          );
        }}
      </FormItem>
      <Divider />
      <Title level={5}>Is this an observer user?</Title>
      <FormItem
        info={hook.getInfo('isObserverUser')}
        noStyle
        valuePropName="checked"
      >
        <Checkbox className="b5">Mark as an observer user</Checkbox>
      </FormItem>
      <div className="my10 text-gray-scale-2">
        An observer user's activities on care portal will not contribute to billing,
        and will not influence data analysis.
      </div>
    </div>
  );
};

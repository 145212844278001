import { useEffect, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import {
  Sort$Direction,
  PatientSearchFilter,
  usePatientPatientSearchList,
} from '../../../uc-api-sdk';

export const usePatientSearchPage = (value?: string | null) => {
  const patients = usePatientPatientSearchList({});
  const [localSearchValue, setLocalSearchValue] = useState<string>('');
  const { userId, isProvider } = useLoggedInUserFromContext();

  const handleSearch = (
    value = localSearchValue,
    selectedClinicIds: string[] | undefined = undefined,
    isCreateOtherEventsInCalender = false,
  ) => {
    if (value) {
      const filter: PatientSearchFilter = {
        patient: {
          $or: {
            keyValues: {
              nameNear: value,
              firstNameNear: value,
              lastNameNear: value,
              birthdayNear: value,
              phoneNumberNear: value,
              identificationNear: value,
              ...(isCreateOtherEventsInCalender && isProvider ? { doctorId: userId } : {})
            },
          },
          clinicIdsIn: selectedClinicIds ? { in: selectedClinicIds } : undefined
        },
      };

      if (!isProvider) {
        filter.needToFilter = false;
      }

      patients.send({
        params: {
          searchRequest: {
            filter,
            pageInfo: {
              page: 1,
              size: 20,
              sort: [
                {
                  direction: Sort$Direction.ASC,
                  property: 'profile.firstName',
                },
              ],
              pagination: true,
            },
          },
        },
      });
    }
    setLocalSearchValue(value);
  };

  const getInitialPatient = (v: string) => {
    if (v) {
      const filter: PatientSearchFilter = {
        patient: {
          id: v,
        },
      };

      if (!isProvider) {
        filter.needToFilter = false;
      }

      patients.send({
        params: {
          searchRequest: {
            filter,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (value) {
      getInitialPatient(value);
    }
  }, []);

  return { handleSearch, patients };
};

import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { Employee } from '../../../../../uc-api-sdk';
import { EmployeeSelectComponentInfo } from '../../../../Employee/component/EmployeeSelectComponent/EmployeeSelectComponent';
import { createColumns } from './SelectedStaffTableColumn';

export interface SelectedStaffTableComponentProps extends Omit<TableProps<EmployeeSelectComponentInfo<Employee>>, 'column'> {
  removeStaff: (id: string) => void;
  onCheckboxChange: (v: CheckboxValueType[]) => void
}

export const SelectedStaffTableComponent = ({
  removeStaff,
  onCheckboxChange,
  ...props
}: SelectedStaffTableComponentProps) => {
  const columns = useTableColumns(
    createColumns,
    ['name', { name: 'role', args: [onCheckboxChange] }, { name: 'action', args: [removeStaff] }],
  );

  return (
    <Table
      className="SelectedStaffTableComponent"
      rowKey={(v) => v.id}
      columns={columns}
          // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      pagination={false}
    />
  );
};

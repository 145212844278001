import { FC } from 'react';
import { useSymptomLogDelete } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import CancelDeleteButtonsComponent from '../../../../uiComponent/CancelDeleteButtonComponent/CancelDeleteButtonComponent';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface SymptomDeleteComponentProps {
  id?: string | undefined;
}

export const SymptomDeleteComponent:
  FC<SymptomDeleteComponentProps> = ({ id }) => {
    const deleteSymptomHook = useSymptomLogDelete({});
    const refetchPatientSymptom = useUpdate('SYMPTOM_UPDATED');
    const { send } = useMixpanelContext();

    const handleDelete = async () => {
      if (id) {
        const res = deleteSymptomHook.send({
          params: {
            id,
          },
        });
        await ApiRequestHelper.tryCatch(res, {
          success: 'Symptom deleted!',
          error: 'Failed to delete Symptom.',
          onSuccess: () => {
            refetchPatientSymptom.updateValue();
            send({ event: MixpanelEvents.DeleteSymptom });
          },
        });
      }
    };

    return (
      <ModalButton
        button="Delete"
        buttonType="text"
        closable={false}
        maskStyle={{ backgroundColor: 'transparent' }}
        width={416}
      >
        {({ close }) => (
          <>
            <div className="fs2 my20 b7">
              Are you sure you want to delete this symptom?
            </div>
            <CancelDeleteButtonsComponent
              onDelete={handleDelete}
              onCancel={close}
              cancelBtnText="No"
              deleteBtnText="Yes"
            />
          </>
        )}
      </ModalButton>
    );
  };

import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button, Form, FormProps, Radio, Space
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { BillingGenerationEnumComponent } from '../../../../enumComponent/BillingGenerationEnumComponent/BillingGenerationEnumComponent';
import { InvoiceCycleEnumComponent } from '../../../../enumComponent/InvoiceCycleEnumComponent/InvoiceCycleEnumComponent';
import {
  BillingFeeModelEnum, BillingGenerationEnum,
  InvoiceCycleEnum, PerCodeFeeModel, ProgramCategoryEnum, UnitFeeModel
} from '../../../../uc-api-sdk';
import { AlertComponent } from '../../../../uiComponent/AlertComponent/AlertComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { REVERSED_BILLING_CODE_DEPENDENCIES } from '../../constants/billingCodes';
import { MedicalOrgConsentFormContainer } from '../../container/MedicalOrgConsentFormContainer/MedicalOrgConsentFormContainer';
import { useMedicalOrgProgramParticipationForm } from '../../hook/useMedicalOrgProgramParticipationForm';
import { BillingCodeCheckboxValue } from '../../medicalOrg/component/BillingCodeInputComponent/BillingCodeInputComponent';
import { CcmRpmConfigurationComponent } from '../CcmRpmConfigurationComponent/CcmRpmConfigurationComponent';
import './MedicalOrgProgramParticipationFormComponent.scss';

export interface ProgramFormValues {
  programType?: 'ccmRpm' | 'valueBased';
  programs?: ProgramCategoryEnum[];
  consentFormId1?: string;
  consentFormId2?: string;
  clinicId?: string;
  feeModel?: BillingFeeModelEnum,
  codeSettings?: Record<string, BillingCodeCheckboxValue>;
  perCode?: PerCodeFeeModel;
  pmapm?: UnitFeeModel;
  pepm?: UnitFeeModel;
  pbpm?: UnitFeeModel;
  billingCodes?: string[];
  isSaasModel: boolean;
  invoiceCycle?: InvoiceCycleEnum;
  isReportRequiredForInsurance?: boolean;
  billingGeneration?: BillingGenerationEnum;
}

export interface MedicalOrgMedicalOrgProgramParticipationFormComponentProps extends FormProps {
  onSubmit: (v: ProgramFormValues) => void;
}

export const MedicalOrgProgramParticipationFormComponent = ({
  onSubmit,
  ...props
}: MedicalOrgMedicalOrgProgramParticipationFormComponentProps) => {
  const hook = useMedicalOrgProgramParticipationForm();
  const { form } = hook;
  const onValuesChange = (changedValues: ProgramFormValues, allValues: ProgramFormValues) => {
    // reset initialvalue for programs if ccmRpm is checked
    if (changedValues.programType === 'ccmRpm' && !allValues.programs) {
      form.setFieldsValue({
        programs: props?.initialValues?.programs
          ?? [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM]
      });
    }

    if (changedValues.programType === 'valueBased') {
      form.setFieldValue('isReportRequiredForInsurance', false);
    }

    // update billingcodes based on their dependencies
    if (changedValues.codeSettings) {
      const code = String(Object.keys(changedValues.codeSettings)[0]);
      const depCode = REVERSED_BILLING_CODE_DEPENDENCIES[code];
      if (depCode
        && !changedValues.codeSettings[code].checked) {
        form?.setFieldsValue({
          codeSettings: {
            [depCode]: {
              checked: false,
              code: undefined,
              capUnits: undefined,
            },
          },
        });
      } else if (changedValues.codeSettings[code].checked === false) {
        form?.setFieldsValue(
          {
            codeSettings: {
              [code]: {
                checked: false,
                code,
                capUnits: undefined,
              },
            },
          }
        );
      }
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classNames('h100 w100', props.className)}
      size="middle"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onFinish={hook.handleSubmit(onSubmit)}
      onValuesChange={onValuesChange}
    >
      <FixedComponent className="w100">
        <FixedComponent.Child>
          <div className="flex fd-c gap4 medicalOrgProgramParticipationFormFields mb90">
            <div>
              <Title level={5}>Is this a Saas model clinic?</Title>
              <FormItem
                name={hook.getName('isSaasModel')}
                required
                noStyle
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </FormItem>
            </div>
            <div>
              <Title level={5}>Please select this organization's participating program(s)</Title>
              <FormItem
                name={hook.getName('programType')}
                label={null}
                className="w100"
                required
              >
                <Radio.Group className="w100">
                  <Space direction="vertical" className="w100">
                    <Radio value="ccmRpm">CCM/RPM</Radio>
                    <FormItem
                      dependencies={['programType']}
                    >
                      {({ getFieldValue }) => {
                        const show = hook.isCcmRpm(getFieldValue);
                        return show && (
                          <CcmRpmConfigurationComponent />
                        );
                      }}
                    </FormItem>
                    <FormItem shouldUpdate={hook.shouldUpdate(['isSaasModel', 'programType'])}>
                      {({ getFieldValue }) => {
                        const show = !getFieldValue('isSaasModel');
                        return show && (
                          <Radio
                            value="valueBased"
                          >
                            Value-based Care
                          </Radio>
                        );
                      }}
                    </FormItem>
                  </Space>
                </Radio.Group>
              </FormItem>
            </div>
            <FormItem shouldUpdate={hook.shouldUpdate(['programType', 'isSaasModel'])}>
              {({ getFieldValue }) => {
                const show = !getFieldValue('isSaasModel') && !hook.isCcmRpm(getFieldValue);
                return show && (
                  <FormItem
                    name={hook.getName('consentFormId2')}
                    label={null}
                    required
                  >
                    <MedicalOrgConsentFormContainer
                      programs={[ProgramCategoryEnum.VALUE_BASED]}
                    />
                  </FormItem>
                );
              }}
            </FormItem>
            <div>
              <Title level={5} className="m0i">{hook.getLabel('invoiceCycle')}</Title>
              <p>How often does the organization wish iHealth accounting to send invoice? </p>
              <FormItem
                name={hook.getName('invoiceCycle')}
                label={null}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={InvoiceCycleEnum.QUARTERLY}>
                      <InvoiceCycleEnumComponent value={InvoiceCycleEnum.QUARTERLY} />
                    </Radio>
                    <Radio value={InvoiceCycleEnum.MONTHLY}>
                      <InvoiceCycleEnumComponent value={InvoiceCycleEnum.MONTHLY} />
                    </Radio>
                  </Space>
                </Radio.Group>
              </FormItem>
            </div>
            <div>
              <Title level={5}>{hook.getLabel('isReportRequiredForInsurance')}</Title>
              <FormItem shouldUpdate={hook.shouldUpdate(['programType'])}>
                {({ getFieldValue }) => {
                  const disabled = getFieldValue('programType') === 'valueBased';
                  return (
                    <FormItem
                      name={hook.getName('isReportRequiredForInsurance')}
                      label={null}
                    >
                      <YesNoRadioComponent disabled={disabled} />
                    </FormItem>
                  );
                }}
              </FormItem>
            </div>
            <div className="mb90">
              <FormItem shouldUpdate={hook.shouldUpdate(['isReportRequiredForInsurance'])}>
                {({ getFieldValue }) => {
                  const show = getFieldValue('isReportRequiredForInsurance');
                  return show && (
                    <div>
                      <Title level={5} className="m0i">{hook.getLabel('billingGeneration')}</Title>
                      <p>How does this organization prefer billing documents to be generated?</p>
                      <FormItem
                        name={hook.getName('billingGeneration')}
                        label={null}
                      >
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value={BillingGenerationEnum.SINGLE}>
                              <BillingGenerationEnumComponent
                                value={BillingGenerationEnum.SINGLE}
                              />
                            </Radio>
                            <Radio value={BillingGenerationEnum.SPLIT}>
                              <BillingGenerationEnumComponent value={BillingGenerationEnum.SPLIT} />
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  );
                }}
              </FormItem>
              <FormItem shouldUpdate={hook.shouldUpdate(['billingGeneration', 'programType', 'isReportRequiredForInsurance'])} className="px20">
                {({ getFieldValue }) => {
                  const show = getFieldValue('billingGeneration') === BillingGenerationEnum.SPLIT
                    && hook.isCcmRpm(getFieldValue) && getFieldValue('isReportRequiredForInsurance');
                  return show && (
                    <AlertComponent
                      icon={<InfoCircleFilled />}
                      message={(
                        <div>
                          Please make sure that providers who
                          {' '}
                          <strong>do not qualify to bill independently</strong>
                          ,
                          like NPs and PAs have a designated supervising
                          provider selected in their profile
                          so they can bill jointly with their supervisor.
                        </div>
                      )}
                    />
                  );
                }}
              </FormItem>
            </div>
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <Button htmlType="submit" type="primary" className="w100 b5">Save</Button>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};

import { Modal } from 'antd';
import { RemoveRoleValidationResponse } from '../../../../uc-api-sdk';
import { CheckRemoveUserValidationModalComponent } from '../CheckRemoveUserValidationModalComponent/CheckRemoveUserValidationModalComponent';

export const userRolesRestrictionModal = (
  type: 'tip' | 'warning',
  validationModalData?: RemoveRoleValidationResponse[],
) => {
  Modal.info({
    width: type === 'tip' ? 600 : 680,
    icon: null,
    title: type === 'tip'
      ? "Can't Modify Certain Roles or Care Units?"
      : 'Unable to remove this user',
    content: <CheckRemoveUserValidationModalComponent data={validationModalData} />,
    okText: 'Got it',
    okType: 'default',
  });
};

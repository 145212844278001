import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';

export interface LabelWithTooltipComponentProps {
  label: ReactNode;
  icon?: ReactNode;
  message?: ReactNode;
}

export const LabelWithTooltipComponent = ({
  label,
  icon,
  message,
}: LabelWithTooltipComponentProps) => (
  <div className="label-with-tooltip-component flex gap2 ai-c jc-c">
    <div className="label-with-tooltip-component__label">
      {label}
    </div>
    <Tooltip title={message}>
      {icon ?? <QuestionCircleOutlined />}
    </Tooltip>
  </div>
);

import { AlertApiContextProvider } from '../../../../AlertRuleEngine/context/AlertApiContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { AlertEngineMedicalOrgCardComponent } from '../AlertEngineMedicalOrgCardComponent/AlertEngineMedicalOrgCardComponent';

export interface AlertEngineMedicalOrgComponentProps {
}

export const AlertEngineMedicalOrgComponent = () => {
  const { medicalOrgService } = useMedicalOrgContext();

  if (!medicalOrgService.id) return null;

  return (
    <AlertApiContextProvider clinicId={medicalOrgService.id}>
      <AlertEngineMedicalOrgCardComponent />
    </AlertApiContextProvider>
  );
};

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Popover,
  Spin
} from 'antd';
import { REWRITE_WITH_AI_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import './MessageAIDraftComponent.scss';
import { MessageAIDraftInstructionComponent } from '../MessageAIDraftInstructionComponent/MessageAIDraftInstructionComponent';

const { Panel } = Collapse;

export interface MessageAIDraftComponentProps {}

export const MessageAIDraftComponent = () => {
  const {
    collapseAIChatDraft,
    isLoadingDraftFromAI,
    draftFromAI,
    handleInsertToTypeBoxAIChatDraft,
    handleCollapseAIChatDraft,
  } = useMessagePatientContext();

  const onChange = (key: string | string[]) => {
    if (Array.isArray(key)) {
      handleCollapseAIChatDraft(key);
    }
  };

  return (
    <Collapse
      defaultActiveKey={[]}
      activeKey={collapseAIChatDraft}
      onChange={onChange}
      expandIconPosition="right"
      collapsible="icon"
    >
      <Panel
        header={(
          <div className="flex gap2">
            <Icon info={REWRITE_WITH_AI_ICON} />
            <div className="ai-title">AI Powered Reply</div>
            <Popover
              placement="left"
              title="How is this reply generated?"
              trigger="click"
              content={
                <MessageAIDraftInstructionComponent instruction={draftFromAI?.instruction ?? ''} />
              }
            >
              <InfoCircleOutlined className="ai-info" />
            </Popover>
          </div>
        )}
        key="1"
      >
        <div className="message-ai-draft-component">
          {isLoadingDraftFromAI ? <Spin /> : draftFromAI?.response}
        </div>
        <Button type="primary" ghost className="message-ai-draft-btn" onClick={handleInsertToTypeBoxAIChatDraft}>
          Insert to type box
        </Button>
      </Panel>
    </Collapse>
  );
};

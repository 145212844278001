import '../MedicalOrgConfigurationComponent/MedicalOrgConfigurationComponent.scss';
import { MedicalOrgEHRComponent } from '../MedicalOrgEHRComponent/MedicalOrgEHRComponent';
import { MedicalOrgOutstandingItemsConfigComponent } from '../MedicalOrgOutstandingItemsConfigComponent/MedicalOrgOutstandingItemsConfigComponent';
import { MedicalOrgServiceComponent } from '../MedicalOrgServiceComponent/MedicalOrgServiceComponent';

export const MedicalOrgServiceConfigurationComponent = () => (
  <div className="medical-org-configuration">
    <MedicalOrgServiceComponent />
    <MedicalOrgEHRComponent />
    <MedicalOrgOutstandingItemsConfigComponent />
  </div>
);

import { BgMealTypeEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../Input/types';
import { MealTypeEnumComponent } from '../../enumComponent/MealTypeEnumComponent/MealTypeEnumComponent';

export interface BGMealTypeSelectComponentProps extends InputType<BgMealTypeEnum> {
  options: BgMealTypeEnum[];
}

export const BGMealTypeSelectComponent = ({
  options,
  value,
  onChange,
}: BGMealTypeSelectComponentProps) => (
  <EnumSelectComponent
    className="w100"
    options={options}
    // @ts-ignore
    optionRenderer={MealTypeEnumComponent}
    // @ts-ignore
    onChange={onChange}
    // @ts-ignore
    value={value}
  />
);

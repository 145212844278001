import { Drawer, FormProps } from 'antd';
import { MediumLargeDrawerWidth } from '../../../../../constants/drawer';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { MedicalOrgEHRFormContainer } from '../../container/MedicalOrgEHRFormContainer/MedicalOrgEHRFormContainer';

export interface MedicalOrgEHRFormDrawerComponentProps {
  onSuccess: () => void;
  initialValues: FormProps['initialValues'];
}

export const MedicalOrgEHRFormDrawerComponent = ({
  onSuccess,
  initialValues
}: MedicalOrgEHRFormDrawerComponentProps) => {
  const { isOpen, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditMedicalOrgEHR);

  return (
    <Drawer
      destroyOnClose
      title="EHR Related"
      open={isOpen}
      onClose={close}
      width={MediumLargeDrawerWidth}
    >
      <MedicalOrgEHRFormContainer onSuccess={onSuccess} initialValues={initialValues} />
    </Drawer>
  );
};

import { Drawer } from 'antd';
import { Moment } from 'moment';
import { ContentDetail } from '../../../../uc-api-sdk';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../adminPortal2/layout/context/AdminPortalContext/AdminPortalContext';
import { CreateMobileVersionConfigFormComponent } from '../CreateMobileVersionConfigFormComponent/CreateMobileVersionConfigFormComponent';

export interface CreateEditMobileVersionConfigFormValue {
  id?: string;
  name?: string;
  versionDetails?: string[];
  medOrgIds?: string[]
  activateDate?: Moment | string;
  contents?: ContentDetail[];
  note?: string;
}

export interface CreateEditMobileVersionConfigDrawerComponentProps {
  isLoading?: boolean;
  onSubmit?: (v: CreateEditMobileVersionConfigFormValue) => void;
}

// this is Create drawer component.
export const CreateEditMobileVersionConfigDrawerComponent = ({
  isLoading,
  onSubmit,
}: CreateEditMobileVersionConfigDrawerComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, close } = getDrawer(PopupDrawerTypes.CreateMobileConfig);

  const handleOnSubmit = (v: CreateEditMobileVersionConfigFormValue) => {
    onSubmit?.(v);
    close();
  };

  return (
    <Drawer
      destroyOnClose
      width={643}
      open={isOpen}
      onClose={close}
      title="Create Mobile Version Configuration"
    >
      <CreateMobileVersionConfigFormComponent
        isLoading={isLoading}
        onSubmit={handleOnSubmit}
      />
    </Drawer>
  );
};

import { Button, Checkbox, Form } from 'antd';
import { useMemo, useState } from 'react';

import {
  keyBy,
  map,
  omit,
  uniqueId
} from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PatientSymptom, SymptomLog, SymptomEnum } from '../../../../uc-api-sdk';
import FormSubmitButton from '../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import { SymptomSubmitValue, useCreateSymptomForm } from '../../hook/useCreateSymptomForm';
import { SeverityAndNoteSelectionComponent } from '../SeverityAndNoteSelectionComponent/SeverityAndNoteSelectionComponent';
import { SymptomNameEnumComponent } from '../SymptomTableComponent/SymptomNameEnumComponent';

export interface SymptomFormValue {
  symptoms?: PatientSymptom[] | [];
}

export interface FormButtonsProps {
  onCancel?: () => void;
}

export interface SymptomFormComponentProps {
  initialValues?: SymptomLog | undefined;
  isLoading: boolean;
  onSubmit?: (values: SymptomSubmitValue) => void | undefined;
}

export const SymptomFormComponent = ({
  initialValues,
  isLoading,
  onSubmit,
}: SymptomFormComponentProps) => {
  const symptomFormInfo = useCreateSymptomForm();
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (checked: boolean, symptomName: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [symptomName]: checked,
    }));
  };

  const onFinishPreCheck = (value: SymptomSubmitValue) => {
    onSubmit?.(value);
  };

  const shouldDisableSaveBtn = (value: SymptomSubmitValue) => {
    const submitList = Object.entries(value.symptomList || {}).filter(([key, s]) => key && s.flag);
    return submitList.length === 0;
  };

  const parsedInitialValues = useMemo(() => {
    const { symptomList } = initialValues || {};
    const existingSymptoms = map(symptomList, (symptom) => ({
      ...symptom,
      flag: true,
    }));
    return keyBy(existingSymptoms, (v) => v.symptom || `Added_${uniqueId()}`);
  }, []);

  const onClickDelete = (key: string) => {
    const { getFieldValue, setFieldValue } = symptomFormInfo.form;
    const symptomList = getFieldValue(symptomFormInfo.getMainFormItemName());
    const newSymptoms = omit(symptomList, key);
    setFieldValue(
      symptomFormInfo.getMainFormItemName(),
      newSymptoms,
    );
  };

  return (
    <Form
      form={symptomFormInfo.form}
      onFinish={symptomFormInfo.handleSubmit(onFinishPreCheck)}
      scrollToFirstError
      disabled={isLoading}
      layout="vertical"
      initialValues={{ symptomList: parsedInitialValues }}
    >
      {/* Render enum symptoms */}
      <div className="bold mb32">Please select symptoms:</div>
      {map(SymptomEnum, (curSymptomEnum) => {
        if (curSymptomEnum === SymptomEnum.OTHER) {
          return null;
        }
        return (
          <>
            <FormItem
              key={curSymptomEnum}
              name={symptomFormInfo.makeObjectNamePath('flag', curSymptomEnum)}
              valuePropName="checked"
            >
              <Checkbox
                checked={!!checkedItems[curSymptomEnum]}
                onChange={
                  () => handleCheckboxChange(!!checkedItems[curSymptomEnum], curSymptomEnum)
                }
              >
                <SymptomNameEnumComponent value={curSymptomEnum} />
              </Checkbox>
            </FormItem>
            <FormItem noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const shouldShow = !!symptomFormInfo.getObjectValue('flag', getFieldValue, curSymptomEnum);
                if (!shouldShow) {
                  return null;
                }
                return (
                  <SeverityAndNoteSelectionComponent
                    curSymptomEnum={curSymptomEnum}
                  />
                );
              }}
            </FormItem>
          </>
        );
      })}

      {/* Render free input symptoms */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const symptomList = getFieldValue(symptomFormInfo.getMainFormItemName()) || {};
          const customListKeys = Object.keys(symptomList).filter((fKey) => fKey.startsWith('Added_'));
          return customListKeys.map((key) => (
            <div key={key} className="flex mt20">
              <div>
                <FormItem
                  name={symptomFormInfo.makeObjectNamePath('manualSymptom', key)}
                  required
                  label={<div className="fs13 text-darker-gray-2">Symptom</div>}
                  className="w400"
                >
                  <TextArea rows={1} placeholder="Type symptom here" />
                </FormItem>
                <SeverityAndNoteSelectionComponent curSymptomEnum={key as SymptomEnum} />
                <FormItem
                  name={symptomFormInfo.makeObjectNamePath('flag', key)}
                  initialValue
                />
                {' '}
              </div>
              <DeleteOutlined
                className="ai-s ml10 mt32"
                onClick={() => onClickDelete(key)}
              />
            </div>
          ));
        }}
      </FormItem>

      <Button
        type="link"
        className="mt5 pl0"
        onClick={() => {
          const symptomList = symptomFormInfo.form.getFieldValue(
            symptomFormInfo.getMainFormItemName()
          ) || {};
          const newKey = `Added_${Date.now()}`;
          symptomFormInfo.form.setFieldsValue({
            [symptomFormInfo.getMainFormItemName()]: {
              ...symptomList,
              [newKey]: {
                manualSymptom: '', severity: '', note: '', flag: true
              },
            },
          });
        }}
      >
        Add another symptom
      </Button>

      <FormSubmitButton
        text="Save"
        rightAlign
        shouldBeDisabled={shouldDisableSaveBtn}
        isLoading={isLoading}
      />
    </Form>
  );
};

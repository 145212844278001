import { useCallback } from 'react';
import { startCase } from 'lodash';
import { BasicEnumComponent } from '../../../../enumComponent/BasicEnumComponent/BasicEnumComponent';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../Input/types';
import { useConditionsContext } from '../../context/ConditionContext';
import { AlertCondition } from '../../type';

export interface ConditionSelectValue {
  value: string;
  condition: AlertCondition;
}

export interface ConditionSelectComponentProps extends InputType<ConditionSelectValue> {
}

export const ConditionSelectComponent = ({
  onChange,
  value,
}: ConditionSelectComponentProps) => {
  const { conditionsService } = useConditionsContext();

  const handleChange = useCallback<Exclude<EnumSelectComponentProps<string>['onChange'], undefined>>((conditionName) => {
    onChange?.(conditionName && conditionsService
      ? {
        value: conditionName,
        condition: conditionsService.getCondition(conditionName),
      }
      : undefined);
  }, [conditionsService, onChange]);

  return (
    <EnumSelectComponent
      options={conditionsService?.options || []}
      optionRenderer={BasicEnumComponent}
      onChange={handleChange}
      value={value?.value}
      placeholder="Select Condition"
      className="w100"
      showSearch
      filterOption={(input, option) => (
        (startCase(option?.value?.toString() || '').toLocaleLowerCase() ?? '')
          .includes(input.toLocaleLowerCase())
      )}
    />
  );
};

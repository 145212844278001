import { Drawer } from 'antd';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../layout/context/AdminPortalContext/AdminPortalContext';
import { MedicalOrgProgramParticipationFormComponent, ProgramFormValues } from '../MedicalOrgProgramParticipationFormComponent/MedicalOrgProgramParticipationFormComponent';

export interface MedicalOrgProgramParticipationFormComponentProps {
  onSubmit: (v: ProgramFormValues) => void;
  initialValues?: ProgramFormValues;
}

export const MedicalOrgProgramParticipationDrawerComponent = ({
  onSubmit,
  initialValues,
}: MedicalOrgProgramParticipationFormComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, close } = getDrawer(PopupDrawerTypes.EditMedicalOrgPrograms);

  return (
    <Drawer
      destroyOnClose
      width={643}
      open={isOpen}
      onClose={close}
      title="Program Participation, Fee models, and Policy Agreement"
    >
      <MedicalOrgProgramParticipationFormComponent
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </Drawer>
  );
};

import { Modal } from 'antd';
import { CustomAlertCreateRequest } from '../../../../uc-api-sdk';
import { AlertCreateEditContainer } from '../../container/AlertCreateEditContainer/AlertCreateEditContainer';
import { useAlertApiContext } from '../../context/AlertApiContext';
import { Alert } from '../../type';

export interface AlertCreateEditModalComponentProps {
  alert?: Alert;
  isOpen: boolean;
  onCancel: () => void;
}

export const AlertCreateEditModalComponent = ({
  alert,
  isOpen,
  onCancel,
}: AlertCreateEditModalComponentProps) => {
  const {
    onAddCustomAlert,
    onEditAlert,
    isAddCustomAlertLoading,
    isEditAlertLoading,
  } = useAlertApiContext();

  const handleSubmit = async (values: Alert) => {
    if (alert?.alertType) {
      await onEditAlert?.(values);
    } else {
      await onAddCustomAlert?.(values as CustomAlertCreateRequest);
    }
    onCancel();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={750}
      destroyOnClose
    >
      <AlertCreateEditContainer
        alert={alert}
        onCancel={onCancel}
        isLoading={isEditAlertLoading || isAddCustomAlertLoading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export enum DefaultExtendableDrawers {
  addMedication = 'addMedication',
  editMedication = 'editMedication',
  viewMedication = 'viewMedication',
  addLabResult = 'addLabResult',
  editLabResult = 'editLabResult',
  viewLabResult = 'viewLabResult',
  addCareNote = 'addCareNote',
  addCareNoteHistory = 'addCareNoteHistory',
  editCareNote = 'editCareNote',
  addPatient = 'addPatient',
  LLMLabResult = 'LLMLabResult',
  editSymptom = 'editSymptom'
}

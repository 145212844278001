import { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { createSymptomColumns, SymptomTableRowValue } from './columns';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { SymptomLog } from '../../../../uc-api-sdk';
import { SymptomDrawerComponent } from '../SymptomDrawerComponent/SymptomDrawerComponent';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { Filter, Sorter } from '../../../../hooks/useTableChange/useTableChange';
import './SymptomTableComponent.scss';
import ExpandIcon from '../../../../uiComponent/ExpandIconComponent/ExpandIconComponent';

export interface SymptomTableComponentProps extends
  Omit<TableProps<SymptomLog>, 'column' > {
  isLoading?: boolean;
  filters: Filter<string>;
  sorters: Sorter<SymptomTableRowValue>;
}

export const SymptomTableComponent: FC<SymptomTableComponentProps> = ({
  dataSource,
  isLoading,
  onChange,
  pagination,
  filters,
  sorters,
  ...restProps
}) => {
  const { patientId } = restProps as SymptomLog;

  const dataProcessor = (data?: readonly SymptomLog[]) => {
    const res: SymptomTableRowValue[] = [];
    if (!data) return res;

    for (let i = 0; i < data.length; i += 1) {
      const { symptomList } = data[i];
      if (symptomList) {
        const symptom: SymptomTableRowValue = {
          ...data[i],
          originalValue: data[i],
          hasMultiple: (symptomList?.length || 0) > 1,
          total: symptomList?.length || 0,
          symptomList: [symptomList[0]],
          expanded: false,
          isNested: false,
          children: undefined,
        };

        if (symptomList.length > 1) {
          const children: SymptomTableRowValue[] = [];
          for (let j = 1; j < (data[i].symptomList?.length || 0); j += 1) {
            children.push({
              ...data[i],
              id: `${data[i].id}-${j}`,
              hasMultiple: false,
              total: 0,
              symptomList: [symptomList[j]],
              expanded: false,
              isNested: true,
            });
          }
          symptom.children = children;
        }
        res.push(symptom);
      }
    }
    return res;
  };

  const [flattenData, setFlattenData] = useState(dataProcessor(dataSource));

  useEffect(() => {
    setFlattenData(dataProcessor(dataSource));
  }, [dataSource]);

  const columns = useTableColumns(
    createSymptomColumns,
    ['action', 'reportDate', 'reporter', 'symptoms', 'severity', 'note', 'edit'],
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters,
  });

  return (
    <div className="SymptomTableComponent">
      <div className="flex jc-e">
        <SymptomDrawerComponent patientId={patientId || undefined} />
      </div>
      <Table
        className="mb0"
        dataSource={flattenData}
        loading={isLoading}
        size="middle"
        columns={columnsWithFilters}
        pagination={pagination}
        onChange={onChange}
        rowKey={(rec) => rec.id || `row-${Math.random()}`}
        expandable={{ expandIcon: ExpandIcon }}
      />
    </div>
  );
};

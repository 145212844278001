import { Button, Drawer } from 'antd';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { EmployeeResponseClass, RemoveRoleValidationResponse } from '../../../../uc-api-sdk';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../layout/context/AdminPortalContext/AdminPortalContext';
import { CreateEditEmployeeFormComponent } from '../../users/component/CreateEditEmployeeFormComponent/CreateEditEmployeeFormComponent';
import { CreateUserFormValues } from '../../users/type';
import { CreateUserSuccessComponent } from '../CreateUserSuccessComponent/CreateUserSuccessComponent';

export interface CreateClinicUserDrawerComponentProps {
  initialValues?: CreateUserFormValues;
  mode?: 'create' | 'edit';
  value?: EmployeeResponseClass;
  submitted?: boolean;
  onSubmit: (values: CreateUserFormValues) => void;
  showButton?: boolean;
  onReset?: () => void;
  hasPatientsCheck?: RemoveRoleValidationResponse[];
  orgIdsWithPt?: string[];
}

export interface ValidationHookProps {
  fieldName: string;
  fieldValue: string;
  fieldLabel: string;
  setValid?: () => void;
  setInvalid?: () => void;
}

export const CreateClinicUserDrawerComponent = ({
  initialValues,
  mode = 'create',
  value,
  submitted,
  onSubmit,
  showButton = true,
  onReset,
  hasPatientsCheck,
  orgIdsWithPt
}: CreateClinicUserDrawerComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, open, close } = getDrawer(PopupDrawerTypes.CreateUser);
  const resetForm = useUpdate('RESET_EMPLOYEE_FORM');
  const handleReset = () => {
    onReset?.();
    resetForm.updateValue();
  };

  const handleSubmit = (values: CreateUserFormValues) => {
    onSubmit(values);
    if (mode === 'edit') {
      onReset?.();
      close();
    }
  };

  const handleClose = () => {
    onReset?.();
    close();
  };

  return (
    <>
      {showButton && (
        <Button type="primary" onClick={() => open()}>
          {mode === 'create' ? 'Create User' : 'Edit User'}
        </Button>
      )}
      <Drawer
        destroyOnClose
        onClose={handleClose}
        open={isOpen}
        title={`${mode === 'create' ? 'Create' : 'Edit'} User`}
        size="large"
      >
        {submitted && mode === 'create' ? (
          <CreateUserSuccessComponent
            data={value}
            onReset={handleReset}
            onClose={handleClose}
          />
        )
          : (
            <CreateEditEmployeeFormComponent
              onSubmit={handleSubmit}
              mode={mode}
              initialValues={initialValues}
              hasPatientsCheck={hasPatientsCheck}
              orgIdsWithPt={orgIdsWithPt}
            />
          )}
      </Drawer>
    </>
  );
};

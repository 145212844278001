import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import {
  usePatientUpdateHealthCondition,
  usePatientUpdatePatientComplexity,
  usePatientUpdateEnrollment,
  PatientDiagnosesEnum,
  HealthConditionItem, IcdCodeConfigRequest,
  useIcdCodeConfigClassifyByIcdCode,
  ProgramCategoryEnum,
  VitalEnumType,
  EnrollmentActionEnum
} from '../../../../uc-api-sdk';
import { ICDCodeSubmitValue } from '../../../ICDCode/hook/useICDCodeForm';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { DiagnosisIcdCodeFormComponent, DiagnosisIcdCodeFormComponentProps } from '../../../ICDCode/component/DiagnosisIcdCodeFormComponent/DiagnosisIcdCodeFormComponent';
import { ComplexityEditComponentProps } from '../../../complexity/component/ComplexityEditComponent/ComplexityEditComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';

export interface DiagnosisIcdCodeFormContainerProps
  extends Omit<ComplexityEditComponentProps, 'onUpdate'>,
  Omit<DiagnosisIcdCodeFormComponentProps, 'onSubmit' | 'onValuesChange'>,
  NestedFormComponentChildProps {
  form?: FormOptions['form'];
  patientId: string;
  visitId?: string;
  programCategory?: ProgramCategoryEnum[];
  vitals?: VitalEnumType[];
  refetchSync?: boolean; // for clinical goal and disease history
}

export const DiagnosisIcdCodeFormContainer = ({
  form,
  patientId,
  visitId,
  initialValues,
  programCategory,
  vitals,
  onSubmit,
  onValuesChange,
  onError,
  onCancel,
  refetchSync = true,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />
}: DiagnosisIcdCodeFormContainerProps) => {
  const healthConditionUpdateInfo = usePatientUpdateHealthCondition({});
  const patientComplexityUpdateInfo = usePatientUpdatePatientComplexity({});
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});

  const update = useUpdate('ICD_UPDATED');

  const handleSubmit = async (values: ICDCodeSubmitValue) => {
    // update health condition
    const hcReq = healthConditionUpdateInfo.send({
      params: {
        memberId: patientId,
        request: {
          healthConditions: values.icdTable,
          visitId,
        },
      },
    });

    const hcRes = await ApiRequestHelper.tryCatch(hcReq, 'Failed to update icd code');
    if (!hcRes) {
      onError?.();
      return;
    }

    // successfully update icd code
    // refetch click goals/behavior goals/disease history
    if (refetchSync) {
      update.updateValue();
    }

    // update complexity
    const pcReq = patientComplexityUpdateInfo.send({
      params: {
        memberId: patientId,
        request: {
          level: values?.complexity?.level,
          source: values?.complexity?.source,
        }
      },
    });
    const pcRes = await ApiRequestHelper.tryCatch(pcReq, 'Failed to update complexity');
    if (!pcRes) {
      onError?.();
      return;
    }

    if (!form) { // diagnoses will be saved by parent's form
      // update diagnosis
      const dxReq = programEnrollmentUpdate.send({
        params: {
          memberId: patientId,
          request: {
            action: EnrollmentActionEnum.ENROLL,
            programEnrollment: {
              patientDiagnoses: values.diagnoses,
              programCategories: programCategory,
              vitalTypes: vitals,
            },
          },
        },
      });

      const dxRes = await ApiRequestHelper.tryCatch(dxReq, 'Failed to update diagnoses');
      if (!dxRes) {
        onError?.();
        return;
      }
    }
    onSubmit?.(values);
  };

  const icdCodeConfigClassifyReq = (
    updateHealthConditions: HealthConditionItem[]
  ): IcdCodeConfigRequest => {
    const icdCodes = updateHealthConditions
      ?.map(condition => condition?.code)
      .filter((code): code is string => code !== undefined) || [];
    return { icdCodes };
  };

  const diagnosesAndCodeClassifyInfo = useIcdCodeConfigClassifyByIcdCode({
    options: { sendOnMount: !!initialValues?.icdTable?.length },
    params: {
      request: icdCodeConfigClassifyReq(initialValues?.icdTable || []),
    }
  });

  const fetchDiagnosis = (icdCodes: string[]) => (
    diagnosesAndCodeClassifyInfo.send({
      params: {
        request: { icdCodes },
      }
    })
  );

  const targetDx = diagnosesAndCodeClassifyInfo.data?.data?.diagnoses?.filter(
    i => i !== PatientDiagnosesEnum.OTHERS
  ) || [];

  return (
    <ErrorBoundaryComponent identifier="DiagnosisIcdCodeFormContainer">
      <DiagnosisIcdCodeFormComponent
        form={form}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        getDiagnosisBasedOnIcd={fetchDiagnosis}
        targetDx={targetDx}
        formButtons={formButtons}
      />
    </ErrorBoundaryComponent>
  );
};

import {
  InputNumber, InputNumberProps, Radio, RadioGroupProps
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './PerCodeFeeModelButtonComponent.scss';

export interface PerCodeFeeModelButtonComponentProps {
  options: number[];
  other?: boolean;
  value?: number;
  onChange?: (v: number) => void;
  title: React.ReactNode;
}

export const PerCodeFeeModelButtonComponent = ({
  options,
  other = true,
  value,
  onChange,
  title,
}: PerCodeFeeModelButtonComponentProps) => {
  const [v, setV] = useState<number | undefined>(Number(value));
  const [customValue, setCustomValue] = useState<number | undefined>(
    options.includes(v as number) ? undefined : v
  );

  const handleCustomValueChange: InputNumberProps['onChange'] = (k) => {
    setCustomValue(k as number);
    setV(k as number);
    onChange?.(k as number);
  };

  const handleChange: RadioGroupProps['onChange'] = (e) => {
    setV(e.target.value);
    setCustomValue(undefined);
    onChange?.(e.target.value);
  };

  useEffect(() => {
    if (!value) {
      onChange?.(v as number);
    }
  }, []);

  return (
    <>
      <div className="text-gray-scale-2 mb5 require-mark">{title}</div>
      <Radio.Group
        buttonStyle="solid"
        value={v}
        className="mb20 PerCodeFeeModelButtonComponent flex gap2"
        onChange={handleChange}
      >
        {options.map((option) => (
          <Radio.Button
            key={option}
            value={option}
            checked={v === option}
          >
            {`$${option}`}
          </Radio.Button>
        ))}
        {other && (
          <InputNumber
            className={classNames('customAmountInput', customValue !== undefined && 'active')}
            placeholder="Other amount"
            prefix="$"
            min={1}
            precision={2}
            value={customValue}
            onChange={handleCustomValueChange}
          />
        )}
      </Radio.Group>
    </>
  );
};

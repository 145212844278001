import { DischargeReasonEnum } from '../../uc-api-sdk';

export interface DischargeReasonEnumComponentProps {
  value?: DischargeReasonEnum | null;
}

export const DischargeReasonEnumComponent = ({
  value,
}: DischargeReasonEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case DischargeReasonEnum.BEHAVIOR_POSES_RISK_TO_CT:
        return 'Behavior poses risk to CT (specify details)';
      case DischargeReasonEnum.DECEASED:
        return 'Patient deceased';
      case DischargeReasonEnum.DEVICE_ISSUES:
        return 'Device Issues (accuracy/discrepancy...)';
      case DischargeReasonEnum.DEVICE_UNDERUSED:
        return 'Device underuse and request for discharge';
      case DischargeReasonEnum.TECHNICAL_CHALLENGES_TO_USE:
        return 'Technical challenges to use (smartphone/internet...)';
      case DischargeReasonEnum.PROGRAM_OVERWHELMING:
        return 'Patient overwhelmed (too many calls/messages/visits...)';
      case DischargeReasonEnum.SWITCH_TO_ANOTHER_DEVICE:
        return 'Device change (specify new device brand/model if possible)';
      case DischargeReasonEnum.PCP_CHANGE:
        return 'Patient no longer sees this doctor/ Patients moved/ PCP change';
      case DischargeReasonEnum.INSURANCE_RELATED_ISSUES:
        return 'Insurance issues (lost ins./specify new ins. if possible)';
      case DischargeReasonEnum.REFUSE_TO_MONITOR_VITALS:
        return 'Refusal to monitor vitals';
      case DischargeReasonEnum.PATIENT_HEALTH_STABLE:
        return "Patient's condition is stable";
      case DischargeReasonEnum.OTHERS:
        return 'Others (specify details)';
      default:
        return null;
    }
  };

  return (<span>{getText()}</span>);
};

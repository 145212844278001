import { Space, DatePicker, Button } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Moment } from 'moment';
import { useState } from 'react';
import { PatientSearchSelectContainer } from '../../../patient/container/PatientSearchSelectContainer/PatientSearchSelectContainer';
import { Measurement, MeasurementResultTypeEnum, useMeasurementResultList } from '../../../../uc-api-sdk';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface AlertFetchMeasurementContainerProps {
  type: MeasurementResultTypeEnum;
  onChange: (v: Measurement[]) => void;
}

export const AlertFetchMeasurementContainer = ({
  type,
  onChange,
}: AlertFetchMeasurementContainerProps) => {
  const [patientId, setPatientId] = useState<string>();
  const [dateRange, setDateRange] = useState<[Moment, Moment] | undefined>();

  const getMeasurements = useMeasurementResultList({
    options: { sendOnMount: !!patientId && !!dateRange?.[0] && !!dateRange?.[1] },
    params: {
      searchRequest: {
        filter: {
          memberId: patientId,
          dateRange: {
            gte: dateRange?.[0].toISOString(),
            lte: dateRange?.[1].toISOString(),
          },
          typeList: [type],
        },
        pageInfo: {
          pagination: false,
        },
      }
    },
  });

  const handleClick = () => {
    if (getMeasurements.isLoading) {
      return;
    }
    onChange(getMeasurements.data?.data?.results?.content || []);
  };

  const handleDateChange: RangePickerProps['onChange'] = (dates) => {
    if (dates && !!dates[0] && !!dates[1]) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  return (
    <Space direction="vertical">
      <PatientSearchSelectContainer value={patientId} onChange={setPatientId} />
      <DatePicker.RangePicker
        format={USA_DATE}
        value={dateRange}
        onChange={handleDateChange}
      />
      <Button
        onClick={handleClick}
        disabled={
          getMeasurements.isLoading
          || !patientId
          || !dateRange?.[0]
          || !dateRange?.[1]
        }
        type="primary"
      >
        Populate Table
      </Button>
    </Space>
  );
};

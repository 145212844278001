import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { RoleTypeEnumComponent } from '../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { EmployeeResponseClass, RoleTypeEnum } from '../../../../uc-api-sdk';
import './CreateUserSuccessComponent.scss';

export interface CreateUserSuccessComponentProps {
  data?: EmployeeResponseClass;
  onReset?: () => void;
  onClose?: () => void;
}

export const CreateUserSuccessComponent = ({
  data,
  onReset,
  onClose
}: CreateUserSuccessComponentProps) => {
  const { employee, roles } = data || {};
  const roleMap: { [key: string]: string[] } = {};
  roles?.forEach((role) => {
    const type = role.roleType as string;
    if (type in roleMap) {
      roleMap[type].push(role.miniOrganization?.name || '');
    } else {
      roleMap[type] = [role.miniOrganization?.name || ''];
    }
  });
  return (
    <div className="ta-c flex fd-c ai-c jc-c gap2 CreateUserSuccessComponent relative h100">
      <CheckCircleFilled className="success" />
      <Title level={2}>User account Created</Title>
      <p>
        An invitation email has been sent to the user's email address:
        {' '}
        <span className="bold">{employee?.email}</span>
      </p>

      <div className="py30 px40 ta-l ai-s bg-gray5 default-br">
        <Row gutter={20}>
          <Col>
            Name
          </Col>
          <Col className="bold">
            {`${employee?.employeeProfile?.firstName} ${employee?.employeeProfile?.lastName}`}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col>
            Roles
          </Col>
          <Col>
            {Object.keys(roleMap).map((t) => (
              <div>
                <Row gutter={5}>
                  <Col className="bold">
                    <RoleTypeEnumComponent value={t as RoleTypeEnum} />
                    :
                  </Col>
                  <Col>
                    {roleMap[t].map((r) => (
                      <div key={r}>{r}</div>))}
                  </Col>

                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </div>
      <div className="flex fd-c buttonsGroup w100">
        <Button type="primary" className="w100 b5" onClick={onClose}>Done</Button>
        <Button type="link" onClick={onReset} className="w100 b5">Create Another User</Button>
      </div>
    </div>
  );
};

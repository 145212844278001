import { FormInstance, Input } from 'antd';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { LabelWithTooltipComponent } from '../../../../../uiComponent/LabelWithTooltipComponent/LabelWithTooltipComponent';
import { YesNoRadioComponent } from '../../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { useMedicalOrgEHRForm } from '../../../hook/useMedicalOrgEHRForm';
import { AthenaDepartmentsContainer } from '../../container/AthenaDepartmentsContainer/AthenaDepartmentsContainer';

export interface EhrAthenaAdvancedConfigContainerProps {
  form: FormInstance;
}

export const EhrAthenaAdvancedConfigContainer = ({
  form,
}: EhrAthenaAdvancedConfigContainerProps) => {
  const hook = useMedicalOrgEHRForm({ form });

  return (
    <div className="flex fd-c gap2 p20 bg-gray5 secondary-br">
      <FormItem
        info={hook.getInfo('athenaPracticeId')}
        required
      >
        <Input />
      </FormItem>
      <FormItem dependencies={['athenaPracticeId']} noStyle>
        {({ getFieldValue }) => {
          const practiceId = getFieldValue('athenaPracticeId');
          return (
            <FormItem
              name={hook.getName('departments')}
              required
            >
              <AthenaDepartmentsContainer athenaPracticeId={practiceId || ''} />
            </FormItem>
          );
        }}
      </FormItem>
      <FormItem dependencies={['athenaPracticeId', 'departments']} noStyle>
        {({ getFieldValue }) => {
          const departmentsList = getFieldValue('departments');
          const disabled = !departmentsList || departmentsList?.length === 0;
          return (
            <FormItem
              name={hook.getName('autoClaimSubmit')}
              label={(
                <LabelWithTooltipComponent
                  label={hook.getLabel('autoClaimSubmit')}
                  message="If a patient qualifies for CCM/RPM CPT code reimbursement,
                  the system will automatically submit the claim to the Athena system.
                  For more details on CPT code generation, service date determination,
                   billing providers, and claimed amounts, please contact your iHealth Account Manager."
                />
              )}
            >
              <YesNoRadioComponent disabled={disabled} />
            </FormItem>
          );
        }}
      </FormItem>
    </div>
  );
};

import { Drawer } from 'antd';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../layout/context/AdminPortalContext/AdminPortalContext';
import { MedicalOrgServiceFormComponent, ServiceFormValues } from '../MedicalOrgServiceFormComponent/MedicalOrgServiceFormComponent';

export interface MedicalOrgServiceDrawerComponentProps {
  initialValues?: ServiceFormValues;
  onSubmit?: (values: ServiceFormValues) => void;
}

export const MedicalOrgServiceDrawerComponent = ({
  initialValues,
  onSubmit,
}: MedicalOrgServiceDrawerComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, close } = getDrawer(PopupDrawerTypes.EditMedicalOrgService);

  return (
    <Drawer
      destroyOnClose
      width={643}
      open={isOpen}
      onClose={close}
      title="Add Service"
    >
      <MedicalOrgServiceFormComponent
        initialValues={initialValues}
        onSubmit={onSubmit}
        orgId={initialValues?.orgId || ''}
      />
    </Drawer>
  );
};

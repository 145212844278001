import { BillingGenerationEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface BillingGenerationEnumComponentProps {
  value?: BillingGenerationEnum;
}

export const BillingGenerationEnumComponent = ({ value }: BillingGenerationEnumComponentProps) => {
  const renderValue = (value?: BillingGenerationEnum) => {
    switch (value) {
      case BillingGenerationEnum.SINGLE: return 'Single copy for the whole organization';
      case BillingGenerationEnum.SPLIT: return 'Split copy of each provider';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };
  return <div>{renderValue(value)}</div>;
};

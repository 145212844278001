import { UploadProps } from 'antd';

import { FileInfo } from '../../../../../uc-api-sdk';
import { MedicalOrgCareQualityStep1Component } from '../MedicalOrgCareQualityStep1Component/MedicalOrgCareQualityStep1Component';
import { MedicalOrgCareQualityStep2Component, MedicalOrgCareQualityStep2ComponentProps } from '../MedicalOrgCareQualityStep2Component/MedicalOrgCareQualityStep2Component';

export interface MedicalOrgCareQualitySettingsComponentProps
  extends MedicalOrgCareQualityStep2ComponentProps {
  file?: string;
  fileName?: string;
  fileInfo?: FileInfo;
  action?: UploadProps['action'];
}

export const MedicalOrgCareQualitySettingsComponent = ({
  file,
  fileName,
  fileInfo,
  action,
  defaultMedicalOrg,
  setDefaultMedicalOrg
}: MedicalOrgCareQualitySettingsComponentProps) => (
  <div className="flex gap2 fd-c">
    <MedicalOrgCareQualityStep1Component
      file={file}
      fileName={fileName}
      fileInfo={fileInfo}
      action={action}
    />
    <MedicalOrgCareQualityStep2Component
      defaultMedicalOrg={defaultMedicalOrg}
      setDefaultMedicalOrg={setDefaultMedicalOrg}
    />
  </div>
);

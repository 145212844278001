import {
  isNaN, isString,
  map
} from 'lodash';
import { sanitizeFilename } from '../file';

export const makeCSVData = (data: string[][]) => (
  map(data, (row) => {
    const columns = map(row, (column) => `"${column}"`);
    return columns.join(',');
  }).join('\n')
);

export const csv = (data: string, filename: string) => {
  let sanitizedFilename = filename;
  if (filename.endsWith('.csv')) {
    // remove extension
    sanitizedFilename = filename.slice(0, -4);
  }
  sanitizedFilename = sanitizeFilename(sanitizedFilename, '');
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', sanitizedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// make sure string number parsed as text in csv
export const parseStringNumber = (value?: string | number | undefined | null) => {
  if (!value) return '';
  return `="${String(value)}"`;
};

// Common Format and MIME Type for Comma-Separated Values (CSV) Files
// Eg. to have values foo and bar,baz, you do foo,"bar,baz"
export const parseCSVValue = (
  value?: string | number | undefined | null,
) => {
  if (isString(value) && !isNaN(Number(value[0]))) {
    return parseStringNumber(value);
  }
  return `"${value}"`;
};

import './MessageAIDraftInstructionComponent.scss';

export interface MessageAIDraftInstructionComponentProps {
  instruction: string
}

export const MessageAIDraftInstructionComponent = ({
  instruction
}: MessageAIDraftInstructionComponentProps) => {
  const intro = 'Suggested replies are generated by AI based on a summary of the patient’s data and the recent chat history. Since suggested replies are generated by AI, it is possible that they may contain false information. If you are unsure about the accuracy of anything in the suggested reply, you can look at this info box to see exactly what information the AI was given before it generated the suggested reply.';
  const sub = '\n\nReply suggestions are generated after the patient has sent at least 1 text-based message. If you would like AutoDraft to suggest replies to other types of messages (e.g. food logs, vitals readings, etc.), please let the Care Portal team know.';
  const heading = '\nPrompt given to AI to generate the suggested reply:\n';
  return (
    <div className="ai_instruction">
      <div className="m10">
        {intro}
        {sub}
        <p>{instruction ? heading : ''}</p>
      </div>
      {instruction ? (
        <pre>
          {instruction}
        </pre>
      ) : null}

    </div>
  );
};

import { useMemo } from 'react';
import { CustomizedAlertsFormComponent, CustomizedAlertsFormComponentProps } from '../../../adminPortal2/medicalOrg/component/CustomizedAlertsFormComponent/CustomizedAlertsFormComponent';
import { Alert } from '../../type';

export interface AlertCreateEditContainerProps {
  alert?: Alert;
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit?: (alert: Alert) => void;
}

export const AlertCreateEditContainer = ({
  alert,
  isLoading,
  onCancel,
  onSubmit,
}: AlertCreateEditContainerProps) => {
  const mode = alert?.alertType ? 'Edit' : 'Create';

  const initialValues = useMemo(() => (alert ? {
    name: alert.displayName ?? undefined,
    description: alert.description ?? undefined,
    muteLogic: alert.silencedDuration
      ? parseInt(alert.silencedDuration.match(/(\d+)/)?.[0] || '0', 10)
      : undefined,
    muteLogicScale: alert.silencedDuration?.includes('h') ? 'Hours' : 'Day',
    priority: alert.priority ?? undefined,
    type: alert.eventType ?? undefined,
    category: alert.alertCategory ?? undefined,
  } : {}), [alert]);

  const handleSubmit: CustomizedAlertsFormComponentProps['onSubmit'] = (values) => {
    onSubmit?.({
      alertType: mode === 'Edit' ? alert?.alertType : values.name,
      displayName: values.name,
      description: values.description,
      priority: values.priority,
      eventType: values.type,
      alertCategory: values.category,
      silencedDuration: values.muteLogic && values.muteLogicScale
        ? values.muteLogic.toString() + (
          values.muteLogicScale === 'Hours' ? 'h' : 'd'
        )
        : null,
    });
  };

  return (
    <CustomizedAlertsFormComponent
      mode={mode}
      initialValues={initialValues}
      isLoading={isLoading}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

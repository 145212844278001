import { useCallback } from 'react';
import { VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { useWorklistActions } from '../../hook/useWorklistActions';
import { WorklistComponentCommonProps } from '../../types';
import { VisitSummaryInputComponent } from '../VisitSummaryInputComponent/VisitSummaryInputComponent';
import { WorklistButtonComponent } from '../WorklistButtonComponent/WorklistButtonComponent';
import { WorklistPatientInfoComponent } from '../WorklistPatientInfoComponent/WorklistPatientInfoComponent';
import { WorklistVisitInfoComponent } from '../WorklistVisitInfoComponent/WorklistVisitInfoComponent';
import { TechWorklistGuideComponent } from '../TechWorklistGuideComponent/TechWorklistGuideComponent';
import { WorklistDevicesComponent } from '../WorklistDevicesComponent/WorklistDevicesComponent';

export interface CommonWorklistTemplateComponentProps extends WorklistComponentCommonProps {}

export const TechWorklistComponent = ({
  patientId,
  clinicEvent,
  onClinicEventUpdate,
  onClose,
  onCheckOut,
  onCompleteCharting,
  isLoading
}: CommonWorklistTemplateComponentProps) => {
  const {
    getVisitSummary,
    isVisitSummaryChanged,
    setVisitSummary,
    validateVisitSummary,
    errors,
  } = useWorklistActions(clinicEvent);

  const getClinicEventValues = useCallback(() => (
    { visitSummary: getVisitSummary() }
  ), [getVisitSummary]);

  const handleCheckOut = () => {
    onCheckOut?.(getClinicEventValues());
  };
  const handleCompleteCharting = () => {
    if (!validateVisitSummary()) return;
    onCompleteCharting?.(getClinicEventValues());
  };

  const handleClose = () => {
    if (isVisitSummaryChanged) {
      onClinicEventUpdate?.(getClinicEventValues());
    }
    onClose?.();
  };

  return (
    <div className="flex fr-reverse">
      <FixedComponent className="worklist-navigation">
        <FixedComponent.Child isFixed>
          <WorklistVisitInfoComponent
            visitType={clinicEvent.visitType as VisitTypeEnum}
            visitMethod={clinicEvent.visitMethod as VisitMethodEnum}
            description={clinicEvent.description || ''}
            startTime={clinicEvent.checkInTime || ''}
            endTime={clinicEvent.checkOutTime || ''}
            onClose={handleClose}
          />
        </FixedComponent.Child>
        <FixedComponent.Child className="worklist-drawer-content">
          <WorklistPatientInfoComponent />
          <VisitSummaryInputComponent
            defaultValue={getVisitSummary() ?? undefined}
            onChange={setVisitSummary}
            showRequiredMark={errors.VISIT_SUMMARY}
          />
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <WorklistButtonComponent
            isLoading={isLoading}
            clinicEvent={clinicEvent}
            onCheckout={handleCheckOut}
            onCompleteCharting={handleCompleteCharting}
            onClose={handleClose}
          />
        </FixedComponent.Child>
      </FixedComponent>
      <div className="worklist-section">
        <TechWorklistGuideComponent patientId={patientId} />
        <WorklistDevicesComponent patientId={patientId} isTech />
      </div>
    </div>
  );
};

import React, { FC, useEffect } from 'react';
import { Select } from 'antd';
import { map } from 'lodash';
import { SelectType } from '../../features/Input/types';
import { TimezoneType } from '../../types/timezone';
import TimezoneComponent from '../../uiComponent/TimezoneComponent/TimezoneComponent';
import { STATE_TIMEZONE_MAP } from '../../constants/timezone';

export interface TimezoneSelectComponentProps extends SelectType<TimezoneType> {
  state?: string,
}

export const TimezoneSelectComponent: FC<TimezoneSelectComponentProps> = ({
  value,
  onChange,
  state,
}) => {
  const handleChange = (v: string) => {
    onChange?.(v as TimezoneType);
  };

  useEffect(() => {
    if (state === undefined) return;
    const timezone = STATE_TIMEZONE_MAP[state as keyof typeof STATE_TIMEZONE_MAP];
    if (timezone) {
      onChange?.(timezone as TimezoneType);
    }
  }, [state]);

  return (
    <div className="timezone-select-component" data-testid="timezone-select-component">
      <Select
        className="w100"
        value={value}
        onChange={handleChange}
        showArrow
      >
        {map(TimezoneType, (v) => (
          <Select.Option key={v} value={v}>
            <TimezoneComponent value={v} />
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default TimezoneSelectComponent;

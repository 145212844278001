import { Divider, Form, UploadProps } from 'antd';
import Title from 'antd/lib/typography/Title';
import CancelSubmitButtonsComponent from '../../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import { FormItem } from '../../../../../uiComponent/FormItem/FormItem';
import { LabelWithTooltipComponent } from '../../../../../uiComponent/LabelWithTooltipComponent/LabelWithTooltipComponent';
import { YesNoRadioComponent } from '../../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { FormType } from '../../../../Input/types';
import { MedicalOrgEHRFormSubmitValue, useMedicalOrgEHRForm } from '../../../hook/useMedicalOrgEHRForm';
import { CareQualityConsentDocHistoryComponent } from '../CareQualityConsentDocHistoryComponent/CareQualityConsentDocHistoryComponent';
import { EhrAthenaAdvancedConfigContainer } from '../EhrAthenaAdvancedConfigContainer/EhrAthenaAdvancedConfigContainer';
import { MedicalOrgCareQualitySettingsComponent } from '../MedicalOrgCareQualitySettingsComponent/MedicalOrgCareQualitySettingsComponent';
import { MedicalOrgCareQualityStep2ComponentProps } from '../MedicalOrgCareQualityStep2Component/MedicalOrgCareQualityStep2Component';
import { MedicalOrgEHRFormSelectComponent } from '../MedicalOrgEHRFormSelectComponent/MedicalOrgEHRFormSelectComponent';
import './MedicalOrgEHRFormComponent.scss';

export interface MedicalOrgEHRFormComponentProps
  extends FormType<MedicalOrgEHRFormSubmitValue>, MedicalOrgCareQualityStep2ComponentProps {
  action: UploadProps['action'];
  file?: string;
  fileName?: string;
}

export const MedicalOrgEHRFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  action,
  file,
  fileName,
  defaultMedicalOrg,
  setDefaultMedicalOrg
}: MedicalOrgEHRFormComponentProps) => {
  const medicalOrgEHRForm = useMedicalOrgEHRForm();
  const { form } = medicalOrgEHRForm;

  const handleFormSubmit = (values: unknown) => {
    const submitValues = values;
    onSubmit?.(submitValues as MedicalOrgEHRFormSubmitValue);
  };

  const handleDisabled = () => {
    if (isLoading) {
      return true;
    }
    if (form.getFieldValue('careQuality') && ((!initialValues?.fileInfo && !file) || !defaultMedicalOrg)) {
      return true;
    }
    return false;
  };

  const onValuesChange = (changedValues: MedicalOrgEHRFormSubmitValue) => {
    if (changedValues.athenaPracticeId) {
      form.setFieldsValue({
        departments: [],
        autoClaimSubmit: false
      });
    } else if (changedValues.departments?.length === 0) {
      form.setFieldsValue({
        autoClaimSubmit: false
      });
    }

    if (changedValues.careQuality === false) {
      setDefaultMedicalOrg?.(undefined);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      className="medical-org-ehr-form-component h100"
      onFinish={handleFormSubmit}
      disabled={isLoading}
      layout="vertical"
      onValuesChange={onValuesChange}
      form={form}
    >
      <FixedComponent>
        <FixedComponent.Child>
          <div className="flex fd-c gap3 ai-s">
            <div className="w100">
              <Title level={5}>EHR Service Provider</Title>
              <FormItem
                info={medicalOrgEHRForm.getInfo('ehrData')}
              >
                <MedicalOrgEHRFormSelectComponent />
              </FormItem>
              <FormItem
                info={medicalOrgEHRForm.getInfo('ehrIntegrationAvailable')}
              >
                <YesNoRadioComponent />
              </FormItem>
              <FormItem dependencies={['ehrData', 'ehrIntegrationAvailable']} noStyle>
                {({ getFieldValue }) => {
                  const v = medicalOrgEHRForm.showEnableAdvancedConfigurationRadio(getFieldValue);
                  return v && (
                    <FormItem
                      name={medicalOrgEHRForm.getName('enableAdvancedConfiguration')}
                      label={(
                        <LabelWithTooltipComponent
                          label={medicalOrgEHRForm.getLabel('enableAdvancedConfiguration')}
                          message="You can add your organization's practice ID and department ID
                          to simplify patient referrals and enable automatic claim submission."
                        />
                      )}
                    >
                      <YesNoRadioComponent />
                    </FormItem>
                  );
                }}
              </FormItem>
              <FormItem dependencies={['ehrData', 'ehrIntegrationAvailable', 'enableAdvancedConfiguration']} noStyle>
                {({ getFieldValue }) => {
                  const v = medicalOrgEHRForm.shouldShowAdvancedConfiguration(getFieldValue);
                  return v && (
                    <FormItem>
                      <EhrAthenaAdvancedConfigContainer
                        form={form}
                      />
                    </FormItem>
                  );
                }}
              </FormItem>
            </div>
            <div className="w100">
              <div className="flex gap2 ai-c">
                <Title level={5}>
                  <LabelWithTooltipComponent
                    label="Care Quality"
                    message="Care Quality is a network that enables different healthcare systems to share patient data.
                    Once enabled, care portal users will be able to access patient's data from EHR through care portal."
                  />
                </Title>
                {(initialValues?.history?.length ?? 0) > 1
                  && (
                    <CareQualityConsentDocHistoryComponent
                      history={initialValues?.history || []}
                    />
                  )}
              </div>
              <FormItem
                info={medicalOrgEHRForm.getInfo('careQuality')}
              >
                <YesNoRadioComponent />
              </FormItem>
              <FormItem dependencies={['careQuality']}>
                {({ getFieldValue }) => {
                  const v = getFieldValue('careQuality');
                  return v && (
                    <MedicalOrgCareQualitySettingsComponent
                      file={file}
                      fileName={fileName}
                      fileInfo={initialValues?.fileInfo}
                      action={action}
                      defaultMedicalOrg={defaultMedicalOrg}
                      setDefaultMedicalOrg={setDefaultMedicalOrg}
                    />
                  );
                }}
              </FormItem>
            </div>
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <Divider />
          <CancelSubmitButtonsComponent
            submitText="Save"
            className="w100"
            shouldBeDisabled={handleDisabled}
          />

        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};

/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum CredentialType {
  MD = 'MD',
  DO = 'DO',
  PA = 'PA',
  NP = 'NP',
  RN = 'RN',
  CNA = 'CNA',
  LVN = 'LVN',
  LPN = 'LPN',
  PharmD = 'PharmD',
  RPh = 'RPh',
  MS = 'MS',
  MA = 'MA',
  PhD = 'PhD',
  MPH = 'MPH',
  DPH = 'DPH',
  RD = 'RD',
  CDCES = 'CDCES'
}

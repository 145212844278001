import { filter } from 'lodash';
import { EHRProviderEnumComponent } from '../../../../../enumComponent/EHRProviderEnumComponent/EHRProviderEnumComponent';
import { EhrServiceName } from '../../../../../uc-api-sdk';
import { EnumSelectComponent } from '../../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../../Input/types';

export interface MedicalOrgEHRFormSelectComponentProps extends
  InputType<EhrServiceName> {
}

export const MedicalOrgEHRFormSelectComponent = ({
  value,
  onChange,
  disabled,
}: MedicalOrgEHRFormSelectComponentProps) => (
  <EnumSelectComponent
    options={filter(EhrServiceName, (v) => v !== EhrServiceName.value)}
    optionRenderer={EHRProviderEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
    showSearch
  />
);

import { useState } from 'react';
import { sumBy } from 'lodash';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Alert } from '../../type';
import { AlertDefaultCardComponent } from '../AlertDefaultCardComponent/AlertDefaultCardComponent';
import { AlertCustomizedCardComponent } from '../AlertCustomizedCardComponent/AlertCustomizedCardComponent';
import { AlertCreateEditModalComponent } from '../AlertCreateEditModalComponent/AlertCreateEditModalComponent';
import { useAlertApiContext } from '../../context/AlertApiContext';

export interface AlertEngineComponentProps {
}

export const AlertEngineComponent = () => {
  const { alerts: value } = useAlertApiContext();
  const [selectedAlert, setSelectedAlert] = useState<Alert | undefined>();

  const renderHeader = (alertName: string, alert?: Alert[]) => (
    <div className="flex jc-sb">
      <h4>
        {alertName}
      </h4>
      <div>
        {sumBy(alert, (curr) => (curr?.active ? 1 : 0))}
        {' '}
        activated
      </div>
    </div>
  );

  const renderAlertCard = (alert: Alert, i: number) => (
    <AlertDefaultCardComponent key={i} alert={alert} />
  );

  const renderCustomizedAlertCard = (alert: Alert, i: number) => (
    <AlertCustomizedCardComponent key={i} alert={alert} />
  );

  const handleCreateAlert = () => {
    setSelectedAlert({});
  };

  const handleCloseEdit = () => {
    setSelectedAlert(undefined);
  };

  return (
    <div className="h100">
      <div>
        {renderHeader('Clinical Alerts', value?.clinicalAlerts)}
        <div className="p10">
          {value?.clinicalAlerts?.map(renderAlertCard)}
        </div>
      </div>
      <div className="pt60">
        {renderHeader('Provider Alerts', value?.providerAlerts)}
        <div className="p10">
          {value?.providerAlerts?.map(renderAlertCard)}
        </div>
      </div>
      <div className="pt60">
        {renderHeader('Customized Provider Alerts', value?.customizedProviderAlerts)}
        <div className="p10">
          {value?.customizedProviderAlerts?.map(renderCustomizedAlertCard)}
        </div>
      </div>
      <Button
        type="link"
        className="mb30"
        icon={<PlusOutlined />}
        onClick={handleCreateAlert}
      >
        Create
      </Button>
      <AlertCreateEditModalComponent
        alert={selectedAlert}
        isOpen={!!selectedAlert}
        onCancel={handleCloseEdit}
      />
    </div>
  );
};

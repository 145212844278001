import { Radio, RadioProps, Space } from 'antd';
import { MedicalOrganizationType } from '../../../../../uc-api-sdk';

export interface OrganizationTypeInputComponentProps extends RadioProps {
}

export const OrganizationTypeInputComponent = ({
  onChange,
  value,
  disabled
}: OrganizationTypeInputComponentProps) => (
  <Radio.Group onChange={onChange} value={value} disabled={disabled}>
    <Space direction="vertical">
      <Radio value={MedicalOrganizationType.CLINIC}>Clinic</Radio>
      <Radio value={MedicalOrganizationType.MEDICAL_GROUP}>Medical Group</Radio>
      {/* <Radio value="CUSTOM"> // not in p0
        Custom
        {' '}
        <Tooltip
          className="ml10"
          placement="top"
          title="Customized organizations refer to healthcare
            organizations or setups that do not fit neatly
            into the predefined categories of clinics or
            medical groups. It also can be used for
            internal initiatives or usage."
        >
          <InfoCircleOutlined />

        </Tooltip>
      </Radio> */}
    </Space>
  </Radio.Group>
);

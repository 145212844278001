import {
  Card,
  Space,
  Switch,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useBoolean } from 'usehooks-ts';
import { Alert } from '../../type';
import { LabelValueComponent } from '../../../../uiComponent/LabelValueComponent/LabelValueComponent';
import { DisplayOrEmptyComponent } from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { PriorityEnumComponent } from '../../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { Icons } from '../../../../icons/Icons';
import { AlertRuleComponent } from '../AlertRuleComponent/AlertRuleComponent';
import { handleDeleteAlertModal, handleDeleteRuleModal, handleDuplicateAlertModal } from '../../modal/confirmModals';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { AlertCreateEditModalComponent } from '../AlertCreateEditModalComponent/AlertCreateEditModalComponent';
import { useAlertApiContext } from '../../context/AlertApiContext';
import './AlertCustomizedCardComponent.scss';
import { EventTypeEnum } from '../../../../uc-api-sdk';

export interface AlertCustomizedCardComponentProps {
  alert: Alert;
}

export const AlertCustomizedCardComponent = ({
  alert,
}: AlertCustomizedCardComponentProps) => {
  const {
    onDeleteAlert,
    onDuplicateAlert,
    onDeleteRule,
    onActiveChange,
    isActiveChangeLoading,
  } = useAlertApiContext();
  const { value: isHovering, setTrue, setFalse } = useBoolean();
  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useOpen();

  const handleActiveChange = (value: boolean) => {
    onActiveChange(alert.alertType as string, value);
  };

  const handleDeleteAlert = () => {
    handleDeleteAlertModal({
      onDelete: () => onDeleteAlert(alert.alertType as string),
    });
  };

  const handleCopyAlert = () => {
    handleDuplicateAlertModal({
      onDuplicate: () => onDuplicateAlert(alert.alertType as string),
    });
  };

  const handleDeleteRule = (ruleId: string) => {
    handleDeleteRuleModal({
      onDelete: () => onDeleteRule?.(alert.alertType as string, ruleId),
    });
  };

  return (
    <Card className="alert-customized-card-component" onMouseEnter={setTrue} onMouseLeave={setFalse}>
      <div className="alert-customized-card-component__content">
        <div>
          <div className="alert-customized-card-component__info">
            <Space className="">
              <Switch
                disabled={isActiveChangeLoading}
                checked={!!alert.active}
                onChange={handleActiveChange}
              />
              <div className="alert-name">{alert.displayName}</div>
              <EditOutlined onClick={openEditModal} />
            </Space>
            <div className="alert-customized-card-component__key-value">
              <div>
                <LabelValueComponent
                  label="Description:"
                  labelClassName="alert-customized-card-component__label"
                  value={<DisplayOrEmptyComponent value={alert.description} />}
                />
              </div>
              <div>
                <LabelValueComponent
                  label="Priority:"
                  labelClassName="alert-customized-card-component__label"
                  value={<PriorityEnumComponent value={alert.priority} />}
                />
              </div>
              <div>
                <LabelValueComponent
                  label="Type:"
                  labelClassName="alert-customized-card-component__label"
                  value={<DisplayOrEmptyComponent value={alert.eventType} />}
                />
              </div>
              <div>
                <LabelValueComponent
                  label="Category:"
                  labelClassName="alert-customized-card-component__label"
                  value={<DisplayOrEmptyComponent value={alert.alertCategory} />}
                />
              </div>
              <div>
                <LabelValueComponent
                  label="Mute logic:"
                  labelClassName="alert-customized-card-component__label"
                  value={<DisplayOrEmptyComponent value={alert.silencedDuration} />}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="alert-customized-card-component__rules">
          <AlertRuleComponent
            alertId={alert.alertType as string}
            type={alert.eventType as EventTypeEnum}
            rules={alert.rules || []}
            onDelete={handleDeleteRule}
          />
        </div>
        {isHovering ? (
          <div className="alert-customized-card-component__options">
            <div className="flex fd-c gap4">
              <Icons.CopyIcon
                onClick={handleCopyAlert}
              />
              <Icons.TrashCanIcon
                hexColor="#DA6453"
                onClick={handleDeleteAlert}
              />
            </div>
          </div>
        ) : <div className="alert-customized-card-component__options" />}
      </div>
      <AlertCreateEditModalComponent
        alert={alert}
        isOpen={isEditModalOpen}
        onCancel={closeEditModal}
      />
    </Card>
  );
};

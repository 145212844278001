import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

export const CredentialsTooltipComponent = () => (
  <Tooltip
    className="credentialInputTooltip"
    title="The credentials will be displayed together after your name. eg, Jane Doe, RD, CDE in various places such as the Patient Mobile App and chat channels."
  >
    <InfoCircleOutlined />
  </Tooltip>
);

import Title from 'antd/lib/typography/Title';
import './ProgramsCommonWrapperComponent.scss';

export interface ProgramsCommonWrapperComponentProps {
  title: string;
  children: React.ReactNode;
}

export const ProgramsCommonWrapperComponent = ({ children, title }:
  ProgramsCommonWrapperComponentProps) => (
    <div className="ProgramsCommonWrapperComponent">
      <Title level={5}>{title}</Title>
      <div className="SelectWrapperContent">
        {children}
      </div>
    </div>
);

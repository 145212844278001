import { Drawer } from 'antd';
import { AlertEngineContainer } from '../../container/AlertEngineContainer/AlertEngineContainer';

export interface AlertEngineDrawerComponentProps {
  clinicId: string;
  isOpen?: boolean;
  onClose?: () => void;
}

export const AlertEngineDrawerComponent = ({
  clinicId,
  isOpen,
  onClose,
}: AlertEngineDrawerComponentProps) => (
  <Drawer
    open={isOpen}
    width={1000}
    title="Medical Alert Configuration"
    onClose={onClose}
  >
    <AlertEngineContainer clinicId={clinicId} />
  </Drawer>
);

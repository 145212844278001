import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { useMedicalOrganizationUpsertAssignment, useUcOrganizationGetAssignedPatientEmployeeList } from '../../../../../uc-api-sdk';
import { AssociatedCareUnitDrawerComponent } from '../../component/AssociatedCareUnitDrawerComponent/AssociatedCareUnitDrawerComponent';
import { AssociatedCareUnitFormValue } from '../../component/AssociatedCareUnitFormComponent/AssociatedCareUnitFormComponent';

export interface AssociatedCareUnitDrawerContainerProps {
  showButton?: boolean;
  initialValues?: AssociatedCareUnitFormValue;
  onSuccess?: () => void;
}

export const AssociatedCareUnitDrawerContainer = ({
  showButton = false,
  initialValues,
  onSuccess
}: AssociatedCareUnitDrawerContainerProps) => {
  const hook = useMedicalOrganizationUpsertAssignment({});
  const moveCareGroupWarningHook = useUcOrganizationGetAssignedPatientEmployeeList({
    params: {
      id: initialValues?.careGroupId || '',
      medOrgId: initialValues?.id || ''
    }
  });

  const onSubmit = async (v: AssociatedCareUnitFormValue) => {
    const result = hook.send({
      params: {
        t: {
          careGroupId: v.careGroupId,
          medicalOrgId: initialValues?.id || ''
        }
      }
    });
    await ApiRequestHelper.tryCatch(
      result,
      {
        success: 'Care United Associated Successfully',
        error: 'An error occurred while associating the care unit.',
        onSuccess
      }
    );
  };

  return (
    <div>
      <AssociatedCareUnitDrawerComponent
        showButton={showButton}
        onSubmit={onSubmit}
        initialValues={initialValues}
        moveCareGroupWarningPatientList={moveCareGroupWarningHook.data?.data || []}
      />
    </div>
  );
};

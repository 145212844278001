import { InfoCircleFilled } from '@ant-design/icons';
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { EhrClinic, useEhrOrganizationSearchDepartments } from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';

export interface AthenaDepartmentsContainerProps extends Omit<SelectProps, 'options'> {
  athenaPracticeId: string;
}

interface OptionType extends DefaultOptionType {
  item: EhrClinic;
}

export const AthenaDepartmentsContainer = ({
  athenaPracticeId,
  value,
  onChange,
  ...props
}: AthenaDepartmentsContainerProps) => {
  const departments = useEhrOrganizationSearchDepartments({
    options: {
      sendOnMount: false
    }
  });

  const [selectedDepartment, setSelectedDepartment] = useState<EhrClinic[]>([]);

  const searchDepartments = useCallback(
    useDebounce((practiceId) => {
      departments.send({
        params: {
          ehr: 'athena',
          ehrOrganizationId: practiceId
        }
      });
    }, 800, [departments]),
    []
  );

  useEffect(() => {
    if (athenaPracticeId) {
      searchDepartments(athenaPracticeId);
    }
  }, [athenaPracticeId]);

  const options = useMemo(() => departments.data?.data?.departments?.sort(
    (a: EhrClinic, b: EhrClinic) => a.businessName?.toLowerCase().localeCompare(b.businessName?.toLowerCase() || '') || 0
  ).map(
    (department: EhrClinic) => ({
      label: department.businessName,
      value: department.id,
      item: department
    }) || []
  ), [departments.data?.data?.departments]);

  const handleChange: SelectProps['onChange'] = (value, option) => {
    const selected = option.map((opt: OptionType) => opt.item);
    setSelectedDepartment(selected);
    onChange?.(selected, option);
  };

  useEffect(() => {
    if (departments.data?.data?.departments && value) {
      const selected = departments.data?.data?.departments.filter(dep => value.includes(dep.id));
      const selectedOptions = options?.filter((opt) => value.includes(opt.value));
      setSelectedDepartment(selected);
      onChange?.(selected, selectedOptions as DefaultOptionType[]);
    }
  }, [departments.data?.data?.departments]);

  return (
    <FetchComponent
      info={departments}
      showLoadingOverlay
      alwaysShowChildren
    >
      {(data, info) => {
        const disabled = !athenaPracticeId || !options || options?.length === 0;
        return (
          <>
            {(
              (
                options && options?.length === 0
                && !info.isLoading && data?.code === 200
              )
              || (data && data?.code !== 200))
              && (
                <div className="text-red">
                  <InfoCircleFilled />
                  {' '}
                  Cannot find any matching result for this ID.
                </div>
              )}
            <div className="required-label">
              Select Departments
            </div>
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="label"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              options={options}
              disabled={disabled}
              onChange={handleChange}
              value={selectedDepartment.map((department) => department.id)}
            />
          </>
        );
      }}
    </FetchComponent>
  );
};

import { useCallback } from 'react';
import { RoleTypeEnum } from '../../../uc-api-sdk';
import { roleCombinationLimit } from '../users/component/InternalEmployeeRoleTreeComponent/constant';

export const useRoleLimit = () => {
  const shouldDisableRole = useCallback((
    role: RoleTypeEnum,
    selectedRoles: RoleTypeEnum[],
  ) => {
    const roleLimit = [] as RoleTypeEnum[];
    selectedRoles?.forEach((v) => {
      const limit = roleCombinationLimit[v];
      if (limit) roleLimit.push(...limit);
    });
    return roleLimit.includes(role);
  }, []);

  return {
    shouldDisableRole,
  };
};

import { CloseOutlined } from '@ant-design/icons';
import { Button, FormInstance } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { EmployeeInputValidationComponent } from '../EmployeeInputValidationComponent/EmployeeInputValidationComponent';
import './UserIdInputFormItemComponent.scss';

export interface UserIdInputFormItemComponentProps {
  onClose: () => void;
  name: string;
  label: string;
  setIdInvalid: () => void;
  setIdValid: () => void;
  form: FormInstance,
  shouldValidate?: number;
  currentValue?: string;
}

export const UserIdInputFormItemComponent = ({
  onClose,
  name,
  label,
  setIdInvalid,
  setIdValid,
  form,
  shouldValidate,
  currentValue
}: UserIdInputFormItemComponentProps) => (
  <div className="UserIdInputFormItemComponent">
    <Button onClick={onClose} type="text" className="closeIcon">
      <CloseOutlined />
    </Button>
    <div className="mb20">If User ID is enabled, user will receive an email to set up password after account creation. User will be able to log in using either google account or user ID and password.</div>
    <FormItem
      name={name}
      label={label}
    >
      {/* <Input /> */}
      <EmployeeInputValidationComponent
        form={form}
        setValid={setIdValid}
        setInvalid={setIdInvalid}
        fieldName="loginId"
        fieldLabel="Login ID"
        shouldValidate={shouldValidate}
        currentValue={currentValue}
      />
    </FormItem>
  </div>
);

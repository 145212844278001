export interface BlueBackgroundComponentProps {
  children?: React.ReactNode;
}

export const BlueBackgroundComponent = ({
  children
}: BlueBackgroundComponentProps) => (
  <div className="w100 bg-gray5 default-br p20">
    {children}
  </div>
);

import { InfoCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import { EhrClinic } from '../../../../../uc-api-sdk';
import { CareQualityDefaultMedicalOrgComponent } from '../CareQualityDefaultMedicalOrgComponent/CareQualityDefaultMedicalOrgComponent';

const CareQualityMedicalOrgSearchDrawerContainer = React.lazy(() => import('../../container/CareQualityMedicalOrgSearchContainer/CareQualityMedicalOrgSearchContainer'));

export interface MedicalOrgCareQualityStep2ComponentProps {
  defaultMedicalOrg?: EhrClinic
  setDefaultMedicalOrg?: (defaultMedicalOrg?: EhrClinic) => void;
}

export const MedicalOrgCareQualityStep2Component = ({
  defaultMedicalOrg,
  setDefaultMedicalOrg
}: MedicalOrgCareQualityStep2ComponentProps) => {
  const { value: open, setTrue, setFalse } = useBoolean();
  const handleSubmit = (defaultMedicalOrg: EhrClinic) => {
    setDefaultMedicalOrg?.(defaultMedicalOrg);
    setFalse();
  };

  return (
    <div className="p20 bg-gray5 default-br text-gray-scale-2">
      <strong>
        Step 2. Set up the default medical organization in Care Quality
        {' '}
        <span className="text-red">*</span>
      </strong>
      <p>
        Due to different naming in UC and Care Quality, please manually search this medical
        organization in the Care Quality network. This will set it as the
        default for retrieving data for patients from this medical organization.
      </p>
      {!defaultMedicalOrg
        ? (
          <Button
            onClick={setTrue}
            type="link"
            className="primary-link p0 no-border"
          >
            Search and set default medical organization
          </Button>
        )
        : (
          <div className="white-card p10">
            <CareQualityDefaultMedicalOrgComponent
              defaultMedicalOrg={defaultMedicalOrg}
              onClick={setTrue}
            />
          </div>
        )}
      <React.Suspense fallback={<div>Loading...</div>}>
        <CareQualityMedicalOrgSearchDrawerContainer
          onSubmit={handleSubmit}
          open={open}
          onCancel={setFalse}
        />
      </React.Suspense>
      {!defaultMedicalOrg && (
        <div className="text-red">
          <InfoCircleFilled />
          {' '}
          This field is required.
        </div>
      )}
    </div>
  );
};

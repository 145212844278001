import { LoadingType } from '../../../../../types/common';
import { DrawerComponent, DrawerComponentProps } from '../../../../../uiComponent/Drawer/DrawerComponent';
import { AddStaffComponent, AddStaffComponentValue } from '../AddStaffComponent/AddStaffComponent';

export interface AddStaffDrawerComponentProps extends DrawerComponentProps, LoadingType {
  onSave?: (close: () => void, roles: AddStaffComponentValue) => void;
}

export const AddStaffDrawerComponent = ({
  onSave,
  buttonText = 'Add',
  buttonType = 'link',
  isLoading
}: AddStaffDrawerComponentProps) => (
  <DrawerComponent
    size="default"
    width="643px"
    title="Add Clinical Staff"
    buttonText={buttonText}
    buttonType={buttonType}
    destroyOnClose
  >
    {({ close }) => (
      <AddStaffComponent
        onClickAdd={(employees) => {
          onSave?.(close, employees);
        }}
        isLoading={isLoading}
      />
    )}
  </DrawerComponent>
);

import modal from 'antd/lib/modal';
import { useUpdate, useUpdateListener } from '../../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { useTableChange } from '../../../../../hooks/useTableChange/useTableChange';
import {
  Employee,
  MedicalOrganizationStaffFilter,
  RoleTypeEnum,
  SearchRequest,
  Sort$Direction,
  useMedicalOrganizationRemoveStaff,
  useMedicalOrganizationRemoveStaffCheck,
  useMedicalOrganizationSearchStaff
} from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';
import { userRolesRestrictionModal } from '../../../component/UserRolesRestrictionModal/UserRolesRestrictionModal';
import { StaffTableComponent } from '../../component/StaffTableComponent/StaffTableComponent';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';

export const defaultSorter = () => [
  { direction: Sort$Direction.ASC, property: 'employeeProfile.firstName' },
  { direction: Sort$Direction.ASC, property: 'employeeProfile.lastName' },
];

export interface StaffTableContainerProps {
}

export const StaffTableContainer = () => {
  const { medicalOrgService } = useMedicalOrgContext();
  const updateHook = useUpdate('STAFF_UPDATED');
  const removeRequest = useMedicalOrganizationRemoveStaff({});
  const hasPatientsCheckHook = useMedicalOrganizationRemoveStaffCheck({});

  const removeStaff = (id: string) => {
    ApiRequestHelper.tryCatch(removeRequest.send({
      params: {
        request: {
          medicalOrganizationId: medicalOrgService.id ?? '',
          staffId: id,
        },
      }
    }), {
      success: 'Remove staff successfully.',
      error: 'Failed to remove staff.',
      onSuccess: () => {
        updateHook.updateValue();
      }
    });
  };

  const tableChange = useTableChange<
      Employee,
      SearchRequest<MedicalOrganizationStaffFilter>['filter']
  >({
    sort: defaultSorter(),
    sortProcessor: () => defaultSorter(),
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<MedicalOrganizationStaffFilter>['filter'] = {};
      if (filters.role) {
        newFilters.roleIn = { in: filters.role as RoleTypeEnum[] };
      }
      return newFilters;
    },
  });

  const info = useMedicalOrganizationSearchStaff({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          medicalOrganizationId: medicalOrgService.id,
          ...tableChange.processedFilter,
        },
        pageInfo: tableChange.pageInfo,
      }
    },
  });

  const handleUnableToRemove = async (id: string) => {
    const res = await hasPatientsCheckHook.send({
      params: {
        request: {
          memberId: id,
          medicalOrgOrCareUnitId: medicalOrgService.id,
        }
      }
    });
    if (res?.code === 200) {
      if (res.data?.length) {
        userRolesRestrictionModal('tip', res.data);
      } else {
        modal.confirm({
          title: 'Are you sure to remove this staff from this medical organization? ',
          icon: null,
          okText: 'Yes',
          cancelText: 'Cancel',
          onOk: () => removeStaff(id),
        });
      }
    }
  };

  useUpdateListener('STAFF_UPDATED', info.refetch);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => (
        <StaffTableComponent
          dataSource={value?.rawValue?.data?.content}
          onChange={tableChange.handleTableChange}
          removeStaff={handleUnableToRemove}
          pagination={info.pagination}
          medOrgId={medicalOrgService.id as string}
        />
      )}
    </FetchComponent>
  );
};

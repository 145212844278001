import { ColumnType } from 'antd/lib/table';
import { map } from 'lodash';
import { RoleTypeEnumComponent } from '../../../../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';
import { AccountHelper } from '../../../../../helpers/account';
import { Employee, RoleTypeEnum } from '../../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { createEllipsisRender } from '../../../../table/column/ellipsis';
import { MiniUserComponent } from '../../../../user/component/MiniUserComponent/MiniUserComponent';
import { StaffTableActionsContainer } from '../../container/StaffTableActionsContainer/StaffTableActionsContainer';
import { StaffRoleListComponent } from '../StaffRoleListComponent/StaffRoleListComponent';

export const createColumns = {
  name: (): ColumnType<Employee> => ({
    title: 'Name',
    render: (value, d?: Employee) => (
      <MiniUserComponent
        id={d?.id || undefined}
        avatar={d?.employeeProfile?.avatar?.url || undefined}
        name={AccountHelper.getFullName(d?.employeeProfile)}
        email={d?.email || undefined}
        isObserver={d?.isObserverUser as boolean}
      />
    ),
    width: '30%',
  }),
  role: (currentOrgId?: string): ColumnType<Employee> => ({
    title: 'Role',
    render: (value, d?: Employee) => (
      <StaffRoleListComponent roles={d?.assignedRoles || []} orgId={currentOrgId} />
    ),
    filters: map([
      RoleTypeEnum.PROVIDER,
      RoleTypeEnum.MA,
      RoleTypeEnum.CLINICAL_MANAGER,
      RoleTypeEnum.CLINICAL_GROUP_BILLER,
    ], (value) => ({
      text: (<RoleTypeEnumComponent value={value} />),
      value,
    })),
    width: '10%',
  }),
  lastLoginAt: (): ColumnType<Employee> => ({
    title: 'Last login time',
    render: (value, d?: Employee) => (
      <DisplayDateComponent value={d?.lastLoginTime} format="USA_DATE" fallbackText={<DisplayOrEmptyComponent />} />
    ),
  }),
  description: (): ColumnType<Employee> => ({
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '30%',
    ...createEllipsisRender()
  }),
  action: (removeStaff?: (id: string) => void, medOrgId?: string):
  ColumnType<Employee> => ({
    render: (value, d?: Employee) => (
      <StaffTableActionsContainer
        medOrgId={medOrgId || ''}
        removeStaff={removeStaff}
        employee={d}
      />
    ),
  }),
};

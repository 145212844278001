import { includes, isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PortalPlatform } from '../../contexts/loggedInUserContext/types';
import { EhrPageParams } from '../../features/ehr/component/EhrRedirectComponent/EhrRedirectComponent';
import { EhrQuery } from '../../features/ehr/type';
import { resetErrorBoundaryManually } from '../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryCardComponent';
import { CalendarPageParams, PatientPageParams, PatientPageState } from './types';

export const carePortalUrl = '/care-portal';
export const adminPortalUrl = '/admin-portal';
export const billerPortalUrl = '/biller-portal';
export const internalToolUrl = '/internal-tool';

export type HomeUrl = typeof carePortalUrl
  | typeof adminPortalUrl
  | typeof billerPortalUrl
  | typeof internalToolUrl;

export enum IncompleteBillingSource {
  HomePage = 'home-page',
}

export const determinePortalPlatform = () => {
  const { pathname } = window.location;
  if (includes(pathname, carePortalUrl)) {
    return PortalPlatform.CarePortal;
  }
  if (includes(pathname, billerPortalUrl)) {
    return PortalPlatform.BillerPortal;
  }
  if (includes(pathname, adminPortalUrl)) {
    return PortalPlatform.AdminPortal;
  }
  return undefined;
};

export const useChangePage = () => {
  const navigateHook = useNavigate();

  const navigate: NavigateFunction = (
    ...args
  ) => {
    resetErrorBoundaryManually();
    navigateHook(...args as Parameters<NavigateFunction>);
  };

  const getPlatformUrl = (platform: PortalPlatform) => {
    switch (platform) {
      case PortalPlatform.AdminPortal:
        return adminPortalUrl;
      case PortalPlatform.CarePortal:
        return carePortalUrl;
      case PortalPlatform.BillerPortal:
        return billerPortalUrl;
      default:
        return carePortalUrl;
    }
  };

  const createUrlBasedOnPlatform = (url: string) => {
    const platform = determinePortalPlatform();
    const platformUrl = getPlatformUrl(platform as PortalPlatform);
    return `${platformUrl}${url}`;
  };

  const goToPlatform = useCallback((platform: PortalPlatform) => {
    navigate(getPlatformUrl(platform));
  }, [navigate]);

  const goToIndex = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const createLoggedInUserSettingsUrl = (
    portalUrl: string,
  ) => `${portalUrl}/settings`;

  const goToLoggedInUserSettings = useCallback((
    portalUrl: HomeUrl,
  ) => {
    navigate(createLoggedInUserSettingsUrl(portalUrl));
  }, [navigate]);

  //
  // --- NEW ADMIN PORTAL
  //
  const createAdminPortalUrl = (append = '') => (
    `${adminPortalUrl}${append}`.trim()
  );
  const createAdminPortalHomeUrl = () => createAdminPortalUrl('/home');
  const goToAdminPortalHome = useCallback(() => {
    navigate(createAdminPortalHomeUrl());
  }, [navigate]);

  const createAdminPortalCareOperationHierarchyUrl = () => createAdminPortalUrl('/care-operation-hierarchy');
  const goToAdminPortalCareOperationHierarchy = useCallback(() => {
    navigate(createAdminPortalCareOperationHierarchyUrl());
  }, [navigate]);

  const createAdminPortalMedicalOrganizationsUrl = () => createAdminPortalUrl('/medical-orgs');
  const goToAdminPortalMedicalOrganizations = useCallback(() => {
    navigate(createAdminPortalMedicalOrganizationsUrl());
  }, [navigate]);

  const createAdminPortalMedicalOrgUrl = (medicalOrgId: string) => createAdminPortalUrl(`/medical-orgs/${medicalOrgId}`);
  const goToAdminPortalMedicalOrg = useCallback((medicalOrgId: string) => {
    navigate(createAdminPortalMedicalOrgUrl(medicalOrgId));
  }, [navigate]);

  const createAdminPortalUsersUrl = () => createAdminPortalUrl('/users');
  const goToAdminPortalUsers = useCallback(() => {
    navigate(createAdminPortalUsersUrl());
  }, [navigate]);

  const createAdminPortalUserUrl = (id: string) => createAdminPortalUrl(`/users/${id}`);
  const goToAdminPortalUser = useCallback((id: string) => {
    navigate(createAdminPortalUserUrl(id));
  }, [navigate]);

  const createAdminPortalUtilitiesUrl = () => createAdminPortalUrl('/utilities');
  const goToAdminPortalUtilities = useCallback(() => {
    navigate(createAdminPortalUtilitiesUrl());
  }, [navigate]);

  const createAdminPortalSettingsUrl = () => createLoggedInUserSettingsUrl(adminPortalUrl);
  const goToAdminPortalSettings = useCallback(() => {
    navigate(createAdminPortalSettingsUrl());
  }, [navigate]);

  const createAdminPortalMobileVersionsConfigUrl = () => createAdminPortalUrl('/utilities/mobile-versions-config');
  const goToAdminPortalMobileVersionsConfig = useCallback(() => {
    navigate(createAdminPortalMobileVersionsConfigUrl());
  }, [navigate]);

  //
  // --- INTERNAL TOOL
  //
  const createInternalToolUrl = (append = '') => (
    `${internalToolUrl}${append}`.trim()
  );
  // open in new tab
  const createAdminPortalBatchAssignmentUrl = () => createInternalToolUrl('/reassign-patients');
  const goToAdminPortalBatchAssignment = useCallback(() => {
    const url = createAdminPortalBatchAssignmentUrl();
    window.open(url, '_blank');
  }, [createAdminPortalBatchAssignmentUrl]);

  // open in new tab
  const createAdminPortalBatchMessagingUrl = () => createInternalToolUrl('/batch-messaging');
  const goToAdminPortalBatchMessaging = useCallback(() => {
    const url = createAdminPortalBatchMessagingUrl();
    window.open(url, '_blank');
  }, [createAdminPortalBatchAssignmentUrl]);

  //
  // --- OLD ADMIN PORTAL
  //
  // REMOVE this later
  const createOldAdminPortalUrl = (append = '') => (
    `deprecatedAdminPortal${append}`.trim()
  );
  const createCreateClinicalOrgUrl = () => createOldAdminPortalUrl('/clinical-orgs/create');
  const goToCreateClinicalOrg = useCallback(() => {
    navigate(createCreateClinicalOrgUrl());
  }, [navigate]);

  const createCreateChildClinicalOrgUrl = (parentId?: string) => createOldAdminPortalUrl(`/clinical-orgs/create${parentId ? `?parentId=${parentId}` : ''}`);
  const goToCreateChildClinicalOrg = useCallback((parentId?: string) => {
    navigate(createCreateChildClinicalOrgUrl(parentId));
  }, [navigate]);

  const createEditClinicalOrgProfileUrl = (clinicId?: string) => createOldAdminPortalUrl(`/clinical-orgs/${clinicId}/edit`);
  const goToEditClinicalOrgProfile = useCallback((clinicId?: string) => {
    navigate(createEditClinicalOrgProfileUrl(clinicId));
  }, [navigate]);

  const createClinicalOrgsUrl = () => createOldAdminPortalUrl('/clinical-orgs');
  const goToClinicalOrgs = useCallback(() => {
    navigate(createClinicalOrgsUrl());
  }, [navigate]);

  const createClinicalOrgProfileUrl = (id: string) => createOldAdminPortalUrl(`/clinical-orgs/${id}`);
  const goToClinicalOrgProfile = useCallback((id: string) => {
    navigate(createClinicalOrgProfileUrl(id));
  }, [navigate]);

  const createUserProfileUrl = (id: string) => createOldAdminPortalUrl(`/users/${id}/profile`);
  const goToUserProfile = useCallback((id: string) => {
    navigate(createUserProfileUrl(id));
    window.scrollTo(0, 0);
  }, [navigate]);

  //
  // --- GENERAL NAVIGATION
  //
  const createPatientUrl = ({ patientId, ...params }: PatientPageParams) => {
    const search = isEmpty(params) ? '' : `?${stringify(params)}`;
    return createUrlBasedOnPlatform(`/patients/${patientId}${search}`);
  };
  const goToPatientProfilePage = useCallback((
    params: PatientPageParams,
    state?: PatientPageState,
  ) => {
    navigate(createPatientUrl(params), { state });
  }, [navigate]);

  //
  // --- BILLER PORTAL
  //
  const createBillerPortalUrl = (append = '') => (
    `${billerPortalUrl}${append}`.trim()
  );
  const createBillerPortalReportsUrl = () => createBillerPortalUrl('/reports');
  const goToBillerPortalReports = useCallback(() => {
    navigate(createBillerPortalReportsUrl());
  }, [navigate]);

  const createBillerPortalSettingsUrl = () => createBillerPortalUrl('/settings');
  const goToBillerPortalSettings = useCallback(() => {
    navigate(createBillerPortalSettingsUrl());
  }, [navigate]);

  const createBillerPortalUtilitiesUrl = () => createBillerPortalUrl('/utilities');
  const goToBillerPortalUtilities = useCallback(() => {
    navigate(createBillerPortalUtilitiesUrl());
  }, [navigate]);

  const createBillerPortalBillableTimeReviewUrl = () => createBillerPortalUrl('/utilities/billable-time-review');
  const goToBillerPortalBillableTimeReview = useCallback(() => {
    navigate(createBillerPortalBillableTimeReviewUrl());
  }, [navigate]);

  const createBillerPortalInsurancePriorAuthorizationUrl = () => createBillerPortalUrl('/utilities/prior-auth');
  const goToBillerPortalInsurancePriorAuthorization = useCallback(() => {
    navigate(createBillerPortalInsurancePriorAuthorizationUrl());
  }, [navigate]);

  const createBillerPortalMissingPatientUrl = () => createBillerPortalUrl('/utilities/list-missing-information-report-version');
  const goToBillerPortalMissingPatient = useCallback(() => {
    navigate(createBillerPortalMissingPatientUrl());
  }, [navigate]);

  //
  // --- CARE PORTAL
  //
  const createCarePortalUrl = (append = '') => (
    `${carePortalUrl}${append}`.trim()
  );
  const createCarePortalHomeUrl = () => createCarePortalUrl('/home');
  const goToCarePortalHome = useCallback(() => {
    navigate(createCarePortalHomeUrl());
  }, [navigate]);

  const createCarePortalEnrollmentUrl = () => createCarePortalUrl('/enrollment');
  const goToCarePortalEnrollment = useCallback(() => {
    navigate(createCarePortalEnrollmentUrl());
  }, [navigate]);

  const createCarePortalPatientUrl = ({ patientId, ...params }: PatientPageParams) => {
    const search = isEmpty(params) ? '' : `?${stringify(params)}`;
    return createUrlBasedOnPlatform(`/patients/${patientId}${search}`);
  };
  const goToCarePortalPatient = useCallback((
    params: PatientPageParams,
    state?: PatientPageState,
  ) => {
    goToPatientProfilePage(params, state);
  }, [goToPatientProfilePage]);

  const createCarePortalPatientCareUrl = (
    { patientId = '', ...params }: Partial<PatientPageParams> = {},
  ) => {
    const search = isEmpty(params) ? '' : `?${stringify(params)}`;
    return createCarePortalUrl(`/patient-care/${patientId}${search}`);
  };
  const goToCarePortalPatientCare = useCallback((params: Partial<PatientPageParams> = {}) => {
    navigate(createCarePortalPatientCareUrl(params));
  }, [navigate]);

  const createCarePortalPatientsUrl = () => createCarePortalUrl('/patients');
  const goToCarePortalPatients = useCallback(() => {
    navigate(createCarePortalPatientsUrl());
  }, [navigate]);

  const createCarePortalQAPatientUrl = (patientId: string) => (
    createCarePortalUrl(`/qa/${patientId}`)
  );
  const goToCarePortalQAPatient = useCallback((patientId: string) => {
    navigate(createCarePortalQAPatientUrl(patientId));
  }, [navigate]);

  const createCarePortalQAUrl = () => createCarePortalUrl('/qa');
  const goToCarePortalQA = useCallback(() => {
    navigate(createCarePortalQAUrl());
  }, [navigate]);

  const createCarePortalInsightsUrl = () => createCarePortalUrl('/insights');
  const goToCarePortalInsights = useCallback(() => {
    navigate(createCarePortalInsightsUrl());
  }, [navigate]);

  const createCarePortalTaskListUrl = () => createCarePortalUrl('/task-list');
  const goToCarePortalTaskList = useCallback(() => {
    navigate(createCarePortalTaskListUrl());
  }, [navigate]);

  const createCarePortalMessagesUrl = () => createCarePortalUrl('/messages');
  const goToCarePortalMessages = useCallback(() => {
    navigate(createCarePortalMessagesUrl());
  }, [navigate]);

  const createCarePortalTicketUrl = () => createCarePortalUrl('/task');
  const goToCarePortalTicket = useCallback(() => {
    navigate(createCarePortalTicketUrl());
  }, [navigate]);

  const createCarePortalPaymentAndInvoiceUrl = () => createCarePortalUrl('/clinic-billing/payment-and-invoice');
  const goToCarePortalPaymentAndInvoice = useCallback(() => {
    navigate(createCarePortalPaymentAndInvoiceUrl());
  }, [navigate]);

  const createCalendarUrl = (params: CalendarPageParams = {}) => (
    createCarePortalUrl(`/calendar?${stringify(params)}`)
  );
  const goToCalendar = useCallback((params: CalendarPageParams = {}) => {
    navigate(createCalendarUrl(params));
  }, [navigate]);

  const createCarePortalUtilitiesUrl = () => createCarePortalUrl('/utilities');
  const goToCarePortalUtilities = useCallback(() => {
    navigate(createCarePortalUtilitiesUrl());
  }, [navigate]);

  const createCarePortalScreeningUrl = () => createCarePortalUrl('/utilities/screening');
  const goToCarePortalScreening = useCallback(() => {
    navigate(createCarePortalScreeningUrl());
  }, [navigate]);

  const createCarePortalTranscribeUrl = () => createCarePortalUrl('/utilities/transcribe');
  const goToCarePortalTranscribe = useCallback(() => {
    navigate(createCarePortalTranscribeUrl());
  }, [navigate]);

  const createCarePortalBillableTimeReviewUrl = () => createCarePortalUrl('/utilities/billable-time-review');
  const goToCarePortalBillableTimeReview = useCallback(() => {
    navigate(createCarePortalBillableTimeReviewUrl());
  }, [navigate]);

  const createCarePortalBillableTimeDetailedLogUrl = () => createCarePortalUrl('/utilities/billable-time-detailed-log');
  const goToCarePortalBillableTimeDetailedLog = useCallback(() => {
    navigate(createCarePortalBillableTimeDetailedLogUrl());
  }, [navigate]);

  const createCarePortalMedicalAlertDashboardUrl = () => createCarePortalUrl('/utilities/medical-alert-dashboard');
  const goToCarePortalMedicalAlertDashboard = useCallback(() => {
    navigate(createCarePortalMedicalAlertDashboardUrl());
  }, [navigate]);

  const createCarePortalInsurancePriorAuthorizationUrl = () => (
    createCarePortalUrl('/utilities/prior-auth')
  );
  const goToCarePortalInsurancePriorAuthorization = useCallback(() => {
    navigate(createCarePortalInsurancePriorAuthorizationUrl());
  }, [navigate]);

  const createProviderPortalMedicalAlertUrl = () => createCarePortalUrl('/medical-alert-dashboard');
  const goToProviderPortalMedicalAlert = useCallback(() => {
    navigate(createProviderPortalMedicalAlertUrl());
  }, [navigate]);

  const createCarePortalSettingsUrl = () => createLoggedInUserSettingsUrl(carePortalUrl);
  const goToCarePortalSettings = useCallback(() => {
    navigate(createCarePortalSettingsUrl());
  }, [navigate]);

  const createCarePortalUtilitiesBillableTimeUrl = () => createCarePortalUrl('/utilities/billable-time-review');
  const goToReviewBillableTime = useCallback(() => {
    navigate(createCarePortalUtilitiesBillableTimeUrl());
  }, [navigate]);

  const createIncompleteInsuranceAndEligibilityPageUrl = (
    source?: IncompleteBillingSource
  ) => createUrlBasedOnPlatform(`/utilities/insurance-and-eligibility?${source ? stringify({ source }) : ''}`);
  const goToIncompleteInsuranceAndEligibilityPage = useCallback(() => {
    navigate(createIncompleteInsuranceAndEligibilityPageUrl());
  }, [navigate]);
  const goToIncompleteInsuranceAndEligibilityPageFromHome = useCallback(() => {
    navigate(createIncompleteInsuranceAndEligibilityPageUrl(IncompleteBillingSource.HomePage));
  }, [navigate]);

  const createCarePortalVisitsAndEventsUrl = () => createCarePortalUrl('/visits-and-events');
  const gotoVisitsAndEvents = useCallback(() => {
    navigate(createCarePortalVisitsAndEventsUrl());
  }, [navigate]);

  const goToEhrRedirectComponent = useCallback((ehrPageParams: EhrPageParams) => {
    navigate(`/ehr?${stringify(ehrPageParams)}`);
  }, [navigate]);

  const goToEhrReferComponent = useCallback((ehrQuery: EhrQuery) => {
    navigate(`/ehr/refer?${stringify(ehrQuery)}`);
  }, [navigate]);

  const goToCarePortalClinicBilling = useCallback(() => {
    navigate(createCarePortalUrl('/clinic-billing'));
  }, [navigate]);

  const createCarePortalBillingReportPageUrl = () => createCarePortalUrl('/clinic-billing/billing-report-page');
  const goToCarePortalBillingReportPage = useCallback(() => {
    navigate(createCarePortalBillingReportPageUrl());
  }, [navigate]);

  return {
    goToIndex,
    getPlatformUrl,
    goToPlatform,
    createPatientUrl,
    // ADMIN PORTAL HELPERS
    createAdminPortalUrl,
    createAdminPortalHomeUrl,
    goToCarePortalClinicBilling,
    goToAdminPortalHome,
    createAdminPortalCareOperationHierarchyUrl,
    goToAdminPortalCareOperationHierarchy,
    createAdminPortalMedicalOrganizationsUrl,
    goToAdminPortalMedicalOrg,
    goToAdminPortalMedicalOrganizations,
    createAdminPortalUsersUrl,
    goToAdminPortalUsers,
    createAdminPortalUtilitiesUrl,
    goToAdminPortalUtilities,
    createAdminPortalSettingsUrl,
    goToAdminPortalSettings,
    createOldAdminPortalUrl,
    createCreateClinicalOrgUrl,
    goToCreateClinicalOrg,
    createCreateChildClinicalOrgUrl,
    goToCreateChildClinicalOrg,
    createClinicalOrgsUrl,
    goToClinicalOrgs,
    createClinicalOrgProfileUrl,
    goToClinicalOrgProfile,
    createEditClinicalOrgProfileUrl,
    goToEditClinicalOrgProfile,
    createUserProfileUrl,
    goToUserProfile,
    goToAdminPortalUser,
    goToAdminPortalBatchAssignment,
    goToAdminPortalBatchMessaging,
    goToAdminPortalMobileVersionsConfig,
    // BILLER PORTAL HELPERS
    createBillerPortalUrl,
    createBillerPortalReportsUrl,
    createBillerPortalSettingsUrl,
    createBillerPortalUtilitiesUrl,
    createBillerPortalBillableTimeReviewUrl,
    goToBillerPortalReports,
    goToBillerPortalSettings,
    goToBillerPortalUtilities,
    goToBillerPortalBillableTimeReview,
    goToBillerPortalInsurancePriorAuthorization,
    goToBillerPortalMissingPatient,
    // CARE PORTAL HELPERS
    createCarePortalUrl,
    createCarePortalHomeUrl,
    goToCarePortalHome,
    createCarePortalEnrollmentUrl,
    goToCarePortalEnrollment,
    createCarePortalPatientUrl,
    goToCarePortalPatient,
    createCarePortalPatientCareUrl,
    goToCarePortalPatientCare,
    createCarePortalPatientsUrl,
    goToCarePortalPatients,
    goToCarePortalQAPatient,
    goToCarePortalQA,
    createCarePortalInsightsUrl,
    goToCarePortalInsights,
    createCarePortalTaskListUrl,
    goToCarePortalTaskList,
    createCarePortalMessagesUrl,
    goToCarePortalMessages,
    createCarePortalTicketUrl,
    goToCarePortalTicket,
    createCarePortalUtilitiesUrl,
    goToCarePortalUtilities,
    createCarePortalBillableTimeReviewUrl,
    createCarePortalBillableTimeDetailedLogUrl,
    goToCarePortalBillableTimeReview,
    goToCarePortalBillableTimeDetailedLog,
    createCarePortalMedicalAlertDashboardUrl,
    goToCarePortalMedicalAlertDashboard,
    createCarePortalBillingReportPageUrl,
    goToCarePortalBillingReportPage,
    createProviderPortalMedicalAlertUrl,
    goToProviderPortalMedicalAlert,
    createCarePortalScreeningUrl,
    goToCarePortalScreening,
    createCarePortalTranscribeUrl,
    goToCarePortalTranscribe,
    createLoggedInUserSettingsUrl,
    goToLoggedInUserSettings,
    createCalendarUrl,
    goToCalendar,
    createCarePortalSettingsUrl,
    goToCarePortalSettings,
    goToReviewBillableTime,
    createCarePortalVisitsAndEventsUrl,
    gotoVisitsAndEvents,
    goToEhrRedirectComponent,
    createCarePortalInsurancePriorAuthorizationUrl,
    goToCarePortalInsurancePriorAuthorization,
    goToEhrReferComponent,
    createCarePortalPaymentAndInvoiceUrl,
    goToCarePortalPaymentAndInvoice,
    createIncompleteInsuranceAndEligibilityPageUrl,
    goToIncompleteInsuranceAndEligibilityPage,
    goToIncompleteInsuranceAndEligibilityPageFromHome,
  };
};

export default useChangePage;

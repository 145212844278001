import { useGeneralConfigSearch } from '../../../../../uc-api-sdk';
import { CallCenterLocalNumberSelectComponent, CallCenterLocalNumberSelectComponentProps } from '../../component/CallCenterLocalNumberSelectComponent/CallCenterLocalNumberSelectComponent';

export const CallCenterLocalNumberSelectContainer = ({
  value,
  onChange,
}: CallCenterLocalNumberSelectComponentProps) => {
  const getCallCenterLocalPhoneNumberList = useGeneralConfigSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        configType: 'CALL_CENTER_PHONE_NUMBER',
      },
    }
  });

  const callCenterLocalPhoneNumberList = getCallCenterLocalPhoneNumberList.data?.data?.content;
  const callCenterLocalPhoneNumbers = callCenterLocalPhoneNumberList?.map(
    (v?) => v?.doc?.phoneNumber
  ) || [];

  return (
    <CallCenterLocalNumberSelectComponent
      data={callCenterLocalPhoneNumbers}
      value={value}
      onChange={onChange}
    />
  );
};

import { map } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { ARRHYTHMIA_ICON } from '../../../../assets/commonIconInfo';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { Icon } from '../../../../icons/Icon';
import { BPTextComponent } from '../BPTextComponent/BPTextComponent';
import { LogbookCellComponent } from '../LogbookCellComponent/LogbookCellComponent';
import { LogbookComponentProps } from '../LogbookComponent/LogbookComponent';
import { LogbookModalComponent } from '../LogbookComponent/LogbookModalComponent';
import { LogbookInfoComponent } from '../LogbookInfoComponent/LogbookInfoComponent';
import { MeasurementTagsIconsComponent } from '../MeasurementTagsIconsComponent/MeasurementTagsIconsComponent';
import { BPMeasurementService } from '../../helper/BPMeasurementService';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface BPTableCellComponentProps {
  data: BPMeasurementService[];
}

export const BPTableCellComponent: FC<BPTableCellComponentProps> = ({
  data,
}) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean();

  const children = useMemo(() => map(data, (v, i) => (
    <LogbookCellComponent
      key={i}
      text={(
        <BPTextComponent
          systolic={v.systolic.value}
          diastolic={v.diastolic.value}
          heartRate={v.heartRate.value}
        />
      )}
      color={v.severityColor}
      hasNote={!!v.userNote}
      topRightIcon={
        v.arrhythmia && <Icon src={ARRHYTHMIA_ICON.src} alt={ARRHYTHMIA_ICON.alt} size={16} />
      }
      bottomLeftIcon={(
        <MeasurementTagsIconsComponent
          hadMedicine={v.hadMedicine}
          color="#FFF"
        />
      )}
      bottomRightIcon={(
        <MeasurementTagsIconsComponent
          hadSymptom={v.hadSymptom}
          color="#FFF"
        />
      )}
    />
  )), [data]);

  const renderTab = useCallback<LogbookComponentProps<BPMeasurementService>['renderTab']>((dataValue, isSelected, onSelect) => {
    const iconColor = isSelected
      ? '#FFF'
      : dataValue.severityColor;

    return (
      <LogbookCellComponent
        color={dataValue.severityColor}
        text={(
          <BPTextComponent
            systolic={dataValue.systolic.value}
            diastolic={dataValue.diastolic.value}
            heartRate={dataValue.heartRate.value}
          />
      )}
        hasNote={!!dataValue.userNote}
        onClick={onSelect}
        reverseColor={!isSelected}
        topRightIcon={
          dataValue.arrhythmia && (
            <Icon
              info={ARRHYTHMIA_ICON}
              size={16}
              hexColor={iconColor}
            />
          )
        }
        bottomLeftIcon={(
          <MeasurementTagsIconsComponent
            hadMedicine={dataValue.hadMedicine}
            color={iconColor}
          />
        )}
      />
    );
  }, []);

  const renderLogbookInfo = useCallback<LogbookComponentProps<BPMeasurementService>['renderLogbookInfo']>((dataValue) => (
    <LogbookInfoComponent
      vitalType={VitalEnumType.BP}
      hadActivity={dataValue.hadActivity}
      hadInsulin={dataValue.hadInsulin}
      hadMedicine={dataValue.hadMedicine}
      moodTags={[
        ...(dataValue.symptom?.bpSymptomList || ['No symptoms']),
        ...(dataValue.moodTags || []),
      ]}
      patientNote={dataValue.userNote}
      others={[{
        title: 'Triage information',
        content: dataValue.triageInfo,
      }]}
    />
  ), []);

  const renderTitle = useCallback<LogbookComponentProps<BPMeasurementService>['renderTitle']>((dataValue) => (
    TimezoneService.getTimeOfDayText(dataValue.timeOfDay)
  ), []);

  return (
    <>
      <LogbookModalComponent<BPMeasurementService>
        open={open}
        onCancel={closeModal}
        logbookProps={{
          data,
          renderLogbookInfo,
          renderTitle,
          renderTab,
        }}
      />
      <LogbookCellComponent.Group onClick={openModal}>
        {children}
      </LogbookCellComponent.Group>
    </>
  );
};

import { BILLING_CODES } from '../../../constants/billingCodes';
import { BillingCodesFormItemComponent } from '../../component/BillingCodesFormItemComponent/BillingCodesFormItemComponent';

export const BillingCodesContainer = () => {
  const codes = BILLING_CODES; // may get this from backend later

  return (
    <BillingCodesFormItemComponent ccmCodes={codes.ccm} rpmCodes={codes.rpm} />
  );
};

import { NonNullUndefined } from '../../../types/common';
import {
  AlertSeverityEnum,
  BloodPressure,
  BpSeverityEnum,
  HeartRate,
  Measurement,
} from '../../../uc-api-sdk';
import { MeasurementService } from './MeasurementService';
import { MeasurementType } from '../type/MeasurementTypes';

export class BPMeasurementService extends MeasurementService {
  constructor(measurement: Measurement) {
    super(measurement as MeasurementType);
  }

  get beforeMeal() {
    return this.value.beforeMeal;
  }

  get mealType() {
    return this.value.mealType;
  }

  get symptom() {
    return this.value.symptom;
  }

  get hadSymptom() {
    return (this.value?.symptom?.bpSymptomList?.length || 0) > 0;
  }

  get severity() {
    return this.value.bpSeverity;
  }

  get triageInfo() {
    return this.value.triageInfo;
  }

  static getSeverityColor(severity?: BpSeverityEnum) {
    switch (severity) {
      case BpSeverityEnum.LOW: return '#3177c9';
      case BpSeverityEnum.NORMAL: return '#69d376';
      case BpSeverityEnum.ELEVATED: return '#fdb543';
      case BpSeverityEnum.HYPERTENSION_STAGE_1: return '#ff862e';
      case BpSeverityEnum.HYPERTENSION_STAGE_2: return '#ef4545';
      case BpSeverityEnum.HYPERTENSIVE_CRISIS: return '#d91515';
      default: return '#37cc8d';
    }
  }

  get severityColor() {
    return BPMeasurementService.getSeverityColor(this.severity);
  }

  get chartSeverity() {
    switch (this.severity) {
      case BpSeverityEnum.LOW:
      case BpSeverityEnum.ELEVATED:
      case BpSeverityEnum.HYPERTENSION_STAGE_1:
        return AlertSeverityEnum.RISK;
      case BpSeverityEnum.HYPERTENSION_STAGE_2:
      case BpSeverityEnum.HYPERTENSIVE_CRISIS:
        return AlertSeverityEnum.CRITICAL;
      case BpSeverityEnum.NORMAL:
      default: return AlertSeverityEnum.NORMAL;
    }
  }

  get timeOfDay() {
    return this.value.timeOfTheDay;
  }

  get arrhythmia() {
    return this.value.arrhythmia;
  }

  get diastolic() {
    return {
      ...this.value.diastolic_blood_pressure as NonNullUndefined<BloodPressure>,
    };
  }

  get systolic() {
    return {
      ...this.value.systolic_blood_pressure as NonNullUndefined<BloodPressure>,
    };
  }

  get heartRate() {
    return {
      ...this.value.heart_rate as NonNullUndefined<HeartRate>,
    };
  }

  get triageLevel() {
    return this.value.triageLevel;
  }
}

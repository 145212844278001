import {
  FC, useEffect, useMemo
} from 'react';
import { isArray, map } from 'lodash';
import {
  Employee,
  useEmployeeSearch,
} from '../../../../uc-api-sdk';
import { BaseSearchParams, BaseSortDirection } from '../../../../uc-api-sdk/staticFiles/useReqHook';
import {
  EmployeeSelectComponent,
  EmployeeSelectComponentInfo,
  EmployeeSelectComponentProps,
} from '../../component/EmployeeSelectComponent/EmployeeSelectComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface EmployeeSelectContainerProps extends
  Omit<EmployeeSelectComponentProps<Employee>, 'value' | 'onChange' | 'onSearch' | 'data' | 'disabled'> {
  value?: string[];
  onChange?: (value: string[]) => void;
  getEmployData?: (value?: EmployeeSelectComponentInfo<Employee>) => void;
  filter?: (value: string) => BaseSearchParams<Employee>['filter'];
  onlyIHealthEmployee?: boolean;
}

export const EmployeeSelectContainer: FC<EmployeeSelectContainerProps> = ({
  value = [],
  onChange,
  getEmployData,
  optionRenderer,
  tagRender,
  className,
  filter,
  onlyIHealthEmployee = false,
  ...props
}) => {
  const employees = useEmployeeSearch({});

  const createFilter = (value: string): BaseSearchParams<Employee>['filter'] => {
    const filter: BaseSearchParams<Employee>['filter'] = {
      $or: {
        keyValues: {
          nameNear: value,
          firstNameNear: value,
          lastNameNear: value,
        },
      },
    };

    return filter;
  };

  const handleSearch: EmployeeSelectComponentProps<Employee>['onSearch'] = (value) => {
    if (value) {
      employees.send({
        params: {
          filter: filter ? filter(value) : createFilter(value),
          pageInfo: {
            page: 1,
            size: 20,
            sort: [
              {
                direction: BaseSortDirection.ASC,
                property: 'employeeProfile.firstName',
              },
            ],
            pagination: true,
          },
        },
      });
    }
  };

  const getInitialEmployees = (v: string[]) => {
    if (v && v.length > 0) {
      employees.send({
        params: {
          filter: {
            idIn: {
              in: isArray(v) ? v : [v],
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (value?.length) {
      getInitialEmployees(value);
    }
  }, [value]);

  const debounceHandleSearch = useDebounce(handleSearch);

  const employeeList = useMemo<EmployeeSelectComponentInfo<Employee>[]>(() => (
    map(employees.data?.data?.content, (v) => ({
      id: v.id || '',
      fullName: `${v.employeeProfile?.firstName} ${v.employeeProfile?.lastName}`,
      info: v,
    }))
  ), [employees]);

  const handleChange: EmployeeSelectComponentProps<Employee>['onChange'] = (value) => {
    onChange?.(value);
    getEmployData?.(employeeList.find((v) => v.id === value[0]));
  };

  return (
    <EmployeeSelectComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      showArrow={employees.isLoading}
      isLoading={employees.isLoading}
      className={className}
      data={employeeList}
      onSearch={debounceHandleSearch}
      value={value}
      optionRenderer={optionRenderer}
      onChange={handleChange}
      placeholder={
        onlyIHealthEmployee
          ? 'Search iHealth employee. ex. Jane, or janedoe@ihealthlabs.com'
          : 'Search for a user'
      }
      tagRender={tagRender}
      mode="multiple"
    />
  );
};

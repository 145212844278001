import { usePutWithFormData } from '../../../hooks/ajaxRequest';
import StrictFormData from '../../../hooks/ajaxRequest/formData/strictFormData';
import { UploadCoBrandLogoParams } from '../../../hooks/mutation/clinical/restful/types/updateClinical';
import { RESTfulResponse } from '../../../types/restful';
import { CoBrandingLogoModification } from '../../../uc-api-sdk';

export const useMedicalOrgCobrandingLogoUpload = () => {
  const {
    mutateAsync: uploadPhoto,
  } = usePutWithFormData<
    StrictFormData<{ photo: Blob }>,
    RESTfulResponse<CoBrandingLogoModification>,
    { id: string }
  >(
    ({ id }: UploadCoBrandLogoParams) => `/medical-organization/${id}/configuration/co-brand-logo`,
    {
      logError: true,
      throwError: true,
    },
  )();
  return { uploadPhoto };
};

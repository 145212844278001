import { Employee, useMedicalOrganizationRemoveStaffCheck } from '../../../../../uc-api-sdk';
import { userRolesRestrictionModal } from '../../../component/UserRolesRestrictionModal/UserRolesRestrictionModal';
import { StaffTableActionsComponent } from '../../component/StaffTableActionsComponent/StaffTableActionsComponent';

export interface StaffTableActionsContainerProps {
  medOrgId: string;
  removeStaff?: (id: string) => void;
  employee?: Employee;
}

export const StaffTableActionsContainer = ({
  medOrgId,
  removeStaff,
  employee
}: StaffTableActionsContainerProps) => {
  const removeStaffCheckHook = useMedicalOrganizationRemoveStaffCheck({});

  const onRemoveMember = async (id: string) => {
    const res = await removeStaffCheckHook.send({
      params: {
        request: {
          medicalOrgOrCareUnitId: medOrgId,
          memberId: employee?.id || '',
        }
      }
    });
    if (res?.code === 200) {
      if (res.data?.length) {
        userRolesRestrictionModal('warning', res.data);
      } else {
        removeStaff?.(id);
      }
    }
  };

  const rolesInOtherOrgs = Array.from(new Set(employee?.assignedRoles?.filter(
    r => r.organizationId !== medOrgId
  ).map(r => r.roleType) || []));

  const currentRoles = employee?.assignedRoles?.filter(
    r => r.organizationId === medOrgId
  ).map(r => r.roleType);

  return (
    <StaffTableActionsComponent
      memberId={employee?.id || ''}
      onRemoveMember={onRemoveMember}
      medOrgId={medOrgId}
      currentRoles={currentRoles}
      otherRoles={rolesInOtherOrgs}
    />
  );
};

import { FC } from 'react';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import { InfoCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { SymptomEnum, SymptomSeverityEnum } from '../../../../uc-api-sdk';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { SymptomSeverityEnumComponent } from '../SymptomSeverityEnumComponent/SymptomSeverityEnumComponent';
import { useCreateSymptomForm } from '../../hook/useCreateSymptomForm';

interface SeverityAndNoteSelectionComponentProps {
  curSymptomEnum?: SymptomEnum;
}

export const SeverityAndNoteSelectionComponent: FC<SeverityAndNoteSelectionComponentProps> = ({
  curSymptomEnum,
}) => {
  const symptomFormInfo = useCreateSymptomForm();

  const severityOptions = [
    {
      label: <SymptomSeverityEnumComponent
        value={SymptomSeverityEnum.MILD}
        type="tag"
      />,
      value: SymptomSeverityEnum.MILD,
    },
    {
      label: <SymptomSeverityEnumComponent
        value={SymptomSeverityEnum.MODERATE}
        type="tag"
      />,
      value: SymptomSeverityEnum.MODERATE,
    },
    {
      label: <SymptomSeverityEnumComponent
        value={SymptomSeverityEnum.SEVERE}
        type="tag"
      />,
      value: SymptomSeverityEnum.SEVERE,
    },
  ];

  return (
    <>
      <FormItem
        name={symptomFormInfo.makeObjectNamePath('severity', curSymptomEnum)}
        required
        rules={[{
          required: true,
          message: (
            <Space>
              <InfoCircleFilled className="danger-svg" />
              <div>Missing!</div>
            </Space>
          )
        }]}
        label={(
          <div className="fs13 text-darker-gray">
            Severity
          </div>
              )}
        className="border-gray-1 pl24 py12"
      >
        <RadioGroupComponent
          className="f-w"
          options={severityOptions}
        />
      </FormItem>
      <FormItem
        name={symptomFormInfo.makeObjectNamePath('note', curSymptomEnum)}
        className="mt10"
      >
        <TextArea
          placeholder="Note (Optional)"
          rows={3}
        />
      </FormItem>
    </>
  );
};

import { Table } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { HSUnitProps } from '../../../../helpers/weight';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import '../vitalsTable.scss';
import { HSSummaryTableColumns } from './HSSummaryTableColumns';
import './HSSummaryTableColumns.scss';

export interface HSSummaryTableData {
  period: string;
  total: number;
  average: number;
  bmi: number;
  min: number;
  max: number;
  changePercent: number;
}

export interface HSSummaryTableComponentProps extends HSUnitProps {
  data: Partial<HSSummaryTableData>[];
}

export const HSSummaryTableComponent: FC<HSSummaryTableComponentProps> = ({
  data,
  unit
}) => {
  const columns = useTableColumns(
    HSSummaryTableColumns(unit),
    ['period', 'total', 'average', 'bmi', 'range', 'changePercent'],
    [unit]
  );
  return (
    <Table
      className={classNames({
        'summary-table': true,
        'vitals-un-bordered-table': true,
        'hs-vitals-table': true,
      })}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(v) => v.type}
    />
  );
};

import { Button, Drawer } from 'antd';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { AssociatedCareUnitFormComponent, AssociatedCareUnitFormValue } from '../AssociatedCareUnitFormComponent/AssociatedCareUnitFormComponent';
import { EmployeeResponse } from '../../../../../uc-api-sdk';

export interface AssociatedCareUnitDrawerComponentProps {
  showButton?: boolean;
  onSubmit: (v: AssociatedCareUnitFormValue) => void;
  initialValues?: AssociatedCareUnitFormValue;
  moveCareGroupWarningPatientList?: EmployeeResponse[];
}

export const AssociatedCareUnitDrawerComponent = ({
  showButton = true,
  onSubmit,
  initialValues,
  moveCareGroupWarningPatientList,
}: AssociatedCareUnitDrawerComponentProps) => {
  const { isOpen, close, open } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.AssociateCareUnit);
  const handleClick = () => {
    open({});
  };

  return (
    <div>
      {showButton && <Button type="primary" onClick={handleClick}>Associate to Care Unit</Button>}
      <Drawer
        open={isOpen}
        onClose={close}
        title="Associated Care Unit"
        width={643}
      >
        <AssociatedCareUnitFormComponent
          onSubmit={onSubmit}
          initialValues={initialValues}
          moveCareGroupWarningPatientList={moveCareGroupWarningPatientList}
        />
      </Drawer>
    </div>
  );
};

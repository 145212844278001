import { Employee } from '../../../../uc-api-sdk';

/* eslint-disable no-underscore-dangle */

export const _getFirstName = (employee: Employee) => employee.employeeProfile?.firstName;

export const _getLastName = (employee: Employee) => employee.employeeProfile?.lastName;

export const _getFullName = (employee: Employee) => {
  const firstName = _getLastName(employee);
  const lastName = _getLastName(employee);
  if (firstName && lastName) {
    return `${_getFirstName(employee)} ${_getLastName(employee)}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return undefined;
};

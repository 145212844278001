import { EhrServiceName } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface EHRProviderEnumComponentProps {
  value?: EhrServiceName | null | undefined;
}

export const EHRProviderEnumComponent = ({ value }: EHRProviderEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case EhrServiceName.AMAZING_CHART: return 'Amazing Chart';
      case EhrServiceName.ATHENA: return 'Athenahealth';
      case EhrServiceName.CAREQUALITY: return 'Care Quality';
      case EhrServiceName.UNIFIED_CARE: return 'Unified Care';
      case EhrServiceName.CITRIX_WORKSPACE: return 'Citrix Workspace';
      case EhrServiceName.LCMC: return 'LCMC';
      case EhrServiceName.EHI: return 'EHI';
      case EhrServiceName.EPIC_SYSTEMS: return 'Epic Systems';
      case EhrServiceName.E_CLINICAL_WORKS: return 'eClinicalWorks';
      case EhrServiceName.E_MDS: return 'eMDs';
      case EhrServiceName.E_MED_REC: return 'eMedRec';
      case EhrServiceName.HEALTH_FUSION: return 'Healthfusion';
      case EhrServiceName.IMS: return 'IMS';
      case EhrServiceName.LIQUID: return 'Liquid';
      case EhrServiceName.NOT_USING_ANY_EHR: return 'Not Using Any EHR';
      case EhrServiceName.OFFICE_ALLY: return 'OfficeAlly';
      case EhrServiceName.PRACTICE_FUSION: return 'Practice Fusion';
      case EhrServiceName.PRACTICE_SUITE: return 'Practice Suite';
      case EhrServiceName.OTHERS: return 'Others';
      case EhrServiceName.EBIO: return 'Ebio';
      case EhrServiceName.ALL_SCRIPTS: return 'Allscripts';
      case EhrServiceName.CERNER: return 'Cerner(Oracle Health)';
      case EhrServiceName.CHART_LOGIC: return 'ChartLogic';
      case EhrServiceName.ELATION: return 'Elation';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};

import { Divider } from 'antd';
import { forEach, isEmpty, map } from 'lodash';
import { RemoveRoleValidationResponse, RoleTypeEnum } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';

export interface CheckRemoveUserValidationModalComponentProps {
  data?: RemoveRoleValidationResponse[];
}

export const CheckRemoveUserValidationModalComponent = ({
  data,
}: CheckRemoveUserValidationModalComponentProps) => {
  const processedItems = useDeepCompareMemo(() => {
    // sorted by roles
    const listObj = {} as { [key in RoleTypeEnum]: RemoveRoleValidationResponse[] };
    forEach(data, (item) => {
      item.roles?.forEach((role) => {
        listObj[role] = [...(listObj[role] || []), item];
      });
    });
    return [
      { [RoleTypeEnum.RD]: listObj[RoleTypeEnum.RD] },
      { [RoleTypeEnum.HC]: listObj[RoleTypeEnum.HC] },
      { [RoleTypeEnum.CA]: listObj[RoleTypeEnum.CA] },
    ] as { [key in RoleTypeEnum]: RemoveRoleValidationResponse[] | undefined }[];
  }, [data]);

  return (
    <div>
      <div>
        This user still has patients assigned from medical organizations listed below.
        To avoid any operational errors,
        please contact engineer to reassign these patients to others before proceeding.
      </div>
      {
        processedItems && (
          <>
            <Divider />
            <div>
              {
                map(processedItems, (item) => {
                  const role = Object.keys(item)[0] as RoleTypeEnum;
                  const subItems = item[role];
                  return (
                    isEmpty(subItems)
                      ? null
                      : (
                        <div key={role}>
                          <div className="b7">
                            {role}
                          </div>
                          {
                            map(
                              subItems,
                              (item, idx) => (
                                <div key={idx} className="mb20">
                                  <div className="mb10">
                                    {item?.careGroupAssociatedMaps?.map(
                                      (group) => (
                                        <>
                                          <div
                                            key={group.careGroup?.id}
                                            className="text-secondary-gray"
                                          >
                                            {group.careGroup?.name}
                                          </div>
                                          <div>
                                            {group.limitedClinics?.map(
                                              (medOrg) => (
                                                <div key={medOrg.id}>
                                                  <ClinicNameDisplayComponent
                                                    clinic={medOrg}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      )
                                    )}
                                    {item?.limitedClinics?.map(
                                      (medicalOrg) => (
                                        <div>
                                          {medicalOrg.alias}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )
                          }
                        </div>
                      )
                  );
                })
              }
            </div>
          </>
        )
      }
    </div>
  );
};

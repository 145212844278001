import {
  Select,
  SelectProps
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FC } from 'react';
import { SelectType } from '../../features/Input/types';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { MedicationDictionaryItem } from '../../uc-api-sdk';
import { EllipsisComponent } from '../../uiComponent/EllipsisComponent/EllipsisComponent';
import './MedicationNameSelectComponent.scss';

export interface MedicationNameSelectComponentProps extends Omit<SelectType<MedicationDictionaryItem>, 'options'> {
  onSearch?: (value: string) => void;
  options: MedicationDictionaryItem[];
  onPopupScroll?: SelectProps['onPopupScroll'];
  loading?: boolean;
}

export const MedicationNameSelectComponent: FC<MedicationNameSelectComponentProps> = ({
  value,
  onChange,
  loading,
  onSearch,
  options,
  onPopupScroll,
}) => {
  const parsedOptions = useDeepCompareMemo(() => (
    options.map((m) => (
      {
        label: (<EllipsisComponent>{m.displayName}</EllipsisComponent>),
        value: m.displayName,
        data: m,
      }))
  ), [options]);

  const handleChange: SelectProps<string>['onChange'] = (value, option) => {
    onChange?.((option as DefaultOptionType).data, option);
  };

  return (
    <div className="medicationNameWrapper">
      <Select
        showSearch
        loading={loading}
        defaultActiveFirstOption
        data-testid="medNameInput"
        value={value?.displayName}
        options={parsedOptions}
        optionLabelProp="value"
        onSearch={onSearch}
        onChange={handleChange}
        onPopupScroll={onPopupScroll}
      />
    </div>
  );
};

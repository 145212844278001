import classNames from 'classnames';
import React from 'react';
import './AlertComponent.scss';

type AlertComponentProps = {
  message: React.ReactNode;
  icon: React.ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error';
};

export const AlertComponent: React.FC<AlertComponentProps> = ({ icon, message, type = 'info' }) => (
  <div className={classNames({
    'alert-component': true,
    [type]: true
  })}
  >
    <div className="alert-icon">
      {icon}
    </div>
    <div className="alert-message">
      {message}
    </div>
  </div>
);

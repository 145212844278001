/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-invalid-html-attribute */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { MoreOutlined } from '@ant-design/icons';
import {
  DatePicker, Dropdown, InputNumber, Table
} from 'antd';
import { filter, map, uniqueId } from 'lodash';
import { Moment } from 'moment';
import { DATE_TIME } from '../../../../constants/timeFormat';
import { InputTypeArray } from '../../../Input/types';
import { BpTriageLevelEnum } from '../../../../uc-api-sdk';
import { BPTriageLevelSelectComponent } from '../../inputComponent/BPTriageLevelSelectComponent/BPTriageLevelSelectComponent';
import { AlertGeneratedComponent } from '../AlertGeneratedComponent/AlertGeneratedComponent';

export interface BPAlertTableData {
  id?: string;
  success?: boolean;
  dateTime?: Moment;
  systolicBloodPressure?: number;
  diastolicBloodPressure?: number;
  heartRate?: number;
  triageLevel?: BpTriageLevelEnum;
}

export interface AlertBPTableComponentProps
  extends InputTypeArray<BPAlertTableData> {
}

type DateFields = 'dateTime';
type SBPInputFields = 'systolicBloodPressure';
type DBPInputFields = 'diastolicBloodPressure';
type HRInputFields = 'heartRate';
type TriageLevelInputFields = 'triageLevel';

export const AlertBPTableComponent = ({
  value,
  onChange,
}: AlertBPTableComponentProps) => {
  const handleChange = (index: number, key: keyof BPAlertTableData, v: unknown) => {
    onChange?.(map(value, ((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: v,
        };
      }
      return item;
    })));
  };

  const handleDateChange = (index: number, key: DateFields) => (
    (v: Moment | null | undefined = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleSBPInputChange = (index: number, key: SBPInputFields) => (
    (v: number | undefined | null = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleDBPInputChange = (index: number, key: DBPInputFields) => (
    <T, >(v: T | null | undefined = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleHRInputChange = (index: number, key: HRInputFields) => (
    (v: number | undefined | null = undefined) => {
      handleChange(index, key, v);
    }
  );

  const handleTriageLevelChange = (index: number, key: TriageLevelInputFields) => (
    <T, >(v: T | null | undefined = undefined) => {
      handleChange(index, key, v);
    }
  );

  const renderRowOptions = (index: number) => {
    const handleAddRowAbove = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index, 0, {});
      onChange?.(newVal);
    };

    const handleAddRowBelow = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index + 1, 0, {});
      onChange?.(newVal);
    };

    const handleRemoveRow = () => {
      const newVal = value ? [...value] : [];
      newVal.splice(index, 1);
      onChange?.(newVal);
    };

    return [
      {
        title: 'Alert Generated',
        dataIndex: 'success',
        key: 'alertGenerated',
        render: (v: boolean) => (
          <AlertGeneratedComponent success={v} />
        ),
      },
      {
        key: 'addRowAbove',
        label: (
          <a onClick={handleAddRowAbove}>
            Add row above
          </a>
        ),
      },
      {
        key: 'addRowBelow',
        label: (
          <a onClick={handleAddRowBelow}>
            Add row below
          </a>
        ),
      },
      {
        key: 'deleteRow',
        label: (
          <a onClick={handleRemoveRow}>
            Remove row
          </a>
        ),
      },
    ];
  };

  const columns = [
    {
      title: '',
      dataIndex: 'success',
      key: 'alertGenerated',
      render: (v: boolean) => (
        <AlertGeneratedComponent success={v} />
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'dateTime',
      key: 'uploadedAt',
      width: 200,
      render: (v: Moment | null | undefined, record: BPAlertTableData, index: number) => (
        <DatePicker
          format={DATE_TIME}
          showTime
          value={v}
          onChange={handleDateChange(index, 'dateTime')}
        />
      ),
    },
    {
      title: 'Systolic Blood Pressure (mmHg)',
      dataIndex: 'systolicBloodPressure',
      key: 'systolicBloodPressure',
      render: (v: string | null | undefined, record: BPAlertTableData, index: number) => (
        <InputNumber
          value={v ? parseInt(v, 10) : undefined}
          onChange={handleSBPInputChange(index, 'systolicBloodPressure')}
        />
      ),
    },
    {
      title: 'Diastolic Blood Pressure (mmHg)',
      dataIndex: 'diastolicBloodPressure',
      key: 'diastolicBloodPressure',
      render: (v: string | null | undefined, record: BPAlertTableData, index: number) => (
        <InputNumber
          value={v ? parseInt(v, 10) : undefined}
          onChange={handleDBPInputChange(index, 'diastolicBloodPressure')}
        />
      ),
    },
    {
      title: 'Heart Rate (beats/min)',
      dataIndex: 'heartRate',
      key: 'heartRate',
      render: (v: string | null | undefined, record: BPAlertTableData, index: number) => (
        <InputNumber
          value={v ? parseInt(v, 10) : undefined}
          onChange={handleHRInputChange(index, 'heartRate')}
        />
      ),
    },
    {
      title: 'Triage Level',
      dataIndex: 'triageLevel',
      key: 'triageLevel',
      with: 100,
      render: (v: BpTriageLevelEnum, record: BPAlertTableData, index: number) => (
        <BPTriageLevelSelectComponent
          options={map(filter(BpTriageLevelEnum, (v) => v !== BpTriageLevelEnum.info))}
          value={v}
          onChange={handleTriageLevelChange(index, 'triageLevel')}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (v: unknown, record: BPAlertTableData, index: number) => (
        <Dropdown menu={{ items: renderRowOptions(index) }}>
          <MoreOutlined />
        </Dropdown>
      ),
    }
  ];
  return (
    <Table
      dataSource={value}
      columns={columns}
      bordered
      pagination={false}
      rowKey={uniqueId()}
      scroll={{ y: 400, x: true }}
    />
  );
};

import { Radio, RadioProps } from 'antd';
import classNames from 'classnames';
import { FeeModelEnumComponent } from '../../../../../enumComponent/FeeModelEnumComponent/FeeModelEnumComponent';
import { BillingFeeModelEnum } from '../../../../../uc-api-sdk';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { ProgramsCommonWrapperComponent } from '../../../component/ProgramsCommonWrapperComponent/ProgramsCommonWrapperComponent';
import { BillingCodesContainer } from '../../container/BillingCodesContainer/BillingCodesContainer';
import { PbpmFeeModelFormItemComponent } from '../PbpmFeeModelFormItemComponent/PbpmFeeModelFormItemComponent';
import { PepmFeeModelFormItemComponent } from '../PepmFeeModelInputComponent/PepmFeeModelInputComponent';
import { PerCodeFeeModelFormItemComponent } from '../PerCodeFeeModelFormItemComponent/PerCodeFeeModelFormItemComponent';
import { PmapmFeeModelFormItemComponent } from '../PmapmFeeModelFormItemComponent/PmapmFeeModelFormItemComponent';

export interface BillingFeeModelFormItemComponentProps extends RadioProps {
}

export const BillingFeeModelFormItemComponent = ({
  ...props
}: BillingFeeModelFormItemComponentProps) => {
  const itemName = [props.name];
  return (
    <ProgramsCommonWrapperComponent title="Fee model">
      <p className="text-gray-scale-2">How do we charge this medical organization?</p>
      <FormItem name={itemName} required>
        <Radio.Group
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          className={classNames('w100 flex fd-c gap2', props.className)}
        >
          <div className="flex fd-c gap2">
            <Radio value={BillingFeeModelEnum.PER_CODE}>
              <FeeModelEnumComponent value={BillingFeeModelEnum.PER_CODE} />
            </Radio>
            <FormItem dependencies={itemName} noStyle>
              {
                ({ getFieldValue }) => {
                  const show = getFieldValue(itemName) === BillingFeeModelEnum.PER_CODE;
                  return show && <PerCodeFeeModelFormItemComponent />;
                }
              }
            </FormItem>
            <FormItem dependencies={itemName} noStyle>
              {
                ({ getFieldValue }) => {
                  const show = getFieldValue(itemName) === BillingFeeModelEnum.PER_CODE;
                  return show && <BillingCodesContainer />;
                }
              }
            </FormItem>
          </div>

          <Radio value={BillingFeeModelEnum.PMAPM}>
            <FeeModelEnumComponent value={BillingFeeModelEnum.PMAPM} />
            <FormItem dependencies={itemName} noStyle>
              {
                ({ getFieldValue }) => {
                  const show = getFieldValue(itemName) === BillingFeeModelEnum.PMAPM;
                  return show && <PmapmFeeModelFormItemComponent />;
                }
              }
            </FormItem>
          </Radio>
          <Radio value={BillingFeeModelEnum.PBPM}>
            <FeeModelEnumComponent value={BillingFeeModelEnum.PBPM} />
            <FormItem dependencies={itemName} noStyle>
              {
                ({ getFieldValue }) => {
                  const show = getFieldValue(itemName) === BillingFeeModelEnum.PBPM;
                  return show && <PbpmFeeModelFormItemComponent />;
                }
              }
            </FormItem>
          </Radio>
          <Radio value={BillingFeeModelEnum.PEPM}>
            <FeeModelEnumComponent value={BillingFeeModelEnum.PEPM} />
            <FormItem dependencies={itemName} noStyle>
              {
                ({ getFieldValue }) => {
                  const show = getFieldValue(itemName) === BillingFeeModelEnum.PEPM;
                  return show && <PepmFeeModelFormItemComponent />;
                }
              }
            </FormItem>
          </Radio>
          <Radio value={BillingFeeModelEnum.NA}>
            <FeeModelEnumComponent value={BillingFeeModelEnum.NA} />
          </Radio>

        </Radio.Group>
      </FormItem>
    </ProgramsCommonWrapperComponent>
  );
};

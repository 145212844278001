import { Divider, Button } from 'antd';
import { useEffect, useState } from 'react';
import { CompleteChartingButtonComponent } from '../CompleteChartingButtonComponent/CompleteChartingButtonComponent';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useZoomContext } from '../../../../contexts/ZoomContext/ZoomContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface WorklistButtonComponentProps {
  clinicEvent: ClinicEventsService;
  onCompleteCharting: () => void;
  onCheckout: () => void;
  onClose?: () => void;
  isLoading?: boolean;
}

export const WorklistButtonComponent = ({
  clinicEvent,
  onCheckout,
  onCompleteCharting,
  onClose,
  isLoading
}: WorklistButtonComponentProps) => {
  const [
    showCompleteChartingBtn,
    setShowCompleteChartingBtn
  ] = useState(clinicEvent.isCheckedOut);
  const {
    endZoom,
  } = useZoomContext();
  const {
    send,
  } = useMixpanelContext();

  const getButtonText = () => {
    if (clinicEvent.isVideoVisit) return 'End Zoom and Check out';
    return 'Check out';
  };

  const handleCheckout = useDebounce(() => {
    if (clinicEvent.isVideoVisit) {
      send({ event: MixpanelEvents.WorkVisitEndVideoAndCheckOutBtn });
      endZoom();
    } else {
      send({ event: MixpanelEvents.WorkVisitCheckOutBtn });
    }
    onCheckout?.();
  }, 250, [endZoom, onCheckout]);

  const handleCompleteCharting = useDebounce(() => {
    onCompleteCharting?.();
  }, 250, [onCompleteCharting]);

  const handleOnClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (!showCompleteChartingBtn && clinicEvent.isCheckedOut) {
      setShowCompleteChartingBtn(true);
    }
  }, [clinicEvent.isCheckedOut]);

  return (
    !clinicEvent.isCompleted
      ? (
        <div>
          <Divider className="my10" />
          <div className="w100 flex jc-e">
            {
            showCompleteChartingBtn
              ? (
                <CompleteChartingButtonComponent
                  isLoading={isLoading}
                  disabled={isLoading}
                  onOk={handleCompleteCharting}
                  onCancel={handleOnClose}
                />
              ) : (
                <Button
                  loading={isLoading}
                  type="primary"
                  onClick={handleCheckout}
                  size="large"
                >
                  {getButtonText()}
                </Button>
              )
          }
          </div>
        </div>
      ) : null
  );
};

import { Space } from 'antd';
import React, { FC } from 'react';
import { WORLD_ICON } from '../../assets/commonIconInfo';
import { TimezoneOffset, TimezoneType } from '../../types/timezone';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import Icon from '../Icon/Icon';
import TimezoneService from '../../helpers/timezone/timezoneService';

export interface TimezoneComponentProps {
  value: TimezoneType | TimezoneOffset;
  showIcon?: boolean;
  type?: 'name' | 'nameAndOffset';
}

export const TimezoneComponent: FC<TimezoneComponentProps> = ({
  value,
  showIcon,
  type = 'nameAndOffset',
}) => {
  const getValue = () => {
    switch (type) {
      case 'name':
        return TimezoneService.getTimezoneName(value);
      case 'nameAndOffset':
      default:
        return TimezoneService.getTimezoneText(value);
    }
  };

  return (
    <span data-testid="timezone">
      <Space align="center">
        {showIcon && <Icon img={WORLD_ICON} />}
        <DisplayOrEmptyComponent<string> value={getValue()} />
      </Space>
    </span>
  );
};

export default TimezoneComponent;

import { ROLE_ENUM, ROLE_GROUP_MAP, ROLE_NAME_MAP } from '../../../../../types/roles';
import { RoleTypeEnum } from '../../../../../uc-api-sdk';

export const iHealthEmployeeRoleTypes = [
  {
    label: ROLE_NAME_MAP.RD,
    value: ROLE_ENUM.RD,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.HC,
    value: ROLE_ENUM.HC,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.CA,
    value: ROLE_ENUM.CA,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.MANAGER,
    value: ROLE_ENUM.MANAGER,
    group: ROLE_GROUP_MAP.UC_MANAGER,
  },
  {
    label: ROLE_NAME_MAP.BILLER,
    value: ROLE_ENUM.BILLER,
    group: ROLE_GROUP_MAP.BILLING_DEPT,
  },
  {
    label: ROLE_NAME_MAP.CALL_CENTER_REPRESENTATIVE,
    value: ROLE_ENUM.CALL_CENTER_REPRESENTATIVE,
    group: ROLE_GROUP_MAP.CALL_CENTER_DEPT,
  },
  {
    label: ROLE_NAME_MAP.ADMIN,
    value: ROLE_ENUM.ADMIN,
    group: ROLE_GROUP_MAP.ADMIN,
  },
];

// mapped role list can't be combined together with the current role
// no map => can combine with any
export const roleCombinationLimit = {
  [RoleTypeEnum.RD]: [RoleTypeEnum.HC, RoleTypeEnum.CA, RoleTypeEnum.BILLER],
  [RoleTypeEnum.HC]: [RoleTypeEnum.RD, RoleTypeEnum.BILLER],
  [RoleTypeEnum.CA]: [RoleTypeEnum.RD, RoleTypeEnum.BILLER],
  [RoleTypeEnum.BILLER]: [RoleTypeEnum.RD, RoleTypeEnum.HC, RoleTypeEnum.CA],
  [RoleTypeEnum.PROVIDER]: [RoleTypeEnum.MA],
  [RoleTypeEnum.MA]: [RoleTypeEnum.PROVIDER],
  [RoleTypeEnum.CLINICAL_GROUP_BILLER]: [RoleTypeEnum.CLINICAL_MANAGER],
  [RoleTypeEnum.CLINICAL_MANAGER]: [RoleTypeEnum.CLINICAL_GROUP_BILLER],
} as Record<Partial<RoleTypeEnum>, RoleTypeEnum[]>;

export const roleWithDefaultCareUnit = [
  RoleTypeEnum.ADMIN,
  RoleTypeEnum.BILLER,
];

export const rootRole = [
  RoleTypeEnum.SUPER_ADMIN,
  RoleTypeEnum.ADMIN,
  RoleTypeEnum.BILLER,
];

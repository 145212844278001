import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { ProgramsCommonWrapperComponent } from '../ProgramsCommonWrapperComponent/ProgramsCommonWrapperComponent';
import './MedicalOrgParticipatingProgramsComponent.scss';

export interface MedicalOrgParticipatingProgramsComponentProps extends CheckboxGroupProps {
}

export const MedicalOrgParticipatingProgramsComponent = ({
  value = [],
  onChange,
}: MedicalOrgParticipatingProgramsComponentProps) => (
  <ProgramsCommonWrapperComponent title="Program Configuration">
    <Checkbox.Group
      value={value}
      onChange={onChange}
      className="programsCheckboxGroup"
    >
      <Checkbox className="programCheckbox" value={ProgramCategoryEnum.CCM}>CCM</Checkbox>
      <Checkbox className="programCheckbox" value={ProgramCategoryEnum.RPM}>RPM</Checkbox>
    </Checkbox.Group>
  </ProgramsCommonWrapperComponent>
);

import { Modal } from 'antd';
import './AssociatedMedicalOrgManageRestrictionModal.scss';

export const associatedMedicalOrgManageRestrictionModal = () => {
  const renderContent = () => (
    <div>
      <div className="mb20">
        You can't remove a Medical Organization if it has
        {' '}
        patients assigned to the care member from current Care Unit.
      </div>
      <div>
        If you wish to associate a Medical Organization with another Care Unit,
        please go to the Medical Organization page to perform this action.
      </div>
    </div>
  );

  return (
    Modal.info({
      className: 'associated-medical-org-manage-restriction-modal',
      width: 600,
      icon: null,
      title: "Can't Remove Certain Medical Orgs",
      content: renderContent(),
      okText: 'Got it',
      okType: 'default',
    })
  );
};

import { Select, SelectProps, Tag } from 'antd';
import { useMemo } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MedicalOrganizationHelper } from '../../../../helpers/medicalOrganization';
import { MedicalOrganization } from '../../../../uc-api-sdk';
import { useCareOperationContext } from '../../careOperationHierarchy/context/CareOperationContext/CareOperationContext';

export interface MedicalOrganizationsSelectComponentProps extends SelectProps<string[]> {
  data: MedicalOrganization[];
  showAssociated?: boolean;
  restrictedMedOrgIds?: string[];
  orgIdsWithPt?: string[];
}

export const MedicalOrganizationsSelectComponent = ({
  value,
  onChange,
  data,
  showAssociated = true,
  restrictedMedOrgIds,
  orgIdsWithPt,
  ...props
}: MedicalOrganizationsSelectComponentProps) => {
  const { selectedEntityId = '' } = useCareOperationContext();
  const { isProvider } = useLoggedInUserFromContext();

  const options = useMemo(() => MedicalOrganizationHelper.makeAndSort(data, isProvider)
    .map((item) => {
      const disabled = !!item.careUnit
        && item.careUnit.id !== selectedEntityId && showAssociated;
      const unableToRemove = restrictedMedOrgIds?.includes(item.id as string);
      const hasPatientsInOrg = orgIdsWithPt?.includes(item.id as string);
      return {
        value: item.id,
        label: (
          <div className="flex jc-sb">
            {item.searchable}
            {disabled && <div>{`Associated to ${item.careUnit?.name}`}</div>}
          </div>),
        disabled: disabled || unableToRemove || hasPatientsInOrg,
        name: item.searchable as string,
      };
    }), [data, selectedEntityId, showAssociated, restrictedMedOrgIds, orgIdsWithPt]);

  const tagRender: SelectProps['tagRender'] = (props) => {
    const { label, value, onClose } = props;
    const shouldDisable = restrictedMedOrgIds?.includes(value) || orgIdsWithPt?.includes(value);
    return (
      <Tag
        className="flex"
        closable={!shouldDisable}
        onClose={shouldDisable ? undefined : onClose}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Select
      value={value}
      mode="multiple"
      onChange={onChange}
      filterOption={(inputValue, option) => {
        const label = option?.name || '';
        return label.toLowerCase().includes(inputValue.toLowerCase());
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      optionFilterProp="label"
      options={options}
      tagRender={tagRender}
    />
  );
};

import {
  message, Upload, UploadFile, UploadProps
} from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import ImgCrop from 'antd-img-crop';
import { debounce } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import StrictFormData from '../../../../hooks/ajaxRequest/formData/strictFormData';
import { useMedicalOrganizationLogoDelete, useMedicalOrganizationLogoDownloadUrl } from '../../../../uc-api-sdk';
import { useMedicalOrgCobrandingLogoUpload } from '../../hook/useMedicalOrgCobrandingLogoUpload';
import './MedicalOrgCobrandingUploadComponent.scss';

export interface MedicalOrgCobrandingUploadComponentProps {
  id: string;
  value?: string;
}

export const MedicalOrgCobrandingUploadComponent = ({
  id,
  value,
}: MedicalOrgCobrandingUploadComponentProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const {
    uploadPhoto,
  } = useMedicalOrgCobrandingLogoUpload();

  const refetchMedicalOrgService = useUpdate('MEDICAL_ORG_SERVICE_UPDATED');

  const download = useMedicalOrganizationLogoDownloadUrl({});
  const remove = useMedicalOrganizationLogoDelete({});

  const downloadFile = debounce(() => {
    const result = download.send({
      params: {
        avatarDownloadUrlInput: {
          fileKey: value,
        }
      }
    });
    ApiRequestHelper.tryCatch(result, {
      success: undefined,
      error: 'Unable to download logo. Please try again later.',
      onSuccess(res) {
        setFileList([{
          uid: '-1',
          name: 'coBrandingLogo.png',
          status: 'done',
          url: res?.data?.url || '',
        }]);
      },
    });
  }, 1000);

  const handleRemove = () => {
    const result = remove.send({
      params: {
        id,
      }
    });
    ApiRequestHelper.tryCatch(result, {
      success: 'Logo has been removed.',
      error: 'Unable to remove logo. Please try again later.',
      onSuccess() {
        setFileList([]);
        refetchMedicalOrgService.updateValue();
      },
    });
  };

  useEffect(() => {
    if (!value) return;
    downloadFile();
  }, []);

  const handleUpload = async (options: UploadRequestOption) => {
    const {
      onSuccess, file
    } = options;
    try {
      const strictFormData = new StrictFormData({
        photo: file as Blob,
      });
      const res = await uploadPhoto(strictFormData, { id });
      if (res?.code && ![200, 204].includes(res.code)) {
        throw new Error(res.msg as string);
      }
      onSuccess?.('Ok');
      refetchMedicalOrgService.updateValue();
    } catch (e) {
      console.error(e);
      message.error('Failed to upload photo');
    }
    return '';
  };

  return (
    <ImgCrop>
      <Upload
        name="coBrandingLogo"
        className="co-brand-logo-uploader"
        listType="picture-card"
        accept="image/*"
        method="put"
        fileList={fileList}
        showUploadList={{ showPreviewIcon: false, showDownloadIcon: false }}
        onChange={onChange}
        customRequest={handleUpload}
        onRemove={handleRemove}
      >
        +
      </Upload>
    </ImgCrop>
  );
};

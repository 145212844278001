import { TimezoneType } from '../../types/timezone';

// eslint-disable-next-line import/prefer-default-export
export const STATE_TIMEZONE_MAP: { [key: string]: TimezoneType } = {
  AL: TimezoneType.CT,
  AK: TimezoneType.AK,
  AZ: TimezoneType.MT,
  AR: TimezoneType.CT,
  CA: TimezoneType.PT,
  CO: TimezoneType.MT,
  CT: TimezoneType.ET,
  DE: TimezoneType.ET,
  DC: TimezoneType.ET,
  FL: TimezoneType.ET,
  GA: TimezoneType.ET,
  GU: TimezoneType.GT,
  HI: TimezoneType.HT,
  ID: TimezoneType.MT,
  IL: TimezoneType.CT,
  IN: TimezoneType.ET,
  IA: TimezoneType.CT,
  KS: TimezoneType.CT,
  KY: TimezoneType.CT,
  LA: TimezoneType.CT,
  ME: TimezoneType.ET,
  MD: TimezoneType.ET,
  MA: TimezoneType.ET,
  MI: TimezoneType.ET,
  MN: TimezoneType.CT,
  MS: TimezoneType.CT,
  MO: TimezoneType.CT,
  MT: TimezoneType.MT,
  NE: TimezoneType.CT,
  NV: TimezoneType.PT,
  NH: TimezoneType.ET,
  NJ: TimezoneType.ET,
  NM: TimezoneType.MT,
  NY: TimezoneType.ET,
  NC: TimezoneType.ET,
  ND: TimezoneType.CT,
  MP: TimezoneType.GT,
  OH: TimezoneType.ET,
  OK: TimezoneType.CT,
  OR: TimezoneType.PT,
  PA: TimezoneType.ET,
  PR: TimezoneType.ET,
  RI: TimezoneType.ET,
  SC: TimezoneType.ET,
  SD: TimezoneType.CT,
  TN: TimezoneType.CT,
  TX: TimezoneType.CT,
  VI: TimezoneType.ET,
  UT: TimezoneType.MT,
  VT: TimezoneType.ET,
  VA: TimezoneType.ET,
  WA: TimezoneType.PT,
  WV: TimezoneType.ET,
  WI: TimezoneType.CT,
  WY: TimezoneType.MT,
};

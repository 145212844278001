import _ from 'lodash';
import { ProgramCategoryEnumListComponent } from '../../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import {
  BillingFeeModelEnum, BillingGenerationEnum,
  CodeCap, InvoiceCycleEnum,
  PerCodeFeeModel, ProgramCategoryEnum
} from '../../../../../uc-api-sdk';
import CardEditWithHeader from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { BillingCodesSummaryComponent } from '../../../component/BillingCodesSummaryComponent/BillingCodesSummaryComponent';
import { ProgramFormValues } from '../../../component/MedicalOrgProgramParticipationFormComponent/MedicalOrgProgramParticipationFormComponent';
import { PricingModelSummaryComponent } from '../../../component/PricingModelSummaryComponent/PricingModelSummaryComponent';
import { BILLING_CODES } from '../../../constants/billingCodes';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { ConsentFormNameContainer } from '../../container/ConsentFormNameContainer/ConsentFormNameContainer';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { BillingCodeCheckboxValue } from '../BillingCodeInputComponent/BillingCodeInputComponent';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';

export const MedicalOrgProgramComponent = () => {
  const { medicalOrgService, refetch } = useMedicalOrgContext();
  const { open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditMedicalOrgPrograms);
  const consentFormSection = {
    key: 'Consent Form',
    value: (
      <div>
        <ConsentFormNameContainer
          id={medicalOrgService.programs?.find(p => p.consentFormId)?.consentFormId || ''}
        />
      </div>
    ),
  };

  const data = [
    {
      key: 'SaaS model clinic',
      value: medicalOrgService.isSaasModel ? 'Yes' : 'No'
    },
    {
      key: 'Program',
      value: (
        <ProgramCategoryEnumListComponent
          type="short"
          value={medicalOrgService.programs?.map(p => p.name) as ProgramCategoryEnum[]}
          splitter=", "
        />
      ),
    },
    {
      key: 'Pricing model',
      value: (<PricingModelSummaryComponent
        type={medicalOrgService.feeModel?.type || undefined}
        fees={medicalOrgService.feeList}
      />),
    },
  ];

  if (medicalOrgService.feeModelType === BillingFeeModelEnum.PER_CODE) {
    data.push({
      key: 'Applied billing code and cap',
      value: (
        <BillingCodesSummaryComponent codes={medicalOrgService.feeModel?.codes || []} />
      ),
    });
  }

  data.push(consentFormSection);

  data.push({
    key: 'iHealth invoice cycle',
    value: <DisplayOrEmptyComponent value={medicalOrgService.feeModel?.invoiceCycle} />,
  });

  data.push({
    key: 'Report required for insurance',
    value: <DisplayOrEmptyComponent value={medicalOrgService.feeModel?.isReportRequiredForInsurance ? 'Yes' : 'No'} />,
  });

  data.push({
    key: 'Billing generation',
    value: <DisplayOrEmptyComponent value={medicalOrgService.feeModel?.billingGeneration} />,
  });

  const reducer = (acc: Record<string, BillingCodeCheckboxValue>, code: string | CodeCap) => {
    const c = typeof code === 'string' ? code : code.code || '';
    acc[c] = {
      ...(typeof code === 'object' && code),
      checked: true,
      code: c,
    } as BillingCodeCheckboxValue;
    return acc;
  };

  const { feeModel = {} } = medicalOrgService;
  const perCode = _.get(medicalOrgService, 'feeModel.perCode', {} as PerCodeFeeModel);
  const assignedCodes = _.get(medicalOrgService, 'feeModel.codes', [] as CodeCap[]);
  const allCodes = [...BILLING_CODES.ccm, ...BILLING_CODES.rpm];

  const initialValues: ProgramFormValues = {
    isSaasModel: medicalOrgService.isSaasModel,
    programType: medicalOrgService.programs?.some(
      p => p.name === ProgramCategoryEnum.CCM || p.name === ProgramCategoryEnum.RPM
    ) ? 'ccmRpm' : 'valueBased',

    programs: medicalOrgService.programs?.filter(
      n => n.name !== ProgramCategoryEnum.VALUE_BASED
    ).map(p => p.name) as ProgramCategoryEnum[]
      ?? [ProgramCategoryEnum.CCM, ProgramCategoryEnum.RPM],
    consentFormId1: medicalOrgService.programs?.find(
      p => p.name !== ProgramCategoryEnum.VALUE_BASED
    )?.consentFormId || undefined,
    consentFormId2: medicalOrgService.programs?.find(
      p => p.name === ProgramCategoryEnum.VALUE_BASED
    )?.consentFormId || undefined,
    clinicId: medicalOrgService.id || undefined,
    feeModel: feeModel?.type || undefined,
    perCode: {
      dataServiceFee: perCode?.dataServiceFee || '20',
      first20MinutesFee: perCode?.first20MinutesFee || '30',
      moreThan40MinutesFee: perCode?.moreThan40MinutesFee || '60',
    },
    billingCodes: feeModel ? assignedCodes.map(r => r.code) as string[]
      : allCodes,
    codeSettings: feeModel?.type === BillingFeeModelEnum.PER_CODE
      ? assignedCodes.reduce(reducer, {}) : allCodes.reduce(reducer, {}),
    pmapm: (feeModel?.pmapm ? { ...feeModel?.pmapm } : { unitFee: '40' }),
    pbpm: (feeModel?.pbpm ? { ...feeModel?.pbpm } : { unitFee: '40' }),
    pepm: (feeModel?.pepm ? { ...feeModel?.pepm } : { unitFee: '40' }),
    invoiceCycle: feeModel?.invoiceCycle || InvoiceCycleEnum.MONTHLY,
    isReportRequiredForInsurance: feeModel?.isReportRequiredForInsurance || false,
    billingGeneration: feeModel?.billingGeneration || BillingGenerationEnum.SINGLE,
  };

  const handleClick = () => {
    open({
      onSuccess: () => {
        close();
        refetch();
      },
      initialValues
    });
  };

  return (
    <CardEditWithHeader
      modal={{
        maskClosable: false,
      }}
      header="Participated Program, Fee  Model and Consent Form"
      onEdit={handleClick}
    >
      <MedicalOrgCommonCardContentComponent data={data} />
    </CardEditWithHeader>
  );
};

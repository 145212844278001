import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { AlternativeDeviveType, useMedicalOrganizationUpdate } from '../../../../../uc-api-sdk';
import { MedicalOrgServiceDrawerComponent } from '../../../component/MedicalOrgServiceDrawerComponent/MedicalOrgServiceDrawerComponent';
import { ServiceFormValues } from '../../../component/MedicalOrgServiceFormComponent/MedicalOrgServiceFormComponent';

export interface MedicalOrgServiceContainerProps {
  initialValues?: ServiceFormValues;
  onSuccess?: () => void;
}

export const MedicalOrgServiceContainer = ({
  initialValues,
  onSuccess,
}: MedicalOrgServiceContainerProps) => {
  const hook = useMedicalOrganizationUpdate({});
  const onUpdate = async (values: ServiceFormValues) => {
    if (!initialValues?.orgId) {
      return;
    }
    const coBrandConfig = {
      coBrandName: values.coBrandName || '',
    };

    const devices = values.nonSmartPhoneAlternatives || [];
    if ((devices.includes(AlternativeDeviveType.WIFI_ROUTER)
      || devices.includes(AlternativeDeviveType.HOTSPOT))
      && !devices.includes(AlternativeDeviveType.BP_CLEAR)) {
      devices.push(AlternativeDeviveType.BP_CLEAR);
    }

    const result = hook.send({
      params: {
        id: initialValues?.orgId,
        // @ts-ignore
        document: {
          serviceConfig: {
            nonSmartPhoneAlternatives: devices,
            inClinicInventory: values.inClinicInventory,
            callCenterLocalNumber: values.callCenterLocalNumber || '',
            coBrandConfig,
          }
        }
      }
    });
    await ApiRequestHelper.tryCatch(result, {
      success: 'Service updated!',
      error: 'An error occurred. Please try again later.',
      onSuccess() {
        onSuccess?.();
      },
    });
  };

  return (
    <MedicalOrgServiceDrawerComponent
      initialValues={initialValues}
      onSubmit={onUpdate}
    />
  );
};

export default MedicalOrgServiceContainer;

import { Select } from 'antd';
import { useEffect } from 'react';
import { ConsentForm } from '../../../../uc-api-sdk';
import { SelectType } from '../../../Input/types';

export interface MedicalOrgConsentFormSelectComponentProps extends
  SelectType<string> {
  consentForms: ConsentForm[];
}

export const MedicalOrgConsentFormSelectComponent = ({
  consentForms = [],
  ...props
}: MedicalOrgConsentFormSelectComponentProps) => {
  const options = consentForms.map((form) => ({
    label: `${form.name} (${form.languages?.sort().join(',')})`,
    value: form.id,
  }));
  useEffect(() => {
    if (options.length === 1) {
      props.onChange?.(options[0].value || undefined);
    }
  }, [options.length]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...props} className="w100" options={options} />
  );
};

import { useState } from 'react';
import {
  CloseOutlined, EditOutlined, PlusOutlined
} from '@ant-design/icons';
import { Button, Space } from 'antd';
import { AlertConfigModalComponent } from '../AlertConfigModalComponent/AlertConfigModalComponent';
import { AlertRule } from '../../type';
import { EventTypeEnum } from '../../../../uc-api-sdk';
import './AlertRuleComponent.scss';

export interface AlertRuleComponentProps {
  alertId: string;
  type: EventTypeEnum;
  rules: AlertRule[];
  onDelete?: (value: string) => void;
}

export const AlertRuleComponent = ({
  alertId,
  type,
  rules,
  onDelete,
}: AlertRuleComponentProps) => {
  const [selectedRule, setSelectedRule] = useState<AlertRule | undefined>();

  const handleEdit = (
    evt: React.MouseEvent<HTMLElement, MouseEvent>,
    v: AlertRule,
  ) => {
    evt.stopPropagation();
    setSelectedRule(v);
  };

  const handleDelete = (
    evt: React.MouseEvent<HTMLElement, MouseEvent>,
    v: string,
  ) => {
    evt.stopPropagation();
    onDelete?.(v);
  };

  const handleClickAdd = () => setSelectedRule({} as AlertRule);

  const renderRule = (rule: AlertRule, i: number) => (
    <Space className="alert-rule-comp__rule" key={i}>
      <div className="underlined breakword">{rule.name}</div>
      <Button
        type="default"
        className="p0"
        onClick={(evt) => handleEdit(evt, rule)}
        icon={<EditOutlined />}
      />
      {onDelete ? (
        <Button
          type="default"
          className="p0"
          onClick={(evt) => handleDelete(evt, rule.id as string)}
          icon={<CloseOutlined />}
        />
      ) : <div className="empty-div" />}
    </Space>
  );

  return (
    <div className="alert-rule-component">
      <Space direction="vertical">
        {rules.map(renderRule)}
        <Button
          type="link"
          className="p0"
          onClick={handleClickAdd}
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      </Space>
      <AlertConfigModalComponent
        alertId={alertId}
        rule={selectedRule}
        isOpen={!!selectedRule}
        onCancel={() => setSelectedRule(undefined)}
        type={type}
      />
    </div>
  );
};

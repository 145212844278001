import { Col, Row, Timeline } from 'antd';
import classNames from 'classnames';
import { useMemo } from 'react';
import { USA_DATE } from '../../../../../constants/timeFormat';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import { TimezoneType } from '../../../../../types/timezone';
import { CareQualityStatus, FileInfo } from '../../../../../uc-api-sdk';
import ModalButton from '../../../../../uiComponent/ModalButton/ModalButton';
import { CareQualityConsentDocumentContainer } from '../../container/CareQualityConsentDocumentContainer/CareQualityConsentDocumentContainer';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import './CareQualityConsentDocHistoryComponent.scss';

export interface CareQualityConsentDocHistoryComponentProps {
  history: CareQualityStatus[];
}

export const CareQualityConsentDocHistoryComponent = ({
  history
}: CareQualityConsentDocHistoryComponentProps) => {
  const { medicalOrgService } = useMedicalOrgContext();
  const { timezone } = medicalOrgService;

  const renderTimelineItem = (item: CareQualityStatus, idx: number) => (
    <Timeline.Item key={`${item.signedDate}_${idx}`} color="gray">
      <div>
        <Row gutter={20}>
          <Col span={6}>
            {item.signedDate ? TimezoneService.calcDateTimeDayjs(item?.signedDate, timezone || TimezoneType.PT).format(USA_DATE) : ''}
          </Col>
          <Col>
            <span className={classNames({
              success: item.agreedToJoin,
              danger: !item.agreedToJoin,
              b5: true
            })}
            >
              {item.agreedToJoin ? 'Yes' : 'No'}
            </span>
          </Col>
        </Row>
        <Row>
          <Col offset={6}>
            <CareQualityConsentDocumentContainer fileInfo={item.fileInfo as FileInfo} />
          </Col>
        </Row>
      </div>
    </Timeline.Item>
  );

  const renderTimeline = useMemo(() => (
    <Timeline>
      {history.filter((h) => h.signedDate).reverse().map((item, idx) => (
        renderTimelineItem(item, idx)
      ))}
    </Timeline>
  ), [history]);

  return (
    <ModalButton
      buttonType="link"
      title="Setting history"
      buttonClassName="primary-link p0 no-border"
      button="History"
      destroyOnClose
      wrapClassName="care-quality-consent-doc-history-modal"
    >
      {renderTimeline}
    </ModalButton>
  );
};

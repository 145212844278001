import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BlueBackgroundComponent } from '../BlueBackgroundComponent/BlueBackgroundComponent';
import { PerCodeFeeModelButtonComponent } from '../PerCodeFeeModelButtonComponent/PerCodeFeeModelButtonComponent';

export const PepmFeeModelFormItemComponent = () => (
  <BlueBackgroundComponent>
    <FormItem name={['pepm', 'unitFee']} required>
      <PerCodeFeeModelButtonComponent
        options={[12, 20, 29, 38, 40]}
        title="Fee for each patient enrolled"
      />
    </FormItem>
  </BlueBackgroundComponent>
);

import { Button, Drawer, FormProps } from 'antd';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { AssociatedFormValues, AssociateMedicalOrgFormComponent } from '../AssociateMedicalOrgFormComponent/AssociateMedicalOrgFormComponent';

export interface AssociateMedicalOrgDrawerComponentProps {
  onSubmit: (values: AssociatedFormValues) => void;
  showButton?: boolean;
  initialValues?: FormProps['initialValues'];
  onSuccess?: () => void;
  restrictedMedOrgIds?: string[];
}

export const AssociateMedicalOrgDrawerComponent = ({
  onSubmit,
  initialValues,
  showButton,
  onSuccess,
  restrictedMedOrgIds,
}: AssociateMedicalOrgDrawerComponentProps) => {
  const { isOpen, open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.AssociateMedicalOrg);

  const handleSubmit = (values: AssociatedFormValues) => {
    onSubmit(values);
    close?.();
  };

  const handleClick = () => {
    open({
      initialValues,
      onSuccess
    });
  };

  return (
    <>
      {showButton && <Button type="primary" onClick={handleClick}>Associate Medical Organization</Button>}
      <Drawer
        title="Manage Associated Medical Organization"
        destroyOnClose
        open={isOpen}
        onClose={close}
        width={643}
      >
        <AssociateMedicalOrgFormComponent
          onSubmit={handleSubmit}
          initialValues={initialValues}
          restrictedMedOrgIds={restrictedMedOrgIds}
        />
      </Drawer>
    </>
  );
};

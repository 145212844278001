import React, { useCallback, useContext, useState } from 'react';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import { useGetContextValue } from '../../../../../hooks/useGetContextValue/useGetContextValue';
import { DefaultDrawerProps } from '../../../../../types/drawer';
import { CreateMedicalOrganizationFormDrawerContainer, CreateMedicalOrganizationFormDrawerContainerProps } from '../../../container/CreateMedicalOrganizationFormDrawerContainer/CreateMedicalOrganizationFormDrawerContainer';
import CreateUserDrawerContainer, { CreateUserDrawerContainerProps } from '../../../container/CreateUserDrawerContainer';
import { MedicalOrgProgramParticipationContainer, MedicalOrgProgramParticipationContainerProps } from '../../../container/MedicalOrgProgramParticipationContainer/MedicalOrgProgramParticipationContainer';
import { MedicalOrgEHRFormDrawerComponent, MedicalOrgEHRFormDrawerComponentProps } from '../../../medicalOrg/component/MedicalOrgEHRFormDrawerComponent/MedicalOrgEHRFormDrawerComponent';
import { AssociatedCareUnitDrawerContainer, AssociatedCareUnitDrawerContainerProps } from '../../../medicalOrg/container/AssociatedCareUnitDrawerContainer/AssociatedCareUnitDrawerContainer';
import { AssociatedMedicalOrgDrawerContainer, AssociatedMedicalOrgDrawerContainerProps } from '../../../medicalOrg/container/AssociatedMedicalOrgDrawerContainer/AssociatedMedicalOrgDrawerContainer';
import { EditOutstandingItemConfigDrawerContainer, EditOutstandingItemConfigDrawerContainerProps } from '../../../medicalOrg/container/EditOutstandingItemConfigDrawerContainer/EditOutstandingItemConfigDrawerContainer';
import { MedicalOrgBillingFormContainer, MedicalOrgBillingFormContainerProps } from '../../../medicalOrg/container/MedicalOrgBillingFormContainer/MedicalOrgBillingFormContainer';
import { MedicalOrgServiceContainer, MedicalOrgServiceContainerProps } from '../../../MedicalOrgService/container/MedicalOrgServiceContainer/MedicalOrgServiceContainer';
import { CreateEditMobileVersionConfigDrawerContainer, CreateEditMobileVersionConfigDrawerContainerProps } from '../../../../mobileVersionConfiguration/containers/CreateEditMobileVersionConfigDrawerContainer/CreateEditMobileVersionConfigDrawerContainer';

export enum PopupDrawerTypes {
  CreateUser = 'CreateUser',
  CreateMedicalOrg = 'CreateMedicalOrg',
  EditMedicalOrgBilling = 'EditMedicalOrgBilling',
  EditMedicalOrgService = 'EditMedicalOrgService',
  EditMedicalOrgPrograms = 'EditMedicalOrgPrograms',
  EditMedicalOrgEHR = 'EditMedicalOrgEHR',
  AssociateMedicalOrg = 'AssociateMedicalOrg',
  AssociateCareUnit = 'AssociateCareUnit',
  EditOutstandingItemsConfig = 'EditOutstandingItemsConfig',
  CreateMobileConfig = 'CreateMobileConfig',
}
export interface OpenPopupArg extends DefaultDrawerProps {
  mode?: 'create' | 'edit';
}

type OpenPopup = (arg?: OpenPopupArg) => void;
type ClosePopup = () => void;

export interface PopupValue {
  isOpen: boolean;
  open: OpenPopup;
  close: ClosePopup;
}

export interface AdminPortalPopupValue {
  getDrawer: (type: PopupDrawerTypes) => PopupValue;
}

export const AdminPortalPopupContext = React.createContext<
  AdminPortalPopupValue
  | undefined>(undefined);

export interface AdminPortalPopupContextProviderProps {
  children: React.ReactNode;
}

export const AdminPortalPopupContextProvider = ({
  children,
}: AdminPortalPopupContextProviderProps) => {
  // Create user drawer
  const [args, setArgs] = useState<OpenPopupArg | undefined>();
  const [currentDrawer, setCurrentDrawer] = useState<PopupDrawerTypes | undefined>();

  const drawers = Object.keys(PopupDrawerTypes).reduce((acc, key) => {
    acc[key as PopupDrawerTypes] = useOpen();
    return acc;
  }, {} as Record<PopupDrawerTypes, ReturnType<typeof useOpen>>);

  const getDrawer = useCallback((type: PopupDrawerTypes) => ({
    ...drawers[type],
    open: (arg?: OpenPopupArg) => {
      setCurrentDrawer(type);
      setArgs(arg);
      drawers[type].open();
    }
  }), [Object.values(drawers).map((drawer) => drawer.isOpen)]);

  const renderDrawer = useCallback(() => {
    switch (currentDrawer) {
      case PopupDrawerTypes.CreateUser:
        return (
          <CreateUserDrawerContainer
            showButton={false}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as CreateUserDrawerContainerProps}
          />
        );
      case PopupDrawerTypes.CreateMedicalOrg:
        return (
          <CreateMedicalOrganizationFormDrawerContainer
            showButton={false}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as CreateMedicalOrganizationFormDrawerContainerProps}
          />
        );
      case PopupDrawerTypes.EditMedicalOrgBilling:
        return (
          <MedicalOrgBillingFormContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as MedicalOrgBillingFormContainerProps}
          />
        );
      case PopupDrawerTypes.EditMedicalOrgService:
        return (
          <MedicalOrgServiceContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as MedicalOrgServiceContainerProps}
          />
        );
      case PopupDrawerTypes.EditMedicalOrgPrograms:
        return (
          <MedicalOrgProgramParticipationContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as MedicalOrgProgramParticipationContainerProps}
          />
        );
      case PopupDrawerTypes.EditMedicalOrgEHR:
        return (
          <MedicalOrgEHRFormDrawerComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as MedicalOrgEHRFormDrawerComponentProps}
          />
        );
      case PopupDrawerTypes.AssociateCareUnit:
        return (
          <AssociatedCareUnitDrawerContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as AssociatedCareUnitDrawerContainerProps}
          />
        );
      case PopupDrawerTypes.AssociateMedicalOrg:
        return (
          <AssociatedMedicalOrgDrawerContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as AssociatedMedicalOrgDrawerContainerProps}
          />
        );
      case PopupDrawerTypes.EditOutstandingItemsConfig:
        return (
          <EditOutstandingItemConfigDrawerContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as EditOutstandingItemConfigDrawerContainerProps}
          />
        );
      case PopupDrawerTypes.CreateMobileConfig:
        return (
          <CreateEditMobileVersionConfigDrawerContainer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args as CreateEditMobileVersionConfigDrawerContainerProps}
          />
        );
      default:
        return null;
    }
  }, [currentDrawer, args]);

  const value = useGetContextValue<AdminPortalPopupValue>({
    getDrawer
  });

  return (
    <AdminPortalPopupContext.Provider value={value}>
      {children}
      {renderDrawer()}
    </AdminPortalPopupContext.Provider>
  );
};

export const useAdminPortalPopupContext = () => {
  const context = useContext(AdminPortalPopupContext);
  return (context || {}) as AdminPortalPopupValue;
};

/* eslint-disable @typescript-eslint/no-empty-function */
import {
  find, flatMap, map, values
} from 'lodash';
import {
  createContext,
  ReactNode,
  useContext,
  useMemo
} from 'react';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import {
  AlertCategoryEnum,
  AlertDetail,
  CustomAlertCreateRequest,
  RuleBase,
  RuleItem,
  useRuleEngineAddCustomAlert,
  useRuleEngineAddRule,
  useRuleEngineDeleteClinicConfig,
  useRuleEngineDeleteRule,
  useRuleEngineGetClinicConfig,
  useRuleEngineUpdateClinicConfig,
  useRuleEngineUpdateCustomAlert,
  useRuleEngineUpdateRule
} from '../../../uc-api-sdk';
import { AlertsValue } from '../type';

interface AlertApiContextValue {
  alerts: AlertsValue;
  refetch: () => void;
  onAddRule: (alertId: string, rule: RuleBase) => void;
  onDuplicateAlert: (alertId: string) => void;
  onActiveChange: (alertId: string, isActive: boolean) => void;
  onDeleteAlert: (alertId: string) => void;
  onEditAlert: (alert: AlertDetail) => void;
  onEditRule: (alertId: string, rule: RuleItem) => void;
  onAddCustomAlert: (alert: CustomAlertCreateRequest) => void;
  onDeleteRule?: (alertId: string, ruleId: string) => void;
  isLoading: boolean;
  isEditAlertLoading: boolean;
  isEditRuleLoading: boolean;
  isDeleteAlertLoading: boolean;
  isDuplicationLoading: boolean;
  isDeleteRuleLoading: boolean;
  isAddRuleLoading: boolean;
  isAddCustomAlertLoading: boolean;
  isActiveChangeLoading: boolean;
}

const AlertApiContext = createContext<AlertApiContextValue>({
  alerts: {} as AlertsValue,
  refetch: () => { },
  onAddRule: () => { },
  onDuplicateAlert: () => { },
  onActiveChange: () => { },
  onDeleteAlert: () => { },
  onEditAlert: () => { },
  onEditRule: () => { },
  onAddCustomAlert: () => { },
  onDeleteRule: () => { },
  isLoading: false,
  isEditAlertLoading: false,
  isAddCustomAlertLoading: false,
  isDuplicationLoading: false,
  isActiveChangeLoading: false,
  isDeleteAlertLoading: false,
  isDeleteRuleLoading: false,
  isEditRuleLoading: false,
  isAddRuleLoading: false,
});

export interface AlertApiContextProviderProps {
  clinicId: string;
  children: ReactNode;
}

export const AlertApiContextProvider = ({
  clinicId,
  children,
}: AlertApiContextProviderProps) => {
  const getAlerts = useRuleEngineGetClinicConfig({
    options: { sendOnMount: true },
    params: {
      clinicId,
    }
  });
  const addCustomAlert = useRuleEngineAddCustomAlert({});
  const updateCustomAlert = useRuleEngineUpdateCustomAlert({});
  const updateAlert = useRuleEngineUpdateClinicConfig({});
  const deleteAlert = useRuleEngineDeleteClinicConfig({});
  const addRule = useRuleEngineAddRule({});
  const updateRule = useRuleEngineUpdateRule({});
  const deleteRule = useRuleEngineDeleteRule({});

  const alerts = useMemo(() => {
    const processedAlerts: AlertsValue = {
      clinicalAlerts: getAlerts.data?.data?.CLINICAL || [],
      providerAlerts: getAlerts.data?.data?.PROVIDER || [],
      customizedProviderAlerts: [
        ...(getAlerts.data?.data?.CUSTOM_CLINICAL || []),
        ...(getAlerts.data?.data?.CUSTOM_PROVIDER || []),
      ],
    };
    return processedAlerts;
  }, [getAlerts.data?.data]);

  const getFlatAlerts = () => flatMap(values(alerts));

  const findAlert = (alertId: string) => {
    const allAlerts = flatMap(values(alerts));
    const alert = find(allAlerts, (a) => a.alertType === alertId);
    return alert;
  };

  const onDuplicateAlert: AlertApiContextValue['onDuplicateAlert'] = (alertId) => {
    const alert = findAlert(alertId);
    if (!alert) return;
    const req = addCustomAlert.send({
      params: {
        request: {
          ...alert,
          alertCategory: AlertCategoryEnum.PROVIDER,
          clinicId,
          active: false,
          displayName: `${alert.displayName} Copy`,
          rules: (alert.rules || []).map((rule) => ({ ...rule, id: undefined })),
        } as CustomAlertCreateRequest,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Alert duplicated successfully!',
      error: 'Failed to duplicate alert!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onActiveChange: AlertApiContextValue['onActiveChange'] = (alertId, isActive) => {
    const alert = findAlert(alertId);
    if (!alert) return;
    const allAlerts = getFlatAlerts();
    const updatedAlerts = map(allAlerts, (a) => {
      if (a.alertType === alert.alertType) {
        return {
          ...a,
          active: isActive,
        };
      }
      return a;
    });
    const filteredActiveAlerts = updatedAlerts.filter((a) => a.active);

    const req = updateAlert.send({
      params: {
        request: {
          clinicId,
          alertTypes: map(filteredActiveAlerts, (a) => a.alertType as string),
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Activated alert successfully!',
      error: 'Failed to activate alert!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onDeleteAlert: AlertApiContextValue['onDeleteAlert'] = (alertId) => {
    const req = deleteAlert.send({
      params: {
        request: {
          clinicId,
          alertTypes: [encodeURI(alertId)],
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Deleted alert successfully!',
      error: 'Failed to delete alert!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onAddCustomAlert: AlertApiContextValue['onAddCustomAlert'] = (alert) => {
    const req = addCustomAlert.send({
      params: {
        request: {
          ...alert,
          rules: [],
          clinicId,
        } as CustomAlertCreateRequest,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Added custom alert successfully!',
      error: 'Failed to add custom alert!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onAddRule: AlertApiContextValue['onAddRule'] = (alertId, rule) => {
    const req = addRule.send({
      params: {
        alertType: encodeURI(alertId),
        rule,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Added alert rule successfully!',
      error: 'Failed to added alert rule!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onEditAlert: AlertApiContextValue['onEditAlert'] = (alert) => {
    const oldAlert = findAlert(alert.alertType as string);
    if (!alert) return;
    const req = updateCustomAlert.send({
      params: {
        request: {
          ...oldAlert,
          ...alert,
          clinicId,
        } as CustomAlertCreateRequest,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Updated alert successfully!',
      error: 'Failed to update alert!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onEditRule: AlertApiContextValue['onEditRule'] = (alertId, rule) => {
    const req = updateRule.send({
      params: {
        alertType: encodeURI(alertId),
        rule,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Updated alert rule successfully!',
      error: 'Failed to update alert rule!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const onDeleteRule: AlertApiContextValue['onDeleteRule'] = (alertId, ruleId) => {
    const req = deleteRule.send({
      params: {
        alertType: encodeURI(alertId),
        request: {
          ids: [ruleId],
        }
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Deleted alert rule successfully!',
      error: 'Failed to delete alert rule!',
      onSuccess: () => {
        getAlerts.refetch();
      }
    });
  };

  const contextValue = useMemo<AlertApiContextValue>(() => ({
    refetch: getAlerts.refetch,
    alerts,
    onDuplicateAlert,
    onActiveChange,
    onDeleteAlert,
    onEditAlert,
    onAddCustomAlert,
    onAddRule,
    onEditRule,
    onDeleteRule,
    isLoading: getAlerts.isLoading,
    isActiveChangeLoading: updateAlert.isLoading,
    isDeleteAlertLoading: deleteAlert.isLoading,
    isDeleteRuleLoading: deleteRule.isLoading,
    isAddRuleLoading: addRule.isLoading,
    isAddCustomAlertLoading: addCustomAlert.isLoading,
    isEditAlertLoading: updateCustomAlert.isLoading,
    isEditRuleLoading: updateRule.isLoading,
    isDuplicationLoading: addCustomAlert.isLoading,
  }), [
    alerts,
    addCustomAlert.isLoading,
    addRule.isLoading,
    deleteAlert.isLoading,
    updateCustomAlert.isLoading,
    getAlerts.isLoading,
    updateRule.isLoading,
    deleteRule.isLoading,
  ]);

  return (
    <AlertApiContext.Provider
      value={contextValue}
    >
      {children}
    </AlertApiContext.Provider>
  );
};

export const useAlertApiContext = () => {
  const context = useContext(AlertApiContext) || {};
  return context;
};

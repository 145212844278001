import { InfoCircleFilled } from '@ant-design/icons';
import { Button, UploadProps } from 'antd';
// @ts-ignore
import CONSENT_TEMPLATE_FILE from '../../../../../assets/files/pdfs/Join_Care_Quality_Network_Consent.pdf';
import { FileInfo } from '../../../../../uc-api-sdk';
import { UploadComponent } from '../../../../../uiComponent/UploadComponent/UploadComponent';
import { CareQualityConsentDocumentComponent } from '../CareQualityConsentDocumentComponent/CareQualityConsentDocumentComponent';

export interface MedicalOrgCareQualityStep1ComponentProps {
  file?: string;
  fileName?: string;
  fileInfo?: FileInfo;
  action?: UploadProps['action'];
}

export const MedicalOrgCareQualityStep1Component = ({
  file,
  fileName,
  fileInfo,
  action,
}: MedicalOrgCareQualityStep1ComponentProps) => (
  <div className="p20 bg-gray5 default-br text-gray-scale-2">
    <strong>
      Step 1. Upload provider's consent to share UC data with Care Quality Network
      {' '}
      <span className="text-red">*</span>
    </strong>
    <p>
      To enable, it's required to share patients data from UC to Care Quality.
      Get provider's consent and upload the signed form to enable data sharing.
      {' '}
      <a className="underline p0 text-gray-scale-2" download href={CONSENT_TEMPLATE_FILE}>Download Consent Form Template</a>
    </p>

    {(file || fileInfo) && (
      <div className="white-card p10">
        <CareQualityConsentDocumentComponent
          file={file}
          showUpdateButton
          action={action}
          fileName={fileName}
          existingFile={fileInfo}
        />
      </div>
    )}
    {!file && !fileInfo && (
      <div>
        <UploadComponent
          action={action}
          showUploadList={false}
          accept=".pdf"
        >
          <Button type="link" className="primary-link p0 no-border">Upload consent form</Button>
        </UploadComponent>
      </div>
    )}
    {!file && !fileInfo && (
      <div className="text-red">
        <InfoCircleFilled />
        {' '}
        Consent form is required.
      </div>
    )}
  </div>
);

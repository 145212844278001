import { Button, Drawer } from 'antd';
import { EditMobileConfigContainer } from '../../containers/EditMobileConfigContainer/EditMobileConfigContainer';
import { AppVersionConfig } from '../../../../uc-api-sdk';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

export interface CreateEditMobileConfigDrawerComponentProps {
  initialValues?: AppVersionConfig;
}

// this is Edit drawer component.
export const CreateEditMobileConfigDrawerComponent = ({
  initialValues,
}: CreateEditMobileConfigDrawerComponentProps) => {
  const { isOpen, open, close } = useOpen();

  return (
    <>
      <Button type="primary" onClick={open}>Edit</Button>
      <Drawer
        destroyOnClose
        width={643}
        open={isOpen}
        onClose={close}
        title="Edit Mobile Version Configuration"
      >
        <EditMobileConfigContainer onSuccess={close} initialValues={initialValues} />
      </Drawer>
    </>
  );
};

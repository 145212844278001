import {
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
} from 'antd';
import { InputType } from '../../../Input/types';
import { OperatorValueType } from '../../type';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import './OperatorValueInputComponent.scss';

export type OperatorValueInputValue = boolean | string | string[] | undefined;

export interface OperatorValueInputComponentProps extends InputType<OperatorValueInputValue> {
  type: OperatorValueType | undefined;
  options: string[];
}

export const OperatorValueInputComponent = ({
  type,
  options,
  onChange,
  value,
}: OperatorValueInputComponentProps) => {
  const handleNumberChange: InputNumberProps['onChange'] = (v) => {
    onChange?.(v?.toString());
  };

  const handleInputChange: InputProps['onChange'] = (evt) => {
    onChange?.(evt.target.value);
  };

  const handleSelectChange: EnumSelectComponentProps<string>['onChange'] = (v) => {
    onChange?.(v);
  };

  const renderOperatorType = () => {
    switch (type) {
      case OperatorValueType.BOOLEAN: return (
        <YesNoRadioComponent value={value as boolean} onChange={onChange} />
      );
      case OperatorValueType.INT: return (
        <InputNumber precision={0} value={value as string} onChange={handleNumberChange} />
      );
      case OperatorValueType.DOUBLE: return (
        <InputNumber value={value as string} onChange={handleNumberChange} />
      );
      case OperatorValueType.STRING: return (
        <Input value={value as string} onChange={handleInputChange} />
      );
      case OperatorValueType.SELECT: return (
        <EnumSelectComponent
          className="select-option-comp"
          options={options}
          value={value as string}
          onChange={handleSelectChange}
        />
      );
      case OperatorValueType.SELECT_MULTIPLE: return (
        <EnumSelectComponent
          className="select-option-comp"
          options={options}
          value={value as string}
          onChange={handleSelectChange}
          mode="multiple"
        />
      );
      case undefined: return null;
      default:
        return <div>Input type not supported</div>;
    }
  };

  return (
    <div className="operator-value-input-component">
      {renderOperatorType()}
    </div>
  );
};

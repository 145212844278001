import { SymptomEnum } from '../../../../uc-api-sdk';

export interface SymptomNameEnumComponentProps {
  value: SymptomEnum | undefined;
  type?: 'name';
}

export const symptomNameMap: Record<SymptomEnum, string> = {
  [SymptomEnum.ABDOMINAL_PAIN]: 'Abdominal Pain',
  [SymptomEnum.CONSTIPATION]: 'Constipation',
  [SymptomEnum.DIARRHEA]: 'Diarrhea',
  [SymptomEnum.DIZZINESS]: 'Dizziness',
  [SymptomEnum.HAIR_LOSS]: 'Hair Loss',
  [SymptomEnum.HEADACHE]: 'Headache',
  [SymptomEnum.LACK_OF_APPETITE]: 'Lack of Appetite',
  [SymptomEnum.NAUSEA]: 'Nausea',
  [SymptomEnum.RASH]: 'Rash',
  [SymptomEnum.REACTION_AT_INJECTION_SITE]: 'Reaction at Injection Site',
  [SymptomEnum.VOMITING]: 'Vomiting',
  [SymptomEnum.OTHER]: 'Other',
};

export const SymptomNameEnumComponent = ({
  value,
  type = 'name',
}: SymptomNameEnumComponentProps) => {
  const getSymptomNameText = () => {
    switch (value) {
      case SymptomEnum.ABDOMINAL_PAIN:
        return 'Abdominal Pain';
      case SymptomEnum.DIARRHEA:
        return 'Diarrhea';
      case SymptomEnum.CONSTIPATION:
        return 'Constipation';
      case SymptomEnum.DIZZINESS:
        return 'Dizziness';
      case SymptomEnum.HAIR_LOSS:
        return 'Hair Loss';
      case SymptomEnum.HEADACHE:
        return 'Headache';
      case SymptomEnum.LACK_OF_APPETITE:
        return 'Lack of Appetite';
      case SymptomEnum.NAUSEA:
        return 'Nausea';
      case SymptomEnum.RASH:
        return 'Rash';
      case SymptomEnum.REACTION_AT_INJECTION_SITE:
        return 'Reaction at Injection Site';
      case SymptomEnum.VOMITING:
        return 'Vomiting';
      default:
        return 'Other';
    }
  };

  switch (type) {
    case 'name':
      return <>{getSymptomNameText()}</>;
    default:
      return <>{getSymptomNameText()}</>;
  }
};

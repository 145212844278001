import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FC } from 'react';
import { AlternativeDeviveType } from '../../../../uc-api-sdk';
import { DeviceBundleComponent } from '../../../device/component/DeviceBundleComponent/DeviceBundleComponent';
import './MedicalOrgDeviceSelectComponent.scss';

export interface MedicalOrgDeviceSelectComponentProps extends CheckboxGroupProps {
}

export const MedicalOrgDeviceSelectComponent: FC<MedicalOrgDeviceSelectComponentProps> = ({
  value,
  onChange,
}) => (
  <div className="AdminDeviceSelectComponent device-select-component" data-testid="adminDeviceSelectComponent">
    <Checkbox.Group
      value={value}
      onChange={onChange}
      className="device-select-item-wrapper flex fd-c"
    >
      <div className="mt20 text-gray-scale-2 b5">BP devices</div>
      <div className="flex ai-c f-w gap0">
        <div className="deviceCheckbox text-align-center flex jc-c">
          <Checkbox value={AlternativeDeviveType.WIFI_ROUTER}>
            <DeviceBundleComponent devices={[
              AlternativeDeviveType.BP_CLEAR,
              AlternativeDeviveType.WIFI_ROUTER,
            ]}
            />
          </Checkbox>
        </div>
        <div className="deviceCheckbox text-align-center flex fd-c ai-c">
          <Checkbox value={AlternativeDeviveType.HOTSPOT}>
            <DeviceBundleComponent devices={[
              AlternativeDeviveType.BP_CLEAR,
              AlternativeDeviveType.HOTSPOT,
            ]}
            />
          </Checkbox>
        </div>
        <div className="deviceCheckbox text-align-center flex fd-c ai-c">
          <Checkbox value={AlternativeDeviveType.BP_CELLULAR_PYLO}>
            <DeviceBundleComponent devices={[AlternativeDeviveType.BP_CELLULAR_PYLO]} />
          </Checkbox>
        </div>
      </div>
    </Checkbox.Group>
  </div>
);

import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { pdfStringHeaders } from '../../../../../helpers/file';
import { FileInfo } from '../../../../../uc-api-sdk';
import ModalButton from '../../../../../uiComponent/ModalButton/ModalButton';
import { PDFViewerComponent } from '../../../../../uiComponent/PDFViewerComponent/PDFViewerComponent';
import { UploadComponent, UploadComponentProps } from '../../../../../uiComponent/UploadComponent/UploadComponent';
import { CareQualityConsentDocumentContainer } from '../../container/CareQualityConsentDocumentContainer/CareQualityConsentDocumentContainer';
import './CareQualityConsentDocumentComponent.scss';

export interface CareQualityConsentDocumentComponentProps {
  file?: string;
  fileName?: string;
  showUpdateButton?: boolean;
  action?: UploadComponentProps['action'];
  existingFile?: FileInfo;
}

export const CareQualityConsentDocumentComponent = ({
  file,
  showUpdateButton,
  action,
  fileName,
  existingFile
}: CareQualityConsentDocumentComponentProps) => {
  const renderExistingFile = () => (existingFile ? (
    <CareQualityConsentDocumentContainer fileInfo={existingFile} />
  ) : null);

  const renderUploadFile = () => (
    <div className="flex ai-c">
      <CheckCircleFilled className="primary-link fs20" />
      <ModalButton
        title="Care Quality Consent Document"
        width={800}
        footer={null}
        button={fileName}
        buttonType="link"
        className="f1"
        bodyStyle={{ height: '80vh' }}
        buttonClassName="preview-consent-button text-gray-scale-1 underline breakword ta-l autoH"
      >
        <PDFViewerComponent
          title={fileName}
          fileUrl={`${pdfStringHeaders}${file}`}
          height="100%"
        />
      </ModalButton>
    </div>
  );

  return (
    <div className="flex jc-sb ai-c">
      {file ? renderUploadFile() : renderExistingFile()}
      {showUpdateButton && (
      <UploadComponent
        action={action}
        showUploadList={false}
        accept=".pdf"
      >
        <Button type="link" className="primary-link p0 no-border">Update</Button>
      </UploadComponent>
      )}
    </div>
  );
};

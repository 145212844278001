import {
  Button, Form, Modal, Select
} from 'antd';
import { MeasurementDeletionReasonEnumComponent } from '../../../../enumComponent/MeasurementDeletionReasonEnumComponent/MeasurementDeletionReasonEnumComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { MeasurementDeletionReasonEnum } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface HSDeleteCFormValues {
  deleteReason: MeasurementDeletionReasonEnum;
}

export interface HSDeleteConfirmModalComponentProps {
  onSubmit?: (v: HSDeleteCFormValues) => void;
  isLoading?: boolean;
}

export const HSDeleteConfirmModalComponent = ({
  onSubmit,
  isLoading
}: HSDeleteConfirmModalComponentProps) => {
  const { isOpen, open, close } = useOpen();
  const [form] = Form.useForm();

  const handleSubmit = async (v: HSDeleteCFormValues) => {
    await onSubmit?.(v);
    form.resetFields();
    close();
  };

  const options = Object.keys(MeasurementDeletionReasonEnum).map((key) => ({
    label: <MeasurementDeletionReasonEnumComponent value={key as MeasurementDeletionReasonEnum} />,
    value: key
  }));

  return (
    <div>
      <Modal
        title="Delete this measurement?"
        open={isOpen}
        onCancel={close}
        footer={null}
      >
        <Form
          onFinish={handleSubmit}
          layout="vertical"
          form={form}
          disabled={isLoading}
        >
          <FormItem
            required
            name="deleteReason"
            label="Reason for Deletion"
          >
            <Select options={options} />
          </FormItem>
          <div className="text-gray-scale-2 fs12">
            <span className="danger">Disclaimer: </span>
            Removing this weight log may affect patient measurement days and potentially
            impact billing-related measurement days metrics.
          </div>
          <FormItem>
            <div className="flex gap2 mt20">
              <Button onClick={close}>Cancel</Button>
              <Button type="primary" danger htmlType="submit">Confirm Deletion</Button>
            </div>
          </FormItem>
        </Form>
      </Modal>
      <Button danger onClick={open} loading={isLoading}>Delete Measurement</Button>
    </div>
  );
};

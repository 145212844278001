import { Button } from 'antd';
import { AccountHelper } from '../../../../helpers/account';
import { csv, makeCSVData } from '../../../../helpers/csv';
import { WeightService } from '../../../../helpers/weight';
import { Measurement, MeasurementResultTypeEnum, Patient } from '../../../../uc-api-sdk';
import { BGHelper } from '../../helper/BGHelper';

export interface VitalsToCsvButtonComponentProps {
  patientInfo?: Patient;
  measurements?: Measurement[];
  isLoading?: boolean;
}

export const VitalsToCsvButtonComponent = ({
  patientInfo,
  measurements,
  isLoading
}: VitalsToCsvButtonComponentProps) => {
  const { id: memberId } = patientInfo || {};

  const getMeasurementValue = (measurement: Measurement) => {
    switch (measurement.type) {
      case MeasurementResultTypeEnum.BP:
        return `${Number(measurement.systolic_blood_pressure?.value)?.toFixed(0)}/${Number(measurement.diastolic_blood_pressure?.value)?.toFixed(0)} ${measurement.diastolic_blood_pressure?.unit}`;
      case MeasurementResultTypeEnum.BG:
        if (measurement.blood_glucose?.unit?.toLocaleLowerCase() === 'mmol/l') {
          return `${BGHelper.mmollToMgdl(Number(measurement.blood_glucose?.value || 0)).toFixed(0)} mg/dL`;
        }
        return `${Number(measurement.blood_glucose?.value)?.toFixed(0)} ${measurement.blood_glucose?.unit}`;
      case MeasurementResultTypeEnum.PO:
        return `${Number(measurement.blood_oxygen?.value)?.toFixed(0)}${measurement.blood_oxygen?.unit}`;
      case MeasurementResultTypeEnum.HS:
        if (measurement.body_weight?.unit?.toLowerCase() === 'kg') {
          return `${WeightService.kgToLb(Number(measurement.body_weight?.value || 0))} lb`;
        }
        return `${Number(measurement.body_weight?.value)?.toFixed(1)} lb`;
      default:
        return '';
    }
  };

  const toRows = (data: Measurement[]) => data.map((item) => ([
    item.date as string,
    item.type as string,
    getMeasurementValue(item).trim() as string,
  ]));

  const onClick = () => {
    const fullName = AccountHelper.getFullName(patientInfo?.profile);
    const fileName = `Vitals_${fullName}_${memberId}`;
    const contents = [] as string[][];
    contents.push(['Date', 'Type', 'Value']);
    contents.push(...toRows(measurements || []));
    csv(makeCSVData(contents || []), fileName);
  };

  if (!memberId) return null;

  return (
    <Button loading={isLoading} onClick={onClick} type="primary">To .csv</Button>
  );
};

import { Drawer, FormProps } from 'antd';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { BillingEntityFormValues, MedicalOrgBillingEntityFormComponent } from '../MedicalOrgBillingEntityFormComponent/MedicalOrgBillingEntityFormComponent';

export interface MedicalOrgBillingEntityDrawerComponentProps {
  onSubmit: (v: BillingEntityFormValues) => void;
  initialValues: FormProps['initialValues'];
}

export const MedicalOrgBillingEntityDrawerComponent = ({
  onSubmit,
  initialValues,
}: MedicalOrgBillingEntityDrawerComponentProps) => {
  const { getDrawer } = useAdminPortalPopupContext();
  const { isOpen, close } = getDrawer(PopupDrawerTypes.EditMedicalOrgBilling);

  return (
    <Drawer
      destroyOnClose
      title="Billing Entity"
      open={isOpen}
      onClose={close}
      width={643}
    >
      <MedicalOrgBillingEntityFormComponent onSubmit={onSubmit} initialValues={initialValues} />
    </Drawer>
  );
};

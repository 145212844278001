import { Button, Drawer } from 'antd';
import { DefaultDrawerProps } from '../../../../../types/drawer';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { MedicalOrgOutstandingItemConfigFormComponent, OutstandingItemConfigFormValues } from '../MedicalOrgOutstandingItemConfigFormComponent/MedicalOrgOutstandingItemConfigFormComponent';

export interface EditOutstandingItemConfigDrawerComponentProps extends DefaultDrawerProps {
  onSubmit: (values: OutstandingItemConfigFormValues) => void;
}

export const EditOutstandingItemConfigDrawerComponent = ({
  onSubmit,
  initialValues,
  showButton,
  onSuccess
}: EditOutstandingItemConfigDrawerComponentProps) => {
  const { isOpen, open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditOutstandingItemsConfig);

  const handleSubmit = (values: OutstandingItemConfigFormValues) => {
    onSubmit(values);
    close?.();
  };

  const handleClick = () => {
    open({
      initialValues,
      onSuccess
    });
  };

  return (
    <>
      {showButton && <Button type="primary" onClick={handleClick}>Associate Medical Organization</Button>}
      <Drawer
        title="Outstanding Items Configuration Settings"
        size="large"
        destroyOnClose
        open={isOpen}
        onClose={close}
      >
        <MedicalOrgOutstandingItemConfigFormComponent
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </Drawer>
    </>
  );
};

import { CloseOutlined } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ColumnType } from 'antd/lib/table';
import { AccountHelper } from '../../../../../helpers/account';
import { Employee } from '../../../../../uc-api-sdk';
import { EmployeeSelectComponentInfo } from '../../../../Employee/component/EmployeeSelectComponent/EmployeeSelectComponent';
import { MiniUserComponent } from '../../../../user/component/MiniUserComponent/MiniUserComponent';
import { StaffRolesCheckboxGroupComponent } from './StaffRolesCheckboxGroupComponent';

export const createColumns = {
  name: (): ColumnType<EmployeeSelectComponentInfo<Employee>> => ({
    title: 'Name',
    render: (value, d?: EmployeeSelectComponentInfo<Employee>) => (
      <MiniUserComponent
        avatar={d?.info.employeeProfile?.avatar?.url || undefined}
        name={AccountHelper.getFullName(d?.info.employeeProfile)}
        email={d?.info.email || undefined}
        isObserver={d?.info.isObserverUser as boolean}
      />
    ),
    width: '45%',
  }),
  role: (onChange?: (v: CheckboxValueType[]) => void): ColumnType<
    EmployeeSelectComponentInfo<Employee>
  > => ({
    title: 'Role in this Org',
    render: (value, d?: EmployeeSelectComponentInfo<Employee>) => (
      <StaffRolesCheckboxGroupComponent
        onChange={onChange}
        name={d?.info.id || ''}
        data={d}
        useNamedValues
      />
    ),
    width: '45%',
  }),
  action: (removeStaff?: (id: string) => void):
   ColumnType<EmployeeSelectComponentInfo<Employee>> => ({
    render: (value, d?: EmployeeSelectComponentInfo<Employee>) => (
      <CloseOutlined
        onClick={
        () => {
          removeStaff?.(d?.info.id || '');
        }
      }
      />
    ),
  }),
};

import { Space } from 'antd';
import { FC, ReactNode } from 'react';
import { Icon } from '../../../../icons/Icon';
import {
  HAD_MEDS_ICON, HAD_INSULIN_ICON, EXERCISED_ICON, HAD_SYMPTOM_ICON
} from '../../../../assets/commonIconInfo';

export interface MeasurementTagsIconsComponentProps {
  hadActivity?: boolean;
  hadInsulin?: boolean;
  hadMedicine?: boolean;
  hadSymptom?: boolean;
  color?: string;
}

const SIZE = 10;

export const MeasurementTagsIconsComponent: FC<MeasurementTagsIconsComponentProps> = ({
  hadActivity,
  hadInsulin,
  hadMedicine,
  hadSymptom,
  color,
}) => {
  const tags: ReactNode[] = [];

  if (hadMedicine) {
    tags.push((
      <Icon
        key="medicine"
        info={HAD_MEDS_ICON}
        size={SIZE}
        hexColor={color}
      />
    ));
  }
  if (hadInsulin) {
    tags.push((
      <Icon
        key="insulin"
        info={HAD_INSULIN_ICON}
        size={SIZE}
        hexColor={color}
      />
    ));
  }
  if (hadActivity) {
    tags.push((
      <Icon
        key="activity"
        info={EXERCISED_ICON}
        size={SIZE}
        hexColor={color}
      />
    ));
  }
  if (hadSymptom) {
    tags.push((
      <Icon
        key="symptom"
        info={HAD_SYMPTOM_ICON}
        size={SIZE}
        hexColor={color}
      />
    ));
  }

  return tags.length > 0
    ? <Space size={2}>{tags}</Space>
    : null;
};

import { MeasurementDeletionReasonEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface MeasurementDeletionReasonEnumComponentProps {
  value?: MeasurementDeletionReasonEnum;
}

export const MeasurementDeletionReasonEnumComponent = ({
  value
}: MeasurementDeletionReasonEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case MeasurementDeletionReasonEnum.CREATED_BY_MISTAKE: return 'Entry created by mistake (accidental entry)';
      case MeasurementDeletionReasonEnum.CREATED_BY_SOMEONE_ELSE: return 'Entry created by someone else (individual other than patient using scale)';
      case MeasurementDeletionReasonEnum.DUPLICATE_ENTRY: return 'Duplicate entry (same weight was logged multiple times)';
      case MeasurementDeletionReasonEnum.FAULTY_READING: return 'Faulty reading (due to circumstance or device error)';
      case MeasurementDeletionReasonEnum.PRIVACY_CONCERN: return 'Privacy Concern (remove for personal or privacy reasons)';
      default: return <EmptyComponent />;
    }
  };

  return (<span>{getText()}</span>);
};

import {
  Button, Col, Form, Radio, Row
} from 'antd';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { map } from 'lodash';
import { MedicationTypeSelectComponent as FormItemMedicationTypeInput } from '../../../../selectComponents/MedicationTypeSelectComponent/MedicationTypeSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicationDeleteConfirmationModal } from '../../../../uiComponent/Medication/MedicationDeleteConfirmationModal';
import { MedicationEndDateInputComponent } from '../../../../uiComponent/Medication/MedicationEndDateInputComponent';
import { MedicationHintComponent } from '../../../../uiComponent/Medication/MedicationHintComponent';
import { MedicationNoteInputComponent } from '../../../../uiComponent/Medication/MedicationNoteInputComponent/MedicationNoteInputComponent';
import { MedicationStartDateInputComponent } from '../../../../uiComponent/Medication/MedicationStartDateInputComponent';
import { MedicationStatusSelectComponent } from '../../../../uiComponent/Medication/MedicationStatusSelectComponent';
import { PrescribedCheckboxComponent } from '../../../../uiComponent/Medication/PrescribedCheckboxComponent';
import { MedicationNameContainer } from '../../container/MedicationNameContainer';
import { MedicationType } from '../../type/type';
import './AddEditMedicationFormComponent.scss';
import { MedicationDosageFrequencySelectComponent } from '../MedicationDosageFrequencySelectComponent/MedicationDosageFrequencySelectComponent';
import { MedicationReminderCardFormItemComponent } from '../MedicationReminderCardFormItemComponent/MedicationReminderCardFormItemComponent';
import { MedicationReminderFormValue } from '../../hook/useMedicationFormReminder';
import { MedicationInstructionSelectComponent } from '../MedicationInstructionSelectComponent/MedicationInstructionSelectComponent';
import { FrequencyDisplayType } from '../../../../uc-api-sdk';
import { MedicationDosageListFormItemComponent } from '../MedicationDosageListFormItemComponent/MedicationDosageListFormItemComponent';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';
import { MedicationRouteTooltipComponent } from '../MedicationRouteTooltipComponent/MedicationRouteTooltipComponent';

export interface AddEditMedicationFormComponentProps {
  initialValues?: MedicationType,
  onSubmit?: (v: MedicationType) => void,
  submitText?: string;
  autoEnableReminder?: boolean;
}

export const AddEditMedicationFormComponent = ({
  initialValues,
  onSubmit,
  submitText = 'Add',
  autoEnableReminder,
}: AddEditMedicationFormComponentProps) => {
  const form = Form.useForm()[0];
  const hook = useMedicationForm({ form });
  const [startDate, setStartDate] = useState<Moment |
    string | null>(initialValues?.startDate ? moment(initialValues?.startDate) : null);
  const [endDate, setEndDate] = useState<Moment | string | null>(
    initialValues?.endDate ? moment(initialValues?.endDate) : null,
  );

  const pInitialValues = useMemo(() => ({
    ...initialValues,
    medicationName: {
      displayName: initialValues?.name,
      id: initialValues?.dictionaryId,
      medicationType: initialValues?.medicationType,
    },
    isSameDosage: initialValues?.isSameDosage ?? true,
    enableReminder: !!autoEnableReminder || !!initialValues?.reminders,
    reminders: map(initialValues?.reminders, ({ hour, minute }) => ({
      timeSelect: (typeof hour === 'number' && typeof minute === 'number')
        ? (hour * 60 + minute)
        : undefined,
    })),
  }) as MedicationType, [initialValues, autoEnableReminder]);

  const onStartDateChange = (value: Moment | null) => {
    form.setFieldsValue({ startDate: value });
    setStartDate(value);
  };

  const onEndDateChange = (value: Moment | null) => {
    form.setFieldsValue({ discontinueDate: value });
    setEndDate(value);
  };

  const handleSubmit = (v: MedicationType) => {
    const {
      enableReminder,
      reminders,
    } = (v || {}) as (MedicationType & MedicationReminderFormValue);
    const addV = {
      ...v,
      medicationType: v.medicationName?.type || undefined,
      name: v.medicationName?.displayName || '',
      dictionaryId: v.medicationName?.id || '',
    };
    const parsedReminders = map(reminders, ({ timeSelect }) => ({
      hour: Math.floor(timeSelect / 60),
      minute: timeSelect % 60
    }));
    onSubmit?.({
      ...addV,
      reminders: enableReminder ? parsedReminders : undefined,
    });
    form.resetFields();
  };

  return (
    <Form
      id="medicationObjectForm"
      form={form}
      onFinish={hook.handleSubmitAndReset(handleSubmit)}
      initialValues={pInitialValues}
      layout="vertical"
    >
      <Row gutter={15}>
        <Col span={24}>
          <FormItem
            info={hook.getInfo('medicationName')}
            className="required-field"
            required
          >
            <MedicationNameContainer />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            name={hook.getName('type')}
            label={(
              <>
                {hook.getLabel('type')}
                <MedicationRouteTooltipComponent />
              </>
            )}
            className="required-field"
            required
          >
            <FormItemMedicationTypeInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('status')}
          >
            <MedicationStatusSelectComponent />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('instruction')}
          >
            <MedicationInstructionSelectComponent />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('frequency')}
          >
            <MedicationDosageFrequencySelectComponent
              onChange={hook.handleDosageFrequencyChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('startDate')}
          >
            <MedicationStartDateInputComponent
              onChange={onStartDateChange}
              disabledAfterDate={endDate}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            info={hook.getInfo('endDate')}
          >
            <MedicationEndDateInputComponent
              disabledBeforeDate={startDate}
              onChange={onEndDateChange}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={12}>
          <FormItem shouldUpdate={hook.shouldUpdate(['frequency'])} noStyle>
            {({ getFieldValue }) => {
              const shouldDisableAndSetSame = hook.shouldDisableSameDosageField(getFieldValue);
              return (
                <FormItem
                  info={hook.getInfo('isSameDosage')}
                >
                  <Radio.Group
                    options={[
                      { label: 'Same', value: true },
                      { label: 'Different', value: false },
                    ]}
                    disabled={shouldDisableAndSetSame}
                    optionType="button"
                    buttonStyle="solid"
                    className="dosage-radio-group"
                  />
                </FormItem>
              );
            }}
          </FormItem>
        </Col>
        <FormItem shouldUpdate={hook.shouldUpdate(['frequency', 'isSameDosage'])} noStyle>
          {({ getFieldValue }) => {
            const frequency = getFieldValue('frequency') as FrequencyDisplayType;
            const sameDosage = getFieldValue('isSameDosage') as boolean;
            return (
              <MedicationDosageListFormItemComponent
                frequency={frequency}
                isSameDosage={sameDosage}
              />
            );
          }}
        </FormItem>
      </Row>
      <div className="my12">
        <MedicationReminderCardFormItemComponent />
      </div>
      <FormItem
        info={hook.getInfo('note')}
        className="medicationNote"
      >
        <MedicationNoteInputComponent />
      </FormItem>
      <FormItem
        name={hook.getName('otherMD')}
      >
        <PrescribedCheckboxComponent />
      </FormItem>
      <FormItem>
        <MedicationHintComponent date={endDate} />
      </FormItem>

      <FormItem className="">
        <Button key="submit" htmlType="submit" type="primary">{submitText}</Button>
        {initialValues?.id && <MedicationDeleteConfirmationModal medId={initialValues?.id} />}
      </FormItem>
    </Form>
  );
};

import { FormItemProps } from 'antd';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import EmailService from '../../../../services/EmailService';
import { EmailRules } from '../../hook/useCreateUserHook';

export const useMedicalOrgBillingEntityForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    billingEntityName: {
      name: 'billingEntityName',
      label: 'NetSuite name',
    },
    netSuiteCustomerId: {
      name: 'netSuiteCustomerId',
      label: 'NetSuite customer ID',
    },
    officeNpi: {
      name: 'officeNpi',
      label: 'Office NPI',
    },
    taxId: {
      name: 'taxId',
      label: 'Tax ID',
    },
    medicalOrgRecipientsEmails: {
      name: 'medicalOrgRecipientsEmails',
      label: 'Medical organization recipients email',
    },
    ihealthInternalRecipientsEmails: {
      name: 'ihealthInternalRecipientsEmails',
      label: 'iHealth internal recipients email',
    },
    ihealthInternalCCRecipientsEmails: {
      name: 'ihealthInternalCCRecipientsEmails',
      label: 'iHealth internal CC recipients email',
    },
    addressName: {
      name: 'addressName',
      label: 'Address name',
    },
    businessAddress: {
      name: 'businessAddress',
      label: 'Business address',
    },
    state: {
      name: 'state',
      label: 'State',
    },
    city: {
      name: 'city',
      label: 'City',
    },
    zip: {
      name: 'zip',
      label: 'Zip',
    },
  }, options);

  const getEmailRules = ({ required, isIhealthEmail }: EmailRules) => {
    const requiredRules = [
      { validator: EmailService.getMultipleEmailsValidator(isIhealthEmail), required },
    ];
    return requiredRules as FormItemProps['rules'];
  };

  return {
    ...factory,
    getEmailRules,
  };
};

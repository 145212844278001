import { CheckCircleFilled } from '@ant-design/icons';
import './AlertGeneratedComponent.scss';

export interface AlertGeneratedComponentProps {
  success: boolean;
}

export const AlertGeneratedComponent = ({
  success,
}: AlertGeneratedComponentProps) => {
  const content = () => {
    if (success) {
      return (
        <CheckCircleFilled />
      );
    }
    return (
      null
    );
  };

  return (
    <div className="alert-generated-component">
      {content()}
    </div>
  );
};

import { Radio, RadioGroupProps, Space } from 'antd';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';

export interface CustomizedAlertTypeRadioComponentProps extends RadioGroupProps {
}

export const CustomizedAlertTypeRadioComponent = ({
  value,
  onChange,
  ...props
}: CustomizedAlertTypeRadioComponentProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Radio.Group onChange={onChange} value={value} {...props}>
    <Space>
      <Radio
        value={VitalEnumType.BP}
      >
        <VitalsEnumTypeComponent value={VitalEnumType.BP} type="short" />
      </Radio>
      <Radio
        value={VitalEnumType.BG}
      >
        <VitalsEnumTypeComponent value={VitalEnumType.BG} type="short" />
      </Radio>
    </Space>
  </Radio.Group>
);

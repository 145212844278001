import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import './SymptomSeverityTagComponent.scss';

export const SymptomSeverityTagComponent = {
  MildTag: () => (
    <TagComponent className="SymptomSeverityTagComponent" iconType="solidDot" background="none" type="success">
      Mild
    </TagComponent>
  ),
  ModerateTag: () => (
    <TagComponent className="SymptomSeverityTagComponent" iconType="solidDot" background="none" type="warning">
      Moderate
    </TagComponent>
  ),
  SevereTag: () => (
    <TagComponent className="SymptomSeverityTagComponent" iconType="solidDot" background="none" type="error">
      Severe
    </TagComponent>
  ),
  NotSureTag: () => (
    <TagComponent className="SymptomSeverityTagComponent" iconType="solidDot" background="none" type="done">
      Not Sure
    </TagComponent>
  ),
  NATag: () => (
    <TagComponent className="SymptomSeverityTagComponent" iconType="solidDot" background="none" type="default">
      N/A
    </TagComponent>
  )
};

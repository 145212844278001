import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { HSUnitProps, WeightService } from '../../../../helpers/weight';
import { WeightSummary } from '../../../../uc-api-sdk';
import { SummaryItem } from '../VitalSummaryComponent/VitalSummaryComponent';

export interface HSSummaryComponentProps extends HSUnitProps {
  data: WeightSummary;
}

export const HSSummaryComponent = ({
  data,
  unit
}: HSSummaryComponentProps) => {
  const fn = WeightService.toLbOrKg(unit);
  const getHSValues = () => {
    const {
      total = 0, average = 0, hsBaseline, bmi,
    } = data;
    return {
      total: total ?? 0,
      average: average ? fn(Number(average ?? 0)) : 0,
      averageBmi: bmi ? Number(bmi)?.toFixed(1) : 'N/A',
      hsBaseline: hsBaseline?.value ? fn(Number(hsBaseline?.value)) : 'N/A',
      baseLineBmi: hsBaseline?.bmi ? Number(hsBaseline?.bmi).toFixed(1) : 'N/A',
      baselineDate: hsBaseline?.updatedAt ? TimezoneService.calcDateTime(hsBaseline?.updatedAt).format('MM/DD/YYYY')
        : 'N/A',
    };
  };

  const v = getHSValues();
  if (!v) return null;

  const baseLineTitle = () => {
    const title = (
      <div>
        <div className="mb20">{`Measurement Date: ${v.baselineDate}`}</div>
        Valid data includes EHR records from up to 30 days prior
        to the enrollment date or direct manual entries by the care team.
      </div>
    );
    return (
      <div>
        Baseline
        {' '}
        <Tooltip title={title} overlayClassName="hs-variability-tooltip">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    );
  };

  return (
    <Row className="vital-summary-component" justify="start">
      <SummaryItem title="Total Measurements" value={v.total} />
      <SummaryItem title="Average" value={v.average} extra={`BMI: ${v.averageBmi}`} />
      <SummaryItem title={baseLineTitle()} value={v.hsBaseline} extra={`BMI: ${v.baseLineBmi}`} />
    </Row>
  );
};

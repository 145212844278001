import { Select, SelectProps } from 'antd';
import { useMemo } from 'react';

export interface MobileVersionSelectComponentProps extends SelectProps<string[]> {
  data: string[];
}

export const MobileVersionSelectComponent = ({
  data,
  value,
  onChange,
  ...props
}: MobileVersionSelectComponentProps) => {
  const handleChange: SelectProps['onChange'] = (value: string[]) => {
    onChange?.(value, []);
  };

  const options = useMemo(() => data.map((item) => ({
    value: item,
    label: item,
  })), [data]);

  return (
    <Select
      mode="multiple"
      options={options}
      placeholder="Please select mobile versions"
      value={value}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  PatientSymptom,
  ReporterEnum,
  SymptomEnum,
  SymptomLog,
  SymptomSeverityEnum,
  useSymptomLogInsert,
  useSymptomLogUpdate,
} from '../../../../uc-api-sdk';
import { SymptomFormComponent } from '../../component/SymptomFormComponent/SymptomFormComponent';
import { SymptomSubmitValue } from '../../hook/useCreateSymptomForm';

export interface SymptomFormContainerProps {
  patientId?: string;
  symptomLog?: SymptomLog | undefined;
  onSubmit?: () => void;
}

export const SymptomFormContainer = ({
  patientId,
  symptomLog,
  onSubmit
}: SymptomFormContainerProps) => {
  const { send } = useMixpanelContext();
  const updateSymptomHook = useSymptomLogUpdate({});
  const createSymptomHook = useSymptomLogInsert({});
  const refetchPatientSymptom = useUpdate('SYMPTOM_UPDATED');

  const { userInfo } = useLoggedInUserFromContext();

  const buildSymptomSubmitList = (submitValue: SymptomSubmitValue) => {
    const submitList = Object.entries(submitValue.symptomList).filter(([, value]) => value.flag);
    const updateSymptomList: PatientSymptom[] = [];

    submitList.forEach(([key, s]) => {
      const baseItem = {
        severity: s.severity as SymptomSeverityEnum,
        note: s.note,
      };

      if (!s.manualSymptom) {
        updateSymptomList.push({
          ...baseItem,
          symptom: key as SymptomEnum,
        });
      } else {
        updateSymptomList.push({
          ...baseItem,
          manualSymptom: s.manualSymptom,
        });
      }
    });
    return updateSymptomList;
  };

  const handleSubmit = (submitValue: SymptomSubmitValue) => {
    const id = symptomLog?.id || undefined;
    const updateSymptomList = buildSymptomSubmitList(submitValue);

    const request: SymptomLog = {
      id,
      patientId,
      symptomList: updateSymptomList,
      reporterType: ReporterEnum.CARE_TEAM_REPORTED,
      reportedBy: userInfo?.id
    };

    // update case
    if (id) {
      ApiRequestHelper.tryCatch(
        updateSymptomHook.send({
          params: {
            id: id ?? '',
            document: request
          },
        }),
        {
          success: 'Symptom updated.',
          error: 'Failed to update symptom!',
          onSuccess: () => {
            onSubmit?.();
            refetchPatientSymptom.updateValue();
            send({ event: MixpanelEvents.UpdateSymptom, patientId });
          },
        }
      );
      // create case
    } else {
      ApiRequestHelper.tryCatch(
        createSymptomHook.send({
          params: {
            document: request
          },
        }),
        {
          success: 'Symptom created.',
          error: 'Failed to create symptom!',
          onSuccess: () => {
            onSubmit?.();
            refetchPatientSymptom.updateValue();
            send({ event: MixpanelEvents.AddSymptom, patientId });
          },
        }
      );
    }
  };

  return (
    <SymptomFormComponent
      initialValues={symptomLog || undefined}
      isLoading={updateSymptomHook.isLoading}
      onSubmit={handleSubmit}
    />
  );
};

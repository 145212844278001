import { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons/lib/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import {
  AppVersionConfig, VersionDetail, ContentDetail, MiniOrganization
} from '../../../../uc-api-sdk';
import { VersionContentsModalComponent } from '../VersionContentsModalComponent/VersionContentsModalComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { FilterDropdownComponent } from '../../../screen/component/ScreenTableComponent/FilterDropdownComponent';
import { MedicalOrganizationSearchSelectContainer } from '../../../adminPortal2/container/MedicalOrganizationSearchSelectContainer/MedicalOrganizationSearchSelectContainer';
import { CreateEditMobileConfigDrawerComponent } from '../CreateEditMobileConfigDrawerComponent/CreateEditMobileConfigDrawerComponent';

export const createColumns = {
  name: (): ColumnType<AppVersionConfig> => ({
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  }),
  versionDetails: (): ColumnType<AppVersionConfig> => ({
    title: 'Version Details',
    dataIndex: 'versionDetails',
    key: 'versionDetails',
    width: 150,
    render: (v: VersionDetail[]) => v?.map((item) => item.version).join(', ')
  }),
  miniOrganizations: (): ColumnType<AppVersionConfig> => ({
    title: 'Medical Orgs',
    dataIndex: 'miniOrganizations',
    key: 'miniOrganizations',
    width: 150,
    render: (v: MiniOrganization[]) => (
      v ? v.map((item) => item.name).join(', ') : <EmptyComponent />
    ),
    filterDropdown: (props: FilterDropdownProps) => {
      const {
        setSelectedKeys,
        clearFilters,
        selectedKeys,
      } = props;
      return (
        <FilterDropdownComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          clearFilters={clearFilters}
          filter={(
            <MedicalOrganizationSearchSelectContainer
              value={selectedKeys?.[0] as string | undefined}
              onChange={(e) => {
                setSelectedKeys(e ? [e] : []);
              }}
            />
          )}
        />
      );
    },
    filterIcon: (filtered?: React.ReactNode) => (
      <SearchOutlined
        className={filtered ? 'active-filter-icon' : 'filter-icon'}
      />
    ),
  }),
  medicalOrgs: (): ColumnType<AppVersionConfig> => ({
    title: 'Medical Orgs',
    dataIndex: 'medicalOrgs',
    key: 'medicalOrgs',
    width: 150,
    render: (v, record) => record.miniOrganizations?.map((item) => item.name).join(', ')
  }),
  activateDate: (): ColumnType<AppVersionConfig> => ({
    title: 'Activate Date',
    dataIndex: 'activateDate',
    key: 'activateDate',
    width: 150,
    sorter: true,
    render: (v) => (
      <DisplayDateComponent value={v} format="USA_DATE" />
    )
  }),
  contents: (): ColumnType<AppVersionConfig> => ({
    title: 'Contents',
    dataIndex: 'contents',
    key: 'contents',
    width: 200,
    render: (v: ContentDetail[]) => (
      v ? <VersionContentsModalComponent data={v} /> : <EmptyComponent />
    ),
  }),
  note: (): ColumnType<AppVersionConfig> => ({
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 150,
  }),
  actions: (): ColumnType<AppVersionConfig> => ({
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 50,
    render: (v, record) => (
      <CreateEditMobileConfigDrawerComponent initialValues={record} />
    ),
  }),
};

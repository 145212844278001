import { CheckCircleFilled } from '@ant-design/icons';
import { BucketsTypeEnum, FileInfo } from '../../../../../uc-api-sdk';
import ModalButton from '../../../../../uiComponent/ModalButton/ModalButton';
import { PDFViewerContainer } from '../../../../../uiContainer/PDFViewerContainer/PDFViewerContainer';
import './CareQualityConsentDocumentContainer.scss';

export interface CareQualityConsentDocumentContainerProps {
  fileInfo?: FileInfo;
}

export const CareQualityConsentDocumentContainer = ({
  fileInfo,
}: CareQualityConsentDocumentContainerProps) => (
  fileInfo?.fileKey
    ? (
      <div className="flex ai-c cq-consent-document">
        <CheckCircleFilled className="primary-link fs20" />
        <ModalButton
          width={800}
          title={fileInfo?.fileName || 'Care Quality Consent Document'}
          button={fileInfo?.fileName ?? 'consent.pdf'}
          buttonType="link"
          className="f1"
          bodyStyle={{ height: '80vh' }}
          buttonClassName="preview-consent-button text-gray-scale-1 underline breakword ta-l autoH"
        >
          <PDFViewerContainer fileKey={fileInfo?.fileKey || ''} bucket={BucketsTypeEnum.PATIENTCONSENT} />
        </ModalButton>
      </div>
    ) : null
);

/* eslint-disable camelcase */
import { message } from 'antd';
import { filter, find } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DATE_STANDARD } from '../../../../constants/timeFormat';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useFormValuesChange } from '../../../../hooks/useFormValuesChange/useFormValuesChange';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { MedicationManagementService } from '../../../../services/MedicationManagementService';
import { PhoneType } from '../../../../types/phone';
import {
  APIResponse,
  EnrollmentActionEnum,
  MeasurementResultTypeEnum,
  MedicationReminderEnum, ResultSourceEnum,
  Transcribe,
  TranscribeStatusEnum, useMeasurementInsertBpBaseline,
  usePatientUpdate,
  usePatientUpdateEnrollment,
  usePatientUpdateHealthCondition,
  usePatientUpdatePatientComplexity,
  usePatientUpdateUcMedicationManagement,
  useTranscribeUpdate
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { ICDCodeHelpers } from '../../../ICDCode/helper';
import { useConfirmEligibilityEffect } from '../../../Insurance/hook/useConfirmEligibilityEffect';
import { useTranscribeDrawer } from '../../component/TranscribeDrawerComponent/TranscribeDrawerComponent';
import { TranscribeVisitDrawerFormComponent, TranscribeVisitFormValues } from '../../component/TranscribeVisitDrawerFormComponent/TranscribeVisitDrawerFormComponent';
import { makePatientParams } from '../../helper';

export interface TranscribeVisitDrawerFormContainerProps {
  transcribeData: Transcribe;
  handleGoToNextTranscribe: (fetchInPlace: boolean) => Promise<void>;
  autoSaveOnUnmount?: boolean;
  shouldGoNextAfterSave?: boolean;
  disabled?: boolean;
}

export const TranscribeVisitDrawerFormContainer = ({
  transcribeData,
  handleGoToNextTranscribe,
  autoSaveOnUnmount = true,
  shouldGoNextAfterSave = true,
  disabled,
}: TranscribeVisitDrawerFormContainerProps) => {
  const {
    info: {
      patientInfoService,
      medicationManagementService,
      enrolledProgramService,
      patientInsurance,
      refetch,
      isLoading,
    } = {},
  } = usePatientContext();
  const { send: sendMixpanel } = useMixpanelContext();
  const id = transcribeData.id || '';
  const patientId = transcribeData.patientId || '';
  const location = useLocation();

  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.VisitTranscribingStart,
    eventEnd: MixpanelEvents.VisitTranscribingEnd,
    parent: location.pathname.includes('care-portal/utilities/transcribe') ? MixpanelEventsParents.Transcribing : MixpanelEventsParents.PatientProfile,
    patientId,
  });

  useEffect(() => {
    startEvent();
  }, []);

  const {
    changes,
    onValuesChange,
    resetValuesChange,
  } = useFormValuesChange<TranscribeVisitFormValues>();
  const {
    handleCloseTranscribeDrawer,
  } = useTranscribeDrawer();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isInitialLoading = (
    patientInfoService?.complexityService === undefined
    || patientInfoService?.healthConditionService === undefined
    || enrolledProgramService === undefined
    || medicationManagementService === undefined
    || patientInsurance === undefined
  );
  const isCompleted = transcribeData.status === TranscribeStatusEnum.COMPLETE;
  const isDisabled = disabled || isCompleted;

  const healthConditionInfo = usePatientUpdateHealthCondition({});
  const patientComplexityInfo = usePatientUpdatePatientComplexity({});
  const patientUpdateInfo = usePatientUpdate({});
  const transcribeUpdateInfo = useTranscribeUpdate({});
  const medicationManagementInfo = usePatientUpdateUcMedicationManagement({});
  const updateHook = useUpdate('TRANSCRIBE_FINISHED');
  const patientInfoUpdateHook = useUpdate('PATIENT_INFO_UPDATED');
  const insertBpBaseline = useMeasurementInsertBpBaseline({});
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});
  const confirmEligibilityEffect = useConfirmEligibilityEffect({
    initialPatientInfo: patientInfoService?.patient,
    initialInsuranceInfo: patientInfoService?.patientInsurance,
  });

  const updateHealthConditions = (v: unknown) => {
    const healthConditions = ICDCodeHelpers.getFormValues(v as Record<string, unknown>);
    if (healthConditions !== undefined) {
      return healthConditionInfo.send({
        params: {
          memberId: patientId,
          request: {
            healthConditions,
          },
        },
      });
    }
    return undefined;
  };

  const updatePatientComplexity = (v: TranscribeVisitFormValues) => {
    const { complexity } = v;

    if (complexity !== undefined) {
      return patientComplexityInfo.send({
        params: {
          memberId: patientId,
          request: {
            source: complexity.source,
            level: complexity.level,
          },
        },
      });
    }
    return undefined;
  };

  const updatePatient = (v: TranscribeVisitFormValues) => {
    const isHeightChanged = patientInfoService?.isHeightChanged(v.height || 0);
    const isWeightChanged = patientInfoService?.isWeightChanged(Number(v.weight) || 0);
    const patientParams = makePatientParams(v);
    // correct phone data
    const existingMobilePhone = patientInfoService?.getMobilePhoneInfo();
    if (existingMobilePhone && patientParams.phone) {
      patientParams.phone = [
        ...filter(patientParams.phone, (p) => p.type !== 'MOBILE'),
        {
          ...(existingMobilePhone || {}),
          ...find(patientParams.phone, { type: PhoneType.MOBILE }),
        }
      ];
    }

    if (isWeightChanged && patientParams.profile) {
      patientParams.profile.weight = {
        ...patientParams.profile.weight,
        source: ResultSourceEnum.TRANSCRIBING
      };
    }

    const patientRequest = {
      email: patientParams.email,
      phone: patientParams.phone,
      editHeight: isHeightChanged || undefined,
      editWeight: isWeightChanged || undefined,
      medicalRecordId: patientParams.medicalRecordId,
      address: patientParams.address,
      profile: patientParams.profile,
    };

    if (Object.values(patientRequest).every((v) => v === undefined)) {
      return undefined;
    }

    return patientUpdateInfo.send({
      params: {
        memberId: patientId,
        request: {
          email: patientParams.email,
          phone: patientParams.phone,
          editHeight: isHeightChanged || undefined,
          editWeight: isWeightChanged || undefined,
          medicalRecordId: patientParams.medicalRecordId,
          address: patientParams.address,
          profile: patientParams.profile,
        },
      },
    });
  };

  const updateMedicationManagement = (v: TranscribeVisitFormValues) => {
    const {
      isEditMedicationReminder,
    } = MedicationManagementService.checkManagementEdit({
      medicationReminder: medicationManagementService?.medicationReminder,
    }, v);
    const unsetFields = [];
    if (
      v.medicationReminder === MedicationReminderEnum.PATIENT_ACCEPTED_MEDICATION_REMINDER
    ) {
      unsetFields.push('medicationReminderDeclineReason');
    }
    return medicationManagementInfo.send({
      params: {
        memberId: patientId,
        request: {
          medicationReminder: v.medicationReminder,
          medicationReminderDeclineReason: v.medicationReminderDeclineReason,
          isEditMedicationReminder,
          note: v.note,
          $unset: { fields: unsetFields },
        },
      },
    });
  };

  const updateDiagnoses = (v: TranscribeVisitFormValues) => {
    if (v.diagnoses) {
      const programCategories = enrolledProgramService?.getPrograms();
      const vitalTypes = enrolledProgramService?.getVitals();
      return programEnrollmentUpdate.send({
        params: {
          memberId: patientId,
          request: {
            action: EnrollmentActionEnum.ENROLL,
            programEnrollment: {
              patientDiagnoses: v.diagnoses,
              programCategories,
              vitalTypes
            },
          },
        },
      });
    }
    return undefined;
  };

  const handleAddBpBaseline = (v: TranscribeVisitFormValues) => {
    const {
      bpBaselineDate: date, systolic_blood_pressure, diastolic_blood_pressure, pulse
    } = v;
    if (date && systolic_blood_pressure && diastolic_blood_pressure) {
      return insertBpBaseline.send({
        params: {
          measurement: {
            memberId: patientId,
            date: TimezoneService.getUTC(date),
            systolic_blood_pressure: {
              value: systolic_blood_pressure,
              unit: 'mmHg',
            },
            diastolic_blood_pressure: {
              value: diastolic_blood_pressure,
              unit: 'mmHg',
            },
            type: MeasurementResultTypeEnum.BP,
            heart_rate: {
              value: pulse,
              unit: 'beats/min',
            },
            day: date.format(DATE_STANDARD)
          },
        },
      });
    }
    return undefined;
  };

  const handleSave = async (
    v: TranscribeVisitFormValues,
    completeTranscribe: boolean,
  ) => {
    setIsSubmitting(true);
    if (completeTranscribe) {
      sendMixpanel({ event: MixpanelEvents.TranscribingComplete });
    } else {
      sendMixpanel({ event: MixpanelEvents.TranscribingSave });
    }
    const transcribeStatus = completeTranscribe ? TranscribeStatusEnum.COMPLETE : undefined;
    let hasError = false;
    let res: APIResponse<unknown> | null | undefined = await transcribeUpdateInfo.send({
      params: {
        id,
        document: {
          status: transcribeStatus,
          notes: [{ note: v.transcribeNote }],
        },
      },
    });
    if (res && res.code !== 200) {
      message.error(`Fail to update transcribe. ${res?.msg}`);
      hasError = true;
    } else if (res?.code === 200) {
      // refetch outstanding items
      updateHook.updateValue();
    }

    res = await updatePatient(v);
    if (res && res.code !== 200) {
      message.error(`Fail to update patient. ${res?.msg}`);
      hasError = true;
    } else if (res?.code === 200) {
      updateHook.updateValue();
    }

    res = await updateMedicationManagement(v);
    if (res && res?.code !== 200) {
      message.error(`Fail to update medication compliance. ${res?.msg}`);
      hasError = true;
    }

    res = await updateHealthConditions(v);
    if (res && res?.code !== 200) {
      message.error(`Fail to update ICD code. ${res?.msg}`);
      hasError = true;
    }

    res = await updatePatientComplexity(v);
    if (res && res?.code !== 200) {
      message.error(`Fail to update patient complexity. ${res?.msg}`);
      hasError = true;
    }

    res = await updateDiagnoses(v);
    if (res && res?.code !== 200) {
      message.error(`Fail to update diagnoses. ${res?.msg}`);
      hasError = true;
    }

    res = await handleAddBpBaseline(v);
    if (res && res?.code !== 200) {
      message.error(`Fail to update patient BP baseline. ${res?.msg}`);
      hasError = true;
    }
    endEvent();
    setIsSubmitting(false);
    if (hasError) {
      return false;
    }
    resetValuesChange();
    const successActionText = completeTranscribe ? 'Completed' : 'Saved';
    message.success(`${successActionText} successfully.`);
    refetch?.();
    patientInfoUpdateHook.updateValue();
    return true;
  };

  const handleOnSubmit = useDebounce(async (
    v: TranscribeVisitFormValues,
    completeTranscribe = false,
  ) => {
    await handleSave(v, completeTranscribe);
    if (shouldGoNextAfterSave) {
      const shouldFetchInPlace = !!completeTranscribe;
      handleGoToNextTranscribe(shouldFetchInPlace);
      return;
    }
    if (completeTranscribe) {
      handleCloseTranscribeDrawer();
    }
  }, 250, [
    handleSave,
    handleGoToNextTranscribe,
  ]);

  useEffect(() => () => {
    if (autoSaveOnUnmount && changes.current?.values) {
      confirmEligibilityEffect(changes.current?.values);
      handleSave(changes.current?.values, false);
    }
  }, [
    medicationManagementService,
    enrolledProgramService,
    patientInfoService,
  ]);

  return (
    <LoadingOverlayComponent
      isLoading={isInitialLoading}
      showSkeleton
    >
      <OverlayLoading loading={isSubmitting} />
      <TranscribeVisitDrawerFormComponent
        patientId={patientId}
        transcribe={transcribeData}
        onSubmit={handleOnSubmit}
        onValuesChange={onValuesChange}
        disabled={isDisabled || isSubmitting}
        showSaveForLaterButton={!isCompleted}
        showSubmitButton={!isCompleted}
        isLoading={isLoading || transcribeUpdateInfo.isLoading || isSubmitting}
      />
    </LoadingOverlayComponent>
  );
};

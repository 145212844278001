import { ColumnType } from 'antd/lib/table';
import { BillableTimeReviewTableDataSource } from './BillableTimeReviewTableComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { BillableTimeReviewActionComponent } from '../BillableTimeReviewActionComponent/BillableTimeReviewActionComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { MillisecondToMinuteComponent } from '../../../../uiComponent/MillisecondToMinuteComponent/MillisecondToMinuteComponent';
import { MedicalOrgNameDisplayComponent } from '../../../../uiComponent/MedicalOrgNameDisplayComponent/MedicalOrgNameDisplayComponent';
import { createBillableTimeReviewStatusCheckboxFilter } from '../../../table/filter/BillableTimeReviewStatusCheckBoxFilter';
import { createClinicFilter } from '../../../table/filter/ClinicFilter';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PatientProfileTabEnum } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { PatientProfileTabAnchors } from '../../../patient/component/PatientProfileProfileTabComponent/PatientProfileProfileComponent';

export const createColumns = () => {
  const changePage = useChangePage();

  return {
    memberName: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Patient',
      dataIndex: 'memberName',
      width: '13%',
      render: (value, d) => (
        <ClickableDiv
          key={d.memberId}
          onClick={() => d.memberId && changePage.goToCarePortalPatient({
            patientId: d.memberId,
            tab: PatientProfileTabEnum.Profile,
          }, {
            anchor: PatientProfileTabAnchors.billingHistory.path,
          })}
        >
          <TextComponent className="primary-link mr5">
            {d.memberName}
          </TextComponent>
        </ClickableDiv>
      )
    }),
    medicalOrganization: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Medical Organization',
      dataIndex: 'medicalOrganization',
      width: '18%',
      render: (value, d) => (
        <MedicalOrgNameDisplayComponent name={d.medicalOrganization} orgNumber={d.orgNumber} />
      ),
    }),
    startTime: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Start at',
      dataIndex: 'startTime',
      width: '10%',
      render: (value) => (
        <DisplayDateComponent value={value} format="DATE_COMMA_TIME_AM_PM" />
      ),
      sorter: true,
    }),
    activity: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Activity',
      dataIndex: 'activity',
      render: (value) => <DisplayOrEmptyComponent value={value?.join(', ')} />,
      width: '14%',
    }),
    totalTime: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Total Time',
      dataIndex: 'totalTime',
      render: (value) => (
        <div className="flex">
          <MillisecondToMinuteComponent value={value} />
          <div>min</div>
        </div>
      ),
    }),
    originBillableTime: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: (
        <TooltipComponent
          title="This is a questionable billable time entry. Requires Review."
          placement="bottom"
          type="info-icon"
          highlightOnHover={false}
        >
          Billable Time required review
        </TooltipComponent>
      ),
      dataIndex: 'originBillableTime',
      render: (value) => (
        <div className="flex">
          <div className="b7"><MillisecondToMinuteComponent value={value} /></div>
          <div>min</div>
        </div>
      ),
      width: '14%',
    }),
    action: (
      confirmApproval?: (id: string) => void
    ): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Action / Confirmed billable time',
      render: (value, d) => (
        <BillableTimeReviewActionComponent record={d} confirmApproval={confirmApproval} />
      ),
      width: 380,
    }),
    statuses: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Review Status',
      dataIndex: 'statuses',
      ...createBillableTimeReviewStatusCheckboxFilter(),
      render: (value, d) => (
        <div className="flex">
          <BillableTimeReviewActionComponent record={d} />
        </div>
      ),
      width: '15%',
    }),
    reviewerName: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Reviewer',
      dataIndex: 'reviewerName',
      render: (value, d) => (
        <div className="flex">
          <DisplayOrEmptyComponent value={d.reviewerName} />
        </div>
      ),
      width: '12%',
    }),
    clinicIds: (): ColumnType<BillableTimeReviewTableDataSource> => ({
      title: 'Medical Organization',
      dataIndex: 'clinicIds',
      width: '18%',
      ...createClinicFilter(),
      render: (value, d) => (
        <MedicalOrgNameDisplayComponent name={d.medicalOrganization} orgNumber={d.orgNumber} />
      ),
    }),
  };
};

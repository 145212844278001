import { PatientSearchFilterComponent } from '../component/PatientSearchFilterComponent/PatientSearchFilterComponent';
import { TableFilterType } from '../type/filter';
import { SearchIcon } from './SearchIcon';

export interface PatientSearchFilterProps {
  selectedOrgIds?: string[];
}

export const createPatientSearchFilter = ({
  selectedOrgIds
}: PatientSearchFilterProps): TableFilterType => ({
  filterDropdown: ((props) => (
    <PatientSearchFilterComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      selectedOrgIds={selectedOrgIds}
    />
  )),
  filterIcon: SearchIcon,
});

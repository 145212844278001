import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  uniqBy,
  sortBy,
  isEmpty,
} from 'lodash';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useSendMessage } from '../../hooks/useSendMessage/useSendMessage';
import {
  MessageHistory, MessageHistoryMessage, MessageType
} from '../../services/CHSServices/types/data';
import {
  MessageACKPublisher, PatientMessageChannel
} from '../../types/message';
import { Patient } from '../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useMessageServicesContext } from './MessageServicesContext';
import { MAX_MSG_PER_CHANNEL } from '../../services/CHSServices/constants';
import { CHSServices } from '../../services/CHSServices/CHSServices';
import { PublishMessageResponseData } from '../../services/CHSServices/types';
import { useRefState } from '../../hooks/useRefState/useRefState';
import { useEffectWithPrevValue } from '../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { omitUnusedMessageFields, getLatestPatientMessageTimestamp } from '../../features/message/helper';
import { useAIChatDraft } from '../../services/AIService/hooks/useAIChatDraftService';
import {
  AIChatDraftReactionParams,
  AIChatDraftRequestParams,
  AIChatDraftResponse
} from '../../services/AIService/types';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { useAIChatDraftReaction } from '../../services/AIService/hooks/useAIChatDraftReaction';
import { useMessageStorageContext } from './MessageStorageContext';

export interface MessagePatientContextValue {
  file?: RcFile;
  setFile: (file?: RcFile) => void;
  patientId: string;
  patientChannel?: PatientMessageChannel;
  patientInfo?: Patient | null;
  isLoadingPatientChannel: boolean;
  getPatientChannelHistoryFirstTime: () => void;
  handleGetMorePatientMessages: (
    fromTimestamp?: string,
    count?: number,
    shouldUpdateHasMore?: boolean,
  ) => void;
  publishACKMessage: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  markChannelAsUnread: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  handleSendMessage: (
    payload: MessageHistoryMessage['payload'],
    initChannel?: boolean,
  ) => Promise<PublishMessageResponseData>;
  scrollToBottom: (ref: MutableRefObject<Element | null>) => void;
  setNotificationTarget: (value: string) => void;
  setPatientActiveSubscription: (isAdded?: boolean) => void;
  // text to be inserted from AI chat draft
  textToBeInsertFromAI?: string;
  // AI chat draft
  isLoadingDraftFromAI: boolean;
  draftFromAI: AIChatDraftResponse;
  setDraftFromAI: (value: AIChatDraftResponse) => void;
  // trigger insert to type box
  handleInsertToTypeBoxAIChatDraft: () => void;
  // AI chat draft collapsed state
  collapseAIChatDraft?: string[];
  handleCollapseAIChatDraft: (prev: string[]) => void;
}

const MessagePatientContext = createContext<MessagePatientContextValue | undefined>(undefined);

export const useMessagePatientContext = () => {
  const context = useContext(MessagePatientContext);
  return (context || {}) as MessagePatientContextValue;
};

export interface MessagePatientContextProviderProps {
  children: ReactNode;
  patientId: string;
  patientInfo?: Patient | null;
  // will fetch history on new notification
  listenToNewNotification?: boolean;
  // to determine if the context is used in patient profile,
  // to prevent unnecessary channelMap update
  isInPatientProfile?: boolean;
}
export const MessagePatientContextProvider = ({
  children,
  patientId,
  patientInfo,
  listenToNewNotification = true,
  isInPatientProfile,
}: MessagePatientContextProviderProps) => {
  const {
    userInfo,
  } = useLoggedInUserFromContext();
  const {
    channelMap,
    userMap,
    handleSetUserMap,
    handleSetPatientMapData,
    handleGetChannelHistory,
    handleInitChannel,
    handleSetChannel,
    setNotificationTarget,
    setActiveSubscription,
    setOnGoingChannel,
  } = useMessageServicesContext();
  const { getDraftById, setAIDraftResponse } = useMessageStorageContext();
  const { aiResponse } = getDraftById(patientId || '');
  const newPatientMessageNotification = channelMap[patientId]?.newNotification;
  const [
    patientChannel,
    setPatientChannel,
  ] = useState<PatientMessageChannel>({
    ...channelMap[patientId],
    patientId,
    hasMoreMessages: false,
    fromTimestamp: undefined,
    messageHistory: {} as MessageHistory,
  });
  const [
    getShouldUpdateChannelMap,
    setShouldUpdateChannelMap,
  ] = useRefState(false);

  const defaultAIChatDraftResponse: AIChatDraftResponse = {
    response: 'No reply suggestion available.',
    instruction: null,
    history_id: null,
    patient_id: null,
    user_id: null,
    latest_patient_msg_timestamp: null,
  };

  const [isLoadingPatientChannel, setIsLoadingPatientChannel] = useState(false);
  const [file, setFile] = useState<RcFile | undefined>(undefined);
  const [isLoadingDraftFromAI, setIsLoadingDraftFromAI] = useState<boolean>(false);
  const [draftFromAI, setDraftFromAI] = useState<AIChatDraftResponse>(defaultAIChatDraftResponse);
  const [textToBeInsertFromAI, setTextToBeInsertFromAI] = useState<string | undefined>(undefined);
  const [collapseAIChatDraft, setCollapseAIChatDraft] = useState<string[]>(['1']);
  const { userId } = useLoggedInUserFromContext();
  const aiChatHook = useAIChatDraft({});
  const aiChatDraftReactionHook = useAIChatDraftReaction({});

  const handleAIDraftCloseRequest = async () => {
    if (draftFromAI?.history_id) {
      const params: AIChatDraftReactionParams = {
        history_id: draftFromAI.history_id,
        closed_by_user: '1',
        insert_to_type_box: null,
      };

      aiChatDraftReactionHook.send({
        params
      });
    }
  };

  const handleAIDraftInsertToTypeBox = async () => {
    if (draftFromAI?.history_id) {
      const params: AIChatDraftReactionParams = {
        history_id: draftFromAI.history_id,
        insert_to_type_box: '1',
        closed_by_user: null,
      };

      aiChatDraftReactionHook.send({
        params
      });
    }
  };

  // This function insert AI chat draft to type box
  const handleInsertToTypeBoxAIChatDraft = async () => {
    setTextToBeInsertFromAI(draftFromAI?.response || '');

    await handleAIDraftInsertToTypeBox();
  };

  // This function request AI draft
  const handleAIDraftRequest = useDebounce(async (patientLastMsgTimestamp: string) => {
    if (patientLastMsgTimestamp === aiResponse?.latest_patient_msg_timestamp
      && aiResponse?.response) {
      setDraftFromAI(aiResponse);
      setIsLoadingDraftFromAI(false);
      return null;
    }

    const params: AIChatDraftRequestParams = {
      patient_id: patientId || '',
      user_id: userId || '',
      latest_patient_msg_timestamp: patientLastMsgTimestamp,
    };
    setIsLoadingDraftFromAI(true);

    try {
      const res: AIChatDraftResponse | null | undefined = await aiChatHook.send({
        params
      });

      setIsLoadingDraftFromAI(false);

      setDraftFromAI(res ?? defaultAIChatDraftResponse);
      // save draft to storage
      setAIDraftResponse(patientId, res || defaultAIChatDraftResponse);
      return res;
    } catch (error) {
      setIsLoadingDraftFromAI(false);
    }
    return null;
  }, 500, [patientChannel.lastMsg, collapseAIChatDraft]);

  // This function handle collapse AI chat draft
  const handleCollapseAIChatDraft = async (prev: string[]) => {
    if (prev.length === 0) {
      // close AI chat draft request(for analysis)
      await handleAIDraftCloseRequest();
    } else {
      // this is triggered by expanding AI chat draft
      // get latest patient message timestamp,
      // so that don't need to request AI draft every time when expand
      const ts = getLatestPatientMessageTimestamp(patientChannel.messageHistory.messages);
      if (ts) {
        await handleAIDraftRequest(ts);
      }
    }

    setCollapseAIChatDraft(prev);
  };

  const {
    sendMessage,
  } = useSendMessage({ patientId, patientInfo });

  const setPatientChannelHistory = (
    messageHistory: MessageHistory,
    shouldUpdateHasMore = true,
  ) => {
    if (isEmpty(messageHistory)) {
      return null;
    }
    const patientMessageChannel = (prev: PatientMessageChannel) => {
      const nonEmptyMessages = messageHistory.messages || [];
      const hasMoreMessages = nonEmptyMessages.length === MAX_MSG_PER_CHANNEL;
      const combinedHistoryWithDatetime = [
        ...nonEmptyMessages,
        ...(prev?.messages || []),
      ].map((msg) => ({
        ...msg,
        dateTime: CHSServices.convertTimestamp(msg.timestamp),
      }));
      const dedupedHistoryMessages = uniqBy(combinedHistoryWithDatetime, (msg) => msg.dateTime);
      const readableMessages = CHSServices.getListOfReadableMessages(dedupedHistoryMessages);
      const sortedHistoryMessages = sortBy(readableMessages, (msg) => msg.dateTime);

      const omittedMessages = omitUnusedMessageFields(sortedHistoryMessages);
      setIsLoadingPatientChannel(false);

      if (getShouldUpdateChannelMap()) {
        // IFF a text message is sent to channel
        handleSetChannel(patientId, messageHistory);
        setShouldUpdateChannelMap(false);
      }

      return {
        ...prev,
        lastClientACK: messageHistory.lastClientACK,
        isUnread: !!messageHistory?.teamUnread,
        messages: omittedMessages,
        lastMsg: CHSServices.getLastMessage(omittedMessages),
        hasMoreMessages: shouldUpdateHasMore ? hasMoreMessages : prev.hasMoreMessages,
        fromTimestamp: omittedMessages?.[0]?.timestamp,
        messageHistory,
      };
    };
    const newPatientChannel = patientMessageChannel(patientChannel);
    setPatientChannel(patientMessageChannel(patientChannel));
    return newPatientChannel;
  };

  const getPatientChannelHistory = async (
    fromTimestamp?: string,
    count = MAX_MSG_PER_CHANNEL,
    shouldUpdateHasMore = true,
  ): Promise<void> => {
    setIsLoadingPatientChannel(true);
    // fromTimestamp is to support load more message in the past
    const res = await handleGetChannelHistory({
      patientIds: [patientId],
      fromTimestamp,
      count,
    });
    const history = res?.[0] as MessageHistory;
    const msg: PatientMessageChannel | null = setPatientChannelHistory(
      history,
      shouldUpdateHasMore
    );
    // after get patient channel history
    // trigger first time and receive new message notification
    if (msg?.lastMsg?.isPatientMessage && msg?.lastMsg?.type === MessageType.TEXT) {
      let latestPatientMsgTimestamp = '';
      if (msg.lastMsg.dateTime) {
        latestPatientMsgTimestamp = String(Math.round(msg.lastMsg.dateTime));
      }
      // user collapse the panel intentionally, collapseAIChatDraft.length === 1
      if (collapseAIChatDraft.length === 1) {
        handleAIDraftRequest(latestPatientMsgTimestamp);
      }
    } else {
      // Collapse AI chat draft if the last message is not from patient
      setCollapseAIChatDraft([]);
    }
  };

  const getPatientChannelHistoryFirstTime = async () => {
    await getPatientChannelHistory();

    if (!userMap[patientId]) {
      handleSetUserMap(patientId, true);
    }
  };

  const handleGetMorePatientMessages: MessagePatientContextValue['handleGetMorePatientMessages'] = (
    fromTimestamp, // to fetch new message
    count = MAX_MSG_PER_CHANNEL,
    shouldUpdateHasMore = true,
  ) => {
    if (!fromTimestamp) {
      setPatientChannel((prev) => ({
        ...prev,
        hasMoreMessages: false,
      }));
    }
    const fTimestamp = fromTimestamp || patientChannel.fromTimestamp || '0';
    getPatientChannelHistory(fTimestamp, count, shouldUpdateHasMore);
  };

  const handleSendMessage: MessagePatientContextValue['handleSendMessage'] = async (
    payload,
    initChannel = true,
  ) => {
    let res;
    try {
      if (initChannel) {
        await handleInitChannel(patientId);
      }
      res = await sendMessage(payload);
      if (!res?.success) {
        throw new Error(res?.error || '');
      }
      if (initChannel) {
        // sent a text message to patient successfully
        setOnGoingChannel(patientId);
        setShouldUpdateChannelMap(true);
        setTextToBeInsertFromAI(undefined);
      }
      return res;
    } catch (error) {
      const axiosError = (error as AxiosError).response?.data as string;
      return {
        success: false,
        error: axiosError || res?.error || 'Failed to send message',
      };
    }
  };

  const publishACKMessage: MessagePatientContextValue['publishACKMessage'] = () => (
    patientChannel?.isUnread
      ? handleSendMessage({
        type: MessageType.ACK,
        publisher: `${MessageACKPublisher.READ}-${userInfo?.id}`,
      }, false)
      : null
  );

  const markChannelAsUnread: MessagePatientContextValue['markChannelAsUnread'] = async () => {
    if (patientChannel?.isUnread) {
      return null;
    }
    const res = handleSendMessage({
      type: MessageType.ACK,
      publisher: `${MessageACKPublisher.UNREAD}-${userInfo?.id}`,
    }, true);
    if (isInPatientProfile) {
      // add channelMap before fetching placeholder
      handleSetChannel(patientId, {
        ...patientChannel?.messageHistory,
        teamUnread: 1,
      });
    }
    return res;
  };

  const scrollToBottom: MessagePatientContextValue['scrollToBottom'] = (
    ref,
  ) => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  };

  const setPatientActiveSubscription: MessagePatientContextValue['setPatientActiveSubscription'] = (isAdded = true) => setActiveSubscription?.(patientId, !!isAdded);

  useDeepCompareEffect(() => {
    if (patientInfo) {
      handleSetPatientMapData(patientInfo);
    }
  }, [patientInfo]);

  useEffect(() => {
    if (listenToNewNotification && newPatientMessageNotification?.receivedAt) {
      if (!isInPatientProfile || channelMap[patientId]) {
        // if there is channelMap for channel,
        // channel is from channels api or ongoing
        setShouldUpdateChannelMap(true);
      }
      setTimeout(() => {
        handleGetMorePatientMessages(
          CHSServices.parseTimestamp(Date.now()),
          1,
          false
        );
      }, 100);
    }
  }, [newPatientMessageNotification?.receivedAt, channelMap[patientId]]);

  useEffectWithPrevValue(channelMap[patientId], (prev) => {
    if (!prev) return;
    if (!listenToNewNotification && channelMap[patientId]) {
      // when not listening to new notification
      // update patient channel based on channelMap
      setPatientChannel({
        ...channelMap[patientId],
        patientId,
        messageHistory: {} as MessageHistory,
      });
    }
  });

  const contextValue = useGetContextValue<MessagePatientContextValue>({
    patientId,
    patientChannel,
    patientInfo,
    isLoadingPatientChannel,
    getPatientChannelHistoryFirstTime,
    handleGetMorePatientMessages,
    publishACKMessage,
    markChannelAsUnread,
    handleSendMessage,
    scrollToBottom,
    file,
    setFile,
    setNotificationTarget,
    setPatientActiveSubscription,
    isLoadingDraftFromAI,
    draftFromAI,
    setDraftFromAI,
    textToBeInsertFromAI,
    handleInsertToTypeBoxAIChatDraft,
    collapseAIChatDraft,
    handleCollapseAIChatDraft,
  });

  return (
    <MessagePatientContext.Provider key={patientId} value={contextValue}>
      {children}
    </MessagePatientContext.Provider>
  );
};

import { useMedicalOrganizationLogoDownloadUrl } from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';

export interface MedicalOrgCoBrandingImageComponentProps {
  fileKey: string;
}

export const MedicalOrgCoBrandingImageComponent = ({
  fileKey
}: MedicalOrgCoBrandingImageComponentProps) => {
  const logo = useMedicalOrganizationLogoDownloadUrl({
    params: {
      avatarDownloadUrlInput: {
        fileKey,
      }
    },
    options: {
      sendOnMount: !!fileKey,
    }
  });

  return (
    <FetchComponent
      info={logo}
    >
      {(data) => (data?.data?.url ? (
        <img
          width={80}
          height={80}
          src={data?.data?.url}
          alt="Co-branding logo"
          className="default-br"
        />
      ) : null)}
    </FetchComponent>
  );
};

import { groupBy } from 'lodash';
import { useMemo } from 'react';
import {
  Employee,
  MedicalOrganization,
  MedicalOrganizationAssignment,
  RoleAssignment,
  RoleOrganizationHierarchyResponse,
  RoleOrgIds,
  RoleTypeEnum, UserTypeEnum
} from '../../../../uc-api-sdk';
import { CARE_UNIT_ROOT_ID } from '../../constants/careUnit';
import { roleWithDefaultCareUnit } from '../component/InternalEmployeeRoleTreeComponent/constant';
import { CreateUserFormValues } from '../type';
import { _getFullName } from './users';

interface UserServiceArgs {
  employee?: Employee;
  medicalOrg?: MedicalOrganization[];
  roleList?: RoleOrganizationHierarchyResponse[];
  roleAssignmentList?: RoleAssignment[];
  employeeAccessList?: MedicalOrganizationAssignment[];
}

export enum EntityType {
  CareDept = 'careDept',
  CallCenterDept = 'callCenterDept',
  CareCenter = 'careCenter',
  BillingDept = 'billingDept',
  CareGroup = 'careGroup',
}

export interface HierarchyNode {
  id?: string;
  key: string;
  title: string;
  parentId?: string;
  type?: EntityType | null;
  isLeaf?: boolean;
  children?: HierarchyNode[];
  checkable?: boolean,
}

export class UserService {
  protected employee?: Employee;

  protected roleAssignmentList?: RoleAssignment[];

  protected medicalOrg?: MedicalOrganization[];

  protected roleList?: RoleOrganizationHierarchyResponse[];

  protected employeeAccessList?: MedicalOrganizationAssignment[];

  constructor(arg?: UserServiceArgs) {
    this.employee = arg?.employee;
    this.medicalOrg = arg?.medicalOrg ?? [];
    this.roleList = arg?.roleList ?? [];
    this.roleAssignmentList = arg?.roleAssignmentList ?? [];
    this.employeeAccessList = arg?.employeeAccessList ?? [];
  }

  get getMedicalOrgList() {
    return this.medicalOrg;
  }

  get getEmployeeAccessList() {
    return this.employeeAccessList;
  }

  get getRoleAssignments() {
    return this.roleAssignmentList;
  }

  get getRoleAssignmentList() {
    return this.roleAssignmentList
      ?.map(role => `${role.miniOrganization?.orgNumber || ''} ${role.miniOrganization?.name || ''}`)
      .filter((v): v is string => !!v) || [];
  }

  get supervisingProvider() {
    return this.employee?.supervisingProviderId;
  }

  get profile() {
    return this.employee?.employeeProfile;
  }

  get npi() {
    // @ts-ignore
    return this.employee?.employeeProfile?.npi;
    // SDK has npi in caps, but the API response has it in lowercase
  }

  get distinctRoles() {
    return Array.from(new Set(this.roleAssignmentList?.map((role) => role.roleType)));
  }

  get fullName() {
    return _getFullName(this.employee || {});
  }

  get firstName() {
    return this.employee?.employeeProfile?.firstName;
  }

  get hierarchyRoles() {
    // get the list of unique roleTypes from the roleList using array.reduce
    return useMemo(() => this.roleAssignmentList?.reduce((acc, role) => {
      if (!acc.includes(role.roleType)) {
        acc.push(role.roleType);
      }
      return acc;
    }, [] as RoleTypeEnum[]), [this.roleAssignmentList]);
  }

  get lastName() {
    return this.employee?.employeeProfile?.lastName;
  }

  get isObserverUser() {
    return this.employee?.isObserverUser;
  }

  get email() {
    return this.employee?.email;
  }

  get loginId() {
    return this.employee?.loginId;
  }

  get languages() {
    return this.profile?.language;
  }

  get credentials() {
    return this.profile?.credentials;
  }

  get userType() {
    return this.employee?.userType;
  }

  get status() {
    return this.employee?.status;
  }

  get lastLoginTime() {
    return this.employee?.lastLoginTime;
  }

  get createdAt() {
    return this.employee?.createdAt;
  }

  get role() {
    return this.employee?.staffRole;
  }

  get memberId() {
    return this.employee?.id;
  }

  get organizations() {
    return this.employee?.assignedRoles;
  }

  get phone() {
    return this.profile?.phone?.number;
  }

  get phoneObject() {
    return this.profile?.phone;
  }

  get medicalOrgNames() {
    const getMedicalOrgAccessListName = this.medicalOrg?.map(org => org.profile?.alias) || [];
    const medicalOrgAccessData = getMedicalOrgAccessListName.filter(
      (name): name is string => name !== undefined
    );

    return medicalOrgAccessData;
  }

  get medicalOrgIds() {
    return this.roleAssignmentList?.map(org => org.organizationId);
  }

  getFullPhoneNumber() {
    const phone = this.profile?.phone;
    if (phone) {
      return `${phone.countryCode}-${phone.number}`;
    }
    return '';
  }

  get rolesList() {
    return this.roleList;
  }

  public getGroupedList() {
    return groupBy(this.rolesList, 'roleType');
  }

  public getStaffGroupedRoleList() {
    return groupBy(this.roleAssignmentList, 'roleType');
  }

  static buildRoles(values: CreateUserFormValues, userType?: UserTypeEnum) {
    let roles: RoleOrgIds[] = [];
    if (
      values.userType === UserTypeEnum.CLINICAL_STAFF
      || userType === UserTypeEnum.CLINICAL_STAFF
    ) {
      values.roles.forEach((role) => {
        roles.push({
          roleType: role as RoleTypeEnum,
          orgIds: values[role] || [],
        });
      });
    } else if (
      values.userType === UserTypeEnum.IHEALTH_EMPLOYEE
      || userType === UserTypeEnum.IHEALTH_EMPLOYEE
    ) {
      roles = values.ihealthRoles?.map((role) => {
        const roleOrgId = {
          roleType: role,
          orgIds: (role in values
            && values[role]) || [],
        };
        if (roleWithDefaultCareUnit.includes(role as RoleTypeEnum)) {
          roleOrgId.orgIds.push(CARE_UNIT_ROOT_ID);
        }

        return roleOrgId;
      }) || [];
    }
    return roles;
  }

  get orgIdsByRole() {
    return this.roleAssignmentList?.reduce((acc, role) => {
      if (role.roleType in acc) {
        acc[role.roleType].push(role.organizationId);
      } else {
        acc[role.roleType] = [role.organizationId];
      }
      return acc;
    }, {} as Record<RoleTypeEnum, string[]>);
  }

  get employeeMedicalOrgAccessNames() {
    const getMedicalOrgAccessListName = this.getEmployeeAccessList?.map(
      org => `${org.medicalOrganization?.orgNumber || ''} ${org.medicalOrganization?.businessName} ${org.medicalOrganization?.alias ? `- ${org.medicalOrganization?.alias}` : ''}`.trim(),
    ) || [];
    const employeeMedicalOrgAccessData = getMedicalOrgAccessListName.filter(
      (name): name is string => name !== undefined
    );

    return employeeMedicalOrgAccessData;
  }

  // based on numerically and then alphabetically order（capital and then lower case)
  public customSort() {
    return this.employeeMedicalOrgAccessNames?.sort((a, b) => (
      a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
        ? a.localeCompare(b)
        : b.localeCompare(a)
    ));
  }

  get description() {
    return this.employee?.description;
  }
}

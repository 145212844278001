import { TreeNodeProps } from 'antd';
import {
  differenceBy, find, get, sortBy
} from 'lodash';
import { MedicalOrganizationHelper } from '../../../../helpers/medicalOrganization';
import { MedicalOrganizationAssignment, OrganizationTypeEnum, UcOrganization } from '../../../../uc-api-sdk';

interface UcOrgServiceArgs {
  ucOrgs?: UcOrganization[]
  accessibleOrgs?: MedicalOrganizationAssignment[]
}

// data structure for tree node with icon & menu title
export interface RenderHierarchyNode {
  key: string;
  title: JSX.Element;
  icon?: JSX.Element;
  children?: RenderHierarchyNode[];
}

// data structure for tree node
export type HierarchyNode = TreeNodeProps & {
  id: string;
  type?: OrganizationTypeEnum | undefined;
  title: string | undefined;
  children?: HierarchyNode[];
  parentId: string;
}

export class UcOrgService {
  protected ucOrgs?: UcOrganization[];

  protected accessibleOrgs?: MedicalOrganizationAssignment[];

  protected orgNodeTable: Record<string, UcOrganization> = {};

  constructor(arg?: UcOrgServiceArgs) {
    this.ucOrgs = arg?.ucOrgs;
    this.accessibleOrgs = arg?.accessibleOrgs;
    this.createOrgGraph();
  }

  get ucOrgsList() {
    return this.ucOrgs;
  }

  get accessibleOrgsList() {
    return this.accessibleOrgs || [];
  }

  get sortedAccessibleOrgsList() {
    const sortedList = MedicalOrganizationHelper.makeAndSortMedicalOrgAssignments(
      this.accessibleOrgs?.filter(r => r.medicalOrganization) || [],
      false
    );
    const ids = sortedList.map((org) => org.medicalOrgId || '') as string[];
    const orgsObj = this.accessibleOrgs?.reduce((acc, org) => {
      acc[org.medicalOrgId as string] = org;
      return acc;
    }, {} as Record<string, MedicalOrganizationAssignment>) || {};

    const sortedOrgs: MedicalOrganizationAssignment[] = ids.map((id) => get(orgsObj, id));
    return sortedOrgs;
  }

  static buildTreeData(org: UcOrganization): HierarchyNode {
    const {
      id,
      organizationType,
      name,
      parentId,
    } = org;

    return ({
      id: id || '',
      title: name || '',
      type: organizationType || undefined,
      key: id || '',
      parentId: parentId || '',
    });
  }

  static get entitySortPriority(): OrganizationTypeEnum[] {
    return [
      OrganizationTypeEnum.ROOT,
      OrganizationTypeEnum.CARE_CENTER,
      OrganizationTypeEnum.CARE_DIVISION,
      OrganizationTypeEnum.CARE_GROUP,
    ];
  }

  static groupTreeData(
    entities = [] as HierarchyNode[],
    disabledNodeIds: string[] = [],
  ): HierarchyNode[] {
    let cloneEntities = sortBy([...entities], (e) => (
      UcOrgService.entitySortPriority.indexOf(e.type as OrganizationTypeEnum)
    ));

    const getChildren = (
      _curEntity: HierarchyNode,
    ): HierarchyNode[] => {
      const children = cloneEntities.filter((e) => (
        e.parentId === _curEntity.id
      ));
      if (!children) {
        return [];
      }
      cloneEntities = differenceBy(cloneEntities, [_curEntity, ...children], 'id');
      return children.map((child: HierarchyNode) => ({
        ...child,
        disabled: disabledNodeIds.includes(child.id),
        children: getChildren(child),
      } as HierarchyNode));
    };

    const grouped = [];
    while (cloneEntities.length) {
      const curEntity = cloneEntities[0];
      const myChildren = getChildren(curEntity);

      grouped.push({
        ...curEntity,
        disabled: disabledNodeIds.includes(curEntity.id),
        ...(myChildren.length && { children: myChildren }),
      });
      cloneEntities = differenceBy(cloneEntities, [curEntity, ...myChildren], 'id');
    }
    return grouped as HierarchyNode[];
  }

  private createOrgGraph() {
    this.ucOrgs?.forEach((org) => {
      const { id } = org;
      if (id) {
        this.orgNodeTable[id as string] = org;
      }
    });
  }

  public getPath(nodeId: string | null) {
    const path: UcOrganization[] = [];
    let currentId: string | null = nodeId;
    while (currentId !== null && this.orgNodeTable[currentId]) {
      path.push(this.orgNodeTable[currentId]);
      currentId = this.orgNodeTable[currentId].parentId ?? null;
    }
    return path.reverse();
  }

  public getTreeData(disabledNodeIds: string[] = []) {
    return UcOrgService.groupTreeData(
      this.ucOrgs?.map(UcOrgService.buildTreeData) || [],
      disabledNodeIds,
    );
  }

  public getNodeType(nodeId: string | null) {
    const node = this.ucOrgsList?.find(node => node.id === nodeId);
    return node?.organizationType;
  }

  public getRoot() {
    const rootNode = find(
      this.ucOrgsList || [],
      (v: UcOrganization) => v.organizationType === OrganizationTypeEnum.ROOT
    );
    return rootNode;
  }

  public isCareGroup(nodeId: string) {
    return this.getNodeType(nodeId) === OrganizationTypeEnum.CARE_GROUP;
  }
}

import { InvoiceCycleEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface InvoiceCycleEnumComponentProps {
  value?: InvoiceCycleEnum;
}

export const InvoiceCycleEnumComponent = ({ value }: InvoiceCycleEnumComponentProps) => {
  const renderValue = (value?: InvoiceCycleEnum) => {
    switch (value) {
      case InvoiceCycleEnum.MONTHLY: return 'Monthly';
      case InvoiceCycleEnum.QUARTERLY: return 'Quarterly';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };
  return <div>{renderValue(value)}</div>;
};

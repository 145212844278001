import { ColumnType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import { ReporterEnum, SymptomLog } from '../../../../uc-api-sdk';
import { SymptomTableDateComponent } from './SymptomTableDateComponent';
import { SymptomTableReporterComponent } from './SymptomTableReporterComponent';
import { SymptomSettingComponent } from './SymptomSettingComponent';
import { SYMPTOM_REPORTER_TYPES } from './SymptomReporterFilterConstant';
import { SymptomSeverityEnumComponent } from '../SymptomSeverityEnumComponent/SymptomSeverityEnumComponent';
import { SymptomNameEnumComponent } from './SymptomNameEnumComponent';
import { SYMPTOM_SEVERITY_TYPES } from './SymptomSeverityFilterComponent';
import { createSymptomSeverityFilter } from '../../../table/filter/SymptomSeverityFilter';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

type dateType = Moment | string | null | undefined;

const dateSorter = (a: dateType, b: dateType) => {
  if (a && b) {
    return moment(a).unix() - moment(b).unix();
  } if (!a) {
    return -1;
  }
  return 1;
};

export interface SymptomTableRowValue extends SymptomLog {
  isNested: boolean;
  originalValue?: SymptomLog;
  hasMultiple?: boolean;
  expanded?: boolean;
  total?: number;
  children?: SymptomTableRowValue[];
}

export const createSymptomColumns = {
  action: (): ColumnType<SymptomTableRowValue> => ({
    title: '',
    dataIndex: 'hasMultiple',
    key: 'hasMultiple',
    width: '1%',
    fixed: 'left',
  }),
  reportDate: (): ColumnType<SymptomTableRowValue> => ({
    title: 'Report date',
    dataIndex: 'reportDate',
    key: 'reportDate',
    width: '20%',
    sorter: (a, b) => dateSorter(a.createdAt, b.createdAt),
    render: (v, record) => (
      !record.isNested ? (
        <SymptomTableDateComponent
          date={record?.createdAt || ''}
          total={record?.total || 0}
          isNested={record.isNested}
        />
      ) : null
    ),
  }),
  reporter: (): ColumnType<SymptomTableRowValue> => ({
    title: 'Reporter',
    dataIndex: 'reporter',
    key: 'reporter',
    width: '16%',
    filters: SYMPTOM_REPORTER_TYPES,
    fixed: 'left',
    onFilter: (value, record) => record.reporterType?.toLowerCase() === String(value).toLowerCase(),
    render: (v, record) => (
      !record.isNested ? (
        <SymptomTableReporterComponent
          reporterType={record.reporterType || undefined}
          reportedByUser={record.reportedByUser || undefined}
          isNested={record.isNested}
        />
      ) : null
    ),
  }),
  symptoms: (): ColumnType<SymptomTableRowValue> => ({
    title: 'Symptoms',
    dataIndex: 'symptoms',
    key: 'symptoms',
    width: '15%',
    render: (v, record) => {
      const symptomEnum = record?.symptomList && record.symptomList.length > 0
        ? record.symptomList[0].symptom
        : undefined;

      if (symptomEnum) {
        return <SymptomNameEnumComponent value={symptomEnum} />;
      }
      return record?.symptomList && record.symptomList.length > 0
        ? record.symptomList[0].manualSymptom
        : undefined;
    },
  }),
  severity: (): ColumnType<SymptomTableRowValue> => ({
    title: 'Severity',
    dataIndex: 'severity',
    key: 'severity',
    width: '14%',
    filters: SYMPTOM_SEVERITY_TYPES,
    ...createSymptomSeverityFilter(),
    render: (v, record) => {
      const severity = record?.symptomList ? record?.symptomList[0]?.severity : undefined;
      return (
        <div className="flex gap0">
          <SymptomSeverityEnumComponent value={severity || undefined} />
        </div>
      );
    }
  }),
  note: (): ColumnType<SymptomTableRowValue> => ({
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    render: (v, record) => (
      <DisplayOrEmptyComponent
        value={record?.symptomList ? record?.symptomList[0]?.note : undefined}
      />
    )
  }),
  edit: (): ColumnType<SymptomTableRowValue> => ({
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    width: '4%',
    render: (v, record) => (
      !record.isNested && record.reporterType === ReporterEnum.CARE_TEAM_REPORTED ? (
        <SymptomSettingComponent
          symptomRowValue={record}
        />
      ) : null
    ),
  }),
};

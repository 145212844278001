import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { AIChatDraftRequestParams, AIChatDraftResponse } from '../types';
import { AIServicesInstance } from '../AIServices';

export const useAIChatDraft = <RCN = undefined>(
  configs?: MakeRequestHookConfig<AIChatDraftRequestParams, GetOptions, RCN>,
) => {
  // @ts-ignore
  const baseConfigs: UseRequestFactory<
    AIChatDraftRequestParams,
    AIChatDraftResponse,
    // @ts-ignore
    typeof AIChatDraftServices.getAIChatDraft,
    GetOptions,
    string
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof AIServicesInstance.getAIChatDraft>) => (
      AIServicesInstance.getAIChatDraft(...args)
    ),
    ResponseClassCreator: AIServicesInstance.getResponse,
    typeName: 'useAIChatDraft',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};

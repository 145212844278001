import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useAssociateMedicalOrgForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    organizationIds: {
      name: 'organizationIds',
      label: 'Associated Medical Organization',
    }
  }, options);
  return factory;
};

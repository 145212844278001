import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BlueBackgroundComponent } from '../BlueBackgroundComponent/BlueBackgroundComponent';
import { PerCodeFeeModelButtonComponent } from '../PerCodeFeeModelButtonComponent/PerCodeFeeModelButtonComponent';

export const PbpmFeeModelFormItemComponent = () => (
  <BlueBackgroundComponent>
    <FormItem name={['pbpm', 'unitFee']} required>
      <PerCodeFeeModelButtonComponent
        options={[35, 40, 45]}
        title="Fee for patients who have met 16 days of measurement or has
received 20 minutes of clinical staff"
      />
    </FormItem>
  </BlueBackgroundComponent>
);

import { BpTriageLevelEnum } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface BPTriageLevelEnumComponentProps {
  value?: BpTriageLevelEnum;
}

export const BPTriageLevelEnumComponent = ({
  value,
}: BPTriageLevelEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BpTriageLevelEnum.TRIAGE_10_MIN: return 'Triage 10 Min';
      case BpTriageLevelEnum.TRIAGE_3_HOUR: return 'Triage 3 Hour';
      case BpTriageLevelEnum.TRIAGE_IMMEDIATE: return 'Triage Immediate';
      case BpTriageLevelEnum.TRIAGE_NOOP: return 'Triage Noop';
      case BpTriageLevelEnum.TRIAGE_NOOP_IGNORE: return 'Triage Noop Ignore';
      default: return <DisplayOrEmptyComponent value={value} />;
    }
  };

  return (<>{getText()}</>);
};

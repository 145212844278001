import { Tooltip } from 'antd';
import React from 'react';
import DisplayOrEmptyComponent from '../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export const createEllipsisRender = () => ({
  render: (v: string) => (v ? (
    <Tooltip placement="topLeft" title={v}>
      <span>{v}</span>
    </Tooltip>
  ) : <DisplayOrEmptyComponent value={v} />),
  ellipsis: true,
});

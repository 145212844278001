import { Button } from 'antd';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { find, map, uniqueId } from 'lodash';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { AlertBGTableComponent, BGAlertTableData } from '../AlertBGTableComponent/AlertBGTableComponent';
import {
  BpTriageLevelEnum,
  DryRunRuleResult,
  EventRequest,
  EventTypeEnum,
  Measurement,
  MeasurementResultTypeEnum,
} from '../../../../uc-api-sdk';
import { BGMeasurementService } from '../../../vitals/helper/BGMeasurementService';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { AlertFetchMeasurementContainer } from '../../container/AlertFetchMeasurementContainer/AlertFetchMeasurementContainer';
import { AlertBPTableComponent, BPAlertTableData } from '../AlertBPTableComponent/AlertBPTableComponent';
import { BPMeasurementService } from '../../../vitals/helper/BPMeasurementService';
import { MealTypeEnum } from '../../type';
import './AlertSimulatorComponent.scss';

export interface AlertSimulatorComponentProps {
  type: EventTypeEnum;
  isLoading: boolean;
  onDryRun?: (measurements: EventRequest[]) => Promise<void>;
  result?: DryRunRuleResult[];
}

export const AlertSimulatorComponent = ({
  type,
  onDryRun,
  isLoading,
  result = [],
}: AlertSimulatorComponentProps) => {
  const [bgMeasurements, setBGMeasurements] = useState<BGAlertTableData[]>([]);
  const [bpMeasurements, setBPMeasurements] = useState<BPAlertTableData[]>([]);
  const [results, setResults] = useState<DryRunRuleResult[]>(result || []);

  useEffect(() => {
    setResults(result);
  }, [result]);

  const findMeasurementSuccess = (res: DryRunRuleResult[], measurementId?: string) => (
    // eslint-disable-next-line no-underscore-dangle
    find(res, (r: DryRunRuleResult) => r.event?._id === measurementId)
  );

  useEffect(() => {
    if (type !== EventTypeEnum.BG) return;
    setBGMeasurements((v) => {
      const newV = map(v, (m) => {
        const newM = {
          ...m,
          success: findMeasurementSuccess(result, m?.id)?.ruleResult?.success ?? undefined,
        };
        return newM;
      });
      return newV;
    });
  }, [results]);

  useEffect(() => {
    if (type !== EventTypeEnum.BP) return;
    setBPMeasurements((v) => {
      const newV = map(v, (m) => {
        const newM = {
          ...m,
          success: findMeasurementSuccess(result, m?.id)?.ruleResult?.success ?? undefined,
        };
        return newM;
      });
      return newV;
    });
  }, [results]);

  const vitalType = useMemo(() => {
    switch (type) {
      case EventTypeEnum.BG:
      case EventTypeEnum.BG_RECHECK:
        return MeasurementResultTypeEnum.BG;
      case EventTypeEnum.BP:
      case EventTypeEnum.BP_RECHECK:
        return MeasurementResultTypeEnum.BP;
      default: return MeasurementResultTypeEnum.BG;
    }
  }, [type]);

  const handleBGChange = (v: Measurement[]) => {
    setBGMeasurements(map(v, (m) => {
      const mObj = new BGMeasurementService(m);
      const newM: BGAlertTableData = {
        id: uniqueId(),
        bloodGlucose: m.blood_glucose?.value || undefined,
        dateTime: m.date
          ? TimezoneService.calcDateTime(m.date) : undefined,
        mealType: mObj.mealType,
        beforeMeal: mObj.beforeMeal,
      };
      return newM;
    }));
  };

  const handleBPChange = (v: Measurement[]) => {
    setBPMeasurements(map(v, (m) => {
      const mObj = new BPMeasurementService(m);
      const newM: BPAlertTableData = {
        id: uniqueId(),
        dateTime: m.date
          ? TimezoneService.calcDateTime(m.date) : undefined,
        systolicBloodPressure: m.systolic_blood_pressure?.value || undefined,
        diastolicBloodPressure: m.diastolic_blood_pressure?.value || undefined,
        heartRate: m.heart_rate?.value || undefined,
        triageLevel: mObj.triageLevel,
      };
      return newM;
    }));
  };

  const handleOnChange = useCallback((v: Measurement[]) => {
    if (results.length > 0) setResults([]);
    switch (type) {
      case EventTypeEnum.BG:
      case EventTypeEnum.BG_RECHECK:
        return handleBGChange(v);
      case EventTypeEnum.BP:
      case EventTypeEnum.BP_RECHECK:
        return handleBPChange(v);
      default: return undefined;
    }
  }, [
    results,
    type,
    setBGMeasurements,
    setBPMeasurements,
  ]);

  const handleDryRun = () => {
    if (type === EventTypeEnum.BG) {
      onDryRun?.(map(bgMeasurements, (m) => ({
        id: m.id,
        measureTime: m.dateTime?.toDate().getTime() as number,
        bgMmol: m.bloodGlucose as number,
        mealType: m.mealType as unknown as MealTypeEnum,
        beforeMeal: m.beforeMeal as boolean,
      })));
    } else if (type === EventTypeEnum.BP) {
      onDryRun?.(map(bpMeasurements, (m) => ({
        id: m.id,
        measureTime: m.dateTime?.toDate().getTime() as number,
        sbp: m.systolicBloodPressure as number,
        dbp: m.diastolicBloodPressure as number,
        pulse: m.heartRate as number,
        triageLevel: m.triageLevel as BpTriageLevelEnum,
      })));
    }
  };

  return (
    <div className="alert-sim-component">
      <FixedComponent>
        <FixedComponent.Child>
          <AlertFetchMeasurementContainer
            type={vitalType}
            onChange={handleOnChange}
          />
          <div className="alert-sim-component__measurement-table">
            {vitalType === MeasurementResultTypeEnum.BG && (
              <AlertBGTableComponent
                value={bgMeasurements}
                onChange={setBGMeasurements}
              />
            )}
            {vitalType === MeasurementResultTypeEnum.BP && (
              <AlertBPTableComponent
                value={bpMeasurements}
                onChange={setBPMeasurements}
              />
            )}
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <div className="flex jc-e mt20">
            <Button onClick={handleDryRun} loading={isLoading} disabled={isLoading}>
              Simulate the alert rule
            </Button>
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </div>
  );
};

import { ColumnFilterItem } from 'antd/lib/table/interface';
import { SymptomSeverityEnum } from '../../../../uc-api-sdk';
import { SymptomSeverityEnumComponent } from '../SymptomSeverityEnumComponent/SymptomSeverityEnumComponent';

export const SYMPTOM_SEVERITY_TYPES: ColumnFilterItem[] = [
  {
    value: SymptomSeverityEnum.MILD,
    text: <SymptomSeverityEnumComponent value={SymptomSeverityEnum.MILD} type="tag" />,
  },
  {
    value: SymptomSeverityEnum.MODERATE,
    text: <SymptomSeverityEnumComponent value={SymptomSeverityEnum.MODERATE} type="tag" />,
  },
  {
    value: SymptomSeverityEnum.SEVERE,
    text: <SymptomSeverityEnumComponent value={SymptomSeverityEnum.SEVERE} type="tag" />,
  },
  {
    value: SymptomSeverityEnum.NOT_SURE,
    text: <SymptomSeverityEnumComponent value={SymptomSeverityEnum.NOT_SURE} type="tag" />,
  },
  {
    value: SymptomSeverityEnum.NA,
    text: <SymptomSeverityEnumComponent value={SymptomSeverityEnum.NA} type="tag" />,
  }
];

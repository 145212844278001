export const BILLING_CODES = {
  ccm: [
    '99490',
    '99439',
    '99487',
    '99489',
  ],
  rpm: [
    '99453',
    '99454',
    '99457',
    '99458',
  ]
};

export const BILLING_CODE_DEPENDENCIES: Record<string, string> = {
  99439: '99490',
  99489: '99487',
  99458: '99457'
};

export const REVERSED_BILLING_CODE_DEPENDENCIES = Object.fromEntries(
  Object.entries(BILLING_CODE_DEPENDENCIES).map(([key, value]) => [value, key])
);

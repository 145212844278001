import { ColumnType } from 'antd/es/table';
import { isNil } from 'lodash';
import { HSUnitProps, WeightService } from '../../../../helpers/weight';
import { HSSummaryTableData } from './HSSummaryTableComponent';

const EMPTY_VALUE = 'N/A';

/* eslint-disable import/prefer-default-export */
export const HSSummaryTableColumns = (unit: HSUnitProps['unit']) => {
  const fn = WeightService.toLbOrKg(unit);
  return {
    period: (): ColumnType<HSSummaryTableData> => ({
      title: 'Time Period',
      key: 'period',
      dataIndex: 'period',
      width: '15%',
    }),
    average: (): ColumnType<HSSummaryTableData> => ({
      title: 'Avg',
      key: 'average',
      dataIndex: 'average',
      render: (value) => (isNil(value) ? EMPTY_VALUE : fn(Number(value))),
    }),
    bmi: (): ColumnType<HSSummaryTableData> => ({
      title: 'BMI',
      key: 'bmi',
      dataIndex: 'bmi',
      render: (value) => (isNil(value) ? EMPTY_VALUE : Number(value).toFixed(1)),
    }),
    range: (): ColumnType<HSSummaryTableData> => ({
      title: 'Range',
      key: 'range',
      dataIndex: 'range',
      width: '25%',
      render: (_, record) => (isNil(record.min) ? EMPTY_VALUE
        : `${fn(Number(record.min))} - ${fn(Number(record.max))}`),
    }),
    total: (): ColumnType<HSSummaryTableData> => ({
      title: '#',
      key: 'total',
      dataIndex: 'total',
    }),
    changePercent: (): ColumnType<HSSummaryTableData> => ({
      title: '% Change',
      key: 'changePercent',
      dataIndex: 'changePercent',
      render: (value) => (isNil(value) ? EMPTY_VALUE : `${value > 0 ? '+' : ''}${value.toFixed(1)}%`),
    }),
  };
};

import { MedicalOrganizationType } from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface MedicalOrgTypeEnumComponentProps {
  type?: MedicalOrganizationType | null;
}

export const MedicalOrgTypeEnumComponent = ({
  type
}: MedicalOrgTypeEnumComponentProps) => {
  const renderType = () => {
    switch (type) {
      case MedicalOrganizationType.CLINIC:
        return 'Clinic';
      case MedicalOrganizationType.MEDICAL_GROUP:
        return 'Medical Group';
      case MedicalOrganizationType.CUSTOM:
        return 'Custom';
      default:
        return <DisplayOrEmptyComponent />;
    }
  };

  return (
    <div>
      {renderType()}
    </div>
  );
};

import { Col, Row, Select } from 'antd';
import React, { useCallback } from 'react';
import { AccountHelper } from '../../../../../helpers/account';
import { Employee, RoleTypeEnum, UserTypeEnum } from '../../../../../uc-api-sdk';
import { BaseSearchParams } from '../../../../../uc-api-sdk/staticFiles/useReqHook';
import { EmployeeSelectLabelComponent } from '../../../../calendar/component/EmployeeCalendarSelectComponent/EmployeeSelectLabelComponent';
import { EmployeeSelectComponentInfo } from '../../../../Employee/component/EmployeeSelectComponent/EmployeeSelectComponent';
import { EmployeeSelectContainer, EmployeeSelectContainerProps } from '../../../../Employee/container/EmployeeSelectContainer/EmployeeSelectContainer';
import { InputTypeArray } from '../../../../Input/types';
import { MiniUserComponent } from '../../../../user/component/MiniUserComponent/MiniUserComponent';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import './SelectStaffComponent.scss';
import { SelectStaffDropdownRenderComponent } from './SelectStaffDropdownRenderComponent';

export interface SelectStaffComponentProps extends InputTypeArray<string> {
  getEmployData?: (value?: EmployeeSelectComponentInfo<Employee>) => void;
}

export const SelectStaffComponent = ({
  value,
  onChange,
  getEmployData,
}: SelectStaffComponentProps) => {
  const { medicalOrgService } = useMedicalOrgContext();
  const { id } = medicalOrgService;
  const optionRenderer = useCallback<Exclude<
    EmployeeSelectContainerProps['optionRenderer'], undefined>
    >((value) => {
      const isInOrg = value.info.assignedRoles?.find(
        (role) => role.organizationId === id
      );
      return (
        <Select.Option
          key={value.id}
          label={value.fullName}
          disabled={isInOrg !== undefined}
        >
          <Row gutter={24}>
            <Col span={12}>
              <MiniUserComponent
                avatar={value.info.employeeProfile?.avatar?.url || undefined}
                name={AccountHelper.getFullName(value.info.employeeProfile)}
                email={value.info.email || undefined}
                isObserver={value.info.isObserverUser as boolean}
              />
            </Col>
            <Col span={12} className="flex ai-c">
              {isInOrg ? 'Already in this organization' : null}
            </Col>
          </Row>
        </Select.Option>
      );
    }, []);

  const dropdownRender = (menu: React.ReactElement) => (
    <SelectStaffDropdownRenderComponent menu={menu} />
  );

  const filter: (value: string) => BaseSearchParams<Employee>['filter'] = (value: string) => ({
    nameNear: value,
    staffRoleIn: {
      in: [
        RoleTypeEnum.PROVIDER,
        RoleTypeEnum.NP,
        RoleTypeEnum.MA,
        RoleTypeEnum.CLINICAL_MANAGER,
        RoleTypeEnum.CLINICAL_GROUP_BILLER
      ],
    },
    userType: UserTypeEnum.CLINICAL_STAFF,
  });

  return (
    <div
      className="org-select-component"
    >
      <EmployeeSelectContainer
        optionRenderer={optionRenderer}
        tagRender={EmployeeSelectLabelComponent}
        onChange={onChange}
        getEmployData={getEmployData}
        value={value}
        dropdownRender={dropdownRender}
        filter={(value: string) => filter(value)}
      />
    </div>
  );
};

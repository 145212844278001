import {
  createContext,
  Key,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { functionNotImplYet } from '../../../../../contexts/helper';
import { StorageKeyEnum, useSessionStorage } from '../../../../../hooks';
import { useEffectFirstTimeWithValue } from '../../../../../hooks/useEffectFirstTimeWithValue/useEffectFirstTimeWithValue';
import { DeprecatedOrganizationTypeEnum } from '../../../../../types/medicalOrg';
import {
  APIResponse,
  MedicalOrganizationAssignment,
  useMedicalOrganizationSearchAssociatedMedicalOrg,
  useUcOrganizationSearch
} from '../../../../../uc-api-sdk';
import { UcOrgService } from '../../service/UcOrganizationService';

const notImplemented = functionNotImplYet('CareOperationContext');

interface CareOperationContextValue {
  getAccessibleOrgsList: () => (
    Promise<APIResponse<MedicalOrganizationAssignment[]> | null | undefined>
  );
  ucOrgService: UcOrgService;
  ucOrgsInfo: ReturnType<typeof useUcOrganizationSearch>;
  accessibleOrgsListInfo:
    ReturnType<typeof useMedicalOrganizationSearchAssociatedMedicalOrg>;
  selectedEntityId: string | undefined;
  setSelectedEntityId: (id: string | undefined) => void;
  expandedNodes: Key[] | undefined;
  handleExpandChange: (expandedKeys: Key[], externalOnExpand?: (keys: Key[]) => void) => void;
  setExpandedNodes: (keys: Key[]) => void;
  refetch: () => void;
}

const CareOperationContext = createContext<CareOperationContextValue>({
  getAccessibleOrgsList: () => Promise.reject(new Error('Not implemented yet!')),
  ucOrgService: {} as UcOrgService,
  ucOrgsInfo: {} as ReturnType<typeof useUcOrganizationSearch>,
  accessibleOrgsListInfo: {} as
    ReturnType<typeof useMedicalOrganizationSearchAssociatedMedicalOrg>,
  selectedEntityId: undefined,
  setSelectedEntityId: notImplemented('setSelectedEntityId'),
  expandedNodes: undefined,
  handleExpandChange: notImplemented('handleExpandChange'),
  setExpandedNodes: notImplemented('setExpandedNodes'),
  refetch: () => Promise.reject(new Error('Not implemented!')),
});

export interface CareOperationContextProviderProps {
  children: ReactNode;
}

export const CareOperationContextProvider = ({
  children,
}: CareOperationContextProviderProps) => {
  const [
    selectedNodeId,
    setSelectedNodeId,
  ] = useSessionStorage<string | undefined>(StorageKeyEnum.SELECTED_MED_ORG_HIERARCHY_NODE_ID);
  const [
    selectedEntityId,
    setSelectedEntityIdBase,
  ] = useState<string | undefined>(selectedNodeId);

  const setSelectedEntityId = useCallback((nodeId: string | undefined) => {
    setSelectedEntityIdBase(nodeId);
    setSelectedNodeId(nodeId);
  }, [setSelectedEntityIdBase, setSelectedNodeId]);

  const [
    expandedNodes,
    setExpandedNodes
  ] = useSessionStorage<Key[] | undefined>(StorageKeyEnum.SELECTED_MED_ORG_HIERARCHY_NODE_IDS);

  const handleExpandChange = useCallback((
    newExpandedKeys: Key[],
    externalOnExpand?: (keys: Key[]) => void
  ) => {
    setExpandedNodes(newExpandedKeys);

    if (externalOnExpand) {
      externalOnExpand(newExpandedKeys);
    }
  }, [setExpandedNodes]);

  // get orgs list
  const ucOrgsReq = useUcOrganizationSearch({
    params: {
      // @ts-ignore
      filter: {
        organizationTypeIn: {
          in: [
            DeprecatedOrganizationTypeEnum.CARE_CENTER,
            DeprecatedOrganizationTypeEnum.CARE_DIVISION,
            DeprecatedOrganizationTypeEnum.CARE_GROUP,
            DeprecatedOrganizationTypeEnum.ROOT,
          ] as DeprecatedOrganizationTypeEnum[] || [],
        }
      },
      pageInfo: {
        pagination: false,
        page: 1,
        size: 1000
      }
    },
    options: {
      sendOnMount: true,
    }
  });
  // get accessible orgs list
  const accessibleOrgsListReq = useMedicalOrganizationSearchAssociatedMedicalOrg({
    options: { sendOnMount: false },
  });

  const ucOrgService = useMemo(() => (
    new UcOrgService({
      ucOrgs: ucOrgsReq.data?.data?.content || [],
      accessibleOrgs: accessibleOrgsListReq.data?.data || []
    })), [ucOrgsReq.data, accessibleOrgsListReq.data]);

  useEffectFirstTimeWithValue(ucOrgService.getRoot()?.id, (rootId) => {
    if (!selectedEntityId && rootId) {
      setSelectedEntityId(rootId);
    }
  });

  const getAccessibleOrgsList = () => (
    accessibleOrgsListReq.send({
      params: {
        searchRequest: {
          filter: {
            careUnitId: selectedEntityId,
          },
          pageInfo: {
            pagination: false,
          }
        },
      }
    })
  );

  // refetch
  const refetch = () => (
    Promise.all([
      ucOrgsReq.refetch(),
      getAccessibleOrgsList(),
    ])
  );

  const value = useMemo<CareOperationContextValue>(() => ({
    getAccessibleOrgsList,
    ucOrgService,
    ucOrgsInfo: { ...ucOrgsReq },
    accessibleOrgsListInfo: { ...accessibleOrgsListReq },
    selectedEntityId,
    setSelectedEntityId,
    selectedNodeId: selectedNodeId as string,
    setSelectedNodeId,
    expandedNodes,
    setExpandedNodes,
    handleExpandChange,
    refetch,
  }), [
    ucOrgsReq.data,
    ucOrgsReq.error,
    ucOrgsReq.isLoading,
    ucOrgsReq,
    accessibleOrgsListReq.data,
    accessibleOrgsListReq.error,
    accessibleOrgsListReq.isLoading,
    accessibleOrgsListReq,
    setSelectedEntityId,
    setSelectedNodeId,
    ucOrgsReq.refetch,
    ucOrgService,
  ]);

  return (
    <CareOperationContext.Provider value={value}>
      {children}
    </CareOperationContext.Provider>
  );
};

export const useCareOperationContext = () => {
  const contextValue = useContext(CareOperationContext);
  return contextValue;
};

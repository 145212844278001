import { useUpdate } from '../../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { RoleTypeEnum, useMedicalOrganizationAddStaff } from '../../../../../uc-api-sdk';
import { DrawerComponentProps } from '../../../../../uiComponent/Drawer/DrawerComponent';
import { AddStaffComponentValue } from '../../component/AddStaffComponent/AddStaffComponent';
import { AddStaffDrawerComponent } from '../../component/AddStaffDrawerComponent/AddStaffDrawerComponent';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';

export interface AddStaffDrawerContainerProps extends DrawerComponentProps {
}

export const AddStaffDrawerContainer = ({
  buttonType,
}: AddStaffDrawerContainerProps) => {
  const { medicalOrgService } = useMedicalOrgContext();
  const updateHook = useUpdate('STAFF_UPDATED');
  const info = useMedicalOrganizationAddStaff({});

  const onClickSave = useDebounce((close: () => void, roles: AddStaffComponentValue) => {
    const staffRoleList = Object.keys(roles).map((key) => ({
      staffId: key,
      roles: roles[key] as RoleTypeEnum[]
    }));
    ApiRequestHelper.tryCatch(info.send({
      params: {
        request: {
          medicalOrganizationId: medicalOrgService.id ?? '',
          staffRoleList
        },
      }
    }), {
      success: 'Add staff successfully.',
      error: 'Failed to add staff.',
      onSuccess: () => {
        close?.();
        updateHook.updateValue();
      }
    });
  }, 300, [info]);

  return (
    <AddStaffDrawerComponent
      buttonType={buttonType}
      onSave={onClickSave}
      isLoading={info.isLoading}
    />
  );
};

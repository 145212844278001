import { Button } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { useCallback, useState } from 'react';
import { LoadingType } from '../../../../../types/common';
import { Employee } from '../../../../../uc-api-sdk';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import { EmployeeSelectComponentInfo } from '../../../../Employee/component/EmployeeSelectComponent/EmployeeSelectComponent';
import { SelectedStaffTableComponent } from './SelectedStaffTableComponent';
import { SelectStaffComponent } from './SelectStaffComponent';

export interface AddStaffComponentValue {
  [memberId: string]: string[];
}
export interface AddStaffComponentProps extends LoadingType {
  onClickAdd?: (roles: AddStaffComponentValue) => void;
}

export const AddStaffComponent = ({
  onClickAdd,
  isLoading
}: AddStaffComponentProps) => {
  const [tableData, setTableData] = useState<EmployeeSelectComponentInfo<Employee>[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<Record<string, string[]>>({});

  const onCheckboxChange = (v: string[], id: string) => {
    setSelectedRoles((pre) => ({ ...pre, [id]: v }));
  };

  const addStaff = (v?: EmployeeSelectComponentInfo<Employee>) => {
    if (!v) return;
    const existingData = tableData.find((data) => data.id === v.id);
    if (existingData) return;
    setTableData([...tableData, v]);
    setSelectedRoles({ ...selectedRoles, [v.id]: [v.info.staffRole || ''] });
  };

  const removeStaff = useCallback((id?: string) => {
    setTableData((v) => {
      const newValue = [...v];
      return newValue.filter((data) => data.id !== id);
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSelectedRoles((pre) => (({ [id || '']: _, ...o }) => o)(pre));
  }, [setTableData]);

  const onChange: CheckboxGroupProps['onChange'] = (v) => {
    if (v.length !== 0) {
      const roles = v.map((role) => role.toString().split('-')[1]);
      const userId = v[0].toString().split('-')[0];
      onCheckboxChange(roles, userId);
    }
  };

  return (
    <FixedComponent>
      <FixedComponent.Child>
        <SelectStaffComponent
          getEmployData={addStaff}
        />
        <SelectedStaffTableComponent
          dataSource={tableData}
          className="mt20"
          removeStaff={removeStaff}
          onCheckboxChange={onChange}
        />
      </FixedComponent.Child>
      <FixedComponent.Child isFixed>
        <Button
          disabled={isLoading}
          loading={isLoading}
          type="primary"
          className="w100"
          onClick={() => {
            onClickAdd?.(selectedRoles);
          }}
        >
          Add
        </Button>
      </FixedComponent.Child>
    </FixedComponent>
  );
};

import { useUpdate } from '../../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import {
  RemoveRoleValidationResponse,
  RoleAssignmentOverWriteByOrganizationRequest,
  RoleTypeEnum,
  useRoleAssignmentOverwriteCurrentRolesInOrganization,
  UserTypeEnum
} from '../../../../../uc-api-sdk';
import { ModifyMemberRolesDrawerComponent } from '../../component/ModifyMemberRolesDrawerComponent/ModifyMemberRolesDrawerComponent';

export interface ModifyMemberDrawerContainerProps {
  memberId: string;
  initialRoles?: RoleTypeEnum[];
  careUnitId: string;
  hasPatientsCheck?: RoleTypeEnum[];
  otherRoles?: RoleTypeEnum[];
  validationModalData: RemoveRoleValidationResponse[];
  userType?: UserTypeEnum;
}

export const ModifyMemberDrawerContainer = ({
  memberId,
  initialRoles,
  careUnitId,
  hasPatientsCheck,
  validationModalData,
  userType,
  otherRoles
}: ModifyMemberDrawerContainerProps) => {
  const memberUpdateHook = useUpdate('MEMBER_UPDATED');
  const staffUpdateHook = useUpdate('STAFF_UPDATED');
  const modifyMemberRolesRequestHook = useRoleAssignmentOverwriteCurrentRolesInOrganization({});

  const onClickSave = useDebounce((
    close: () => void,
    employees: RoleAssignmentOverWriteByOrganizationRequest
  ) => {
    ApiRequestHelper.tryCatch(modifyMemberRolesRequestHook.send({
      params: {
        request: employees,
      }
    }), {
      success: 'Member roles modified successfully.',
      error: 'Failed to modify member roles.',
      onSuccess: () => {
        close?.();
        memberUpdateHook.updateValue();
        staffUpdateHook.updateValue();
      }
    });
  }, 300, [modifyMemberRolesRequestHook]);

  return (
    <ModifyMemberRolesDrawerComponent
      memberId={memberId}
      onSave={onClickSave}
      initialRoles={initialRoles}
      careUnitId={careUnitId}
      hasPatientsCheck={hasPatientsCheck}
      validationModalData={validationModalData}
      userType={userType}
      otherRoles={otherRoles}
      isLoading={modifyMemberRolesRequestHook.isLoading}
    />
  );
};

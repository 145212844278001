import { Space } from 'antd';
import { CodeCap } from '../../../../uc-api-sdk';
import { BILLING_CODES } from '../../constants/billingCodes';

export interface BillingCodesSummaryComponentProps {
  codes: CodeCap[];
}

export const BillingCodesSummaryComponent = ({ codes }: BillingCodesSummaryComponentProps) => {
  const ccmCodes = codes.filter(c => c.code && BILLING_CODES.ccm.includes(c.code || ''));
  const rpmCodes = codes.filter(c => c.code && BILLING_CODES.rpm.includes(c.code || ''));
  return (
    <div>
      <div>
        <Space>
          {ccmCodes.length > 0 && 'CCM Code: '}
          <span>
            {ccmCodes.map(
              (c) => {
                const unit = c.capUnits ? `(Cap Unit: ${c.capUnits}) ` : '';
                const s = `${c.code}${unit}`;
                return s;
              }
            ).join(', ')}
          </span>
        </Space>
      </div>
      <div>
        <Space>
          {rpmCodes.length > 0 && 'RPM Code: '}
          <span>
            {rpmCodes.map(
              (c) => {
                const unit = c.capUnits ? `(Cap Unit: ${c.capUnits}) ` : '';
                const s = `${c.code}${unit}`;
                return s;
              }
            ).join(', ')}
          </span>
        </Space>
      </div>
    </div>
  );
};

import { Tabs, TabsProps } from 'antd';
import classNames from 'classnames';

import './AdminPortalTabsComponent.scss';

export interface AdminPortalTabsComponentProps extends TabsProps {
}

export const AdminPortalTabsComponent = ({
  className = '',
  ...props
}: AdminPortalTabsComponentProps) => (
  <Tabs
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={classNames({
      'admin-portal-tabs-component': true,
      [className]: !!className,
    })}
  />
);

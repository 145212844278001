import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';

export interface PluralComponentProps {
  value?: number;
  unit: string;
  pluralEnd?: string;
}

export const PluralComponent = ({
  value,
  unit,
  pluralEnd = 's',
}: PluralComponentProps) => {
  const getUnit = () => {
    if (!value) return unit;
    return value <= 1 ? unit : `${unit}${unit ? pluralEnd : ''}`;
  };

  return (
    <div className="flex">
      <div className="plural-value">
        <DisplayOrEmptyComponent value={value} />
      </div>
      <div className="plural-unit">
        {getUnit()}
      </div>
    </div>
  );
};

import { MedicalOrgBillingComponent } from '../MedicalOrgBillingComponent/MedicalOrgBillingComponent';
import { MedicalOrgProfileComponent } from '../MedicalOrgProfileComponent/MedicalOrgProfileComponent';
import { MedicalOrgProgramComponent } from '../MedicalOrgProgramComponent/MedicalOrgProgramComponent';
import './MedicalOrgConfigurationComponent.scss';

export interface MedicalOrgConfigurationComponentProps {
}

export const MedicalOrgConfigurationComponent = () => (
  <div className="medical-org-configuration">
    <MedicalOrgProfileComponent />
    <MedicalOrgBillingComponent />
    <MedicalOrgProgramComponent />
  </div>
);

import { ArrowUpOutlined } from '@ant-design/icons';
import { Divider, Progress } from 'antd';
import EnvConfig from '../../../../../configs/envConfig/envConfig';
import { ProgressBodyProps } from '../../../../../uiComponent/HeaderPopoverComponent/HeaderPopoverComponent';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';
import { TimeUpdaterComponent } from '../../../../../uiComponent/TimeUpdaterComponent/TimeUpdaterComponent';
import TooltipComponent from '../../../../../uiComponent/TooltipComponent/TooltipComponent';
import { ADMIN_PORTAL_TEXTS } from '../../../../adminPortal2/constants/texts';
import './PatientInfoProgressTagComponent.scss';

export interface PatientCareProgressTagProps extends ProgressBodyProps {
  hideCurrentSession?: boolean;
}

export const PatientCareProgressTagComponent = ({
  max,
  current,
  startTime,
  hideCurrentSession,
}: PatientCareProgressTagProps) => {
  const progress = Math.floor((current / max) * 100);
  const strokeColor = current < 20 ? '#EF973B' : '#1890FF';

  return (
    <TagComponent iconType="none" background="oval">
      <div className="progress-tag">
        <span>20+mins care</span>
        <Progress
          className="progress-bar"
          percent={progress}
          showInfo={false}
          strokeColor={strokeColor}
          trailColor="#D9D9D9"
          strokeWidth={3.5}
        />
        <span>
          <span className="current-number">
            {current}
          </span>
          {`/${max}`}
        </span>
        {
          !hideCurrentSession
          && (
            <>
              <Divider type="vertical" />
              <TooltipComponent title={ADMIN_PORTAL_TEXTS.tooltips.billableTimeProgressBarTooltip} type={null} placement="bottom">
                <span>
                  Current session
                  {' '}
                  <span className="light-blue">
                    <TimeUpdaterComponent
                      startTime={startTime}
                      stopWatchMaxInSecond={EnvConfig.patientProfileSessionTimer}
                    />
                    mins
                    <ArrowUpOutlined className="arrowUpOutlined" />
                  </span>
                </span>
              </TooltipComponent>
            </>
          )
        }
      </div>
    </TagComponent>
  );
};

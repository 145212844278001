import { useUpdateListener } from '../../../../../contexts/UpdateContext/UpdateContext';
import CardEditWithHeader from '../../../../../uiComponent/CardEditWithHeader/CardEditWithHeader';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import PhoneWithCountryCodeComponent from '../../../../../uiComponent/PhoneComponent/PhoneWithCountryCodeComponent';
import TooltipComponent from '../../../../../uiComponent/TooltipComponent/TooltipComponent';
import { PopupDrawerTypes, useAdminPortalPopupContext } from '../../../layout/context/AdminPortalContext/AdminPortalContext';
import { useMedicalOrgContext } from '../../context/MedicalOrgContext/MedicalOrgContext';
import { MedicalOrgCoBrandingImageComponent } from '../MedicalOrgCoBrandingImageComponent/MedicalOrgCoBrandingImageComponent';
import { MedicalOrgCommonCardContentComponent } from '../MedicalOrgCommonCardContentComponent/MedicalOrgCommonCardContentComponent';
import { MedicalOrgDeviceDisplayComponent } from '../MedicalOrgDeviceDisplayComponent/MedicalOrgDeviceDisplayComponent';

export const MedicalOrgServiceComponent = () => {
  const { medicalOrgService, refetch } = useMedicalOrgContext();
  const { serviceConfig } = medicalOrgService;
  const { open, close } = useAdminPortalPopupContext()
    .getDrawer(PopupDrawerTypes.EditMedicalOrgService);

  useUpdateListener('MEDICAL_ORG_SERVICE_UPDATED', refetch);

  const data = [
    {
      key: (
        <TooltipComponent
          triggerOnHoverIcon
          type="info-icon"
          title="Should measurement devices be maintained
in stock at this organization, patients will
have the option to acquire the device directly
on-site."
        >
          In-clinic Inventory
        </TooltipComponent>
      ),
      value: <div>{serviceConfig?.inClinicInventory ? 'Yes' : 'No'}</div>
    },
    {
      key: (
        <TooltipComponent
          triggerOnHoverIcon
          type="info-icon"
          title="If a local number is selected, the caller ID
  shown on the patient's phone when receiving, calls will be
  &quot;iHealth Unified Care + this number&quot; instead of our
  number starting with 866."
        >
          Call Center Local Number
        </TooltipComponent>
      ),
      value: (
        <PhoneWithCountryCodeComponent
          value={serviceConfig?.callCenterLocalNumber ?? ''}
        />
      )
    },
    {
      key: (
        <TooltipComponent
          triggerOnHoverIcon
          type="info-icon"
          title="If patients are unable to use smartphone devices,
they will be able to use non-smartphone devices
provided in this organization for conducting
vital measurements."
        >
          Non-smartphone Device
        </TooltipComponent>
      ),
      value: (
        <MedicalOrgDeviceDisplayComponent
          devices={serviceConfig?.nonSmartPhoneAlternatives || []}
        />
      )
    },
    {
      key: <div>Co-branding</div>,
      value: (
        <div className="flex fd-c gap1">
          {serviceConfig?.coBrandConfig?.coBrandName ?? <DisplayOrEmptyComponent />}
          {serviceConfig?.coBrandConfig?.logo?.fileKey && (
          <MedicalOrgCoBrandingImageComponent
            fileKey={serviceConfig?.coBrandConfig?.logo?.fileKey || ''}
          />
          )}
        </div>
      )
    },
  ];
  const initialValues = {
    inClinicInventory: serviceConfig?.inClinicInventory || false,
    nonSmartPhoneAlternatives: serviceConfig?.nonSmartPhoneAlternatives || [],
    callCenterLocalNumber: serviceConfig?.callCenterLocalNumber || '',
    coBrandName: serviceConfig?.coBrandConfig?.coBrandName || '',
    logo: serviceConfig?.coBrandConfig?.logo?.fileKey || '',
    orgId: medicalOrgService.id || '',
  };

  const handleClick = () => {
    open({
      onSuccess: () => {
        close();
        refetch();
      },
      initialValues,
    });
  };
  return (
    <CardEditWithHeader
      modal={{
        maskClosable: false,
      }}
      header="Clinic Setup"
      onEdit={handleClick}
    >
      <MedicalOrgCommonCardContentComponent data={data} />
    </CardEditWithHeader>
  );
};

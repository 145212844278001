import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button, Col, Divider, Form, FormProps, Input, InputNumber, Row, Tooltip
} from 'antd';
import classNames from 'classnames';
import TimezoneSelectComponent from '../../../../selectComponents/TimezoneSelectComponent/TimezoneSelectComponent';
import { Address, MedicalOrganizationType } from '../../../../uc-api-sdk';
import { AddressComponent } from '../../../../uiComponent/Address/AddressComponent/AddressComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import PhoneInput from '../../../../uiComponent/PhoneInput/PhoneInput';
import { useCreateMedicalOrganizationForm } from '../../hook/useCreateMedicalOrganizationForm';
import { OrganizationTypeInputComponent } from '../uiComponent/OrganizationTypeInputComponent/OrganizationTypeInputComponent';
import './CreateMedicalOrganizationFormComponent.scss';

export interface CreateMedicalOrganizationFormValue {
  type: MedicalOrganizationType;
  businessName?: string;
  alias: string;
  description?: string;
  phone?: string;
  timezone: string;
  id?: string;
  orgNumber: string;
  addresses?: Address[];
}

export interface CreateMedicalOrganizationFormComponentProps extends FormProps {
  isEditing?: boolean;
  isLoading?: boolean;
  onSubmit: (v: CreateMedicalOrganizationFormValue) => void;
}

export const CreateMedicalOrganizationFormComponent = ({
  isEditing,
  onSubmit,
  ...props
}: CreateMedicalOrganizationFormComponentProps) => {
  const hook = useCreateMedicalOrganizationForm();
  return (
    <div className="flex gap2 fd-c CreateMedicalOrganizationFormComponent h100">
      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        layout="vertical"
        className={classNames('h100 w100', props.className)}
        form={hook.form}
        disabled={props.isLoading}
        onFinish={hook.handleSubmitAndReset(onSubmit)}
      >
        <FixedComponent className="w100">
          <FixedComponent.Child>
            <div className="flex fd-c gap2">
              <FormItem
                info={hook.getInfo('type')}
                required
              >
                <OrganizationTypeInputComponent disabled={isEditing} />
              </FormItem>
              <Row>
                <Col span={22}>
                  <FormItem
                    required
                    info={hook.getInfo('businessName')}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span={1} className="flex ai-c ml15">
                  <Tooltip
                    className="mt20"
                    placement="top"
                    title="The official full name of the organization,
              or the name under which we sign the
              contract (e.g., Primaire Medical Clinic,
              Dr. Victor Jose Meceda)."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
              <FormItem dependencies={['businessName']} noStyle>
                {({ isFieldTouched }) => {
                  const isTouched = isFieldTouched('businessName');
                  return isTouched && (
                    <Row className="mb10">
                      <Col span={22}>
                        <span>
                          <strong>Note:</strong>
                          {' '}
                          The business name will be displayed on the patient Consent Form.
                          Please ensure its accuracy.
                        </span>
                      </Col>
                    </Row>
                  );
                }}
              </FormItem>
              <Row>
                <Col span={3} className="mr20">
                  <FormItem
                    info={hook.getInfo('orgNumber')}
                  >
                    <InputNumber placeholder="No." max={9999} />
                  </FormItem>
                </Col>
                <Col span={18} className="ml5">
                  <FormItem
                    info={hook.getInfo('alias')}
                  >
                    <Input placeholder="State/City - Alias" />
                  </FormItem>
                </Col>
                <Col span={1} className="flex ai-c ml15">
                  <Tooltip
                    className="mt20"
                    placement="top"
                    title="An alias refers to the display name utilized
              within both the Care Portal, Admin console
              and other platforms, in adherence to the
              Internal Care Team Naming Guidelines."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col span={22}>
                  <FormItem
                    info={hook.getInfo('description')}
                  >
                    <Input />
                  </FormItem>
                </Col>

              </Row>
              <Row>
                <Col span={22}>

                  <FormItem
                    info={hook.getInfo('phone')}
                  >
                    <PhoneInput />
                  </FormItem>
                </Col>
              </Row>

              <Divider />

              <Form.List name="addresses">
                {
                (fields, { add, remove }) => (
                  <div className="flex gap4 fd-c mb30">
                    {fields.map(({ key, name, ...restField }) => (
                      <AddressComponent
                        key={key}
                        name={name}
                        label={`Address ${name + 1}`}
                        onRemove={() => remove(name)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...restField}
                      />
                    ))}
                    <Row justify="end">
                      <Col pull={2}>
                        <Button
                          type="link"
                          onClick={() => add()}
                          className="w100 b5 fs16"
                        >
                          + Add location
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )
              }

              </Form.List>
              <Row>
                <Col span={22}>
                  <FormItem
                    info={hook.getInfo('timezone')}
                    required
                  >
                    <TimezoneSelectComponent />
                  </FormItem>
                </Col>
              </Row>
            </div>
          </FixedComponent.Child>
          <FixedComponent.Child isFixed className="border-t">
            <Button type="primary" className="w100 b5 fs16" htmlType="submit">{isEditing ? 'Save' : 'Create'}</Button>
          </FixedComponent.Child>
        </FixedComponent>
      </Form>
    </div>
  );
};
